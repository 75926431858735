/* eslint-disable max-len */
import privacy_policy from "../../assets/images/privacy_policy.png";

export default {
  "header": "Privacy Policy",
  "img_src": privacy_policy,
  "img_alt_txt": "phactMI - Privacy Policy",
  "sections": [
    {
      content: [
        {
          content: "PhaCT-MI, Inc. (“PhaCT-MI”) developed this Privacy Policy to tell you about our practices regarding collection, use, and disclosure of information that you may provide via this website or that we acquire from your use of the site. Your access to and use of the website constitutes acceptance of this Privacy Policy and consent to PhaCT-MI’s collection and use of your information as outlined below."
        },
        {
          content: "From time to time, our internal processes may change, or we may offer new or altered features on the website. If appropriate, we will revise this Privacy Policy. We encourage you to return to this area to read the most recent version of our Privacy Policy. Your use of the website following these changes means that you accept the revised Privacy Policy. This policy was last updated on June 29, 2015."
        },
        {
          content: "This Privacy Policy applies solely and exclusively to this website."
        }
      ]
    },
    {
      title: "INFORMATION COLLECTION AND USE",
      content: [
        {
          title: "Information You Provide Voluntarily",
          content: "In general, you may browse the website without providing any personal information. However, to access certain options and services we may ask you to provide certain personal information and without providing such personal information, you may be unable to access certain options and services (such as Submit a Medical Inquiry or any surveys). You may always choose not to provide us with your personal information, and we will disclose to you at the time we collect your personal information whether it is required for you to receive the information or services you have requested. We may combine the information you submit with other information we have collected from you."
        },
        {
          title: "Information Collected Passively ",
          content: `<p>As you navigate through the website, certain information can be passively collected (that is, gathered without you actively providing the information) using various technologies and means. </p>
            <p>We record the Internet Protocol ("IP ") address of your computer when you visit our site. Your IP Address is a number that is automatically assigned to the computer that you are using by your Internet Service Provider. Collecting IP Addresses is standard practice on the Internet and is done automatically by many web sites. We use IP Addresses for purposes such as calculating website usage levels, helping diagnose server problems, and administering the website.</p>
            <p>Certain information is collected by most browsers, such as your Internet browser type and version, Media Access Control (MAC) address, computer operating system and version, and screen resolution. We may collect similar information, such as your device type and unique device identifier, if you access the website through a mobile device, such as a smartphone or tablet computer.</p>
            <p>Pixel tags, web beacons, clear GIFs, or other similar technologies may be used in connection with some website pages and HTML-formatted e-mail messages to, among other things, track the actions of website users and e-mail recipients, measure the success of our marketing campaigns, and compile statistics about website usage and response rates.</p>
            <p>Our website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to analyze use patterns and may collect information about your use of the website, including your IP address. More information on Google Analytics can be found here: <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/.</a></p>`
        },
        {
          title: "How We Use and Disclose Information",
          content: `<p>PhaCT-MI will use your information to provide information and services to you, including information and services you have requested, or as otherwise disclosed to you in this Privacy Policy or on the web page where you submit your information to us. </p>
            <p>PhaCT-MI or its member companies may use your information to contact you for customer satisfaction inquiries or to seek additional permissions for use of the information you have provided.</p>
            <p>PhaCT-MI will disclose information collected through the website to its member companies who respond to medical inquiries submitted through the website for their respective products in order to enable them to respond to submitted medical inquiries.</p>
            <p>PhaCT-MI will disclose aggregate information collected through the website to its member companies and its service providers who collaborate with PhaCT-MI for business purposes such as data analysis, developing new products, enhancing our website, improving PhaCT-MI’s products and services, identifying website usage trends, determining the effectiveness of our promotional campaigns, and determining the benefit of the website to PhaCT-MI’s member companies. </p>
            <p>PhaCT-MI will disclose information collected through the website to our third party service providers who provide services such as website hosting, mobile application hosting, data analysis, order fulfillment, infrastructure provision, IT services, customer service, e-mail and direct mail delivery services, and other services, in order to enable them to provide services.<p>
            <p>Aggregate information about website visitors that does not contain personal information may be shared with other third parties.<p>
            <p>PhaCT-MI will also disclose identifiable information if we reasonably believe we are required to do so by law, regulation, legal process, or other government authority (such as reporting safety information to the Food and Drug Administration); to investigate fraud; to protect your safety or the safety of others; or when we have reason to believe that disclosing this information is necessary to protect our rights or the rights of a third party who may be harmed by your activities.</p>
            <p>We do not share any of your personal information with third parties for their own direct marketing purposes.</p>`
        }
      ]
    },
    {
      title: "DO-NOT-TRACK SIGNALS AND SIMILAR MECHANISMS",
      content: [{
        content: "Some browsers may transmit “do-not-track” signals to websites with which the browser communicates. As of the last update of this Privacy Policy, an industry standard has not yet been established on how to respond to these signals. Therefore, PhaCT-MI does not currently respond to these signals."
      }]
    },
    {
      title: "THIRD PARTY SITES AND SERVICES",
      content: [
        {
          content: "Some of our websites may contain links to other websites on the Internet that are not under the control of or maintained by PhaCT-MI. Such links do not constitute an endorsement by PhaCT-MI of those other websites, the content displayed therein, or the persons or entities associated therewith. You acknowledge that PhaCT-MI is providing these links to you only as a convenience, and you agree that PhaCT-MI is not responsible for the content of such websites. Your use of these other linked websites is subject to the respective terms of use and privacy policies located on the linked websites for which we have no control."
        }]
    },
    {
      title: "USE OF SITE BY MINORS",
      content: [
        {
          content: "This website is not intended or designed to attract individuals under the age of 18. We do not knowingly collect personally identifiable data from website visitors under the age of 18 and we request that these individuals do not provide personal information through the website."
        }]
    },
    {
      title: "TRANSFER OF DATA TO OTHER COUNTRIES",
      content: [
        {
          content: "Any information you provide to PhaCT-MI through use of the website may be stored and processed, transferred between and accessed from the United States and other countries which may not guarantee the same level of protection of personal information as the one in which you reside. However, PhaCT-MI will handle your personal information in accordance with this Privacy Policy regardless of where your personal information is stored or accessed from. "
        }
      ]
    },
    {
      title: "CONTACTING US",
      subHeader: true,
      content: [
        {
          content: "If you have any questions about this Privacy Policy, please contact us by visiting our <span class=\"contact-us-link\">Contact Us</span> page."
        }
      ]
    }
  ]
}

