import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const TableContainer = styled.div`
`;

export const Row = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding: ${props => props.cols > 4 ? "0 14%" : "0 10%"};
    @media ${mobileRange} {
        padding: 0;
    }
    @media ${tabletRange} {
        padding: 0 10%;
    }
`;

export const Col = styled.div`
    width: ${props => props.count > 4 ? "33%" : "25%"};
    margin-bottom : 65px;
    text-align: center;
    @media ${tabletRange} {
        margin-bottom: 48px;
    }
    @media ${mobileRange} {
        width: 50%;
        border: none;
        margin-bottom : 30px;
    }
    img {
        width: 150px;
        @media ${mobileRange} {
            width: 120px;
        }
        @media ${tabletRange} {
            width: 120px;
        }
    }
`;

export const Title = styled.a`
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.78;
    letter-spacing: -0.23px;
    text-align: center;
    text-decoration: none;
    color: ${colors.bondi_blue} !important;
    @media ${tabletRange} {
        font-size: 17px;
        line-height: 1.59;
        letter-spacing: -0.22px;
    }
    @media ${mobileRange} {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.21px;
        font-weight: 700;
    }
`;

export const Designation = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.23px;
    letter-spacing: -0.17px;
    text-align: center;
    color: ${colors.cornflower_blue};
    @media ${tabletRange} {
        font-size: 13px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: -0.17px;
    }
    @media ${mobileRange} {
        font-size: 14px;
        line-height: 1.75;
        letter-spacing: -0.16px;
    }
`;

export const Company = styled.div`
    opacity: 0.6;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.78;
    letter-spacing: -0.23px;
    text-align: center;
    color: ${colors.dove_gray};

    @media ${tabletRange} {
        font-size: 13px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: -0.17px;
    }

    @media ${mobileRange} {
        font-size: 12px;
        line-height: 1.75;
        letter-spacing: -0.16px;
    }
`;

export const ContentWrapper = styled.div`
    border-right: ${props => props.noborder ? "none" : `1px solid ${colors.geyser}`};
    margin-top: 25px;

    @media ${mobileRange} {
        border-right: 0;
    }
`;