import axios from "axios";
import {endpoint} from "../../config/app.config";

export const getCareer = () => {
  return axios
    .get(`${endpoint}/api/career`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
