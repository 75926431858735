import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const Header = styled.h2`
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: -0.33px;
    text-align: center;
    color: ${colors.Scorpion};
    padding: 40px 0 50px 0;
    margin-bottom: 0;
    @media ${tabletRange} {
        padding: 34px 0 30px 0;
    }
    @media ${mobileRange} {
        padding: 30px 0 30px 0;
        font-weight: 700;
    }
`;

export const Description = styled.div`
    margin-bottom: 55px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.21px;
    display: flex;
    justify-content: center;
    color: ${colors.cornflower_blue};
    div {
        width: 22%;
        text-align: center
    }
`;

export const MembersDirectorsContainer = styled.div`
    background: ${colors.catskill_gray};
`;