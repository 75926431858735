import React from "react";
import { endpoint } from "../../config/app.config";
import * as styled from "./styles";

const CorporatePartnership = (props) => {

  const logoClick = (url) => {
    window.open(`/redirect?url=${url}`);
  }

  const displayLogo = () => {
    const { data } = props;
    if (data) {
      return (
        <styled.CorporateLogoWrapper>
          {
            data.map((element, index) => {
              return (
                <div className="asp_logo" key={index}>
                  <img src={`${endpoint}/api/assets/images?id=${element.imageId}`} alt={element.imageAltText} onClick={() => logoClick(element.url)}></img>
                </div>
              )
            })
          }
        </styled.CorporateLogoWrapper>
      )
    }
  }

  return (
    <styled.MainContainer>
      <styled.BadgeLogoWrapper>
        <styled.BadgeLogo>
          <img src={props.badgelogo} alt={props.badge_logo_alt_txt}></img>
        </styled.BadgeLogo>
      </styled.BadgeLogoWrapper>
      <styled.Title>{props.heading}</styled.Title>
      <styled.Description>{props.description}</styled.Description>
      {displayLogo()}
    </styled.MainContainer>
  )
}

export default React.memo(CorporatePartnership);