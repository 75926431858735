import styled from "styled-components";
import { colors } from "../../../common/styles/variables";
import { tabletRange, ipadProRange, mobileRange } from "../../../common/styles/breakpoints";

export const ContactDetails = styled.div`
    margin-top: 20px;
    width: 100%;
    display: inline-block;
    @media ${mobileRange} {
        text-align: center;
        margin-bottom: 30px;
    }
    @media ${tabletRange} {
        display: flex;
        flex-wrap: wrap;
        margin-top: ${props => props.displaylogo ? "20px" : "40px"};
    }
    @media ${ipadProRange} {
        display: flex;
        flex-wrap: wrap;
        margin-top: ${props => props.displaylogo ? "20px" : "40px"};
    }
`;

export const ContactHeading = styled.h2`
    font-size: 22px;
    color: ${colors.sherpa_blue};
    @media ${mobileRange} {
        text-align: left;
    }
    @media ${tabletRange} {
        width: 100%;
    }
    @media ${ipadProRange} {
        width: 100%;
    }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Contact = styled.div`
    width: 230px;
    display: flex;
    margin: 9px 20px 9px 0;
    border: 1px solid #D5DDE2;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 109px;
    padding: 5px;
    @media ${tabletRange} {
        width: 295px;
    }
    @media ${ipadProRange} {
        width: 270px;
    }
    @media ${mobileRange} {
        width: 100%;
        margin: 15px 2px 10px;
        padding: 0;
    }
`;

export const ContactInner = styled.div`
    width: 100%;
    & a {
        text-decoration: none;
    }
`;

export const Label = styled.div`
    font-size: 15px;
    color: ${colors.nav_blue};
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    white-space: normal;
    @media ${mobileRange} {
        text-overflow: clip;
        -ms-text-overflow: clip;
        white-space: normal;
    }
    @media ${tabletRange} {
        white-space: normal;
    }
    @media ${ipadProRange} {
        white-space: normal;
    }
`;

export const DisplayLink = styled.div`
    font-size: 15px;
    color: ${colors.bondi_blue};
    white-space: normal; 
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    @media ${mobileRange} {
        white-space: normal;
        padding: 0 5px;
    }
    @media ${tabletRange} {
        white-space: normal;
    }
    @media ${ipadProRange} {
        white-space: normal;
    }
`;

export const DisplayText = styled.div`
    font-size: 15px;
    color: ${colors.dove_gray};
    white-space: normal; 
    overflow: hidden;
    text-overflow: ellipsis;
    @media ${mobileRange} {
        text-overflow: clip;
        -ms-text-overflow: clip;
        white-space: normal;
    }
    @media ${tabletRange} {
        white-space: normal;
    }
    @media ${ipadProRange} {
        white-space: normal;
    }
    a {
        color: ${colors.bondi_blue};
    }
`;

export const PhoneLink = styled.div`
    font-size: 15px;
    color: ${colors.dove_gray};
    a {
        color: ${colors.bondi_blue};
    }
`;
