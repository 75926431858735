import React from "react";
import * as styled from "./styles";
import BlackImage from "../../assets/tour-images/black_img.jpeg";

export const TabBar = (props) => {
  const { isLoading, showPiResults } = props;
  return (
    <React.Fragment>
      <styled.TabContainer isLoading={isLoading} showPiResults={showPiResults}>
        { props.children }
      </styled.TabContainer>
    </React.Fragment>
  );
}

export const Tab = ({ children, isActive }) => {
  return (
    <styled.Tab isActive={isActive}>
      {children}
    </styled.Tab>
  );
}

export const TabTitleContainer = ({ children, setActiveTab, index, activeIndex, showTour, changeZIndex, count }) => {
  return (
    <styled.TabTitleContainer
      id={index == 0 ? "pi-tab" : "srd-tab"}
      onClick={(e) => setActiveTab(e, index, count)}
      className={activeIndex === index ? "active-tab" : "inactive-tab"}>
      { children }
    </styled.TabTitleContainer>
  );
}

export const TabTitle = (props) => {
  return (
    <div className="tab-title">
      { props.children }
    </div>
  );
}

export const TabDetails = (props) => {
  return (
    <styled.TabDetails>
      { props.children }
    </styled.TabDetails>
  );
}

export const TabHeader = (props) => {
  return (
    <styled.TabHeader className="tabsd">
      { props.children }
    </styled.TabHeader>
  );
}
