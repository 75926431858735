import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { minLaptopL, mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  min-height: 680px;
  justify-content: center;
  align-items: center;
  color: ${colors.sherpa_blue};
  background: ${colors.white};
  @media ${mobileRange} {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
  }
  @media ${tabletRange} {
    display: flex;
    flex-direction: column;
  }
  @media ${ipadProRange} {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
  }
  @media ${minLaptopL} {
    min-height: 800px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 680px;
  }
`;

export const imageWrapper = styled.div`
  width: 50%;
  text-align: right;
  margin-right: 70px;
  img {
    height: 250px;
    @media ${mobileRange} {
      height: 150px;
    }
    @media ${tabletRange} {
      height: 150px;
    }
    @media ${ipadProRange} {
      height: 150px;
    }
  }
  @media ${mobileRange} {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  @media ${tabletRange} {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  @media ${ipadProRange} {
    width: 100%;
    text-align: center;
    margin-right: 0;  
  }
`;

export const TextWrapper = styled.div`
  width: 50%;
  @media ${mobileRange} {
    width: 100%;
    text-align: center;
  }
  @media ${tabletRange} {
    width: 100%;
    text-align: center;
  }
  @media ${ipadProRange} {
    width: 100%;
    text-align: center;
  }
  h1 {
    font-size: 21px;
    font-weight: 300;
    @media ${mobileRange} {
      font-size: 16px;
    }
    @media ${tabletRange} {
      font-size: 18px;
    }
    @media ${ipadProRange} {
      font-size: 18px;
    }
    span {
      font-size: 110px;
      color: ${colors.violet};
      font-weight: 900;
      @media ${mobileRange} {
        font-size: 70px;
      }
      @media ${tabletRange} {
        font-size: 70px;
      }
      @media ${ipadProRange} {
        font-size: 70px;
      }
    }
  }
`;

export const ReturnButton = styled.button`
  background: ${colors.white};
  padding: 14px 32px;
  margin-top: 40px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${colors.sherpa_blue};
  border-radius: 3px;
  border: solid 1px ${colors.sherpa_blue};
  @media ${mobileRange} {
    font-size: 16px;
    padding: 14px 29px;
  }
  @media ${tabletRange} {
    font-size: 16px;
    padding: 14px 29px;
  }
  @media ${ipadProRange} {
    font-size: 16px;
    padding: 14px 29px;
  }
`;
