import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL } from "../../common/styles/breakpoints";
import save from "../../assets/images/save.png";

export const Container = styled.div`
    padding: ${props => props.showDownload ? "0 16% 0 17%" : "0"};
    display: flex;
    background: ${colors.white};
    @media ${mobileRange} {
        padding: ${props => props.showDownload ? "10px 20px" : "10px 5%"};
        flex-flow: column;
    }
    @media ${tabletRange} {
        padding: ${props => props.showDownload ? "0 34px 0 20px" : "15px 35px"};
    }
    @media ${ipadProRange} {
        padding: ${props => props.showDownload ? "0 7% " : "0"};
    }
    @media ${minLaptop} {
        padding: ${props => props.showDownload ? "0 4% 0 3%" : "0"};
    }
    @media ${minLaptopL} {
        padding: ${props => props.showDownload ? "0 16% 0 17%" : "0"};
    }
`;

export const BreadCrumbTitle = styled.span`
  font-size: 17px;
  line-height: 2.65;
  letter-spacing: -0.22px;
  white-space: nowrap;
  color: ${colors.bondi_blue};
  cursor: pointer;
  display: ${props => props.fromSearchpage ? "flex" : "block"};
  &.drug {
    cursor: initial;
    color: ${colors.dove_gray}
  }
  @media ${tabletRange} {
    font-size: 17px;
    line-height: 2.69;
    letter-spacing: -0.17px;
  }
  @media ${mobileRange} {
      line-height: 1.5;
  }
  .registered_trademark {
      font-size: 75%;
  }
`;

export const BreadCrumbSeparator = styled.span`
    font-size: 17px;
    line-height: 2.65;
    letter-spacing: -0.22px;
    color: ${colors.dove_darkgray};
    padding: 0 10px 0 10px;
    @media ${tabletRange} {
        font-size: 17px;
        line-height: 2.69;
        letter-spacing: -0.17px;
    }
    @media ${mobileRange} {
        line-height: 1.5;
    }
`;

export const BreadCrumbContainer = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    @media ${mobileRange} {
        width: 100%;
    }
`;

export const DownloadContainer = styled.span`
    width: 25%;
    display: flex;
    justify-content: flex-end;
    color: ${colors.bondi_blue}
    cursor: pointer;
    font-size: 17px;
    align-items: center;
    @media ${tabletRange} {
        font-size: 17px;
        line-height: 2.06;
        letter-spacing: -0.22px;
    }

    @media ${mobileRange} {
        display: none;
    }
    
    img {
        width: 16px;
        height: 16px;
    }
`;

export const DownloadButton = styled.div`
    padding-right: 7px;
    @media ${tabletRange} {
        padding-top: 0;
    }
`;

export const Tooltip = styled.span`
    position: relative;
    cursor: pointer;

    &:hover span{
        visibility: visible
        
    }
`

export const TooltipText = styled.span`
    font-size: 14px;
    visibility: hidden;
    background-color: ${colors.aqua_haze};
    border: solid 1px ${colors.sherpa_blue};
    color: ${colors.tundora_approx};
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 130%;
    left: -1600%;
    line-height: 1.4;
    white-space: normal;

    ::after {
        content: "";
        position: absolute;
        bottom: 99%;
        right: 24px;
        margin-left: -4px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.aqua_haze} transparent;
        @media ${mobileRange} {
          right: 7%;
        }
      }
    
      ::before {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 24px;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.sherpa_blue} transparent;
        @media ${mobileRange} {
          right: 7%;
        }
      }

    @media (max-width:425px) {
        left: -1300%;
        width: 150px;
        padding-left:8px;
        padding-right:8px;
    }

    @media (min-width: 768px){
        top: 100%
    }
`