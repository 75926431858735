import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const Content = styled.div`
width: 450px;
font-size: 15px;
line-height: 1.7;
color: ${colors.dove_darkgray};
ul {
  padding: 0 0 0 18px;
}
a {
  font-size: 16px;
  line-height: 1.22;
  letter-spacing: -0.23px;
  color: ${colors.bondi_blue};
  padding: 0 0 0 18px;
  &:hover {
      color: ${colors.bondi_blue};
  }
}
@media ${mobileRange} {
  width: 100%;
  font-size: 14px;
  line-height: 1.81;
  letter-spacing: -0.21px;
  padding-bottom: 30px;
  a {
    font-size: 14px;
    line-height: 1.22;
    letter-spacing: -0.23px;
  }
}
@media ${tabletRange} {
  width: 374px;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.21px;
  ul {
    font-size: 18px;
    margin: 0;
  }
  a {
    font-size: 18px;
    display: inline-block;
    margin-top: 14px;
    line-height: 1.22;
    letter-spacing: -0.23px;
  }
}
`;
export const ListHeading = styled.div`
  margin: 15px 0 10px;
  @media ${mobileRange} {
    font-size: 14px;
    line-height: 1.61;
    letter-spacing: -0.23px;
  }
  @media ${tabletRange} {
    font-size: 16px;
  }
`;