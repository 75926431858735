/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import ErrorBoundary from "./containers/error-boundary";
import Footer from "./components/footer";
import BackToTop from "./components/back-to-top";
import Header from "./components/Header/index";
import Home from "./containers/home";
import About from "./containers/about";
import News from "./containers/news";
import Careers from "./containers/careers";
import NewSearch from "./containers/search/new-search-index";
import Manufacturers from "./containers/manufacturers";
import TermsConditions from "./containers/terms-conditions";
import PrivacyPolicy from "./containers/privacy-policy";
import PageNotFound from "./containers/page-not-found";
import PageContainer from "./containers/page-container";
import store from "./store/store";
import { GlobalStyles } from "./common/styles/globalStyles";
import CodeOfPractice from "./containers/code-of-practice";
import RedirectNote from "./containers/redirect-note";
import SrdViewer from "./containers/srd-viewer";
import resources from "./config/content/searchPage";
import MemberPage from "./components/member-page";
import Covid from "./containers/covid-19";
import OurWhy from "./components/our-why";
import Fellowship from "./containers/fellowship";
import DisplayNewsAndPublication from "./components/newspage";
import Publication from "./containers/publication";
import Podcast from "./containers/podcast";
import MemberContactInfo from "./containers/member-company-info";
import { Amplify, Auth } from "aws-amplify";
import * as awsCognitoConfig from "./config/aws-cognito.config"

const history = createBrowserHistory();
// eslint-disable-next-line no-empty-function
const historyListner = (location) => {};
history.listen(historyListner);
historyListner(window.location);

const MaintenanceApp = (props) => {
  Amplify.configure({
    Auth: {
      region: awsCognitoConfig.awsRegion,
      userPoolId: awsCognitoConfig.userPoolId,
      userPoolWebClientId: awsCognitoConfig.userPoolWebClientId,
      mandatorySignIn: true,
      authenticationFlowType: "USER_PASSWORD_AUTH",
      oauth: {
        domain: awsCognitoConfig.awsCognitoDomain,
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://maintenance.phactmi.org",
        responseType: "code"
      }
    }
  });

  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get("code");
  if (code) {
    localStorage.setItem("cognitoToken", code)
    const url = window.location.href;
    const newUrl = url.replace(/[?&]code=.*?(?=&|$)/, "");
    window.history.replaceState({}, "", newUrl);
  }

  return (
    <>
    {!localStorage.getItem("cognitoToken") ? window.location.assign(awsCognitoConfig.awsCognitoHostedUI):
      <Provider store={store}>
        <Router history={history}>
          <PageContainer {...props}>
            <GlobalStyles />
            <Header />
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" render={(props) => <Home {...props} />} />
                <Route exact path="/home" render={(props) => <Home {...props} />} />
                <Route exact path="/about" component={About} />
                <Route exact path="/about/:repo" component={About} />
                <Route exact path="/news" component={News} />
                <Route
                  exact
                  path="/news/:repo"
                  component={DisplayNewsAndPublication}
                />
                {/* <Route exact path="/careers" component={Careers} /> */}
                <Route exact path="/search" render={(props) => <NewSearch {...props} />} />
                <Route exact path="/members" component={Manufacturers} />
                <Route exact path="/members/:repo" component={MemberPage} />
                <Route
                  exact
                  path="/corporate-partner/:repo"
                  render={(props) => (
                    <MemberPage fromcorporatePartner={true} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/terms-conditions"
                  component={TermsConditions}
                />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route
                  exact
                  path="/code-of-practice"
                  component={CodeOfPractice}
                />
                <Route
                  exact
                  path="/redirect"
                  render={(props) => <RedirectNote seconds={15} {...props} />}
                />
                <Route path="/viewer" component={SrdViewer} />
                {/* <Route exact path="/covid-19" component={Covid} /> */}
                <Route exact path="/our-why" component={OurWhy} />
                <Route exact path="/fellowship" component={Fellowship}></Route>
                {/* <Route exact path="/publications" render={props => <News fromPublication={true} {...props}></News>}></Route> */}
                <Route exact path="/publications" component={Publication}></Route>
                <Route
                  exact
                  path="/publications/:repo"
                  render={(props) => (
                    <DisplayNewsAndPublication
                      fromPublication={true}
                      {...props}
                    />
                  )}
                ></Route>
                {/* <Route exact path="/podcast" component={Podcast}></Route> */}
                <Route
                  exact
                  path="/membercompanyinformation"
                  component={MemberContactInfo}
                ></Route>
                <Route component={PageNotFound} />
              </Switch>
            </ErrorBoundary>
            <Footer />
            <BackToTop />
          </PageContainer>
        </Router>
      </Provider>}
    </>
  );
};

export default MaintenanceApp;