import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import _size from "lodash/size";
import { endpoint } from "../../config/app.config";
import store from "../../store/store";
import { GET_MANUFACTURER_DETAIL, GET_MANUFACTURERS_DETAILS, GET_CORPORATE_PARTNER } from "../../containers/manufacturers/actions";
import withTransition from "../../components/with-transition";
import ManufacturerLoader from "../manufacturers/manufacturer-loader";
import ContactList from "../manufacturers/contact/contact";
import LeagalMessage from "../manufacturers/leagal-message/leagal-message";
import MemberImageList from "../member-slider/index";
import ErrorPopUp from "../errorPopUp/index";
import resources from "../../config/content/manufacturersPage";
import * as styled from "./styles";

const mapStateToProps = state => {
  return {
    manufacturersDetails: state.aboutReducer.manufacturers,
    manufacturersListLoader: state.aboutReducer.loading,
    manufacturerDetail: state.manufacturerReducer.manufacturerDetail,
    manufacturerLoader: state.manufacturerReducer.loading,
    error: state.manufacturerReducer.error,
    corporatePartner: state.manufacturerReducer.corporatePartner
  };
};

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyId: null,
      redirectLink: null,
      selectedCompanyName: ""
    }
  }

  componentDidMount() {
    const { fromcorporatePartner, corporatePartner } = this.props;
    if (fromcorporatePartner) {
      this.setState({
        redirectLink: "members"
      })
      try {
        const location = window.location.pathname.split("/");
        const [, , companyName] = location;
        this.setState({
          selectedCompanyName: companyName
        })
        if (companyName) {
          store.dispatch({
            type: GET_CORPORATE_PARTNER,
            payload: {
              companyName: companyName
            }
          })
        }
      }
      catch (e) {
        // Error Occured
      }
    }
    else {
      try {
        const location = window.location.pathname.split("/");
        const [, , companyName] = location;
        this.setState({
          selectedCompanyName: companyName
        })
        store.dispatch({
          type: GET_MANUFACTURER_DETAIL,
          payload: {
            companyName: companyName.toLowerCase()
          }
        });
      }
      catch (e) {
        //error occured
      }
      if (_size(this.props.manufacturersDetails) === 0) {
        store.dispatch({
          type: GET_MANUFACTURERS_DETAILS
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { alinkplaceholder } = resources;
    const { manufacturerDetail, location, fromcorporatePartner } = this.props;
    const { selectedCompanyName } = this.state;
    const URLlocation = window.location.pathname.split("/");
    const [, , companyName] = URLlocation;
    if (!fromcorporatePartner) {
      if (companyName !== selectedCompanyName) {
        this.setState({
          selectedCompanyName: companyName
        })
        store.dispatch({
          type: GET_MANUFACTURER_DETAIL,
          payload: {
            companyName: companyName.toLowerCase()
          }
        });
        window.scrollTo(0, 0);
      }
    }
    if (prevProps.manufacturerDetail !== manufacturerDetail) {
      if (!fromcorporatePartner) {
        this.setState({
          redirectLink: _get(location.state, "_from")
        })
      }
    }
    const imgTags = document.querySelectorAll(".disclaimer img");
    if (imgTags) {
      imgTags.forEach((imgTag) => {
        let imgSource = imgTag.getAttribute("src");
        if (imgSource.includes(alinkplaceholder) && imgSource.split(alinkplaceholder).length > 0) {
          imgSource = endpoint + imgSource.split(alinkplaceholder)[1];
        }
        imgTag.setAttribute("src", imgSource);
      })
    }
  }

  /**
   * Add Prefix to anchor function
   * @param  {string} data data.
   * @return {string} description description.
   */
  modifyhref = (data) => {
    const prefix = "/redirect?url=";
    let description = data.split("href=\"");
    description = description.map((data, index) => (index ? data = prefix + data : data));
    description = description.join("href=\"");
    return description;
  }

  /**
   * handle anchor click function
   * @param  {object} event event.
   * @return {undefined}.
   */
  handleHrefClick = (event) => {
    if (event.target.href) {
      event.preventDefault();
      window.open(event.target.href)
    }
  }

  displayContent = () => {
    const { manufacturerDetail, fromcorporatePartner, corporatePartner } = this.props;
    const { redirectLink } = this.state;
    const data = fromcorporatePartner ? _get(corporatePartner, "data") : _get(manufacturerDetail, "data");
    const { alt_txt_placeholder } = resources;
    if (data) {
      const description = this.modifyhref(data.description);
      return (
        <React.Fragment>
          {redirectLink && <styled.BreadCrumb>{this.makeBreadCrumb(redirectLink, data.companyName)}</styled.BreadCrumb>}
          <styled.InfoWrapper>
            <styled.Description dangerouslySetInnerHTML={{ __html: description }} onClick={this.handleHrefClick}></styled.Description>
            {
              data.logoAboveContactSection ?
                <styled.LogoWrapper>
                  <img src={`${endpoint}/api/assets/images?id=${data.logoAboveContactSectionUrl}`} alt={`${alt_txt_placeholder}${data.companyName}`}
                    onClick={() => this.logoClick(data.companyName, data.imageUrl)}></img>
                </styled.LogoWrapper>
                : null
            }
            <styled.ContactListWrapper>
              <ContactList contactInfo={data.contactInfo} companyName={data.companyName} label={data.contactLabel}
                companyLogo={data.companyLogo} displaylogo={data.logoAboveContactSection ? true : false}></ContactList>
            </styled.ContactListWrapper>
            <div className="disclaimer">
              <LeagalMessage data={data}></LeagalMessage>
            </div>
          </styled.InfoWrapper>
        </React.Fragment>
      )
    }
    else {
      return null;
    }
  }

  displayManufactureList = () => {
    const { manufacturersDetails } = this.props;
    const { mobile_manufacturers_txt, alt_txt_placeholder } = resources;
    return (
      <MemberImageList {...manufacturersDetails} heading={mobile_manufacturers_txt} alt_txt_placeholder={alt_txt_placeholder}></MemberImageList>
    )
  }

  displayLogo = () => {
    const { manufacturerDetail, fromcorporatePartner, corporatePartner } = this.props;
    const { alt_txt_placeholder } = resources;
    const data = fromcorporatePartner ? _get(corporatePartner, "data") : _get(manufacturerDetail, "data");
    if (data) {
      return (
        <styled.CompanyLogoWrapper>
          <img src={`${endpoint}/api/assets/images?id=${data.companyLogo}`} alt={`${alt_txt_placeholder}${data.companyName}`}/>
        </styled.CompanyLogoWrapper>
      )
    }
  }

  logoClick = (companyName, imageUrl) => {
    if (companyName.includes("Bayer") && imageUrl) {
      window.open(`/redirect?url=${imageUrl}`);
    }
  }

  makeBreadCrumb = (redirectLink, selectedCompanyName) => {
    const { manufacturers_txt, corporate_partner_txt } = resources;
    const { fromcorporatePartner } = this.props;
    return (
      <React.Fragment>
        <styled.LINK> <span onClick={() => this.breadcrumbClick(redirectLink)}> {redirectLink === "members" ?
          fromcorporatePartner ? corporate_partner_txt : manufacturers_txt : redirectLink}
        </span> <span>></span> {selectedCompanyName}</styled.LINK>
      </React.Fragment>
    );
  };

  breadcrumbClick = (redirectLink) => {
    const { history } = this.props;
    history.push(`/${redirectLink}`);
  }

  setTitleMetatag = () => {
    const { manufacturerDetail, fromcorporatePartner, corporatePartner } = this.props;
    const { metaTitle: {title_placeholder, meta_prefix, meta_suffix} } = resources;
    const data = fromcorporatePartner ? _get(corporatePartner, "data") : _get(manufacturerDetail, "data");
    const companyName = data ? data.companyName ? data.companyName : "" : "";
    return (
      <Helmet>
        <title>{`${companyName}${title_placeholder}`}</title>
        <meta name="description" content={`${meta_prefix}${companyName}${meta_suffix}`}></meta>
      </Helmet>
    )
  }

  render() {
    const { manufacturerLoader, error } = this.props;
    const { generalError } = resources;
    if (!error) {
      return (
        <styled.MemberPage>
          <Container className="container">
            {!manufacturerLoader && this.displayLogo()}
            <styled.MainSection>
              {manufacturerLoader && <ManufacturerLoader></ManufacturerLoader>}
              {!manufacturerLoader && this.displayContent()}
              {
                !manufacturerLoader && !error &&
                <styled.ManufactureListWrapper>
                  {this.displayManufactureList()}
                </styled.ManufactureListWrapper>
              }
            </styled.MainSection>
          </Container>
          {this.setTitleMetatag()}
        </styled.MemberPage>
      );
    }
    else {
      return (
        <React.Fragment>
          <ErrorPopUp resources={generalError}></ErrorPopUp>
        </React.Fragment>
      )
    }
  }
}

export default connect(
  mapStateToProps
)(withTransition(Member));
