import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import NoResultsFound from "../../components/no-results-found";
import SearchLoader from "../../components/search-loader";
import CareerConstants from "../../config/content/careerPage";
import withTransition from "../../components/with-transition";
import * as actions from "./actions";
import * as styled from "./style";

const mapStateToProps = state => {
  return {
    careers: state.careerReducer.careers,
    isLoading: state.careerReducer.loading
  };
};

/**
 * Career page container
 *
 * @class Career
 * @extends {Component}
 * @exports Career
 */
class Careers extends Component {

  componentDidMount() {
    const { careers, getCareer } = this.props;
    if (!careers) getCareer();
    window.addEventListener("resize", this.scrollToTop);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.scrollToTop);
  }

  /**
   * Method used to scroll to top of windo on resize
   * @returns {undefined}
   */
  scrollToTop() {
    window.scrollTo({
      top:0,
      behavior: "smooth"
    });
  }

  /**
   * Method is used to render single opening.
   * @param  {Object} positions Details of the openings.
   * @param  {Number} index Index of opening
   * @returns {HTMLElement} element
   */
  renderOpening(positions, index) {
    const { roleDescription, desiredQualifications, position, company, location, postedDate, contact, applyLink, companyId, companyUrlTitle } = positions;
    return (<styled.PositionSection displayTopBorder={index > 0}>
      <styled.PositionHeading dangerouslySetInnerHTML={{ __html: position }}>
      </styled.PositionHeading>
      <styled.Company>
        {companyId ? <Link to={{
          pathname: `/members/${companyUrlTitle}`,
          state: {
            "_id": companyId,
            "_from": "careers"
          }
        }}>{company}</Link> : company}
      </styled.Company>
      <styled.ContactSection>
        <styled.TitleWrapper>
          <span><styled.LocationImage src={CareerConstants.locationImage} alt={CareerConstants.locationImage_alt_txt}></styled.LocationImage></span>
          <span>{CareerConstants.locationText} {location}</span>
        </styled.TitleWrapper>
        {
          postedDate &&
          <styled.SingleContact displayLeftBorder="true">
            <span><styled.PostedImage src={CareerConstants.postedImage} alt={CareerConstants.postedImage_alt_txt}></styled.PostedImage></span>
            <span>{CareerConstants.postedText} {moment(postedDate).format("MMM D, YYYY")}</span>
          </styled.SingleContact>
        }
        {
          contact &&
          <styled.SingleContact displayLeftBorder="true">
            <span><styled.ContactImage src={CareerConstants.contactImage} alt={CareerConstants.contactImage_alt_txt}></styled.ContactImage></span>
            <span dangerouslySetInnerHTML={{ __html: CareerConstants.contactText + contact }}></span>
          </styled.SingleContact>
        }
      </styled.ContactSection>
      <styled.RoleSection>
        <styled.SectionHeading>
          {CareerConstants.roleDescriptionText}
        </styled.SectionHeading>
        <styled.SectionDescription>
          <p dangerouslySetInnerHTML={{__html: roleDescription}}></p>
        </styled.SectionDescription>
      </styled.RoleSection>
      <styled.QualificationSection>
        {
          desiredQualifications && <styled.SectionHeading>
            {CareerConstants.qualificationText}
          </styled.SectionHeading>
        }
        <styled.SectionDescription>
          <p dangerouslySetInnerHTML={{__html: desiredQualifications}}></p>
        </styled.SectionDescription>
      </styled.QualificationSection>
      <styled.ApplyButtonSection>
        <styled.ApplyButton onClick={() => this.handleApplyClick(applyLink)}>{CareerConstants.applyButtonText}</styled.ApplyButton>
      </styled.ApplyButtonSection>
    </styled.PositionSection>);
  }

  /**
   * handle apply click button
   * @param {string} link apply link
   * @returns {undefined}
   */
  handleApplyClick = (link) => {
    const applyLink = `/redirect?url=${link}`;
    window.open(applyLink, "_blank");
  }

  render() {
    const { isLoading, careers } = this.props;
    return (
      <styled.CareerPage>
        <Container className="container">
          <styled.HeaderSection>
            <styled.HeaderMain>
              {CareerConstants.phactmi_txt}
              <span>{CareerConstants.mainHeadingText}</span>
            </styled.HeaderMain>
          </styled.HeaderSection>
          <styled.MainSection>
            <styled.OpenPositionsSection>
              <styled.OpenPositionHeading>
                {CareerConstants.openPositionHeading}
              </styled.OpenPositionHeading>
              <styled.OpenPositionDescription>
                {CareerConstants.openPositionDescription}
              </styled.OpenPositionDescription>
            </styled.OpenPositionsSection>
            {(isLoading) ? <SearchLoader /> :
              (careers && careers.size > 0) ? careers.data.map((item, index) => {
                return (<React.Fragment key={index}>
                  {this.renderOpening(item, index)}
                </React.Fragment>)
              }) : <styled.NoResultContainer><NoResultsFound
                resources={CareerConstants.noResults}
              >
              </NoResultsFound></styled.NoResultContainer>}
          </styled.MainSection>
        </Container>
      </styled.CareerPage>
    );
  }
}

Careers.propTypes = {
  isLoading: PropTypes.bool,
  careers: PropTypes.object
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(Careers));
