import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import * as styled from "./styles";

class FeedbackToolTip extends Component {

  /**
   * Feedback icon button click
   * @returns{undefined}
   * @memberof FeedbackToolTip
   */
  feedbackButtonClick = () => {
    if (document.querySelector(".feedback") && document.querySelector(".feedback").getBoundingClientRect()) {
      document.querySelector(".feedback").scrollIntoView(false);
    }
  }

  render() {
    const { feedback_icon, tooltipContent } = this.props;
    return (
      <styled.FeedbackToolTipContainer>
        <styled.TooltipWrapper ref={elm => { this.entellectText = elm }} onTouchEnd={this.onCloseTooltip}>
          <styled.Tooltip ref={elm => { this.toolTip = elm }} onClick={(e) => { e.stopPropagation(); }}>
            <span dangerouslySetInnerHTML={{ __html: tooltipContent }} onTouchEnd={(e) => { e.stopPropagation(); }}></span>
          </styled.Tooltip>
          <styled.FeedbackImage>
            <img src={feedback_icon} onClick={this.feedbackButtonClick} alt="Feedback Logo"></img>
          </styled.FeedbackImage>
        </styled.TooltipWrapper>
      </styled.FeedbackToolTipContainer>
    );
  }
}

FeedbackToolTip.propTypes = {
  feedback_icon: PropTypes.string,
  tooltipContent: PropTypes.string
}

export default memo(FeedbackToolTip);
