/* eslint-disable max-len */
import WhoWeAre from "../../assets/images/Who_we_are.png";

export default {
  "header": "Our Why",
  "img_src": WhoWeAre,
  "img_src_alt": "Who are we - phactMI",
  "sections": [
    {
      "title": "We Believe…",
      "content": [
        "We are the primary source of unbiased medical information developed by healthcare professionals for healthcare professionals.",
        "Access to timely and accurate answers to your medication questions matters…",
        "Our collective efforts provide comprehensive drug information that empower healthcare professionals to make decisions that impact patient care.",
        "We are transforming the delivery and accessibility of medical information through a first in kind industry collaboration, delivering medical information to our healthcare providers when they need it most.",
        "Our collaboration is a catalyst to elevate the practice of Medical Information."
      ]
    },
    {
      "title": "We Recognize",
      "content": [
        "Trusted sources of credible information matter to you."
      ]
    },
    {
      "title": "We Understand",
      "content": [
        "As the innovator of these medicines, we are driven to bring you transparent, evidence-based medical information consistent with our Code of Practice."
      ]
    },
    {
      "title": "We Created",
      "content": [
        "A consortium of medical information leaders who are dedicated to sharing clinical data on innovative medicines that other sources cannot provide."
      ]
    },
    {
      "title": "We Deliver",
      "content": [
        "Customized, curated, comprehensive medical information through a centralized platform for healthcare professionals."
      ]
    }
  ]
}