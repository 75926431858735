import { combineReducers } from "redux";
import homeReducer from "../containers/home/reducer";
import autoSuggestReducer from "../components/auto-suggest-search/reducer";
import searchResultReducer from "../containers/search/reducer";
import aboutReducer from "../containers/about/reducer";
import manufacturerReducer from "../containers/manufacturers/reducer";
import newsReducer from "../containers/news/reducer";
import userFeedbackReducer from "../components/search-did-this-help/reducer";
import rolesReducer from "../components/search-popup/reducer";
import careerReducer from "../containers/careers/reducer";
import covidReducer from "../containers/covid-19/reducer";
import fellowshipReducer from "../containers/fellowship/reducer";
import podcastReducer from "../containers/podcast/reducer";
import memberContactReducer from "../containers/member-company-info/reducer";

const rootReducer = combineReducers({
  homeReducer,
  aboutReducer,
  autoSuggestReducer,
  manufacturerReducer,
  searchResultReducer,
  newsReducer,
  userFeedbackReducer,
  rolesReducer,
  careerReducer,
  covidReducer,
  fellowshipReducer,
  podcastReducer,
  memberContactReducer
});
export default rootReducer;
