import React, { memo } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import resources from "../../config/content/termsAndConditions";
import withTransition from "../../components/with-transition";
import * as styled from "./style";

/**
 * Terms and Condtion page container
 *
 * @param {object} props object
 * @returns {HTMLElement} element
 */
const CondtionSection = props => {
  window.scrollTo({top: 0});
  const { conditions = [], img_src, heading, img_alt_txt } = resources
  return (
    <styled.HomePage>
      <styled.HeadingContent>
        <Container className="container">
          {heading}
        </Container>
      </styled.HeadingContent>
      <Container className="body-container">
        <styled.ConditionSection>
          <styled.ImageSection>
            <img src={img_src} alt={img_alt_txt} />
          </styled.ImageSection>
          {
            conditions.map((value, key) => {
              return <p key={key}>{value}</p>
            })
          }
        </styled.ConditionSection>
      </Container>
    </styled.HomePage>
  );
};

CondtionSection.prototype = {
  resources: PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.string),
    img_src: PropTypes.string,
    heading: PropTypes.string
  })
}

export default memo(withTransition(CondtionSection));
