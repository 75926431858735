import { colors } from "../../common/styles/variables";
import { mobileRange } from "../../common/styles/breakpoints";

export const spl = `
  h1 {
    font-size: 20px;
    color: ${colors.sherpa_blue};
    font-weight: bold;
  }
  h2 {
    font-weight: bold;
  }
  div {
    font-size: 16px;
  }
  .HighlightsBoxedWarning , .FullBoxedWarning {
    border: solid 1px ${colors.black};
    background-color: ${colors.concrete};
    padding: 23px;
    margin-top: 10px;
    color: ${colors.black};
    h1 {
      color: ${colors.black};
    }
  }

 dd {
    padding: 0;
    position: relative;
    margin-top: -2.9ex;
    margin-left: 1.5em;
  }
  dt {
    display: block;
  }
  .header3911 {
    width: 60%;
  }
  .data3911 { 
    background-color:${colors.mercury};
    width: 40%;
  }
  .tableContentAlign3911 { 
    width:940px;
    padding:0;
    border-spacing:0;
    border-collapse:collapse;
  }

  h1 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    color: black;
  }
  
  div.Section h1 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top:4ex;
    margin-bottom: 0;
    text-align: left;
  }
  
  div.Warning h1 {
    margin-top: 0;
  }
  
  h2 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 2.2ex;
    margin-bottom: 0;
    text-align: left;
  }
  
  h3 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: normal;
    margin-top: 2.2ex;
    margin-bottom: 0;
    text-align: left;
  }
  
  h4 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: normal;
    margin-top: 2.2ex;
    margin-bottom: 0;
    text-align: left;
    font-style: italic;
  }
  
  div.Warning h1 {
    margin-top: 0pt;
    margin-bottom: 4pt;
    text-align: center;
    text-indent: 1em;
  }
  
  div.HighlightSection,
  div.HighlightSubSection
   {
    margin-top: 30px;
    padding-bottom: .25ex;
    border-top-style: dashed; 
    border-top-width: thin;
  }
  
  h1.Highlights {	
    text-align: center;
    margin-bottom: 0;
    margin-top: -1.6ex;
    margin-left: 5%;
    margin-right: 5%;
    white-space: nowrap;
    text-indent: 0;
  }
  
  h1.Highlights span {
    background: white; 
    padding-top:.5ex;
    padding-bottom:1ex;
    padding-left:.25em; 
    padding-right:.25em;
  }
  
  .HighlightsSeeReference {
    font-weight: bold;
  }
  
  .HighlightsRevision {
    text-align: right;
    font-weight: bold;
    margin-bottom: 40px;
    @media ${mobileRange} {
      margin-bottom: 20px;
    }
  }
  
  h1.toc {	
    text-indent: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    padding-left:.25in;
    padding-right: .25in;
    text-transform: uppercase;
  }
  
  h2.toc {
    margin-top: 0;
    font-weight: normal;
    margin-bottom: 0;
    text-align: left;
    padding-left:.40in;
    padding-right: .40in;
  }
  
  h3.toc {
    font-size: 100%;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    padding-left:.55in;
    padding-right: .55in;
  }
  
  h4.toc {	
    padding-left: 3em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    font-style: italic;
    padding-left:.70in;
    padding-right: .70in;
  }
  
  span.SectionNumber {
    position: absolute;
    left: 10pt;
    top: auto;
  }
  
  .titleCase {
    text-transform: capitalize;
  }
  
  .lowerCase {
    text-transform: lowercase;
  }
  p {	
    text-indent: 0;
    margin-top: 1.2ex;
    margin-bottom: 0;
  }
  
  td p {	
    text-indent: 0;
    margin-top: 0; 
    margin-bottom: 0;
  }
          
  th p {	
    text-indent: 0;
    margin-top: 0; 
    margin-bottom: 0;
  }
          
  p.DocumentTitle {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
  
  p.DocumentSubTitle {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  
  .Instruction {	
    color: green;
  }
  
  .DocumentMetadata {
    display:none;
  }

  .EffectiveDate {
    position: relative;
  }
  .DistributorName {
    position: relative;
    text-align: right;
    top: -47px;
  }
  
  span.ParagraphCaption {
    font-weight: bold;
    text-indent: 0;
  }
  
  p.ListCaption {
    font-weight: bold;
    font-size: 90%
  }
  
  p.MultiMediaCaption {
    margin-top: 0; 
    text-align: center;
    font-weight: bold;
    font-size: 90%
  }
  
  p.MultiMediaCaptionNotCentered {
    margin-top: 0; 
    text-align: left;
    font-weight: bold;
    font-size: 90%
  }
  
  p.disclaimer {
    font-style: italic;
  }
  
  .Insert {
    color: green;
    border-bottom: 3px double;
  }
  .Delete {
         text-decoration: line-through;
  }

  .Sub { 
    font-size: 80%;
    position: relative;
    bottom: -3px;
  }
  .Sup {
    font-size: 80%;
    position: relative;
    top: -5px;
  }
  
  table	{
    white-space: wrap;
    font-size: 100%;
    margin-top: 3ex;
    margin-bottom: 3ex;
    border-collapse: collapse;
    border-color: black;
    border-width: thin;
  }
  caption {
    caption-side: top
  }
  table caption {	
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 100%
  }
         
  th, 
  td { 
    border-top-style: none;
    border-bottom-style: none;
    border-right-style: none;
    border-left-style: none;
    border-collapse: collapse;
    border-color: black;
    border-width: thin;
  }
  
  td.twocolumn {
    width: 50%;
    text-align: left;
    vertical-align: top;
    padding-left: 0.5em; 
    padding-right: 0.5em;
  }
  
  tr.Botrule td, 
  tr.Botrule th,
  th.Botrule, 
  td.Botrule { 
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
  
  thead tr.First td,
  thead tr.First th {
    border-top-style: solid;
    border-top-width: medium;
  }
  
  thead tr.Botrule td,
  thead tr.Botrule th {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
  
  thead tr.Toprule td,
  thead tr.Toprule th {
    border-top-style: solid;
    border-bottom-width: thin;
  }
  
  thead tr.Last td, 
  thead tr.Last th {
    border-bottom-style: solid;
    border-bottom-width: thin;
  } 
  
  tbody tr.First td, 
  tbody tr.First th  { 
    border-top-style: solid;
    border-top-width: thin;
  }	 
  
  tbody.Headless tr.First td, 
  tbody.Headless tr.First th  { 
    border-top-style: solid;
    border-top-width: medium;
  }	 
  
  tbody tr.Botrule td, 
  tbody tr.Botrule th { 
    border-bottom-style: solid;
    border-bottom-width: thin;
  }	 
  
  
  tbody tr.Last td,
  tbody tr.Last th { 
    border-bottom-style: solid;
    border-bottom-width: medium;
  }
  
  tbody tr td.Last,
  tbody tr th.Last { 
    border-bottom-style: solid;
    border-bottom-width: medium;
  }	 

  th.Lrule,
  td.Lrule { 
    border-left-style: solid;
    border-left-width: thin;
  }
  
  th.Rrule, 
  td.Rrule { 
    border-right-style: solid;
    border-right-width: thin;
  }
  
  th.Toprule, 
  td.Toprule { 
    border-top-style: solid;
    border-top-width: thin;
  }
  
  th.Botrule, 
  td.Botrule { 
    border-bottom-style: solid;
    border-bottom-width: thin;
  }

  ul { 
    margin-top: .2ex;
    margin-left: 1.5em;
    padding-left: 0;
  }
    
  ol { 
    margin-top: .2ex;
    padding-left: 1.5em;
  }
  
  li {
    margin-top: .2ex;
    margin-left: 0;
  }
  
  li p {
    margin-top: .2ex;
  }
  
  ul.Disc { list-style-type: disc; }
  ul.Circle { list-style-type: circle; }
  ul.Square { list-style-type: square; }
  
  ol.LittleRoman { list-style-type: lower-roman; }
  ol.BigRoman { list-style-type: upper-roman; }
  ol.LittleAlpha { list-style-type: lower-alpha; }
  ol.BigAlpha { list-style-type: upper-alpha; }
  ol.Arabic { list-style-type: arabic; }
  
  hr.Footnoterule {
    margin-top:2ex;
    width: 30%;
    text-align: left;
  }
  dl.Footnote {
    margin-left:0;
    margin-bottom:0;
    margin-top:0;
    padding: 0;
    dt {
      display: block;
    }
    dd {
      display: block;
      margin-left: 14px;
      margin-top: -3.0ex;
    }
  }
  
  dl.FootnoteContents {
    text-align: right;
    margin-left:0;
    margin-bottom:0;
    margin-top:0;
  }
  
  p.Footnote {
    text-indent:-1em;
    margin-left:1em;
    font-size:90%;
  }
  
  p.Footnotesize {
    margin-bottom:0;
    margin-top:0;
    font-size:90%;
  }
  
  img {
    display: block;
    margin-top: 0; 
    margin-bottom: 0;
    text-align: center;
  }
                
  p img {
    display: inline;
    margin-top: 0; 
    margin-bottom: 0;
  }
                
  div.Figure {
    margin-top: 4ex; 
    margin-bottom: 4ex;
  }
  
  div.Subject {
    margin-top: 1ex;
    margin-bottom: 1ex;
    padding-top: 1ex;
    padding-bottom: 1ex;
    padding-left: .5em;
    padding-right: .5em;
    font-size: 80%;
    background-color: ${colors.alto};
    cursor: pointer;
  }
  
  .contentTable {
    border: 1px solid ${colors.dusty_gray};
    background-color: ${colors.gallery};
  }
  .contentTablePetite {
    border: 1px solid ${colors.dusty_gray};
    background-color: ${colors.gallery};
    font-size: 80%;
  }
  .contentTableTitle {
    border-bottom: 1px solid ${colors.dusty_gray};
    background-color: ${colors.silver};
    font-size: 100%;
    font-weight: bold;
  }
  .contentTableReg {
    border-bottom: none;
    background-color: ${colors.silver};
    font-size: 100%;
    font-weight: lighter;
  }
  .formTable {
    border: 1px solid ${colors.white};
    border-collapse: collapse;
  }
  .formTablePetite {
    border: 1px solid ${colors.white};
    border-collapse: collapse;
    font-size: 100%;
  }
  .formTableMorePetite {
    border: 1px solid ${colors.white};
    border-collapse: collapse;
    font-size: 12px;
  }
  .formTitle {
    background-color: ${colors.silver};
    border: 1px solid ${colors.white};
    font-size: 125%;
    font-weight: bold;
  }
  .formHeadingTitle {
    border: none;
    background-color: ${colors.silver};
    font-size: 150%;
    font-weight: bold;
  }
  .formHeadingReg {
    background-color: ${colors.silver};
    border: 1px solid ${colors.white};
    font-size: 125%;
  }
  .formTableRow {
    background-color: ${colors.white};
  }
  .formTableRowAlt {
    background-color: ${colors.concrete};
  }
  .formLabel {
    border: 1px solid ${colors.white};
    background-color: ${colors.alto};
    font-size: 115%;
    font-weight: bold;
  }
  .formItem {
    border: 1px solid ${colors.silver};
    font-size: 110%;
  }
  .formTitle a:visited {
    font-weight: bold;
    text-decoration: underline;
  }
  .formTitle a:active {
    font-weight: bold;
    text-decoration: none;
  }
  .formTitle a:hover {
    font-weight: bold;
    text-decoration: none;
  }
  
  .normalizer {
    font-size: 125%;
    font-weight: lighter;
  }
  
  div.Highlights {
    font-size: 80%;
  }

  div.HighlightsDisclaimer {
    font-weight: bold;
  }
  
  div.Index {
    margin-top: 5ex;
    margin-bottom: 5ex;
    padding-top: 1ex;
    padding-bottom: 1ex;
    border-top-style: solid;
    border-top-width: thin;
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
  
  .Colspan {
    -moz-column-span: all;
    -webkit-column-span: all;
    column-span: all;
  }

  .Bold {
    font-weight: bold;
  }
  .Nobold {	
    font-weight: normal;
  }
  .Italics {	
    font-style: italic;
  }
`;