import React, { memo } from "react";
import PropTypes from "prop-types";
import Accordian from "../accordian/accordian";
import { FaqContainer, FaqTitle } from "./styles";

/**
 * Faq Component
 * @class FaqComponent
 * @exports FaqComponent
 */
const FaqComponent = React.forwardRef(({ data, resources }, ref) => {
  const { title } = resources;
  return (
    <FaqContainer ref={ref}>
      <FaqTitle>{title}</FaqTitle>
      <Accordian data={data} resources={resources} />
    </FaqContainer>
  );
});

FaqComponent.propTypes = {
  data: PropTypes.array,
  resources: PropTypes.object
};

export default memo(FaqComponent);
