export const GET_ROLES = "GET_ROLES";
export const GET_FAIL_ROLES = "GET_FAIL_ROLES";
export const GET_SUCCESS_ROLES = "GET_SUCCESS_ROLES";
export const SEND_DEGREE = "SEND_DEGREE";
export const SEND_DEGREE_SUCCESS = "SEND_DEGREE_SUCCESS";
export const SEND_DEGREE_FAIL = "SEND_DEGREE_FAIL";

export function getRoles() {
  return {
    type: GET_ROLES
  }
}

export function setRole(degree) {
  return {
    type: SEND_DEGREE,
    payload: {
      degree
    }
  }
}
