// import update from 'immutability-helper';

import {
  GET_LATEST_NEWS, GET_SUCCESS_LATEST_NEWS, GET_FAIL_LATEST_NEWS, STOP_TOUR, GET_PARTNERSHIP_DATA, GET_PARTNERSHIP_DATA_SUCCESS, GET_PARTNERSHIP_DATA_FAIL,
  NEWS_LETTER_SUBSCRIPTION, NEWS_LETTER_SUCCESS_SUBSCRIPTION, NEWS_LETTER_FAIL_SUBSCRIPTION, INCREMENT_TOUR_TOOL_INDEX, SHOW_TOUR
} from "./actions";

const initialState = {
  latestNews: {},
  loading: false,
  message: "",
  toolTipIndex: 0,
  tourStatus: false,
  partnershipData: null
}

export default function homeReducer(state = initialState, { type, payload }) {
  switch (type) {

  case GET_LATEST_NEWS:
    return {
      ...state,
      loading: true
    }

  case GET_SUCCESS_LATEST_NEWS:
    return {
      ...state,
      latestNews: payload.result,
      loading: false
    }

  case GET_FAIL_LATEST_NEWS:
    return {
      ...state,
      loading: false
    }

  case NEWS_LETTER_SUBSCRIPTION:
    return {
      ...state,
      loading: true
    }

  case NEWS_LETTER_SUCCESS_SUBSCRIPTION:
    return {
      ...state,
      message: payload.result,
      loading: false
    }

  case NEWS_LETTER_FAIL_SUBSCRIPTION:
    return {
      ...state,
      loading: false
    }

  case INCREMENT_TOUR_TOOL_INDEX:
    return {
      ...state,
      toolTipIndex: state.toolTipIndex + 1
    }

  case SHOW_TOUR:
    return {
      ...state,
      tourStatus: true
    }

  case STOP_TOUR:
    return {
      ...state,
      tourStatus: false,
      toolTipIndex: 0
    }

  case GET_PARTNERSHIP_DATA:
    return {
      ...state
    }

  case GET_PARTNERSHIP_DATA_SUCCESS:
    return {
      ...state,
      partnershipData: payload.result.data
    }

  case GET_PARTNERSHIP_DATA_FAIL:
    return {
      ...state
    }

  default: return state
  }
}
