import * as action from "./actions";

const initialState = {
  podCastData : []
}

export default function podcastReducer(state=initialState, {type, payload}) {
  switch (type) {
  case action.GET_PODCAST:
    return {
      ...state
    }
  case action.GET_PODCAST_SUCCESS:
    return {
      ...state,
      podCastData: payload.result
    }
  case action.GET_PODCAST_FAIL:
    return {
      ...state,
      podCastData: []
    }
  default: return state
  }
}
