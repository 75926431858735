import styled from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minIPadPro } from "../../../common/styles/breakpoints";

export const LeagalMessage = styled.div`
    font-size: 13px;
    height: auto;
    width: 100%;
    margin-top: 20px;
    color: ${colors.gray};
    padding: 25px 0 20px;
    @media ${mobileRange} {
        font-size: 11px;
        padding: 0 0 60px;
        margin: 0;
        text-align: center;
    }
    @media ${tabletRange} {
        font-size: 13px;
    }
    @media ${ipadProRange} {
        font-size: 13px;
    }
`;
