import React, { useState } from "react";
import * as styled from "./style";
import expandIcon from "../../assets/images/expand-icon.svg";
import collapseIcon from "../../assets/images/collapse-icon.svg";

const Accordion = (props) => {
  const { data } = props;

  const [activeIndex, setActiveIndex] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const openAccordian = (index) => {
    setSelectedIndex(prevState => prevState === index ? null : index)
    setActiveIndex((prevState) => activeIndex.includes(index) ? [...activeIndex.filter(idx => index !== idx)] : [...prevState, index])
  }

  const renderAccordianContent = (content, index) => {
    return (
      <section>
        <styled.AccordianContentCard isActive={ activeIndex.includes(index) }>
          {content}
        </styled.AccordianContentCard>
      </section>
    );
  }

  return (
    <section>
      {data && data.length > 0 && <div>
        {data.map((item, index) => (
          <styled.AccordionCard className="accordian-card" key={index} onClick={() => openAccordian(index)}>
            <styled.AccordianHeader className="accordian-header" isExpanded={ index === activeIndex }>
              <div>
                {item.title}
              </div>
              <button type="button">
                <img src={activeIndex.includes(index) ? collapseIcon : expandIcon} alt={index === activeIndex ? "collapse" : "expand"} />
              </button>
            </styled.AccordianHeader>
            {renderAccordianContent(item.content, index)}
          </styled.AccordionCard>
        ))}
      </div>}
    </section>
  );
}

export default Accordion;