/* eslint-disable max-len */
import MailIcon from "../../assets/images/web_globe.png";
import CallIcon from "../../assets/images/call_icon.png";

export default {
  "banner_title": "Support during the <b>COVID-19 crisis<b>",
  "banner_content": "phactMI understands the uncertainty that the current COVID-19 crisis is having with healthcare providers, their patients, and our member companies. Medical Information Departments at our member companies are here to support the healthcare provider's informational needs with up-to-date information on all their medications.",
  "description": "Many of our member companies and partner organizations have developed specific resource pages to address COVID-19 informational needs and how they are specifically responding to the pandemic. Below are links to company specific COVID-19 resource pages and telephone numbers for the company's Medical Information Department.",
  "call_icon": CallIcon,
  "call_icon_alt": "Call icon",
  "mail_icon": MailIcon,
  "mail_icon_alt": "Mail icon"
}