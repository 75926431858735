import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const CodeOfPracticePage = styled.div`
  color: ${colors.sherpa_blue};
  position: relative;
  background: ${colors.white};
  & .body-container {
    max-width: ${containerMaxWidth};
    padding: 0;
    @media ${mobileRange} {
      margin-top: 25px;
    }
    @media ${tabletRange} {
      margin-top: -60px;
    }
  }

  .odd-index {
    background: ${colors.light_grey};
    margin: 36px -96px 0;
    padding: 0 96px 30px;
  }
  .para {
    padding-top: 38px;
  }
`;

export const HeadingContent = styled.div`
  background: ${colors.light_grey};
  font-size: 30px;
  @media ${mobileRange} {
    width: 100%;
    font-size: 25px;
    padding-top: 45px;
  }
  & .container {
    padding: 62px 100px 62px 84px;
    max-width: ${containerMaxWidth};
    font-size: 25px;
    @media ${mobileRange} {
      padding: 55px 2% 120px;
      text-align: center;
    }
    @media ${tabletRange} {
      padding: 30px 0 90px 84px;
    }
  }
`;

export const ConditionSection = styled.div`
  width: 100%;
  padding: 35px 96px 35px;
  background: ${colors.white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  margin-top: -23px;

  @media ${mobileRange} {
    padding: 94px 17px 25px;
  }
  @media ${tabletRange} {
    padding: 39px 64px 50px 84px;
  }
`;

export const ImageSection = styled.div`
  float: right;
  width: 24%;
  margin-top: -135px;

  @media ${mobileRange} {
    width: 100%;
    margin-top: -200px
    text-align: center;
  }

  @media ${tabletRange} {
    width: 34%;
    margin-top: -108px;
  }

  img {
    width: 290px;
    @media ${mobileRange} {
      width: 175px;
    }
    @media ${tabletRange} {
      width: 175px;
    }
  }
`;

export const ContentSection = styled.div`
  @media ${mobileRange} {
    font-size: 15px;
  }

  .title-list {
    margin-bottom: -28px;
  }
`;

export const ContentHeading = styled.div`
  color: ${colors.davy_grey};
  font-weight: bold;

  @media ${tabletRange} {
    font-size: 18px;
  }

  @media ${mobileRange} {
    font-size: 15px;
  }
`;

export const PracticeSection = styled.div`
  line-height: 1.78;
  font-size: 16px;
  letter-spacing: -0.23px;
  text-align: left;
  color: ${colors.davy_grey};
`;
