import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { minLaptop, mobileRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  z-index: 102;
  position: absolute;
  display: ${props => props.tourRun ? "block" : "none"}
  top: ${props => props.top};
  left: ${props => props.left};
  width: 400px;
`;

export const ToolTipContainer = styled.div`
  background: ${colors.white};
  display: block;
  padding: 10px 20px;
  max-width: 420px;
  @media ${mobileRange} {
    max-width: 290px;
  }
`;

export const CountLabel = styled.div`
  font-size: 12px
  line-height: 1.17;
  color: ${colors.light_solid_grey};
`;
export const HeadingSection = styled.div`
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.22;
  color: ${colors.light_black};
  text-align: left;
  @media ${minLaptop} {
    font-size: 19px;
  }

`;
export const ContentSection = styled.div`
  font-size: 18px;
  line-height: 1.22;
  color: ${colors.light_black};
  text-align: left;
  padding: 20px 0 ;
  span {
    font-weight: bold;
  }
  @media ${minLaptop} {
    font-size: 17px;
  }
`;
export const Buttons = styled.div`
  display: flex;
`;
export const SkipButton = styled.div`
  width: 50%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.light_grey_suit};
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 12px;
  }
  div {
    padding-left: 5px;
  }
`;
export const NextButton = styled.div`
  width: 50%;
  text-align: right;
  button {
    width: 104px;
    padding: 8px 0;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.21px;
    color: ${colors.white};
    border-radius: 3px;
    background-color: ${colors.sherpa_blue};
  }
`;
export const Container = styled.div`
  .container {
    min-height: 500px;
  }
`;
export const ToolTipWrapper = styled.div`
  display: flex;
`;
export const ToolPointer = styled.div`
  margin: ${props => props.topPosition ? props.tipMargin : "0"} 0  0 ${props => props.leftPosition ? props.tipMargin : "0"};
  .pointer-top {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${colors.white};
  }
  .pointer-bottom{
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${colors.white};
  }
  .pointer-right {
    width: 0;
    height: 0;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    border-left: 20px solid ${colors.white};
  }
  .pointer-left {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid ${colors.white};
  }
`;