import React, { Component } from "react";
import _get from "lodash/get";
import _size from "lodash/size";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "./actions";
import * as styled from "./style";
import searchPageResources from "../../config/content/searchPage";

const mapStateToProps = state => {
  return {
    userFeedbackResult: state.userFeedbackReducer.userFeedbackResult,
    submittedUserFeedback: state.userFeedbackReducer.submittedUserFeedback,
    feedbackQuestion: state.userFeedbackReducer.feedbackQuestion
  };
};

/**
  * @description Did It Help Section component
  * @class DidItHelpSection
  * @extends {Component}
  * @exports DidItHelpSection
  */
class DidItHelpSection extends Component {

  static propTypes = {
    submittedUserFeedback: PropTypes.bool,
    feedbackConstants: PropTypes.object,
    postUserFeedback: PropTypes.func,
    setUserFeedback: PropTypes.func,
    getFeedbackQuestion: PropTypes.func
  }

  state = {
    isSubmitButtonEnable: true,
    indexOfData: 0,
    leftArrowEnable: false,
    rightArrowEnable: false,
    showInputTextBox: false,
    result: [],
    currentResponse: null,
    inputTextComment: "",
    showSubmitButton: false,
    feedbackQuestion: [],
    responseQ1Mobile: "Yes-0",
    responseQ2Mobile: "Yes-1"
  }

  componentDidMount() {
    const {feedbackQuestion} = this.props;
    if (_isEmpty(feedbackQuestion)) {
      this.props.getFeedbackQuestion();
    }
  }

  /**
   * @description Reset redux store state so that on new search feedback form will be displayed again.
   * @returns {undefined}
   */
  componentWillUnmount() {
    this.props.setUserFeedback();
  }

  /**
   * @description Search button click handler
   * @returns {undefined}
   */
  onSubmitFeedback = () => {
    const { result, indexOfData, currentResponse, inputTextComment } = this.state;
    const { feedbackQuestion, degree, searchQuery } = this.props;
    const currentResult = result;
    const label = _get(_get(feedbackQuestion, "data")[indexOfData], "label");
    let currentplaceholder = "";
    const type = _get(_get(feedbackQuestion, "data")[indexOfData], "contentType");
    if (type === "radio" && currentResponse === "No") {
      currentplaceholder = _get(_get(feedbackQuestion, "data")[indexOfData], "followUpLabel");
    }
    if (!currentResult[indexOfData]) {
      currentResult.splice(indexOfData, 0, {
        label: label,
        content: currentResponse,
        followUpContent: currentplaceholder,
        followUpLabel: inputTextComment
      })
    }
    this.props.postUserFeedback({
      feedback: currentResult,
      degree: degree,
      searchQuery: searchQuery
    });
  }

  /**
    * @description Input field on change handler
    * @param {object} event event object
    * @returns {undefined}
    */
  handleInputChange = event => {
    const { value } = event.target;
    this.setState({
      inputTextComment: value
    });
  }

  /**
   * Radio button change function
   * @param {object} event event object
   * @returns {undefined}
   * @memberof DidItHelpSection
   */
  handleRadioChange = event => {
    const { value } = event.target;
    const { indexOfData } = this.state;
    const { feedbackQuestion } = this.props;
    const followUp = _get(_get(feedbackQuestion, "data")[indexOfData], "followUp");
    const maxIndex = _size(_get(feedbackQuestion, "data")) - 1;
    if (value === "No" && indexOfData === 0) {
      this.setState({
        currentResponse: value,
        inputTextComment: "",
        indexOfData: indexOfData + maxIndex - 1
      }, () => this.handleResult(value));
    }
    else if (followUp && (value === "No")) {
      this.setState({
        showInputTextBox: true,
        currentResponse: value
      });
    }
    else {
      this.setState({
        currentResponse: value,
        inputTextComment: ""
      }, () => this.handleResult(value));
    }
  }

  handleRadioChangeMobile = (event, question) => {
    const { value } = event.target;
    if (value.includes("No-0")) {
      this.setState({
        responseQ1Mobile: value,
        inputTextComment: ""
      }, () => this.handleResultMobile(value, question));
    }
    else if (value.includes("No-1")) {
      this.setState({
        showInputTextBox: true,
        responseQ2Mobile: value
      }, () => this.handleResultMobile(value, question));
    } else if (value.includes("Yes-0")) {
      this.setState({
        responseQ1Mobile: value,
        inputTextComment: ""
      }, () => this.handleResultMobile(value, question));
    } else if (value.includes("Yes-1")) {
      this.setState({
        responseQ2Mobile: value,
        inputTextComment: ""
      }, () => this.handleResultMobile(value, question));
    }
  }

  /**
   * @description  Method to create group of custom radio buttons to be rendered.
   * @param  {array} radioButtonArray - Array of strings for radio buttons.
   * @returns {array} Array of radio buttons.
   */
  renderRadioButtons = radioButtonArray => {
    const radioButtons = [];
    const { currentResponse } = this.state;
    radioButtonArray.forEach((element, index) => {
      radioButtons.push(
        <label className="radioButtonContainer" key={index}>
          <input type="radio" name="didItHelp" checked={currentResponse === element} value={element} onChange={this.handleRadioChange} />
          <span className="checkmark"></span>
          {element}
        </label>
      )
    });
    return radioButtons;
  }

  renderRadioButtonsMobile = (radioButtonArray, data) => {
    const radioButtons = [];
    const { currentResponseMobile, responseQ1Mobile, responseQ2Mobile } = this.state;
    radioButtonArray.forEach((element, index) => {
      const checked = data.order === 0 ? (responseQ1Mobile === `${element}-${data.order}`) : (responseQ2Mobile === `${element}-${data.order}`)
      radioButtons.push(
        <label className="radioButtonContainer" key={index}>
          <input type="radio" name={`element-${data.order}`}
            checked={checked}
            value={`${element}-${data.order}`} onChange={(e) => this.handleRadioChangeMobile(e, data)} />
          <span className="checkmark" style={{ backgroundColor: checked ? "#00B59A" : "#999D9D"}}></span>
          {element}
        </label>
      )
    });
    return radioButtons;
  }

  /**
   * Star render according to input given
   * @param {String} contentMaxLength rating star length
   * @return {HTMLBodyElement} star html
   * @memberof DidItHelpSection
   */
  renderStarButton = (contentMaxLength) => {
    const { currentResponse } = this.state;
    let index = 0;
    const starButton = [];
    while (index < contentMaxLength) {
      starButton.push(
        <React.Fragment key={index}>
          <input type="radio" id={`star${index}`} name="rating" value={contentMaxLength - index}
            onChange={this.handleStar} checked={contentMaxLength - parseInt(currentResponse) === index} />
          <label htmlFor={`star${index}`}></label>
        </React.Fragment>
      )
      index++;
    }
    return (
      <div className="rating">
        {starButton}
      </div>
    );
  }

  renderStarButtonMobile = (contentMaxLength, question) => {
    const { currentResponse } = this.state;
    let index = 0;
    const starButton = [];
    while (index < contentMaxLength) {
      starButton.push(
        <React.Fragment key={index}>
          <input type="radio" id={`star${index}`} name="rating" value={contentMaxLength - index}
            onChange={(e) => this.handleStarMobile(e, question)} checked={contentMaxLength - parseInt(currentResponse) === index} />
          <label htmlFor={`star${index}`}></label>
        </React.Fragment>
      )
      index++;
    }
    return (
      <div className="rating">
        {starButton}
      </div>
    );
  }

  /**
   * Star click function handler
   * @param {event} event object
   * @returns {undefined}
   * @memberof DidItHelpSection
   */
  handleStar = (event) => {
    const { value } = event.target;
    this.setState({
      currentResponse: value,
      isSubmitButtonEnable: false,
      inputTextComment: ""
    });
    this.handleResult(value);
  }

  handleStarMobile = (event, question) => {
    const { value } = event.target;
    this.setState({
      currentResponse: value,
      isSubmitButtonEnable: false,
      inputTextComment: ""
    });
    this.handleResultMobile(value, question);
  }

  /**
   * Result handler function
   * @param {String} value value of click
   * @param {bool} rightArrowClicked check if right is pressed to add the data in result array
   * @returns {undefined}
   * @memberof DidItHelpSection
   */
  handleResult = (value) => {
    const { indexOfData, currentResponse, inputTextComment } = this.state;
    const { feedbackQuestion } = this.props;
    const type = _get(_get(feedbackQuestion, "data")[indexOfData], "contentType");
    const label = _get(_get(feedbackQuestion, "data")[indexOfData], "label");
    const maxIndex = _size(_get(feedbackQuestion, "data")) - 1;
    let currentplaceholder = "";
    if (type === "radio" && currentResponse === "No") {
      currentplaceholder = _get(_get(feedbackQuestion, "data")[indexOfData], "followUpLabel");
    }
    if (indexOfData < maxIndex) {
      setTimeout(() => {
        this.setState({
          result: [...this.state.result, {
            label: label,
            content: value,
            followUpLabel: currentplaceholder,
            followUpContent: inputTextComment
          }
          ],
          content: null,
          currentResponse: null,
          indexOfData: indexOfData + 1
        });
      }, 0);
    }
    if (indexOfData === maxIndex -1) {
      this.setState({showSubmitButton: true});
    }
  }

  handleResultMobile = (value, question) => {
    const response = {
      label: question.label,
      content: value.split("-")[0],
      followUpLabel: question.followUpLabel ? question.followUpLabel : "",
      followUpContent: question.followUpLabel ? this.state.inputTextComment : ""
    }
    const result = [...this.state.result];
    result[question.order] = response

    this.setState({ result })
  }

  /**
   * Slider handler
   * @returns {HTML} html
   * @memberof DidItHelpSection
   */
  handleQuestionSlider = () => {
    const { indexOfData } = this.state;
    const { feedbackQuestion } = this.props;
    const maxIndex = _size(_get(feedbackQuestion, "data")) - 1;
    let index = 0;
    const questionSlider = [];
    while (index <= maxIndex) {
      questionSlider.push(
        indexOfData === index ?
          <styled.ActiveQuestionSlider key={index} />
          :
          <styled.QuestionSlider key={index} />
      )
      index++;
    }
    return <styled.QuestionHandler>{questionSlider}</styled.QuestionHandler>;
  }

  handleCount = () => {
    const { indexOfData } = this.state;
    const { feedbackQuestion } = this.props;
    const maxIndex = _size(_get(feedbackQuestion, "data"));
    return <styled.HandleCount>{indexOfData + 1}<div className="count_of_txt">of</div>{maxIndex}</styled.HandleCount>;
  }

  /**
   * Input text box handle function
   * @returns {html} input text box
   * @memberof DidItHelpSection
   */
  handleInputTextBox = () => {
    const { showInputTextBox, indexOfData, inputTextComment, currentResponse } = this.state;
    const { feedbackQuestion, feedbackConstants } = this.props;
    const input_field_placeholder = _get(_get(feedbackQuestion, "data")[indexOfData], "followUpLabel");
    const followUp = _get(_get(feedbackQuestion, "data")[indexOfData], "followUp");
    const { no_txt, rightArrowNavigateactive } = feedbackConstants;
    return (
      followUp ?
        <styled.InputTextBoxWrapper currentResponse={currentResponse === no_txt}>
          {currentResponse === no_txt ?
            <React.Fragment>
              <styled.InputTextBox type="text" name="feedback" spellCheck="false"
                showInputTextBox={showInputTextBox} value={inputTextComment} autoFocus onClick={this.handleInp}
                onChange={this.handleInputChange} placeholder={input_field_placeholder} autoComplete="off" />
              <div className="submitarrow">
                <img src={rightArrowNavigateactive} alt="left navigate arrow" onClick={this.submitArrowClick}></img>
              </div>
            </React.Fragment>
            :
            <div />}
        </styled.InputTextBoxWrapper>
        : <div />
    )
  }

  handleInputTextBoxMobile = (index) => {
    const { showInputTextBox, indexOfData, inputTextComment, responseQ2Mobile } = this.state;
    const { feedbackQuestion, feedbackConstants } = this.props;
    const input_field_placeholder = _get(_get(feedbackQuestion, "data")[index], "followUpLabel");
    const followUp = _get(_get(feedbackQuestion, "data")[index], "followUp");
    const { no_txt, rightArrowNavigateactive } = feedbackConstants;
    return (
      followUp ?
        <styled.InputTextBoxWrapper currentResponse={responseQ2Mobile.toLowerCase().includes(no_txt.toLowerCase())}>
          {responseQ2Mobile.toLowerCase().includes(no_txt.toLowerCase()) ?
            <React.Fragment>
              <styled.InputTextBox type="text" name="feedback" spellCheck="false"
                showInputTextBox={showInputTextBox} value={inputTextComment} autoFocus
                onChange={(event) => {
                  const updatedResult = [...this.state.result];
                  updatedResult[1].followUpContent = event.target.value
                  this.setState({ inputTextComment: event.target.value,
                    result: updatedResult });
                }} placeholder={input_field_placeholder} autoComplete="off" />
            </React.Fragment>
            :
            <div />}
        </styled.InputTextBoxWrapper>
        : <div />
    )
  }

  /** Submit button click on input box text
   * @returns {undefined}
   * @memberof DidItHelpSection
   */
  submitArrowClick = () => {
    this.handleResult(this.state.currentResponse);
  }

  /**
   * Feedback Question render function
   * @param {Array} data Array of the data
   * @returns {html} questions html
   * @memberof DidItHelpSection
   */
  handleQuestion = () => {
    const {feedbackQuestion, feedbackConstants} = this.props;
    const {indexOfData, showSubmitButton, isSubmitButtonEnable} = this.state;
    const {submit_button_text} = feedbackConstants;
    const feedbackresource = _get(feedbackQuestion, "data");
    if (feedbackresource) {
      const data = feedbackresource[indexOfData]
      if (data.contentType == "rating") {
        return (
          <styled.QuestionWrapper>
            <styled.QuestionText followUp={data.followUp}>{data.label}</styled.QuestionText>
            <styled.RatingWrapper>
              {this.renderStarButton(data.contentMaxLength)}
            </styled.RatingWrapper>
          </styled.QuestionWrapper>
        )
      }
      else {
        return (
          <styled.QuestionWrapper>
            <styled.QuestionText followUp={data.followUp}>{data.label}</styled.QuestionText>
            <styled.RadioButton>
              {this.renderRadioButtons(data.contentOptions, data)}
            </styled.RadioButton>
            {this.handleInputTextBox()}
          </styled.QuestionWrapper>
        )
      }
    }
  }

  handleQuestionMobile = () => {
    const {feedbackQuestion, feedbackConstants} = this.props;
    const {indexOfData, showSubmitButton, isSubmitButtonEnable} = this.state;
    const {submit_button_text} = feedbackConstants;
    const feedbackresource = _get(feedbackQuestion, "data");
    if (feedbackresource) {
      const renderQuestion = feedbackresource.map(question => {
        return (
          <styled.QuestionWrapperMobile key={question._id}>
            <p>{question.label}</p>
            {question.contentType === "rating" ?
              <><styled.RatingWrapper>
                {this.renderStarButtonMobile(question.contentMaxLength, question)}
              </styled.RatingWrapper>
              </> :
              <>
                <styled.RadioButton>
                  {this.renderRadioButtonsMobile(question.contentOptions, question)}
                </styled.RadioButton>
                {this.handleInputTextBoxMobile(question.order)}
              </> }
          </styled.QuestionWrapperMobile>
        )
      })

      return renderQuestion
    }
  }

  /**
   * @description Method to show success or failure resopnse.
   * @param  {object} image Source image
   * @param  {string} image_alt_text alternate text for image
   * @param  {string} message Information to be displayed.
   * @param  {string} bottomHeadline Headline for section.
   * @returns {object} Renders response sections.
   */
  renderApiResponse = (image, image_alt_text, message, bottomHeadline) => {
    return (
      <styled.Container submitFeedback={true}>
        <styled.ImgWrapper src={image} alt={image_alt_text} />
        <styled.ResponseMessageText>{message}</styled.ResponseMessageText>
        <styled.ResponseHeadline>{bottomHeadline}</styled.ResponseHeadline>
      </styled.Container>
    )
  }

  /**
   * @description Renders to component.
   * @returns {Object} Returns component object.
   */
  render() {
    const { isSubmitButtonEnable, showSubmitButton, responseQ2Mobile } = this.state;
    const { submittedUserFeedback, feedbackConstants } = this.props;
    const { submit_button_text, informational_text, success_image, success_image_alt_text, success_message,
      success_headline, failure_image, failure_image_alt_text, failure_message, failure_headline } = feedbackConstants;
    return (
      <React.Fragment>
        {submittedUserFeedback === null && <styled.Container submitFeedback={false} hide={true}>
          <styled.InformationalText>{informational_text}</styled.InformationalText>
          <styled.InformationalUnderline />
          {this.handleQuestion()}
          {this.handleCount()}
          {this.handleQuestionSlider()}
          <styled.SubmitButton showSubmitButton={showSubmitButton} disabled={isSubmitButtonEnable} onClick={this.onSubmitFeedback}>
            {submit_button_text}</styled.SubmitButton>
        </styled.Container>
        }
        {submittedUserFeedback === null && <styled.FeedbackContainerMobile>
          <styled.FeedbackHeadingMobile>Give Feedback</styled.FeedbackHeadingMobile>
          {this.handleQuestionMobile()}
          <styled.MobileSubmit showSubmitButton={showSubmitButton} disabled={isSubmitButtonEnable} onClick={this.onSubmitFeedback}>{submit_button_text}</styled.MobileSubmit>
        </styled.FeedbackContainerMobile>}
        {submittedUserFeedback === true && this.renderApiResponse(success_image, success_image_alt_text, success_message, success_headline)}
        {submittedUserFeedback === false && this.renderApiResponse(failure_image, failure_image_alt_text, failure_message, failure_headline)}
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(DidItHelpSection);
