import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, mobileAndTabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const SectionImageWrapper = styled.div`
  position: relative;
  margin-top: 16px;
  img {
    cursor: pointer;
    width: 170px;
  }
  @media ${mobileRange} {
    img {
      width: 157px;
    }
  }
  @media ${tabletRange} {
    img {
      width: 124px;
    }
  }
`;
export const SectionImgDesc = styled.div`
  margin: -8px 0px 0;
  line-height: 1.45;
  padding: 0 10px;
  font-size: 22px;
  @media ${mobileRange} {
    margin: -6px 0 10px;
    font-size: 16px;
    line-height: 1.56;
    padding: 0 10px;
  }
  @media ${tabletRange} {
    font-size: 14px;
    margin: 0 14px;
    line-height: 1.57;
    padding: 0 18px;
  }
  @media ${ipadProRange} {
    font-size: 14px;
    margin: 0 6px;
    line-height: 1.57;
    padding: 0 60px;
  }
`;
export const ProvideSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 25px;
  @media ${mobileRange} {
    padding: 7px 10px 16px;
    margin-top: 32px;
  }
  @media ${tabletRange} {
    padding: 32px 0 20px;
  }

  h4 {
    display: none;
    @media ${mobileRange} {
      display: block;
      font-size: 24px;
      font-weight: 700;
    }
  }

`;
export const ProvideHeading1 = styled.h2`
  font-size: 28px;
  color: ${colors.sherpa_blue};
  letter-spacing: -0.39px;
  margin-bottom: 0;
  @media ${mobileAndTabletRange} {
    font-size: 20px;
    letter-spacing: -0.26px;
  }
`;
export const ProvideContent = styled.div`
  display: flex;
  text-align: center;
  width: 85%;
  @media ${mobileRange} {
    flex-direction: column;
  }
`;
export const ProvideHeadingContent = styled.div`
  display: block;
  font-size: 20px;
  text-align: center;
  width: 92%;
  span {
    font-weight: 500;
    font-size: 22px;
    color: ${colors.black};
    @media ${tabletRange} {
      font-size: 18px;
    }
  }
  @media ${tabletRange} {
    font-size: 16px;
  }
  @media ${mobileRange} {
    width: 100%;
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }
`;