import { GET_AUTO_SUGGEST, GET_SUCCESS_AUTO_SUGGEST, GET_FAIL_AUTO_SUGGEST, UPDATE_SEARCH_DATA, SET_AUTO_SUGGEST, SET_SEARCH_DATA } from "./actions";

const initialState = {
  autoSuggest: {},
  loading: false,
  searchData: {}
}

export default function autoSuggestReducer(state = initialState, { type, payload }) {

  switch (type) {

  case GET_AUTO_SUGGEST:
    return {
      ...state,
      loading: true
    }

  case GET_SUCCESS_AUTO_SUGGEST:
    return {
      ...state,
      autoSuggest: payload.result,
      loading: false
    }

  case GET_FAIL_AUTO_SUGGEST:
    return {
      ...state,
      loading: false
    }

  case UPDATE_SEARCH_DATA:
    return {
      ...state,
      searchData: { ...payload }
    }

  case SET_AUTO_SUGGEST:
    return {
      ...state,
      autoSuggest: {}
    }

  case SET_SEARCH_DATA:
    return {
      autoSuggest: {},
      loading: false,
      searchData: {}
    }

  default: return state
  }
}
