import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _head from "lodash/head";
import _get from "lodash/get";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import ManufacturersImageList from "../../components/about/manufacturers/manufacturers";
import resources from "../../config/content/manufacturersPage";
import URLRewriter from "../../components/urlRewriter";
import withTransition from "../../components/with-transition";
import { endpoint } from "../../config/app.config";
import * as actions from "./actions";
import * as styled from "./style";

const mapStateToProps = state => {
  return {
    manufacturersDetails: state.aboutReducer.manufacturers,
    manufacturersListLoader: state.aboutReducer.loading,
    manufacturerDetail: state.manufacturerReducer.manufacturerDetail,
    manufacturerLoader: state.manufacturerReducer.loading,
    corporatePartnerDetail: state.manufacturerReducer.corporatePartnerDetail
  };
};

export const makeBreadCrumb = (redirectLink, selectedCompanyName) => {
  return (
    <React.Fragment>
      <styled.LINK> <Link to={redirectLink}>{redirectLink}</Link>{selectedCompanyName}</styled.LINK>
    </React.Fragment>
  );
};

/**
 * Manufacturers page container
 *
 * @class Manufacturers
 * @extends {Component}
 * @exports Manufacturers
 */
class Manufacturers extends Component {

  state = {
    selectedCompanyId: null,
    selectedCompanyName: "",
    showManufacturersListLoader: true,
    showMobileManufacturers: false,
    redirectLink: null
  };

  componentDidMount() {
    const { corporatePartnerDetail, getCorporatePartnerDetail, manufacturerDetail} = this.props;
    if (_isEmpty(manufacturerDetail)) {
      this.props.getManufacturersList();
    }
    if (_isEmpty(corporatePartnerDetail)) {
      getCorporatePartnerDetail();
    }
  }

  componentWillUnmount() {
    this.props.setManufacturerDetail();
    this.setState({ redirectLink: "" });
  }

  componentDidUpdate(prevProps) {
    const { alinkplaceholder } = resources;
    const imgTags = document.querySelectorAll(".disclaimer img");
    if (imgTags) {
      imgTags.forEach((imgTag) => {
        let imgSource = imgTag.getAttribute("src");
        if (imgSource.includes(alinkplaceholder) && imgSource.split(alinkplaceholder).length > 0) {
          imgSource = endpoint + imgSource.split(alinkplaceholder)[1];
        }
        imgTag.setAttribute("src", imgSource);
      })
    }
  }

  /**
   * Guideline content render function
   * @returns {HTMLElement} html for the content
   */
  guidelineInfo = () => {
    const { guidelines_content, guidelines_heading, guidelines_desc_txt } = resources;
    return (
      <React.Fragment>
        <styled.GuideLineHeading>{guidelines_heading}</styled.GuideLineHeading>
        <styled.GuideLineDescription>{guidelines_desc_txt}</styled.GuideLineDescription>
        <styled.GuideLineWrapper>
          {
            guidelines_content.map((element, index) => {
              return (
                <styled.GuideLinesContent key={index}>
                  <img src={element.logo} alt={element.alt_txt}></img>
                  <div>
                    <h3>{element.heading}</h3>
                    <p>{element.description}</p>
                  </div>
                </styled.GuideLinesContent>
              );
            })
          }
        </styled.GuideLineWrapper>
      </React.Fragment>
    )
  }


  /**
   * Corporate partner function
   * @returns {HTMLElement} html for corporate partner
   */
  corporatePartner = () => {
    const {corporate_partners_heading, corporate_partners_description } = resources;
    const { data } = this.props.corporatePartnerDetail;
    if (data) {
      return (
        <styled.CorporatePartnerWrapper>
          <styled.CorporatePartnerHeading> { corporate_partners_heading }</styled.CorporatePartnerHeading>
          <styled.CorporatePartnerDescription> { corporate_partners_description }</styled.CorporatePartnerDescription>
          <styled.CorporatePartnerLogoWrapper>
            {
              data.map((element, index) => {
                return (
                  <styled.CorporatePartnerLogo key={index} onClick={() => this.corporatepartnerClick(element.urlTitle)}>
                    <img src={`${endpoint}/api/assets/images?id=${element.companyLogo}`} alt={element.imageAltText} ></img>
                  </styled.CorporatePartnerLogo>
                )
              })
            }
          </styled.CorporatePartnerLogoWrapper>
        </styled.CorporatePartnerWrapper>
      );
    }
  }

  /**
   * corporate partner click
   * @param {String} urlTitle for the company
   * @returns {undefined}
   */
  corporatepartnerClick = (urlTitle) => {
    this.props.history.push({
      pathname: `/corporate-partner/${urlTitle}`,
      state: {
        companyName: urlTitle
      }
    })
  }

  /**
   * Guideline text click function
   * @returns {undefined}
   */
  guidelineInfoClick = () => {
    if (document.querySelector(".manufacture-guideline-info")) {
      if (window.innerWidth < 768) {
        document.querySelector(".manufacture-guideline-info").scrollIntoView(true);
        window.scrollTo(0, window.pageYOffset - 110);
      }
      else {
        document.querySelector(".manufacture-guideline-info").scrollIntoView(false);
      }
    }
  }

  render() {
    const { manufacturers_txt, productList, contact_txt, about_txt, increment_symbol, filterImages, mobile_manufacturers_txt
      , section_logo, content, manufacture_content_info, section_logo_alt_txt, manufacture_sub_heading } = resources;
    const { manufacturersDetails, manufacturerDetail, manufacturerLoader, history } = this.props;
    const { selectedCompanyId, showManufacturersListLoader, selectedCompanyName, showMobileManufacturers, redirectLink } = this.state;
    const manufacturersCount = _get(manufacturersDetails, "size", 0);
    return (
      <styled.ManufacturesPage>
        <Container className="container">
          <styled.ManufacturersCountSection>
            <div className="manufacturers-count">
              {manufacturersCount}{increment_symbol}
            </div>
            <div className="manufacturers-text">
              {manufacturers_txt}
            </div>
          </styled.ManufacturersCountSection>
          <styled.MainSection>
            <styled.ManufactureInfo>
              <styled.Logosection>
                <img src={section_logo} alt={section_logo_alt_txt}></img>
              </styled.Logosection>
              <styled.ContentSection>
                <h2>{window.innerWidth < 768 ? "Our Member Organizations" : manufacture_content_info.heading}</h2>
                <p>{manufacture_content_info.info}</p>
              </styled.ContentSection>
            </styled.ManufactureInfo>
            <styled.ManufactureLogoList>
              <ManufacturersImageList {...manufacturersDetails} heading={mobile_manufacturers_txt} subheading={manufacture_sub_heading}></ManufacturersImageList>
            </styled.ManufactureLogoList>
            <styled.CorporatePartnership className="corporate-partner">
              {this.corporatePartner()}
            </styled.CorporatePartnership>
            <styled.ManufactureDetailInfo className="manufacture-guideline-info">
              {this.guidelineInfo()}
            </styled.ManufactureDetailInfo>
          </styled.MainSection>
        </Container>
      </styled.ManufacturesPage>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(Manufacturers));
