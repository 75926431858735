import React, { memo } from "react";
import PropTypes from "prop-types";
import * as styled from "./style";

const ProvideSection = props => {
  const { heading_content, heading1_txt, content = [] } = props.resources;
  const {history} = props;

  const renderProvideContent = (item, index) => (
    <div key={index}>
      <styled.SectionImageWrapper>
        <img src={item.img_src} alt={item.img_alt_txt} onClick={goToAboutPage}/>
      </styled.SectionImageWrapper>
      <styled.SectionImgDesc>{item.img_txt}</styled.SectionImgDesc>
    </div>
  );

  const goToAboutPage = () => {
    history.push("/about");
  }

  const createMarkup = (text) => {
    return {__html: text};
  }
  return (
    <styled.ProvideSection>
      <h4>Code of Practice</h4>
      <styled.ProvideHeadingContent dangerouslySetInnerHTML={createMarkup(heading_content)}></styled.ProvideHeadingContent>
      {/* <styled.ProvideHeading1>{heading1_txt}</styled.ProvideHeading1>
      <styled.ProvideContent>
        {content.map(renderProvideContent)}
      </styled.ProvideContent> */}
    </styled.ProvideSection>
  )
};

ProvideSection.propTypes = {
  resources: PropTypes.shape({
    heading1_txt: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
      img_src: PropTypes.string,
      img_alt_txt: PropTypes.string,
      img_txt: PropTypes.string
    }))
  })
};
export default memo(ProvideSection);
