import React from "react";
import _head from "lodash/head";
import * as styled from "./styles";

const renderContent = (assets, details, resource) => {
  const template = [];
  const { mail_to } = resource;

  assets.forEach((asset, key) => {
    const assetKey = _head(Object.keys(asset));
    const assetValue = _head(Object.values(asset));
    const Membershiptxt = "Membership Information";
    template.push(
      <div key={assetKey} className="contact-us">
        <styled.ContactusImageSection>
          <img src={assetValue.img_src} alt={assetValue.img_alt_txt} />
        </styled.ContactusImageSection>
        {key === 0 ?
          (
            <styled.ContactusImageSectionDesc>
              <a href={`${mail_to}:${details[assetKey]}`}>
                {details[assetKey]}
              </a>
            </styled.ContactusImageSectionDesc>
          ) :
          key === 2 ?
            <styled.ContactusImageSectionDesc>
              <div>{Membershiptxt}</div>
              <a href={`${mail_to}:${details["membershipInformation"]}`}>
                {details["membershipInformation"]}
              </a>
            </styled.ContactusImageSectionDesc>
            :
            <styled.ContactusImageSectionDesc>{details[assetKey]}</styled.ContactusImageSectionDesc>
        }
      </div>
    )
  });
  return template;
}

const ContactUs = React.memo(({ details, resource }) => {
  const contactDetails = _head(details.data) || {};
  const {contactUsTitle, contactUsDescription, contactUsImageAssests} = resource;
  const { address, email, phone} = contactDetails;

  if (address && email && phone) {
    return (
      <styled.ContactusSection>
        <styled.ContactusHeading>
          {contactUsTitle}
        </styled.ContactusHeading>
        <styled.ContactusDescription>
          {contactUsDescription}
        </styled.ContactusDescription>
        <styled.ContactusContent>
          {renderContent(contactUsImageAssests, contactDetails, resource)}
        </styled.ContactusContent>
      </styled.ContactusSection>
    );
  } else {
    return null;
  }
});
export default ContactUs;