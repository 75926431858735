import React, { Fragment, memo } from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import * as styled from "./styles";
import "./tooltipstyle.css";
import cancelFilledIcon from "../../assets/images/cancel-filled-icon.svg"

const SearchItemPill = props => {
  const { data, resources, searchDataUpdate, pillCloseClick, fromSearchPage } = props;
  const { clearIcon, clearIcon_alt_txt } = resources;

  const renderSearchData = () => {

    return (
      data.map((element, index) => {
        if (element.length < 15) {
          return (
            <React.Fragment key={index}>
              <styled.PillWrapper>
                <span>{element}</span>
                <styled.ClearInputButton type="button" onClick={() => removeItemFromSearchData(index)}>
                  <img src={window.innerWidth < 768 ? cancelFilledIcon : clearIcon} alt={clearIcon_alt_txt} />
                </styled.ClearInputButton>
              </styled.PillWrapper>
            </React.Fragment>
          )
        }
        else {
          return (
            <styled.ToolTipWrapper key={index}>
              <React.Fragment>
                <Tooltip placement="bottom" trigger={["hover"]} overlay={<span>{element}</span>} >
                  <styled.PillWrapper>
                    <span>{element}</span>
                    <styled.ClearInputButton type="button" onClick={() => removeItemFromSearchData(index)}>
                      <img src={window.innerWidth < 768 ? cancelFilledIcon : clearIcon} alt={clearIcon_alt_txt} />
                    </styled.ClearInputButton>
                  </styled.PillWrapper>
                </Tooltip>
              </React.Fragment>
            </styled.ToolTipWrapper>
          )
        }
      })
    )
  }
  const removeItemFromSearchData = (index) => {
    const newData = data;
    if (index > -1) {
      newData.splice(index, 1);
    }
    searchDataUpdate(newData);
    pillCloseClick(newData);
  }
  return (
    <React.Fragment>
      {renderSearchData()}
    </React.Fragment>
  )
}

export default memo(SearchItemPill);