import React from "react";
import PropTypes from "prop-types";
import * as styled from "./style";

const NewsDetails = React.memo(props => {
  const { selectedNewsDate, data } = props;

  /**
   * check if selcted news presents or else display first news in a list
   *
   * @returns {undefined}
   */
  if (data) {
    let selectedNews = null;
    const row = data.filter((cell, i) => {
      selectedNews = selectedNewsDate
        ? selectedNewsDate
        : Object.keys(data[0])[0];
      if (Object.keys(cell)[0] === selectedNews) return cell[selectedNews];
    });

    if (row) {
      return (
        <styled.NewsDetails id="news-details-container">
          {row[0][selectedNews].map((rowItem, index) => (
            <styled.margin displayTopBorder={ index > 0 } key={index}>
              <styled.NewsHeading key={index}>{rowItem.title}</styled.NewsHeading>
              <styled.NewsSubHeading >{selectedNews}</styled.NewsSubHeading>
              <styled.NewsDescription dangerouslySetInnerHTML={{__html: rowItem.bodyText }}></styled.NewsDescription>
            </styled.margin>
          )
          )}
        </styled.NewsDetails>
      );
    }
  } else {
    return null;
  }
});
NewsDetails.propTypes = {
  resources: PropTypes.shape({
    selectedNewsDate: PropTypes.string,
    data: PropTypes.object
  })
};

export default NewsDetails;
