import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CAREER, GET_SUCCESS_CAREER, GET_FAIL_CAREER } from "./actions";
import { getCareer } from "./api";

export function* getCareerDataWorker({ payload }) {
  try {
    const data = yield call(getCareer, payload);
    yield put({
      type: GET_SUCCESS_CAREER,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FAIL_CAREER });
  }
}

export function* getCareerDataWatcher() {
  yield takeLatest(GET_CAREER, getCareerDataWorker);
}

export default {
  getCareerDataWatcher
};
