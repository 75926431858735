import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import resources from "../../config/content/ourWhy";
import withTransition from "../../components/with-transition";
import * as styled from "./styles";

const OurWhy = props => {
  window.scrollTo(0, 0);
  const { header, img_src, img_src_alt, sections } = resources;
  return (
    <styled.OurWhyContainer>
      <styled.HeadingContent>
        <Container className="container">{header}</Container>
      </styled.HeadingContent>
      <Container className="body-container">
        <styled.ConditionSection>
          <styled.ImageSection>
            <img src={img_src} alt={img_src_alt} />
          </styled.ImageSection>
          {renderContent(
            sections
          )}
        </styled.ConditionSection>
      </Container>
    </styled.OurWhyContainer>
  )
}

const renderContent = (sections) => {
  return (
    <styled.ContentSection>
      {
        sections.map((element, index) => {
          return (
            <styled.Content index={index} key={index}>
              <styled.ContentTitle>
                {element.title}
              </styled.ContentTitle>
              <ul>
                {element.content.map((ele, key) => {
                  return (
                    <li key={key}>{ele}</li>
                  )
                })}
              </ul>
            </styled.Content>
          )
        })
      }
    </styled.ContentSection>
  )
}

OurWhy.propTypes = {
  resources: PropTypes.shape({
    header: PropTypes.string,
    img_src_alt: PropTypes.string,
    img_src: PropTypes.string,
    sections: PropTypes.array
  })
};

export default React.memo(withTransition(OurWhy));