import React, {useState} from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeHeroDesktop from "../../assets/images/Home_hero_desktop.png";
import HomeHeroTablet from "../../assets/images/Home_hero_tablet.png";
import HomeHeroMobile from "../../assets/images/Home_hero_mobile.png";
import AboutHeroDesktop from "../../assets/images/About_hero_desktop.png";
import AboutHeroTablet from "../../assets/images/About_hero_tablet.png";
import AboutHeroMobile from "../../assets/images/About_hero_mobile.png";
import Manufacturer from "../../assets/images/Manufacturer.jpg";
import CareerHeroDesktop from "../../assets/images/Career_hero_desktop.jpg";
import CareerHeroMobile from "../../assets/images/Career_hero_mobile.png";
import CareerHeroTablet from "../../assets/images/Career_hero_tablet.png";
import CovidDesktop from "../../assets/images/covid-banner_desktop.jpg";
import CovidTablet from "../../assets/images/covid-banner_tablet.jpg";
import CovidMobile from "../../assets/images/covid-banner_mobile.png";
import PublicationDesktop from "../../assets/images/publication_desktop.png";
import PublicationTablet from "../../assets/images/publication_tablet.png";
import PublicationMobile from "../../assets/images/publication_mobile.png";
import PodcastDesktop from "../../assets/images/Podcast_desktop.png";
import PodcastTablet from "../../assets/images/Podcast_tablet.png";
import PodcastMobile from "../../assets/images/Podcast_mobile.png";
import News from "../../assets/images/News.jpg";
import Dashboard from "../../assets/images/dashboardBanner.png";
import BlackImage from "../../assets/tour-images/black_img.jpeg";
import { MainContainer, OverLayWrapper } from "./styles";
import resource from "../../config/content/titleAndMeta";
import GAresources from "../../config/content/searchPage";

const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
const { googleAnalytics : {accessKey, pageview_txt}} = GAresources;
ReactGA.initialize(accessKey);
ReactGA.set({ anonymizeIp: true });
const pageVisited = [];
// if (window.location.hostname === "localhost") {
//   const value = "ga-disable-" + accessKey;
//   window[value] = true;
// }

const heroImageList = {
  "home": {
    "desktop": AboutHeroDesktop,
    "tablet": AboutHeroTablet,
    "mobile": AboutHeroMobile
  },
  "about": {
    "desktop": AboutHeroDesktop,
    "tablet": AboutHeroTablet,
    "mobile": AboutHeroMobile
  },
  "members": {
    "desktop": Manufacturer,
    "tablet": Manufacturer,
    "mobile": Manufacturer
  },
  "search": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "contact": {
    "desktop": AboutHeroDesktop,
    "tablet": AboutHeroTablet,
    "mobile": AboutHeroMobile
  },
  "careers": {
    "desktop": CareerHeroDesktop,
    "tablet": CareerHeroTablet,
    "mobile": CareerHeroMobile
  },
  "redirect": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "product": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "news": {
    "desktop": News,
    "tablet": News,
    "mobile": News

  },
  "microsoft": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "login": {
    "desktop": "",
    "tablet": ""
  },
  "dashboard": {
    "desktop": Dashboard,
    "tablet": Dashboard
  },
  "privacy-policy": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "terms-conditions": {
    "desktop": "",
    "tablet": "",
    "mobile": ""
  },
  "forgot-password": {
    "desktop": "",
    "tablet": ""
  },
  "change-password": {
    "desktop": "",
    "tablet": ""
  },
  "covid-19": {
    "desktop": CovidDesktop,
    "tablet": CovidTablet,
    "mobile": CovidMobile
  },
  "fellowship": {
    "desktop": CareerHeroDesktop,
    "tablet": CareerHeroTablet,
    "mobile": CareerHeroMobile
  },
  "publications": {
    "desktop": PublicationDesktop,
    "tablet": PublicationTablet,
    "mobile": PublicationMobile
  },
  "podcast": {
    "desktop": PodcastDesktop,
    "tablet": PodcastTablet,
    "mobile": PodcastMobile
  }
}

const heroImageSelector = pageName => {
  if (pageName.includes("news")) {
    return heroImageList["news"]
  }
  else if (pageName.includes("publications")) {
    return heroImageList["publications"]
  }
  else {
    return heroImageList[pageName] || heroImageList["home"];
  }
}

const visitedPage = [];

/**
 * Page Container
 *
 * @constant PageContainer
 * @exports PageContainer
 * @returns {HTMLElement} element
 */
const PageContainer = ({ children, history }) => {
  const pageComponentName= history.location.pathname.slice(1);
  const bgImageArr = heroImageSelector(pageComponentName);
  const { data } = resource;

  window.onunload = () => {
    const selectedDegree = localStorage.getItem("selectedDegree");
    const sessionId = localStorage.getItem("_sid");
    const dateNowTime = new Date().getTime();
    const lastActiveTime = new Date(localStorage.getItem("_activetime")).getTime();
    const remTime = Math.floor((dateNowTime-lastActiveTime)/ 1000);
    if (remTime > 1799) {
      localStorage.removeItem("_sid");
      sessionStorage.setItem("_sid", sessionId);
    }
    if (selectedDegree) {
      localStorage.removeItem("selectedDegree");
      sessionStorage.setItem("selectedDegree", selectedDegree);
    }
    const expert = localStorage.getItem("expert");
    const adsId = localStorage.getItem("adsId");
    if (adsId) {
      sessionStorage.setItem("adsId", adsId);
      localStorage.removeItem("adsId");
    }
    if (expert) {
      localStorage.removeItem("expert");
      sessionStorage.setItem("expert", expert);
    }
    sessionStorage.removeItem("tourRunning");
  }

  window.onload = () => {
    const selectedDegree = sessionStorage.getItem("selectedDegree");
    const expert = sessionStorage.getItem("expert");
    const adsId = sessionStorage.getItem("adsId");
    if (adsId) {
      localStorage.setItem("adsId", adsId);
    }
    if (selectedDegree) {
      localStorage.setItem("selectedDegree", selectedDegree);
    }
    if (expert) {
      localStorage.setItem("expert", expert);
    }
  }

  const createNewSessionId = () => {
    // const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
    const currentDate = new Date();
    const sessionId = currentDate.getUTCFullYear().toString() +
    (currentDate.getUTCMonth() + 1 > 10 ? (currentDate.getUTCMonth() + 1) : ("0" + (currentDate.getUTCMonth() + 1))) +
    (currentDate.getUTCDate() > 10 ? currentDate.getUTCDate() : "0" + (currentDate.getUTCDate())) +
    (currentDate.getUTCHours() > 10 ? currentDate.getUTCHours() : ("0" + (currentDate.getUTCHours()))) +
    (currentDate.getUTCMinutes() > 10 ? currentDate.getUTCMinutes() : ("0" + currentDate.getUTCMinutes())) +
    (currentDate.getUTCSeconds() > 10 ? currentDate.getUTCSeconds() : ("0") + currentDate.getUTCSeconds()) +
    Math.floor(Math.random() * 90000);
    if (sessionId) {
      localStorage.setItem("_sid", sessionId);
      sessionStorage.setItem("_sid", sessionId);
      // sendGAData(sessionId);
    }
  }

  const checkIdle = () => {
    const lastActiveTime = new Date();
    window.onclick = function () {
      this.lastActiveTime= new Date();
      localStorage.setItem("_activetime", this.lastActiveTime);
    };
    window.onmousemove = function () {
      this.lastActiveTime= new Date();
      localStorage.setItem("_activetime", this.lastActiveTime);
    };
    window.onkeypress = function () {
      this.lastActiveTime= new Date();
      localStorage.setItem("_activetime", this.lastActiveTime);
    };
    window.onscroll = function () {
      this.lastActiveTime= new Date();
      localStorage.setItem("_activetime", this.lastActiveTime);
    };

    const idleTimer_k = window.setInterval(CheckIdleTime, 1000 );

    function CheckIdleTime() {
      const dateNowTime = new Date().getTime();
      //returns idle time every 10 seconds
      // console.log(this.lastActiveTime)
      const lastActiveTime = new Date(this.lastActiveTime).getTime();
      const remTime = Math.floor((dateNowTime-lastActiveTime)/ 1000);
      // converting from milliseconds to seconds
      if (remTime > 1799) {
        localStorage.removeItem("_sid");
        sessionStorage.removeItem("_sid");
        this.lastActiveTime = new Date();
        createNewSessionId();
      }
    }
  }

  const addTitleAndMeta = () => {
    const sessionId = localStorage.getItem("_sid");
    const sessionIdSessionStorage = sessionStorage.getItem("_sid");
    if (sessionId && !sessionIdSessionStorage) {
      sessionStorage.setItem("_sid", sessionId);
    }
    else if (!sessionId && sessionIdSessionStorage) {
      localStorage.setItem("_sid", sessionIdSessionStorage);
    }
    if (!sessionId && !sessionIdSessionStorage) {
      createNewSessionId();
    }
    const mapOfUri = {
      "code-of-practice" : "Code Of Practice",
      "terms-conditions" : "Terms Conditions",
      "privacy-policy" : "Privacy Policy",
      "forgot-password": "Forgot Paswword",
      "change-password": "Change Password"
    }
    let pageComponentName= history.location.pathname.slice(1);
    if (pageComponentName.indexOf("/") >= 0) {
      pageComponentName = pageComponentName.slice(0, pageComponentName.indexOf("/"))
    }
    const title = data[`${pageComponentName}`] ? data[`${pageComponentName}`].title : "phactMI";
    const defaultMeta = "phactMI is a collaboration of pharmaceutical industry Medical Information (MI) leaders who are dedicated to support "
    + "healthcare professionals in their commitment to provide quality patient care.";
    const metaTag = data[`${pageComponentName}`] ? data[`${pageComponentName}`].meta : defaultMeta;
    //Function to fire bing ads tag
    if (window.previousLocation !== pageComponentName) {
      window.previousLocation = pageComponentName;
      window.FireSPA();
    }
    setTimeout(() => {
      if (pageVisited.length > 0) {
        if (pageVisited[0] !== window.location.pathname) {
          pageVisited.pop();
          pageVisited.push(window.location.pathname);
          if (sessionId) {
            sendGAData(sessionId);
          }
          else if (sessionIdSessionStorage) {
            sendGAData(sessionIdSessionStorage);
          }
        }
      }
      else {
        pageVisited.push(window.location.pathname);
      }
    }, 500)
    if (pageComponentName === "home") {
      return (
        <Helmet link = {[
          {
            rel: "canonical",
            href: "https://www.phactmi.org/"
          }
        ]}>
          <title>{title}</title>
          <meta name="description" content={metaTag}></meta>
        </Helmet>
      )
    }
    else {
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaTag}></meta>
        </Helmet>
      )
    }
  }

  const sendGAData = (sessionId) => {
    //Send Data to Google Analytics
    const ga = ReactGA.ga();
    const selectedDegree = JSON.parse(sessionStorage.getItem("selectedDegree"));
    let role = selectedDegree ? selectedDegree.role : "NE";
    if (role.toLowerCase().includes("patient") || role.toLowerCase().includes("consumer") || role.toLowerCase().includes("caregiver")) {
      role = "PT" // PT -> patient
    }
    const title = data[`${pageComponentName}`] ? data[`${pageComponentName}`].title : "phactMI";
    window.dataLayer = window.dataLayer || [];
    // if (!window.location.pathname.includes("/redirect")) {
    //   if (GADegrees.includes(role)) {
    //     ga("send", pageview_txt, `${window.location.pathname}${window.location.hash}`, {dimension1: role,
    //       dimension2: pageview_txt,
    //       dimension3: `${window.location.pathname}${window.location.hash}`,
    //       dimension8: "Null",
    //       dimension10: sessionId});
    //   }
    //   else {
    //     ga("send", pageview_txt, `${window.location.pathname}${window.location.hash}`, {dimension1: "Other",
    //       dimension2: pageview_txt,
    //       dimension3: `${window.location.pathname}${window.location.hash}`,
    //       dimension8: role,
    //       dimension10: sessionId});
    //   }
    // }
    if (!window.location.pathname.includes("/redirect")) {
      if (GADegrees.includes(role)) {
        window.dataLayer.push(
          {
            "event" : pageview_txt,
            "phactmiPageview": {
              "pageTitle" : title,
              "pageUrl" : `${window.location.pathname}${window.location.hash}`,
              "cd1" : role,
              "cd2" : pageview_txt,
              "cd3" : `${window.location.pathname}${window.location.hash}`,
              "cd8" : "Null"
            }
          }
        )
      }
      else {
        window.dataLayer.push(
          {
            "event" : pageview_txt,
            "phactmiPageview": {
              "pageTitle" : title,
              "pageUrl" : `${window.location.pathname}${window.location.hash}`,
              "cd1" : "Other",
              "cd2" : pageview_txt,
              "cd3" : `${window.location.pathname}${window.location.hash}`,
              "cd8" : role
            }
          }
        )
      }
    }
  }

  const addVendorTag = () => {
    let match = false;
    visitedPage.forEach((ele) => {
      if (ele === pageComponentName) {
        match = true;
      }
    })
    if (!match) {
      visitedPage.push(pageComponentName);
      const script = document.createElement("script");
      const script2 = document.createElement("script");
      script.type = "text/javascript";
      script.id = "lassosegment";
      script.src = "https://res.lassomarketing.io/scripts/lassotag.js";
      script2.async = true;
      script2.id = "lasotag";
      script2.type = "text/javascript";
      script2.src = "https://trc.lhmos.com/web?id=4860&type=IMG&enc=true";
      if (document.getElementById("lassosegment")) {
        document.getElementById("lassosegment").remove();
      }
      if (document.getElementById("lasotag")) {
        document.getElementById("lasotag").remove();
      }
      document.head.appendChild(script);
      document.head.appendChild(script2);
    }
  }

  return (
    <MainContainer bgImageArr={bgImageArr} className={`page-${pageComponentName}`}>
      {addTitleAndMeta()}
      {addVendorTag()}
      {checkIdle()}
      <OverLayWrapper className="overlay"></OverLayWrapper>
      {children}
    </MainContainer>
  )
};

export default withRouter(PageContainer);
