import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import resources from "../../config/content/redirectPage";
import searchpage_resource from "../../config/content/searchPage";
import * as styled from "./style";

/**
 * Redirect page container
 * @class RedirectNote
 * @extends {Component}
 * @exports RedirectNote
 */
class RedirectNote extends Component {

  static proptype = {
    img_src: PropTypes.string,
    leaving_message: PropTypes.string,
    navigation_message: PropTypes.string,
    redirect_message: PropTypes.string,
    cancelbutton_text: PropTypes.string,
    continuebutton_txt: PropTypes.string
  }

  state = {
    seconds: this.props.seconds
  }

  componentDidMount() {
    const { googleAnalytics : {externalclicks_txt}} = searchpage_resource;
    this.interval = setInterval(this.tick, 1000);
    const { location } = this.props;
    // const ga = ReactGA.ga();
    // ReactGA.set({ anonymizeIp: true });
    const selectedDegree = JSON.parse(sessionStorage.getItem("selectedDegree"));
    let role = selectedDegree ? selectedDegree.role : "NE";
    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
    if (role.toLowerCase().includes("patient")) {
      role = "PT" // PT -> patient
    }
    const link = location.search.replace("?url=", "");
    // const sessionId = this.getSessionId();
    window.dataLayer = window.dataLayer || [];
    if (GADegrees.includes(role)) {
      // ga("send", "event", role, externalclicks_txt, link, {dimension1: role,
      //   dimension2: externalclicks_txt,
      //   dimension3: link,
      //   dimension8: "Null",
      //   dimension10: sessionId});
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": role,
          "eventAction": externalclicks_txt,
          "eventLabel": link,
          "cd1": role,
          "cd2": externalclicks_txt,
          "cd3": link,
          "cd8": "Null"
        }
      })
    }
    else {
      // ga("send", "event", role, externalclicks_txt, link, {dimension1: "Other",
      //   dimension2: externalclicks_txt,
      //   dimension3: link,
      //   dimension8: role,
      //   dimension10: sessionId});
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": role,
          "eventAction": externalclicks_txt,
          "eventLabel": link,
          "cd1": "Other",
          "cd2": externalclicks_txt,
          "cd3": link,
          "cd8": role
        }
      })
    }
  }

  getSessionId = () => {
    let sessionId = localStorage.getItem("_sid");
    if (!sessionId) {
      sessionId = sessionStorage.getItem("_sid");
      if (!sessionId) {
        sessionId = "NA";
      }
    }
    return sessionId;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
  * counter event handler
  * @returns {undefined}
  */
  tick = () => {
    const { seconds } = this.state;
    this.setState({ seconds: seconds - 1 });
    if (seconds <= 1) {
      clearInterval(this.interval);
      const content = this.props.location.search.replace("?url=", "");
      window.open(content, "_self");
    }
  }

  /**
   * Cancel Button event handler
   * @returns {undefined}
   */
  onClickCancelButton = () => {
    window.close();
  }

  /**
   * Cancel Button event handler
   * @returns {undefined}
   */
  onClickContinueButton = () => {
    const content = this.props.location.search.replace("?url=", "");
    window.open(content, "_self");
  }

  render() {
    const { img_src, leaving_message, navigation_message, redirect_message, cancelbutton_text, continuebutton_txt } = resources;
    return (
      <styled.RedirectPage>
        <styled.HeadingContent>
          <Container className="container" />
        </styled.HeadingContent>
        <Container className="body-container">
          <styled.MessageSection>
            <styled.ImageSection>
              <img src={img_src} alt={img_src} />
            </styled.ImageSection>
            <styled.LeavingMessage>
              {leaving_message}
            </styled.LeavingMessage>
            <styled.NavigationMessage dangerouslySetInnerHTML={{ __html: navigation_message }} />
            <styled.RedirectMessage>
              {redirect_message.replace("time", this.state.seconds)}
            </styled.RedirectMessage>
            <styled.ButtonSection>
              <styled.CancelButton onClick={this.onClickCancelButton}>
                {cancelbutton_text}
              </styled.CancelButton>
              <styled.ContinueButton onClick={this.onClickContinueButton}>
                {continuebutton_txt}
              </styled.ContinueButton>
            </styled.ButtonSection>
          </styled.MessageSection>
        </Container>
      </styled.RedirectPage>
    );
  }
}

export default withRouter(React.memo(RedirectNote));
