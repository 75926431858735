const awsRegion = "us-east-1";
const userPoolId = "us-east-1_uIcoefg0t";
const userPoolWebClientId = "3rrh309kun2bg5edla2bpj8aqi";
const awsCognitoDomain = "https://phactmi.auth.us-east-1.amazoncognito.com";
// eslint-disable-next-line max-len
const awsCognitoHostedUI = "https://phactmi.auth.us-east-1.amazoncognito.com/login?client_id=3rrh309kun2bg5edla2bpj8aqi&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://maintenance.phactmi.org"

export {
  awsRegion,
  userPoolId,
  userPoolWebClientId,
  awsCognitoDomain,
  awsCognitoHostedUI
}