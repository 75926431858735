
export const colors = {
  "sherpa_blue": "#004251",
  "polar": "#fbfdfe",
  "prussian_blue": "#003a4c",
  "regent_gray": "#84959d",
  "gray": "#838383",
  "scorpion": "#575757",
  "scorpion_gray": "#606060",
  "catskill_white": "#FAFBFD",
  "catskill_white_gray": "#F5F9FA",
  "bondi_blue": "#00b59a",
  "white": "#fff",
  "black": "#000",
  "nav_blue": "#1f3633",
  "header_border": "#0c5460",
  "gumbo": "#7FA0A8",
  "atlantis": "#8ec740",
  "japonica": "#d86f6f",
  "dove_gray": "#656565",
  "dove_faintgray": "#6e6e6e",
  "dove_gray_rgb": "rgba(101,101,101, 0.6)",
  "dove_darkgray": "#717171",
  "catskill_gray": "#f6f9fb",
  "santas_gray": "#A0A0A1",
  "santas_gray_rgb": "rgba(160, 160, 161, 0.1)",
  "geyser": "#D2D9E0",
  "mercury": "#e2e2e2",
  "alto": "#d9d9d9",
  "downy": "#6dccbf",
  "davy_grey": "#555",
  "light_grey": "#F4F8F9",
  "botticelli": "#ccdee6",
  "mako": "#434d52",
  "concrete": "#f2f2f2",
  "alabaster": "#f8f8f8",
  "nobel": "#b4b4b4",
  "mine_shaft": "#272727",
  "silver": "#BABABA",
  "dusty_gray": "#999",
  "gallery": "#EAEAEA",
  "tundora": "#434343",
  "silver_chalice": "#adadad",
  "boulder": "#747474",
  "persian_green": "#00b499",
  "mystic": "#E6ECF0",
  "mystic_approx": "#d9e4eb",
  "pine_green": "#006F6D",
  "gumbo_approx": "#80a1a8",
  "alto_approx": "#d1d1d1",
  "alto_fade": "#d8d8d8",
  "loblolly": "#b1c5c9",
  "nobel_grey":"#a2a2a2",
  "yellow_color": "#FFFF00",
  "tundora_approx": "#4d4d4d",
  "aqua_haze": "#eaf2f1",
  "scorpion_faint": "#5a5a5a",
  "cornflower_blue": "#06b69c",
  "science_blue": "#0067B8",
  "punch": "#dd4b39",
  "turquoise_blue": "#4AEFD3",
  "catskill_grayscale": "#EEF3F7",
  "aqua_blue": "#edf2f5",
  "soft_black": "#808080",
  "cruise": "#B9EBE4",
  "alabaster_gray": "#f9f9f9",
  "alto_gray": "#e0e0e0",
  "scorpion_dark": "#575757",
  "alto_solid": "#d2d2d2",
  "gray_suit": "#BCB8C5",
  "gallery_gray": "#ededed",
  "dusty_gray_dark": "#979797",
  "solid_grey": "#5f5f5f",
  "light_blue": "#307180",
  "sky_dark_blue": "#d3e9f0",
  "light_sky_grey": "#a0b2b8",
  "light_black": "#504d4d",
  "light_solid_grey": "#646060",
  "light_grey_suit": "#707070",
  "light_greyish_black": "#7c7c7c",
  "solid__light_grey": "#b7b0b0",
  "light_solid_blue": "#007bff",
  "dark_blue": "#0864c7",
  "light_darkish_blue": "#F7F9FA",
  "gryish_black": "#6F6F6F",
  "bondi_green": "#1EBDA5",
  "light_dove_gray": "#D6DFE3",
  "light_bondi_green": "#E2F2F0",
  "dark_green": "#00717D",
  "light_dove_grey": "#dee2e6",
  "light_grey_black": "#d5dde2",
  "violet": "#3732a7",
  "medium_dove_gray": "#F7F7F7",
  "dark_red": "#e25043",
  "dark_bondi_green": "#22bda5",
  "table_header": "#2b789f",
  "table_row_color":"#eef9ff",
  "light_transparent_green": "#e3edef",
  "light_gray": "e3e3e3",
  "reduced_sky": "#d8f0ee"
}

export const containerMaxWidth = "1140px";
