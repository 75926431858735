import dashboardBanner from "../../assets/images/dashboardBanner.png";
import analytics from "../../assets/images/analytics.png";
import filterCross from "../../assets/images/filterCross.png";
import info from "../../assets/images/info.png";

export default {
  bannerImage: dashboardBanner,
  totalProductsLabel: "Total Products",
  menu: [
    {
      label: "Analytics",
      icon: analytics
    }
  ],
  topProducts: {
    topViewedProducts: "Top Viewed Products",
    headers: [{
      label: "Products"
    },
    {
      label: "Total Views"
    },
    {
      label: "Views by Filter"
    }]
  },
  filter: {
    label: "View data from",
    filterLabel: "Filters",
    items: [
      {
        label: "Last 7 days",
        value: 7
      },
      {
        label: "Last 30 days",
        value: 30
      },
      {
        label: "Last 90 days",
        value: 90
      },
      {
        label: "Last 120 days",
        value: 120
      }
    ],
    allFilter: "All",
    clearAll: "Clear All",
    closeIcon: filterCross,
    filterTypes: {
      products: {
        label: "Products"
      },
      region: {
        label: "Regions"
      },
      userDegree: {
        label: "Professions"
      }
    },
    filterOrder: ["products", "region", "userDegree"]
  },
  dashboardLinks: [
    {
      label: "Dashboard",
      link: "/dashboard",
      customClass: "dashboard-header"
    },
    {
      label: "Logout",
      link: "/home",
      customClass: "logout-header",
      action: "LOGOUT"
    }
  ],
  visitorsText: "Visitors in last time days in USA",
  visitorsByPrefoession: "Visitors by Profession",
  visitorsByRegion: "Visitors by Region",
  tooltipText: {
    visitors: "Visitors in ",
    total: "Total: ",
    byFilter: "By filter: "
  },
  regionMapText: "Kindly hover over the region to see the visitors count",
  regionMapTextDevice: "Kindly tap & hold on the region to see the visitors count",
  barChartText: "Kindly hover over the profession to see the visitors count",
  barChartTextDevice: "Kindly tap & hold on the profession to see the visitors count",
  infoImage: info,
  toolTipData: {
    "visitors": "Visitors",
    "total": "Total: ",
    "by_filter": "By filter: "
  }
}
