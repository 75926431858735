import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const OurWhyContainer = styled.div`
    color: ${colors.sherpa_blue};
    position: relative;
    background: ${colors.white};
    @media ${ipadProRange} {
      margin-top: -10px;  
    }
    & .body-container {
    max-width: ${containerMaxWidth};
    padding: 0;
    @media ${mobileRange} {
        margin-top: 25px;
    }
    @media ${tabletRange} {
        margin-top: -60px;
    }
    }
`;

export const HeadingContent = styled.div`
  background: ${colors.light_grey};
  font-size: 30px;
  @media ${mobileRange} {
    width: 100%;
    font-size: 25px;
    padding-top: 45px;
  }
  & .container {
    padding: 62px 100px 62px 84px;
    max-width: ${containerMaxWidth};
    font-size: 25px;
    @media ${mobileRange} {
      padding: 55px 2% 120px;
      text-align: center;
    }
    @media ${tabletRange} {
      padding: 30px 0 90px 84px;
    }
  }
`;

export const ConditionSection = styled.div`
  width: 100%;
  background: ${colors.white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  margin-top: -23px;
  @media ${mobileRange} {
    padding: 94px 17px 25px;
  }
  @media ${ipadProRange} {
    margin-top: 0px;
  }
`;

export const ImageSection = styled.div`
  float: right;
  width: 28%;
  margin-top: -90px;
  @media ${mobileRange} {
    width: 100%;
    margin-top: -200px
    text-align: center;
  }
  @media ${tabletRange} {
    width: 34%;
    margin-top: -50px;
  }
  @media ${ipadProRange} {
    margin-top: -80px;
  }
  img {
    width: 290px;
    @media ${mobileRange} {
      width: 240px;
    }
    @media ${tabletRange} {
      width: 175px;
    }
    @media ${ipadProRange} {
      width: 240px;
    }
  }
`;

export const ContentSection = styled.div`
`;

export const Content = styled.div`
  background: ${props => props.index % 2 === 0 ? colors.white : colors.light_grey };
  padding: 18px 96px;
  @media ${mobileRange} {
    padding: 25px;
    overflow-wrap: break-word;
  }
  @media ${tabletRange} {
    padding: 20px 82px;
  }
  ul {
    font-size: 18px;
    color: ${colors.davy_grey};
    padding: 0 0 0 20px;
  }
`;

export const ContentTitle = styled.h2`
  font-size: 22px;
  color: ${colors.sherpa_blue};
  letter-spacing: -0.29px;
  padding: 18px 0 20px;
  margin-bottom: 0;
`;