import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, minLaptop, ipadProRange } from "../../common/styles/breakpoints";
import starActive from "../../assets/images/starActive.png";
import starInactive from "../../assets/images/starInactive.png";
import starGreyed from "../../assets/images/star-greyed.svg";
import startColored from "../../assets/images/star-colored.svg";

export const Container = styled.div`
    background-color: ${colors.bondi_blue};
    min-height: 266px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding-top: ${props => props.submitFeedback ? "65px" : "0"};
    }
    @media ${mobileRange} {
        display: ${props => props.hide ? "none" : "flex"};
    }
`;

export const SubmitButton = styled.button`
    border-radius: 5px;
    background-color: ${colors.sherpa_blue};
    padding: 13px 62px;
    border: none;
    display: ${props => props.showSubmitButton ? "block" : "none"};
    margin-bottom: 29px;
    color: ${colors.white};
    font-size: 14px;
    :disabled, [disabled]{
        color: ${colors.gumbo_approx};
    }
    @media ${mobileRange} {
        min-height: 40px;
        min-width: 102px;
        padding: 0;
        display: none;
    }
    @-moz-document url-prefix() {
        height: 44px;
    }
    @media ${tabletRange} {
        min-height: 40px;
        min-width: 102px;
        padding: 0;
    }
`;

export const InputTextBox = styled.input`
    border: solid 1px ${colors.white};
    border-radius: 5px;
    padding: 0 12px;
    background-color: ${colors.bondi_blue};
    opacity: ${props => props.showInputTextBox ? "1" : "0"};
    height: 40px;
    width: 421px;
    font-size: 16px;
    margin-left: 25px;
    line-height: 1.5;
    color: ${colors.white};
    ::placeholder {
        color: ${colors.white};
        opacity:  ${props => props.showInputTextBox ? "0.45" : "0"};
    }
    :-ms-input-placeholder {
        color: ${colors.white};
        opacity: ${props => props.showInputTextBox ? "0.45" : "0"};
    }
    ::-ms-input-placeholder {
        color: ${colors.white};
        opacity: ${props => props.showInputTextBox ? "0.45" : "0"};
    }
    ::-ms-clear {
        display: none;
    }
    @media ${mobileRange} {
        width: 100%;
        border-radius: 5px;
        background-color: ${colors.white};
        border: 1px solid #E0E0E0;
        color: #999D9D;
        ::placeholder {
            color: #999D9D;
        }
        :-ms-input-placeholder {
            color: #999D9D};
        }
        ::-ms-input-placeholder {
            color: #999D9D;
        }
    }
    :focus {
        outline: none;
        box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.25);
        @media ${mobileRange} {
            border: solid 1px ${colors.white};
        }
    }
`;

export const InputTextSection = styled.div`
    min-width: 421px;
    height: 42px;
    margin: 16px;
    border: none;
    color: ${colors.white}
    box-sizing: border-box;
    @media ${mobileRange} {
        min-width: 315px;
        margin: 16px 16px 6px;
    }
`;

export const QuestionText = styled.div`
    font-size: 30px;
    text-align: center;
    line-height: 0.8;
    letter-spacing: 0.39px;
    color: ${colors.white};
    margin-bottom: 16px;
    @media ${mobileRange} {
        font-size: 14px;
        line-height: 1.23;
        padding: 10px 40px 0;
        letter-spacing: -0.34px;
        text-align: left;
        color: #121313;
    }
    @media ${tabletRange} {
        padding: 0 50px;
        line-height: 0.92;
        font-size: 26px;
    }
`;

export const InformationalText = styled.div`
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.21px;
    color: ${colors.white};
    margin: 40px 0 16px;
    @media ${mobileRange} {
        margin: 16px 0;
    }
`;

export const RadioButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media ${mobileRange} {
        justify-content: flex-start;
    }
    .radioButtonContainer {
        display: block;
        position: relative;
        padding-left: 45px;
        margin: 0 10px 9px;
        color: ${colors.white};
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: ${colors.bondi_blue};
        @media ${mobileRange} {
            color: #434545;
            font-size: 14px;
            background: none;
        }
    }
    .radioButtonContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 25%;
        left: 24%;
        height: 17px;
        width: 17px;
        background-color: ${colors.bondi_blue};
        border-radius: 50%;
        border: solid 2px ${colors.white};
        cursor: pointer;
    }
    .radioButtonContainer:hover input ~ .checkmark {
        background-color: ${colors.bondi_blue};
    }
    .radioButtonContainer input:checked ~ .checkmark {
        background-color: ${colors.bondi_blue};
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .radioButtonContainer input:checked ~ .checkmark:after {
        display: block;
    }
    .radioButtonContainer .checkmark:after {
        top: 20%;
        left: 25%;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: ${colors.white};
    }
`;

export const ResponseMessageText = styled.div`
    font-size: 18px;
    text-align: center;
    color: ${colors.white};
`;

export const ResponseHeadline = styled.div`
    font-size: 32px;
    text-align: center;
    color: ${colors.white};
`;

export const ImgWrapper = styled.img`
    margin-bottom: 9px;
    width: 43px;
`;

export const InformationalUnderline = styled.div`
    background-color: ${colors.white};
    opacity: 0.4;
    height: 2px;
    padding: 0 4%;
    @media ${mobileRange} {
        padding: 0 11%;
    }
    @media ${tabletRange} {
        padding: 0 5%;
    }
`;

export const QuestionSlider = styled.div`
   display: inline-block;
   height: 7px;
   width: 7px;
   margin: 0 4px;
   border-radius: 50%;
   opacity: 0.6;
   background-color: white;
`;

export const RatingWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media ${mobileRange} {
        justify-content: flex-start;
        margin-left: 30px;
    }

    .rating {
        overflow: hidden;
        display: inline-block;
        width: auto;
        height: 24px;
      }
      .rating > input {
        opacity: 0;
        display: none;
      }
      .rating > label {
        margin: 0 4px;
        position: relative;
        display: block;
        cursor: pointer;
        float: right;
        background: url(${starInactive});
        background-size: 24px 24px;

        @media ${mobileRange} {
            background: url(${starGreyed});
            width: 28px;
            height: 28px;
        }

      }
      .rating > label:before {
        display: block;
        opacity: 0;
        content: '';
        width: 24px;
        height: 24px;
        background: url(${starActive});
        background-size: 24px 24px;
        transition: opacity 0.2s linear;

        @media ${mobileRange} {
            background: url(${startColored});
            width: 28px;
            height: 28px;
        }
      }
      .rating > label:hover:before,
      .rating > label:hover ~ label:before,
      .rating:not(:hover) > :checked ~ label:before
       { opacity: 1; }
`;


export const InputTextBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    height: 40px;
    @media ${mobileRange} {
        margin: 0;
        height: unset;
        padding-top: 0;
        display: ${props => props.currentResponse ? "flex" : "none"};
    }
    .submitarrow {
        display: flex;
        height: 40px;
        align-items: center;
        margin-left: 25px;
        img {
            cursor: pointer;
            width: 33px;
            @media ${mobileRange} {
                width: 25px;
            }
        }
        @media ${mobileRange} {
            margin-left: 10px;
        }
    }
`;

export const QuestionWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    @media ${mobileRange} {
        height: auto;
    }
    @media ${tabletRange} {
        height: 175px;
    }
    @media ${ipadProRange} {
        height: 180px;
    }
    @media ${minLaptop} {
        height: 180px;
    }
`;

export const QuestionHandler = styled.div`
    padding-bottom: 20px;
`;

export const ActiveQuestionSlider = styled.div`
    display: inline-block;
    height: 7px;
    width: 16px;
    margin: 0 4px;
    border-radius: 16px;
    opacity: 1;
    background-color: white;
`;

export const HandleCount = styled.div`
    display: flex;
    font-size: 16px;
    color: ${colors.white};
    .count_of_txt {
        margin: 0 5px;
    }
`;

export const MobileSubmit = styled.button`
    display: none;
    @media ${mobileRange} {
        border-radius: 5px;
        background-color: #00B59A;
        border: none;
        padding: 7px 50px;
        margin: auto;
        margin-top: 20px;
        display: block;
        color: ${colors.white};
        font-size: 16px;
        :disabled, [disabled]{
            color: ${colors.white};
            cursor: not-allowed;
            opacity: 0.6
        }
    }
`;

export const FeedbackContainerMobile = styled.div`
    display: none;
    @media ${mobileRange} {
        margin: 20px 16px;
        border-radius: 12px;
        background: #F1FCFA; 
        padding: 20px 16px;
        display: block;
    }
`;

export const FeedbackHeadingMobile = styled.h4`
    font-size: 20px;
    font-weight: 700;
    color: #121313;
    margin: 0;
`;

export const QuestionWrapperMobile = styled.div`
    margin-top: 20px;
    p {
        font-size: 14px;
    }
`;