import { takeLatest, put, call } from "redux-saga/effects";
import { GET_SEARCH_RESULT, GET_SUCCESS_SEARCH_RESULT, GET_FAIL_SEARCH_RESULT,
  GET_MANUFACTURER_DETAILS, GET_SUCCESS_MANUFACTURER_DETAILS, GET_FAIL_MANUFACTURER_DETAILS,
  GET_DATA_USING_CODENAME_SUCCESS, GET_DATA_USING_CODENAME_FAIL, GET_DATA_USING_CODENAME,
  GET_SOURCE_NAME, GET_SOURCE_NAME_SUCCESS, GET_SOURCE_NAME_FAIL, GET_SUMMARY, GET_SUMMARY_SUCCESS, GET_SUMMARY_FAIL,
  GET_PI_DETAILS_FAILURE, GET_PI_DETAILS_SUCCESS, GET_PI_DETAILS } from "./actions";
import { getSearchResult, getManufacturerDetails, getCompanyId, getSummary, getProductDetails, getSectionNameUsingDataCode } from "./api";
import _get from "lodash/get";

export function* getSearchResultDataWorker({ payload }) {
  try {
    const data = yield call(getSearchResult, payload);

    yield put({
      type: GET_SUCCESS_SEARCH_RESULT,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FAIL_SEARCH_RESULT });
  }
}

export function* getPIDetailsWorker({ payload }) {
  try {
    const data = yield call(getProductDetails, payload);
    yield put({
      type: GET_PI_DETAILS_SUCCESS,
      payload: {
        result: data
      }
    });
  }
  catch (e) {
    yield put({ type: GET_PI_DETAILS_FAILURE });
  }
}

export function* getPiDetailsWatcher() {
  yield takeLatest(GET_PI_DETAILS, getPIDetailsWorker);
}

export function* getSearchResultDataWatcher() {
  yield takeLatest(GET_SEARCH_RESULT, getSearchResultDataWorker);
}

export function* getManufacturerDetailsDataWorker({ payload }) {
  try {
    const data = yield call(getManufacturerDetails, payload);

    yield put({
      type: GET_SUCCESS_MANUFACTURER_DETAILS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FAIL_MANUFACTURER_DETAILS });
  }
}

export function* getManufacturerDetailsDataWatcher() {
  yield takeLatest(GET_MANUFACTURER_DETAILS, getManufacturerDetailsDataWorker);
}

// export function* getSourceNameDataWorker({ payload }) {
//   const data = [];
//   const compDetails = _get(payload, "companyDetail", []);
//   try {
//     for (let i=0; i<compDetails.length; i++) {
//       const response = yield call (getCompanyId, compDetails[i]);
//       data.push(response);
//     }
//     yield put({
//       type: GET_SOURCE_NAME_SUCCESS,
//       payload: {
//         result: data
//       }
//     })
//   } catch (e) {
//     yield put({ type: GET_SOURCE_NAME_FAIL });
//   }
// }

// export function* getSourceNameDataWatcher() {
//   yield takeLatest(GET_SOURCE_NAME, getSourceNameDataWorker);
// }

export function* getSummaryDataWorker({ payload }) {
  try {
    const data = yield call(getSummary, payload);

    yield put({
      type: GET_SUMMARY_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_SUMMARY_FAIL });
  }
}

export function* getSummaryDataWatcher() {
  yield takeLatest(GET_SUMMARY, getSummaryDataWorker);
}

export function* getDataUsingDataCodeWorker() {
  try {
    const data = yield call(getSectionNameUsingDataCode);
    yield put({
      type: GET_DATA_USING_CODENAME_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({
      type: GET_DATA_USING_CODENAME_FAIL,
      payload: {
        error: e
      }
    });
  }
}

export function* getDataUsingDataCodeWatcher() {
  yield takeLatest(GET_DATA_USING_CODENAME, getDataUsingDataCodeWorker);
}

export default {
  getSearchResultDataWatcher,
  getManufacturerDetailsDataWatcher,
  // getSourceNameDataWatcher,
  getPiDetailsWatcher,
  getSummaryDataWatcher,
  getDataUsingDataCodeWatcher
};
