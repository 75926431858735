import axios from "axios";
import { endpoint } from "../../config/app.config";

export async function getManufacturerDetail(companyName) {
  return axios
    .get(`${endpoint}/api/manufacturer/${companyName}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getCorporatePartnerDetail() {
  return axios
    .get(`${endpoint}/api/corporatepartner`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}

export async function getCorporatepartner(companyName) {
  return axios
    .get(`${endpoint}/api/corporatepartner/${companyName}`)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      throw err;
    })
}
