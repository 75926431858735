import { POST_USER_FEEDBACK, POST_SUCCESS_USER_FEEDBACK, POST_FAIL_USER_FEEDBACK, SET_USER_FEEDBACK,
  GET_FEEDBACK_QUESTION, GET_FEEDBACK_QUESTION_SUCCESS, GET_FEEDBACK_QUESTION_FAIL} from "./actions";

const initialState = {
  submittedUserFeedback: null,
  loading: false,
  feedbackQuestion: []
}

export default function userFeedbackReducer(state = initialState, { type, payload }) {
  switch (type) {

  case POST_USER_FEEDBACK:
    return {
      ...state,
      loading: true
    }

  case POST_SUCCESS_USER_FEEDBACK:
    return {
      ...state,
      submittedUserFeedback: true,
      loading: false
    }

  case POST_FAIL_USER_FEEDBACK:
    return {
      ...state,
      loading: false,
      submittedUserFeedback: false
    }

  case SET_USER_FEEDBACK:
    return {
      ...state,
      submittedUserFeedback: null
    }

  case GET_FEEDBACK_QUESTION:
    return {
      ...state,
      loading: true
    }

  case GET_FEEDBACK_QUESTION_SUCCESS:
    return {
      ...state,
      feedbackQuestion: payload.result,
      loading: false
    }

  case GET_FEEDBACK_QUESTION_FAIL:
    return {
      ...state,
      loading: false
    }

  default: return state
  }
}
