import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const HomePage = styled.div`
  min-height: 700px;
  color: ${colors.sherpa_blue};
  font-size: 20px;
  position: relative;
  background: ${colors.white};
  & .body-container {
    max-width: ${containerMaxWidth};
    padding:0;
    @media ${mobileRange}{
      padding: 0;
    }
    @media ${tabletRange} {
      padding: 0;
      margin-top:-60px
    }
  }
`;

export const HeadingContent = styled.h1`
  background: ${colors.light_grey};
  font-size: 30px;
  margin-bottom: 0;
  @media ${mobileRange}{
    width: 100%;
    font-size: 25px;
    padding-top: 45px;
  }
  & .container {
    padding: 62px 100px 62px 84px;
    max-width: ${containerMaxWidth};
    font-size: 25px;
    @media ${mobileRange}{
      padding: 55px 0 120px;
      text-align: center;
    }
    @media ${tabletRange}{
      padding: 30px 0 90px 84px;
    }
  }
`;

export const ConditionSection = styled.div`
  width:100%
  font-size: 16px;
  line-height: 1.78;
  letter-spacing: -0.23px;
  text-align: left;
  color: ${colors.davy_grey};
  padding: 35px 96px 35px ;
  background: ${colors.white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  margin-top: -23px;

  @media ${mobileRange}{
    padding: 94px 17px 25px ;
  }
  @media ${tabletRange}{
    padding: 25px 64px 20px 84px;
    font-size :14px
  }
`;

export const ImageSection = styled.div`
float: right;
width: 33%;
margin-top: -145px;

@media ${mobileRange} {
  width: 100%;
  text-align: center;
  margin-top: -175px;
}

@media ${tabletRange} {
  width: 41%;
  margin-top: -87px;
}

img {
  width: 336px;
  @media ${mobileRange} {
    width: 205px;
  }
  @media ${tabletRange}{
    width:205px
  }
}
`;

