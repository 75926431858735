import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios"
import { saveAs } from "file-saver";
import BreadCrumbs from "../breadCrumbs";
import NoResultsFound from "../no-results-found";
import { endpoint } from "../../config/app.config";
import * as styled from "./styles";
import { ContentSection } from "../tour-tooltip/styles";

const mapStateToProps = state => {
  return {
    sectionNameUsingDCode: state.searchResultReducer.sectionNameUsingDCode
  }
}

class PiDetails extends Component {

  state = {
    organicName: "",
    hasIndex: true,
    loading: false,
    downloadError: null,
    maxContainerHeight: "700px",
    tableOfContentSize: "500",
    isShowTitleRequired: false,
    showFullTitle: false
  };

  componentDidMount() {
    this.moveWarningToTop();
    const PiDetails = this;
    window.scrollTo(0, 0);
    const scrollableContents = document.querySelectorAll(".scrollable-section");
    if (this.props.item)
      this.getOrganicName();
    if (document.querySelector("#Index")) {
      this.moveIndex();
    }
    else {
      this.hideTitle();
      this.setState({ hasIndex: false });
    }
    const headers = document.querySelectorAll(".newIndex h1 a");
    window.dataLayer = window.dataLayer || [];
    const degreeDetails = JSON.parse(localStorage.getItem("selectedDegree"));
    headers.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "pidocumentsectionclicks",
            "eventLabel":this.props.item.drug,
            "cd1": degreeDetails.role,
            "cd2": "pidocumentsectionclicks",
            "cd3": this.props.item.drug, // doc name
            "cd5": this.props.item.producer, // source name
            "cd12": this.props.item.drug, // product name
            "cd13": "Navbar link", // link type
            "cd14": "", // link url
            "cd15": link.textContent // link name
          }
        })
        const id = document.querySelector(link.getAttribute("href"));
        let currentActive = document.querySelector(".active");
        if (currentActive && currentActive === link.closest(".active")) {
          currentActive.classList.remove("active");
          return;
        }
        if (currentActive)
          currentActive.classList.remove("active");
        currentActive = link.closest(".link-container");
        if (id) {
          this.anchorScrollWithMargin(e, id);
        }
        if (currentActive) {
          currentActive.classList.add("active");
          PiDetails.setActiveIndexContent(currentActive);
        }
      });
    });
    const subheader = document.querySelectorAll(".newIndex h2 a");
    subheader.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "pidocumentsectionclicks",
            "eventLabel":this.props.item.drug,
            "cd1": degreeDetails.role,
            "cd2": "pidocumentsectionclicks",
            "cd3": this.props.item.drug, // doc name
            "cd5": this.props.item.producer, // source name
            "cd12": this.props.item.drug, // product name
            "cd13": "Navbar link", // link type
            "cd14": "", // link url
            "cd15": link.textContent // link name
          }
        })
        const id = document.getElementById(link.getAttribute("href").split("#")[1]);
        if (id) {
          this.subheaderScrollWithMargin(e, id);
        }
      })
    })
    const header = document.querySelectorAll(".newIndex .link-container");
    header.forEach((link) => {
      const subheaderWrapper = link.querySelector(".subheader-wrapper");
      if (subheaderWrapper) {
        const header = link.querySelector("h1");
        subheaderWrapper.addEventListener("click", (e) => {
          e.preventDefault();
          const id1 = header.querySelector("a");
          const id = document.querySelector(id1.getAttribute("href"));
          let currentActive = document.querySelector(".active");
          if (currentActive && currentActive === id1.closest(".active")) {
            currentActive.classList.remove("active");
            return;
          }
          if (currentActive)
            currentActive.classList.remove("active");
          currentActive = id1.closest(".link-container");
          if (currentActive) {
            currentActive.classList.add("active");
            PiDetails.setActiveIndexContent(currentActive);
            if (id) {
              this.anchorScrollWithMargin(e, id);
            }
          }
        })
      }
    })
    if (document.querySelector(".external")) {
      let highlightedIndex, highlightedContent;
      const anchors = document.querySelectorAll(".external a");
      anchors.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const id = link.getAttribute("href");
          if (id) {
            const [alink] = document.getElementsByName(id.split("#")[1]);
            if (alink) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "PhactMI Custom Event",
                phactmiEventData: {
                  "eventAction": "pidocumentsectionclicks",
                  "eventLabel":this.props.item.drug,
                  "cd1": degreeDetails.role,
                  "cd2": "pidocumentsectionclicks",
                  "cd3": this.props.item.drug, // doc name
                  "cd5": this.props.item.producer, // source name
                  "cd12": this.props.item.drug, // product name
                  "cd13": "Cross reference", // link type
                  "cd14": link.textContent, // link url
                  "cd15": link.textContent // link name
                }
              })
              this.anchorScrollWithMargin(e, alink);
            }
          }
        })
      })
      document.querySelector(".external").addEventListener("scroll", (e) => this.scrolltocontent(e, scrollableContents, highlightedIndex, highlightedContent));
      const tableOfContentHeight = window.innerHeight - document.querySelector(".external").offsetTop;
      this.setState({ tableOfContentSize: tableOfContentHeight });
    }
    if (document.querySelector(".newIndex") && document.querySelector(".scrollable-section")) {
      if (document.querySelector(".active"))
        document.querySelector(".active").classList.remove("active");
      if (document.querySelector(".active-content"))
        document.querySelector(".active-content").classList.remove("active-content");
      document.querySelector(".newIndex").firstChild.classList.add("active");
      document.querySelector(".scrollable-section").classList.add("active-content");
    }
  }

  componentDidUpdate() {
    const { tableOfContentSize, isShowTitleRequired } = this.state;
    if (document.querySelector(".external")) {
      const tableOfContentHeight = window.innerHeight - document.querySelector(".external").offsetTop;
      if (tableOfContentHeight !== tableOfContentSize) {
        this.setState({ tableOfContentSize: tableOfContentHeight });
      }
    }
    if (this.state.organicName.length > 0 && !isShowTitleRequired && document.querySelector(".drugTitle")) {
      if (document.querySelector(".drugTitle").scrollHeight > 50) {
        this.setState({ isShowTitleRequired: true })
      }
    }
  }

  componentWillUnmount() {
    const {checkPiComponentLoaded, fromSearchPage} = this.props;
    if (fromSearchPage) {
      checkPiComponentLoaded(false);
    }
  }

  /**
   * Scroll to content function
   * @memberof PiDetails
   */
  scrolltocontent = (e, scrollableContents, highlightedIndex, highlightedContent) => {
    e.preventDefault();
    const scrollDist = document.getElementById("ext").getBoundingClientRect().top;
    scrollableContents.forEach(z => {
      const scrollHeight = z.getBoundingClientRect().top;
      const scrollBottom = z.getBoundingClientRect().bottom;
      if (scrollHeight <= scrollDist && scrollBottom >= scrollDist) {
        const sectionNumber = z.getAttribute("id") ? z.getAttribute("id").split("-").length > 0
          ? z.getAttribute("id").split("-")[1]
          : null
          : null;
        if (sectionNumber == "warning") {
          highlightedIndex = document.querySelector("#content-warning");
          highlightedContent = document.querySelector("#boxed-warning");
        }
        else if (sectionNumber == "highlights") {
          highlightedIndex = document.querySelector("#content-highlights");
          highlightedContent = document.querySelector("#section-highlights");
        }
        else {
          highlightedIndex = document.querySelector(`#content-${sectionNumber}`);
          highlightedContent = document.querySelector(`#sections-${sectionNumber}`);
        }
        if (document.querySelector(".active")) {
          document.querySelector(".active").classList.remove("active");
        }
        if (highlightedIndex)
          highlightedIndex.classList.add("active");
        if (document.querySelector(".active-content"))
          document.querySelector(".active-content").classList.remove("active-content");
        highlightedContent.classList.add("active-content");
        this.inViewPort(highlightedIndex);
      }
    })
  }

  /**
   * Scroll page to the top
   * @param {object} e event object
   * @returns {undefined}
   */
  scrollToPageTop = (e) => {
    const windowTop = window.pageYOffset || document.documentElement.scrollTop;
    const contentWrapper = document.querySelector(".external");
    if (windowTop !== 0 || contentWrapper.scrollTop !== 0) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        contentWrapper.scrollTo(0, 0);
      }, 10);
    } else {
      e.preventDefault();
    }
  }


  /**
   * Scroll page to anchor position with margin of 40
   * @param {object} e event object
   * @returns {undefined}
   */
  anchorScrollWithMargin = (e, id) => {
    window.scrollTo({ top: 0 })
    setTimeout(() => {
      const contentWrapper = document.querySelector(".external");
      contentWrapper.scrollTop = id.offsetTop - document.getElementById("ext").offsetTop + 5;
    }, 10);
  }

  subheaderScrollWithMargin = (e, id) => {
    window.scrollTo({ top: 0 })
    setTimeout(() => {
      const contentWrapper = document.querySelector(".external");
      contentWrapper.scrollTop = id.offsetTop - document.getElementById("ext").offsetTop - 5;
    }, 10);
  }

  hideTitle() {
    const title = document.querySelector(".DocumentTitle");
    if (title && title.nextElementSibling.tagName === "H1") {
      title.nextElementSibling.style.display = "none";
    }
  }

  setActiveIndexContent(activeSectionLink) {
    let activeSection = document.querySelector(".active-content");
    if (activeSection)
      activeSection.classList.remove("active-content");
    const [x, sectionNumber] = activeSectionLink.getAttribute("id").split("-");
    if (sectionNumber == "warning")
      activeSection = document.querySelector("#boxed-warning");
    else if (sectionNumber == "highlights")
      activeSection = document.querySelector("#content-highlights");
    else
      activeSection = document.querySelector(`#sections-${sectionNumber}`);
    if (activeSection)
      activeSection.classList.add("active-content");
  }

  getOrganicName() {
    const { drug, genericName } = this.props.item;
    if (genericName) {
      let genericname = "";
      try {
        genericname = decodeURIComponent(genericName);
        if (!genericname) {
          genericname = "";
        }
      }
      catch (e) {
        genericname = "";
      }
      this.setState({ organicName: (genericname) });
    }
  }

  moveWarningToTop() {
    const warning = document.querySelector(".FullBoxedWarning .Warning");
    const highlights = document.querySelector("#Highlights");
    if (document.querySelector(".FullBoxedWarning")) {
      document.querySelector(".FullBoxedWarning").remove();
      const warningContainer = document.createElement("div");
      warningContainer.classList.add("FullBoxedWarning");
      warningContainer.setAttribute("id", "boxed-warning");
      warningContainer.classList.add("active-content");
      warningContainer.classList.add("scrollable-section");
      warningContainer.append(warning);
      if (document.querySelector("#Highlights")) {
        document.querySelector(".spl").insertBefore(warningContainer, document.querySelector("#Highlights"));
      }
      else {
        if (document.querySelector(".Contents")) {
          document.querySelector(".spl").insertBefore(warningContainer, document.querySelector(".Contents"));
        }
      }
    }
    else {
      const WarningIndex = document.querySelector("#content-warning");
      if (WarningIndex)
        WarningIndex.remove();
    }
    if (document.querySelector("#Highlights")) {
      document.querySelector("#Highlights").classList.add("scrollable-section");
      document.querySelector("#Highlights").setAttribute("id", "section-highlights");
    }

    if (!(highlights || document.querySelector("#section-highlights"))) {
      const highlightIndex = document.querySelector("#content-highlights");
      if (highlightIndex)
        highlightIndex.remove();
    }
  }

  moveIndex() {
    const index = document.querySelector(".Index");
    if (index) {
      document.querySelector(".Index").remove();
    }
  }

  resizeContainer() {
    if (document.querySelector(".newIndex"))
      document.querySelector(".external").style.maxHeight = `${document.querySelector(".newIndex").offsetHeight}px`;
  }

  createMarkup(text) {
    return { __html: text };
  }

  /**
   * Function Return Session ID
   * @returns {Number} Session Id
   */
     getSessionId = () => {
       let sessionId = localStorage.getItem("_sid");
       if (!sessionId) {
         sessionId = sessionStorage.getItem("_sid");
         if (!sessionId) {
           sessionId = "NA";
         }
       }
       return sessionId;
     }

  downloadPIDocument = setId => {
    const downloadUrl = `${endpoint}/api/search/spl/${setId}/download`
    const sessionId = this.getSessionId();
    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
    const degreeDetails = JSON.parse(localStorage.getItem("selectedDegree"));
    let degree;
    if (degreeDetails) {
      degree = degreeDetails.role
      if (degree.toLowerCase().includes("patient") || degree.toLowerCase().includes("consumer") || degree.toLowerCase().includes("caregiver")) {
        degree = "PT"
      } else {
        degree = degreeDetails.role
      }
    } else {
      degree = "NE"
    }
    window.dataLayer = window.dataLayer || [];
    if (GADegrees.includes(degree)) {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": degree,
          "eventAction": "pidocumentdownloadclicks",
          "eventLabel": this.props.item.drug,
          "cd1": degree,
          "cd2": "pidocumentdownloadclicks",
          "cd3": this.props.item.drug,
          "cd4": this.props.usersearchQuery,
          "cd5": this.props.item.producer,
          "cd8": "Null"
        }
      })
    } else {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": "NE",
          "eventAction": "pidocumentdownloadclicks",
          "eventLabel": this.props.item.drug,
          "cd1": "NE",
          "cd2": "pidocumentdownloadclicks",
          "cd3": this.props.item.drug,
          "cd4": this.props.usersearchQuery,
          "cd5": this.props.item.producer,
          "cd8": "Null"
        }
      })
    }
    if (this.isMobileTabletRequest()) {
      const url = `${downloadUrl}?viewonly=true&sessionId=${sessionId}`
      window.open(url, "_blank");
    }
    else {
      const Url = `${downloadUrl}?sessionId=${sessionId}`;
      return axios.get(Url, {
        responseType: "arraybuffer"
      })
        .then((response) => {
          this.setState({ loading: false });
          const file = new Blob([response.data], {
            type: "application/pdf"
          });

          const filename = `${setId}.pdf`;
          saveAs(file, filename)
        })
        .catch((err) => {
          this.setState({
            downloadError: err.response
          });
        })
    }
  }

  isMobileTabletRequest() {
    return (/iphone|ipad|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());
  }

  inViewPort(element) {
    if (!element) return;
    const goToElement = element;
    const goToPosition = goToElement.getBoundingClientRect().top;
    if (goToElement && document.querySelector("#indexcontainer") && document.querySelector(".external")) {
      if (goToPosition > window.innerHeight - (document.querySelector(".external").offsetTop - document.querySelector("#indexcontainer").scrollTop) || goToPosition < 0) {
        setTimeout(() => {
          document.querySelector("#indexcontainer").scrollTop = element.offsetTop - document.getElementById("ext").getBoundingClientRect().top + 5;
        }, 10)
      }
      else if ((goToPosition > 0 && element.offsetTop - document.querySelector(".external").offsetTop < document.querySelector("#indexcontainer").scrollTop)) {
        setTimeout(() => {
          document.querySelector("#indexcontainer").scrollTop = element.offsetTop - document.getElementById("ext").getBoundingClientRect().top - 40;
        }, 10)
      }
    }
  }

  getHtmlContent(htmlContent) {
    const { sectionNameUsingDCode } = this.props;
    const parser = new DOMParser();
    let htmlDoc = parser.parseFromString(htmlContent, "text/html");
    if (htmlDoc && (htmlDoc.querySelector("parsererror") || !htmlDoc.querySelector("#spl")))
      htmlDoc = parser.parseFromString(htmlContent, "text/xml");
    if (htmlDoc) {
      const bodyNode = htmlDoc.querySelector("body");
      let sections = Array.from(htmlDoc.querySelectorAll(".Section"));
      sections = sections.filter(section => !section.parentElement.classList.contains("Section"));
      sections = sections.filter(section => !section.parentElement.classList.contains("Warning"));
      if (sections.length > 0) {
        sections.forEach((section, index) => {
          section.setAttribute("id", `sections-${index + 1}`);
          section.classList.add("scrollable-section");
          const header = section.querySelector("h1");
          if (!header) {
            const dataSectionCode = section.getAttribute("data-sectioncode");
            let sectionTitle = "";
            if (sectionNameUsingDCode) {
              for (let i = 0; i < sectionNameUsingDCode.length; i++) {
                if (dataSectionCode === sectionNameUsingDCode[i].LOINCCode) {
                  sectionTitle = sectionNameUsingDCode[i].LOINCName;
                  break;
                }
              }
            }
            const sectionHeading = document.createElement("h1");
            sectionHeading.innerHTML = sectionTitle;
            try {
              section.insertBefore(sectionHeading, section.firstChild);
            } catch (e) {
              sectionHeading.innerHTML = "";
            }
          }
          const subSections = section.querySelectorAll("h2");
          if (subSections && subSections.length > 0) {
            subSections.forEach((subSection, subIndex) => {
              subSection.setAttribute("id", `sections-${index + 1}.${subIndex + 1}`);
            });
          }
        });
      }

      if (htmlDoc.querySelector("link"))
        htmlDoc.querySelector("link").remove();
      if (bodyNode) {
        return bodyNode.innerHTML || new XMLSerializer().serializeToString(bodyNode);
      }
    }
  }

  createTableOfContent(htmlContent) {
    const { sectionNameUsingDCode } = this.props;
    if (!htmlContent) return;

    const tableOfContent = document.createElement("div");
    const parser = new DOMParser();
    let htmlDoc = parser.parseFromString(htmlContent, "text/html");
    if (htmlDoc && (htmlDoc.querySelector("parsererror") || !htmlDoc.querySelector("#spl")))
      htmlDoc = parser.parseFromString(htmlContent, "text/xml");

    const WARNING = document.createElement("div");
    WARNING.setAttribute("id", "content-warning");
    WARNING.classList.add("link-container");
    const warningLink = document.createElement("h1");
    warningLink.innerHTML = "<a href='#boxed-warning'>BOXED WARNING</a>";

    WARNING.append(warningLink);

    const HIGHLIGHTS = document.createElement("div");
    HIGHLIGHTS.setAttribute("id", "content-highlights");
    HIGHLIGHTS.classList.add("link-container");
    const highlightsLink = document.createElement("h1");
    highlightsLink.innerHTML = "<a href='#section-highlights'>HIGHLIGHTS OF PRESCRIBING INFORMATION</a>";

    HIGHLIGHTS.append(highlightsLink);

    tableOfContent.append(WARNING);
    tableOfContent.append(HIGHLIGHTS);

    let sections = Array.from(htmlDoc.querySelectorAll(".Section"));
    sections = sections.filter(section => !section.parentElement.classList.contains("Section"));
    sections = sections.filter(section => !section.parentElement.classList.contains("Warning"));
    if (sections.length > 0) {
      sections.forEach((section, index) => {
        let header = section.querySelector("h1");
        const contentItemContainer = document.createElement("div");
        contentItemContainer.setAttribute("id", `content-${index + 1}`);
        contentItemContainer.classList.add("link-container");
        if (header && header.innerHTML.length > 0) {
          const headerLink = document.createElement("a");
          headerLink.setAttribute("href", `#sections-${index + 1}`);
          headerLink.addEventListener("click", this.handleclick);
          headerLink.innerHTML = header.innerHTML;
          header.innerHTML = "";
          header.append(headerLink);
        }
        else {
          const dataSectionCode = section.getAttribute("data-sectioncode");
          let sectionTitle = "";
          if (sectionNameUsingDCode) {
            for (let i = 0; i < sectionNameUsingDCode.length; i++) {
              if (dataSectionCode === sectionNameUsingDCode[i].LOINCCode) {
                sectionTitle = sectionNameUsingDCode[i].LOINCName;
                break;
              }
            }
          }
          const headerLink = document.createElement("a");
          headerLink.setAttribute("href", `#sections-${index + 1}`);
          headerLink.addEventListener("click", this.handleclick);
          headerLink.innerHTML = sectionTitle;
          header = document.createElement("H1");
          header.innerHTML = "";
          header.append(headerLink);
        }
        const headerWrapper = document.createElement("div");
        headerWrapper.classList.add("header-wrapper");
        headerWrapper.append(header);
        const subHeaders = section.querySelectorAll("h2");
        if (subHeaders.length > 0) {
          const subheaderWrapper = document.createElement("div");
          subheaderWrapper.classList.add("subheader-wrapper");
          headerWrapper.append(subheaderWrapper);
        }
        contentItemContainer.append(headerWrapper);
        if (subHeaders.length > 0) {
          contentItemContainer.classList.add("multiple");
          const subHeaderCont = document.createElement("div");
          subHeaderCont.classList.add("sub-headers");
          subHeaders.forEach((subHeader, subIndex) => {
            const subHeaderLink = document.createElement("a");
            subHeaderLink.setAttribute("href", `#sections-${index + 1}.${subIndex + 1}`);
            subHeaderLink.innerHTML = subHeader.innerHTML;
            subHeader.innerHTML = "";
            subHeader.append(subHeaderLink);
            subHeaderCont.append(subHeader);
          });
          contentItemContainer.append(subHeaderCont);
        }
        tableOfContent.append(contentItemContainer);
      });
    }
    return (
      tableOfContent.innerHTML
    )
  }

  handletitle = () => {
    const { showFullTitle } = this.state;
    this.setState({
      showFullTitle: !showFullTitle
    })
  }

  render() {
    const { htmlContent, resources, showSearchDetails, isError, noPiResultResources, fromSearchPage } = this.props;
    const { drug, form, producer, setId, drugFullName } = this.props.item ? this.props.item : {};
    const { formLabel, source, registered } = resources;
    const { organicName, hasIndex, maxContainerHeight, showFullTitle, isShowTitleRequired } = this.state;
    const content = isError ? null : this.getHtmlContent(htmlContent);
    const tableOfContent = this.createTableOfContent(htmlContent);
    const viewFullTitle = "view full title";
    const viewLessTitle = "view less";
    return (
      <styled.PiContainer>
        {isError ?
          <styled.NoResultsWrapper>
            <NoResultsFound
              resources={noPiResultResources}
              fromPI={true}
              manufacturerData={form ? null : this.props.item}
              drugName={drug}
              resource={resources}
              showSearchDetails={showSearchDetails}
              setId={setId}
              showDownload={false}
            />
          </styled.NoResultsWrapper>
          :
          <Fragment>
            <styled.ProductHeader>
              {fromSearchPage ?
                <styled.ProductTitle fromSearchPage={fromSearchPage}>
                  {/* <span>{drug.toUpperCase()}</span> */}
                  {/* <span dangerouslySetInnerHTML={this.createMarkup(registered)}></span> */}
                  {
                    organicName.toString().length > 0 ?
                      <styled.GenericnameWrapper className="drugTitle"
                        dangerouslySetInnerHTML={this.createMarkup(`<b>${drug.toUpperCase()}</b> (${organicName.toLocaleLowerCase().trim()})`)}
                        showFullTitle={showFullTitle}></styled.GenericnameWrapper>
                      :
                      <styled.GenericnameWrapper className="drugTitle"
                        dangerouslySetInnerHTML={this.createMarkup(`<b>${drug.toUpperCase()}</b>`)}
                        showFullTitle={showFullTitle}></styled.GenericnameWrapper>
                  }
                  {
                    isShowTitleRequired ?
                      !showFullTitle ?
                        <div className="showFullTitle">
                          <span>...</span>
                          <span className="show_title" onClick={this.handletitle}>{viewFullTitle}</span>
                        </div>
                        :
                        <div className="hideFullTitle">
                          <span className="show_title" onClick={this.handletitle}>{viewLessTitle}</span>
                        </div>
                      : null
                  }
                </styled.ProductTitle>
                :
                <styled.ProductTitle>
                  <span dangerouslySetInnerHTML={this.createMarkup(drugFullName)}></span>
                  <styled.GenericnameWrapper dangerouslySetInnerHTML={this.createMarkup(` ${organicName.trim()}`)}></styled.GenericnameWrapper></styled.ProductTitle>
              }
              <styled.SourceContainer>
                {form && <styled.Label>{formLabel}</styled.Label>}
                {form && <styled.FormValue>{form}</styled.FormValue>}
                <styled.Label className={form ? "separator" : ""}>{source}</styled.Label>
                <styled.SourceValue>{producer}</styled.SourceValue>
              </styled.SourceContainer>
            </styled.ProductHeader>
            <BreadCrumbs
              manufacturerData={form ? null : this.props.item}
              drugName={(drugFullName ? drugFullName : drug)}
              resources={resources}
              showSearchDetails={showSearchDetails}
              setId={setId} downloadPIDocument={this.downloadPIDocument}
              showDownload={true}
            />
            <styled.ResultsContainer>
              <styled.IndexContainer dangerouslySetInnerHTML={this.createMarkup(tableOfContent)} className="newIndex" height={this.state.tableOfContentSize}
                id="indexcontainer">
              </styled.IndexContainer>
              <styled.ExternalPageContainer containerHeight={maxContainerHeight} className={hasIndex ? "external" : "external no-index"}
                hasIndex={hasIndex} height={this.state.tableOfContentSize} id="ext" >
                {htmlContent && <styled.Spl className={"spl"} dangerouslySetInnerHTML={this.createMarkup(content)}></styled.Spl>}
              </styled.ExternalPageContainer>
            </styled.ResultsContainer>
          </Fragment>}
      </styled.PiContainer>
    );
  }
}

PiDetails.propTypes = {
  htmlContent: PropTypes.string,
  item: PropTypes.object,
  resources: PropTypes.object,
  showSearchDetails: PropTypes.func
};

export default connect(mapStateToProps)(PiDetails);
