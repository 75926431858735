import styled, { keyframes } from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, mobileAndTabletRange } from "../../../common/styles/breakpoints";

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`
export const LoaderWrapper = styled.div`
  overflow: hidden;
  padding: 42px 90px 0 42px;
  background-color: ${colors.white};
  height: 100%;
  @media ${mobileRange} {
    padding: 41px 19px 0 23px;
  }
  @media ${tabletRange} {
    padding: 51px 34px 0 24px;
  }
`;

export const ItemLoader = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: linear-gradient(to left, ${colors.mystic}, ${colors.mystic_approx} 31%, ${colors.mystic});
  height: 210px;
  position: relative;
  overflow: hidden;
  @media ${mobileAndTabletRange} {
    height: 210px;
    animation-duration: 3s;
  }
`;

export const ItemLoaderSmall = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: ${colors.alabaster};
  background: linear-gradient(to left, ${colors.mystic}, ${colors.mystic_approx} 31%, ${colors.mystic});
  background-size: 1000px 104px;
  height: 94px;
  position: relative;
  overflow: hidden;
  @media ${mobileAndTabletRange} {
    height: 94px;
  }
`;
export const Mask50 = styled.div`
  background: ${colors.white};
  width: 50%;
  height: 35px;
  float: right;
`;

export const Mask70 = styled.div`
  background: ${colors.white};
  width: 70%;
  height: 35px;
  float: right;
`;

export const Mask1px = styled.div`
  background: ${colors.white};
  width: 1px;
  height: 35px;
  float: right;
`;
export const Mask100 = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 12px;
  float: right;
`;
export const Mask82 = styled.div`
  background: ${colors.white};
  width: 82%;
  height: 40px;
  float: right;
  @media ${mobileRange} {
    height: 43px;
    width: 61%;
  }
  @media ${tabletRange} {
    height: 43px;
    width: 69%;
  }
`;
export const MaskV90 = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 90px;
`;

export const MaskList100 = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 20px;
  float: right;
`;
export const MaskList80 = styled.div`
  background: ${colors.white};
  width: 80%;
  height: 22px;
  float: right;
`;

export const MaskList50 = styled.div`
  background: ${colors.white};
  width: 50%;
  height: 22px;
  float: right;
`;

export const MaskList25 = styled.div`
  background: ${colors.white};
  width: 25%;
  height: 22px;
  float: right;
`;
export const MaskList60 = styled.div`
  background: ${colors.white};
  width: 60%;
  height: 22px;
  float: right;
`;

