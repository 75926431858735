import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, BreadCrumbTitle, BreadCrumbSeparator, BreadCrumbContainer, DownloadContainer, DownloadButton, Tooltip, TooltipText } from "./styles";
import save from "../../assets/images/save.png";

const BreadCrumbs = React.memo(({ drugName, resources: { search, separator, download_txt, registered },
  showSearchDetails,
  setId,
  downloadPIDocument,
  manufacturerData,
  history,
  showDownload }) => {

  const { manufacturer, companyId, producer } = manufacturerData ? manufacturerData : {};
  const onTitleClick = companyId => {
    if (!companyId) {
      sessionStorage.removeItem("selectedAlphabet");
    }
    history.push({
      pathname: "/members",
      state: {
        _id: companyId,
        companyName: producer
      }
    });
  }

  const createMarkup = (text) => {
    return {__html: text};
  }

  return (
    <div>
      <Container showDownload={showDownload}>
        <BreadCrumbContainer>
          {!manufacturerData && <Fragment>
            <BreadCrumbTitle onClick={showSearchDetails}>{search}</BreadCrumbTitle>
            <BreadCrumbSeparator>{separator}</BreadCrumbSeparator>
            {drugName.length > (window.innerWidth > 425 ? 45 : 30) ?
              <BreadCrumbTitle className="drug" fromSearchpage={true}>
                {drugName.substring(0, (window.innerWidth > 425 ? 43 : 18))}<Tooltip>...<TooltipText>{drugName}</TooltipText></Tooltip>
                {/* <div dangerouslySetInnerHTML={createMarkup(drugName)}></div> */}
              </BreadCrumbTitle> :
              <BreadCrumbTitle className="drug" fromSearchpage={true}>
                {drugName}
                {/* <div dangerouslySetInnerHTML={createMarkup(drugName)}></div> */}
              </BreadCrumbTitle>

            }
          </Fragment>}
          {manufacturerData && <Fragment>
            <BreadCrumbTitle onClick={() => { onTitleClick() }}>{manufacturer}</BreadCrumbTitle>
            <BreadCrumbSeparator>{separator}</BreadCrumbSeparator>
            <BreadCrumbTitle onClick={() => { onTitleClick(companyId) }}>{producer}</BreadCrumbTitle>
            <BreadCrumbSeparator>{separator}</BreadCrumbSeparator>
            <BreadCrumbTitle className="drug" dangerouslySetInnerHTML={{__html: drugName}}></BreadCrumbTitle>
          </Fragment>}
        </BreadCrumbContainer>
        {showDownload &&
        <DownloadContainer onClick={() => downloadPIDocument(setId)}>
          <DownloadButton>
            <span>{download_txt}</span>
          </DownloadButton>
          <img src={save} alt="save_icon"></img>
        </DownloadContainer>
        }
      </Container>
    </div>
  );
});

BreadCrumbs.propTypes = {
  drugName: PropTypes.string,
  resources: PropTypes.object,
  showSearchDetails: PropTypes.func,
  setId: PropTypes.string,
  downloadPIDocument: PropTypes.func,
  manufacturerData: PropTypes.object
}

export default withRouter(BreadCrumbs);
