import React, { Component } from "react";
import ErrorPopUp from "../../components/errorPopUp";
import resources from "../../config/content/homePage";

/**
 * Error Boundary container
 *
 * @class ErrorBoundary
 * @extends {Component}
 * @exports ErrorBoundary
 */
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,
      error: null,
      errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const { generalError } = resources;
    if (this.state.hasError) {
      // Error path
      return (
        // <div>
        //   <h2>Something went wrong.</h2>
        //   <section>
        //     {this.state.error && this.state.error.toString()}
        //     <br />
        //     {this.state.errorInfo && this.state.errorInfo.componentStack}
        //   </section>
        // </div>
        <ErrorPopUp history={this.props.history} resources={generalError}></ErrorPopUp>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
