import React, { Component } from "react";
import PropTypes from "prop-types";

import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import * as styled from "./style";

function base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "application/pdf" });
}


const PdfViewer = (props) => {
  const { keywords, base64 } = props;
  const url = URL.createObjectURL(base64ToBlob(base64));
  const searchPluginInstance = searchPlugin({
    keyword: keywords.map((word) => {
      return {
        keyword: word,
        matchCase: true,
        // wholeWords: word.length > 1 ? false : true
        wholeWords: false
      };
    })
  });
  return (
    <styled.ViewerContainer>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
        <Viewer
          fileUrl={url}
          plugins={[searchPluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </Worker>
    </styled.ViewerContainer>
  );
};

PdfViewer.propTypes = {
  base64: PropTypes.string,
  keywords: PropTypes.array
};

export default PdfViewer;
