/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import resources from "../../config/content/aboutPage";
import ContactUs from "../../components/about/contact-us/contactus";
import Manufacturers from "../../components/about/manufacturers/manufacturers";
import AboutSection from "../../components/about-section";
import WhoWeAre from "../../components/who-we-are";
import CodeOfPractice from "../../components/code-of-practice";
import BoardMembers from "../../components/board-members";
import AdditionalResources from "../../components/additional-resources";
import FaqComponent from "../../components/faq/faq";
import withTransition from "../../components/with-transition";
import URLRewriter from "../../components/urlRewriter";
import * as actions from "./actions";
import * as styled from "./style";

const mapStateToProps = state => {
  return {
    contactDetails: state.aboutReducer.contactUs,
    manufacturersDetails: state.aboutReducer.manufacturers,
    boardMembersDirectors: state.aboutReducer.boardMembersDirectors,
    resourcesData: state.aboutReducer.additionalResourcesData,
    faqList: state.aboutReducer.faq
  };
};

/**
 * About page container
 *
 * @class About
 * @extends {Component}
 * @exports About
 */
class About extends Component {

  numAPIcalls = 4;

  state = {
    showLeftArrow: false,
    showRightArrow: false,
    scroll: true,
    activeAnchor: 0
  };

  constructor(props) {
    super(props);
    const currentUrl = window.location.pathname;
    if (currentUrl.split("/").slice(-1).length > 0 && currentUrl.split("/").length > 2) {
      if (currentUrl.split("/")[2].length > 0) {
        window.location.pathname = currentUrl.slice(0, currentUrl.lastIndexOf("/"));
      }
    }
  }

  componentDidMount() {
    this.counter = 0;
    const { getContactUsDetails, getManufacturersDetails, getMembersDirectors, getAdditionalResources, getFaq,
      manufacturersDetails, faqList, boardMembersDirectors, resourcesData, contactDetails } = this.props;
    // _isEmpty(manufacturersDetails) ? getManufacturersDetails() : this.counter = this.counter + 1;
    _isEmpty(faqList) ? getFaq() : this.counter = this.counter + 1;
    _isEmpty(boardMembersDirectors) ? getMembersDirectors() : this.counter = this.counter + 1;
    _isEmpty(resourcesData) ? getAdditionalResources() : this.counter = this.counter + 1;
    _isEmpty(contactDetails) ? getContactUsDetails() : this.counter = this.counter + 1;
    this.manageArrows();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if ((this.counter >= this.numAPIcalls) && this.props.fromFooter && this.state.scroll) {
      this.scrollToElem();
      this.setState({scroll:false})
    } else {
      this.counter = this.counter + 1;
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps !== this.props) && nextProps.location.state && (this.counter >= this.numAPIcalls)) {
      this.scrollToElem();
    }
  }

  /**
   * Scrolls to provided element
   *
   * @param {object} e event object
   * @returns {undefined}
   */
  scrollToElem = (e, href) => {
    if (window.location.hash === "#contact-us") {
      this.setState({ activeAnchor: 4 });
    }
    const hashStr = e ? e.target.hash ? e.target.hash : href : null;
    setTimeout(() => {
      const elmTop = e ? this[hashStr.slice(1)].offsetTop : this.contatctElm.offsetTop;
      const margin = window.innerWidth < 767 ? -30 : 60;
      const headerHeight = window.innerWidth > 1024 ? 90 : window.innerWidth > 767 ? 89 : 74;
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        window.scrollTo(0, elmTop + margin - headerHeight);
      } else {
        window.scrollTo({
          top: elmTop + margin - headerHeight,
          left: 0,
          behavior: "smooth"
        });
      }
    }, 300);

    setTimeout(() => {
      const element = this.aboutElm.querySelector("#items-list");
      element.scrollLeft = this.aboutElm.getElementsByClassName("active")[0].offsetLeft - 75;
      this.manageArrows();
    }, 100);
  }

  manageArrows = () => {
    const aboutref = this.aboutElm.querySelector("#items-list");
    if (window.innerWidth >= 320 && window.innerWidth < 1299) {
      if (aboutref.scrollLeft >= 0 && aboutref.scrollLeft < 25) {
        this.setState({ showLeftArrow: false,
          showRightArrow: true});
      } else if (aboutref.scrollLeft >= 50 && (aboutref.scrollLeft + aboutref.offsetWidth) < aboutref.scrollWidth) {
        this.setState({ showLeftArrow: true,
          showRightArrow: true});
      } else if ((aboutref.scrollLeft + aboutref.offsetWidth) > (aboutref.scrollWidth - 20)) {
        this.setState({ showLeftArrow: true,
          showRightArrow: false});
      }
    }
  }

  /**
   * Achor click handler
   *
   * @param {object} e event object
   * @param {string} label label name
   * @param {string} selectedAnchor anchor index
   * @returns {undefined} undefined
   */
  onAnchorClick(e, label, selectedAnchor, href) {
    e.preventDefault();
    URLRewriter(this.props.history, label);
    this.scrollToElem(e, href);
    this.setState({ activeAnchor: selectedAnchor });
  }

  onClickLeftArrow() {
    this.aboutElm.querySelector("#items-list").scrollLeft -= 75;
    this.manageArrows();
  }

  onClickRightArrow() {
    this.aboutElm.querySelector("#items-list").scrollLeft += 75;
    this.manageArrows();
  }

  render() {
    const { mainHeading1_txt, mainHeading2_txt, mainHeading3_txt,
      aboutSection, whoWeAre, codeOfPractice, contactUsData, manufacturers_heading } = resources;
    const { contactDetails, manufacturersDetails, boardMembersDirectors, resourcesData, faqList } = this.props;
    const {showLeftArrow, showRightArrow, activeAnchor} = this.state;
    return (
      <styled.AboutPage>
        <Container className="container">
          <styled.HeaderSection>
            <styled.MainHeading>
              <h1>{mainHeading1_txt}
                <span>{mainHeading2_txt}</span>{mainHeading3_txt}</h1>
            </styled.MainHeading>
          </styled.HeaderSection>
          <styled.MainSection>
            <AboutSection
              ref={elm => { this.aboutElm = elm }}
              resources={aboutSection}
              onAnchorClick={(e, label, selectedAnchor, href) => this.onAnchorClick(e, label, selectedAnchor, href)}
              onClickLeftArrow={e => this.onClickLeftArrow(e)}
              onClickRightArrow={e => this.onClickRightArrow(e)}
              showLeftArrow={showLeftArrow}
              showRightArrow={showRightArrow}
              activeAnchor={activeAnchor}/>
            <AdditionalResources ref={elm => { this.ARElm = elm }} data={resourcesData} resources={resources.additionalResources} history={this.props.history}/>
            <WhoWeAre resources={whoWeAre} />
            {/* <styled.Manufacturers ref={elm => { this.manufacturersElm = elm }} >
              <Manufacturers {...manufacturersDetails} heading={manufacturers_heading} />
            </styled.Manufacturers> */}
            <CodeOfPractice ref={elm => { this.COPElm = elm }} resources={codeOfPractice} />
            <FaqComponent ref={elm => { this.FAQElm = elm }} data={faqList} resources={resources.faq} />
            <styled.ContactUs ref={elm => { this.contatctElm = elm }} >
              <ContactUs details={contactDetails} resource={contactUsData} />
            </styled.ContactUs>
            { Object.keys(boardMembersDirectors).length !== 0 && <BoardMembers data={boardMembersDirectors} membersHeader={resources.board_members.header}
              directorsHeader={resources.directors.header} /> }
          </styled.MainSection>
        </Container>
      </styled.AboutPage>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(About));
