import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getCovidResource } from "./api";

export function* getCovidResourceDataWorker() {
  try {
    const data = yield call(getCovidResource);
    yield put({
      type: actions.GET_COVID_CONTENT_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_COVID_CONTENT_FAIL });
  }
}

export function* getCovidResourceDataWatcher() {
  yield takeLatest(actions.GET_COVID_CONTENT, getCovidResourceDataWorker);
}

export default {
  getCovidResourceDataWatcher
};
