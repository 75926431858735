/* eslint-disable max-len */
import Redirect from "../../assets/images/redirect.png";
export default {
  "img_src": Redirect,
  "img_alt_txt": "phactMI - message logo",
  "leaving_message": "You are now leaving phactMI.org",
  "navigation_message": "You are navigating to a page that is not owned by the phactMI organization.",
  "redirect_message": "You will automatically be redirected in time seconds.",
  "cancelbutton_text": "Cancel",
  "continuebutton_txt": "Continue"
}
