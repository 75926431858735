import { takeLatest, put, call } from "redux-saga/effects";
import { GET_AUTO_SUGGEST, GET_SUCCESS_AUTO_SUGGEST, GET_FAIL_AUTO_SUGGEST } from "./actions";
import { getAutoSuggest } from "./api";

export function* getAutoSuggestDataWorker({ payload }) {
  try {
    const data = yield call(getAutoSuggest, payload);

    yield put({
      type: GET_SUCCESS_AUTO_SUGGEST,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FAIL_AUTO_SUGGEST });
  }
}

export function* getAutoSuggestDataWatcher() {
  yield takeLatest(GET_AUTO_SUGGEST, getAutoSuggestDataWorker);
}

export default {
  getAutoSuggestDataWatcher
};
