export const GET_NEWS_DETAILS = "GET_NEWS_DETAILS";
export const GET_NEWS_DETAIL = "GET_NEWS_DETAIL";
export const GET_SUCCESS_NEWS_DETAIL = "GET_SUCCESS_NEWS_DETAIL";
export const GET_FAIL_NEWS_DETAIL = "GET_FAIL_NEWS_DETAIL";
export const SET_NEWS_DETAIL= "SET_NEWS_DETAIL";
export const GET_NEWS_BY_ID = "GET_NEWS_BY_ID";
export const GET_NEWS_BY_ID_FAIL = "GET_NEWS_BY_ID_FAIL";
export const GET_NEWS_BY_ID_SUCCESS = "GET_NEWS_BY_ID_SUCCESS";
export const CLEAR_NEWS = "CLEAR_NEWS";
export const GET_PUBLICATION = "GET_PUBLICATION";
export const GET_PUBLICATION_SUCCESS = "GET_PUBLICATION_SUCCESS";
export const GET_PUBLICATION_FAIL = "GET_PUBLICATION_FAIL";
export const GET_PUBLICATION_BY_ID = "GET_PUBLICATION_BY_ID";
export const GET_PUBLICATION_BY_ID_SUCCESS = "GET_PUBLICATION_BY_ID_SUCCESS";
export const GET_PUBLICATION_BY_ID_FAIL = "GET_PUBLICATION_BY_ID_FAIL";

export function getNewsList() {
  return {
    type: GET_NEWS_DETAILS
  }
}

export function getNewsDetail(id) {
  return {
    type: GET_NEWS_DETAIL,
    payload: {
      id
    }
  }
}

export function setNewsDetail() {
  return {
    type: SET_NEWS_DETAIL
  }
}

export function getNewsById(title) {
  return {
    type: GET_NEWS_BY_ID,
    payload: {
      title
    }
  }
}

export function clearNews() {
  return {
    type: CLEAR_NEWS
  }
}

export function getPublication() {
  return {
    type: GET_PUBLICATION
  }
}

export function getPublicationById(title) {
  return {
    type: GET_PUBLICATION_BY_ID,
    payload: {
      title
    }
  }
}
