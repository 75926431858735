import React, { memo } from "react";
import * as styled from "./style";

/**
 * News List Loader- loading status for news list
 *
 * @exports NewsListLoader
 */

const itemLoader = (numberOfRows) => {
  const itemsArr = [];
  for (let i = 0; i < numberOfRows; i++) {
    itemsArr.push(
      <div key={i}>
        <styled.ItemLoader>
          <styled.MaskList100 />
          <styled.MaskList60 />
          <styled.MaskList100 />
          <styled.MaskList80 />
          <styled.MaskList100 />
          <styled.MaskList50 />
          <styled.MaskList100 />
          <styled.MaskList25 />
        </styled.ItemLoader>
      </div>
    )
  }
  return itemsArr;
};

const NewsListLoader = () => {
  return (
    <React.Fragment>
      <styled.LoaderContainer>
        <styled.LoaderWrapper>
          {itemLoader(9)}
        </styled.LoaderWrapper>
      </styled.LoaderContainer>
      <styled.LoaderContainerMobile>
        <styled.LoaderWrapper>
          {itemLoader(2)}
        </styled.LoaderWrapper>
      </styled.LoaderContainerMobile>
    </React.Fragment>
  )
};

export default memo(NewsListLoader);
