import React from "react";
import PropTypes from "prop-types";
import { TableContainer, Col, Row, Title, Company, Designation, ContentWrapper } from "./style";
import { endpoint } from "../../config/app.config";

const TableComponent = React.memo((props) => {
  const { data, columnNumber } = props;
  const dataLength = data ? data.length : 0;

  const hasBorder = (dataLength, index) => {
    if (dataLength > columnNumber) {
      if ((index + 1) === dataLength) {
        return true;
      } else if ( (index+1) % columnNumber === 0) {
        return true;
      } else {
        return false
      }
    } else {
      if ((index + 1) === dataLength) {
        return true;
      } else {
        return false
      }
    }
  }

  return (
    <section>
      {dataLength > 0 && <TableContainer>
        <Row cols={dataLength}>
          {data.map((item, index) => (
            <Col count={dataLength} noborder={hasBorder(dataLength, index)} key={index}>
              <img src={`${endpoint}/api/assets/images?id=${item.image}`} alt={`${item.name} - image`}></img>
              <ContentWrapper noborder={hasBorder(dataLength, index)}>
                <Title href={`mailto:${item.email}`}>{item.name}</Title>
                <Designation>{item.designation}</Designation>
                <Company>{item.company}</Company>
              </ContentWrapper>
            </Col>
          ))}
        </Row>
      </TableContainer>}
    </section>
  );
});

TableComponent.propTypes = {
  data: PropTypes.array,
  columnNumber:PropTypes.number
}

export default TableComponent;