import axios from "axios";
import { endpoint } from "../../config/app.config";

export async function getPodcastApi() {
  return axios
    .get(`${endpoint}/api/podcast`)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
