// import update from 'immutability-helper';

import * as actions from "./actions";

const initialState = {
  contactUs: {},
  manufacturers: {},
  boardMembersDirectors: {},
  additionalResourcesData: [],
  error: {},
  faq: []
}

export default function aboutReducer(state = initialState, { type, payload }) {
  switch (type) {
  case actions.GET_CONTACTUS_DETAILS:
    return {
      ...state,
      loading: true
    }
  case actions.GET_SUCCESS_CONTACTUS_DETAILS:
    return {
      ...state,
      contactUs: payload.result,
      loading: false
    }
  case actions.GET_FAIL_CONTACTUS_DETAILS:
    return {
      ...state,
      loading: false
    }

  case actions.GET_MANUFACTURERS_DETAILS:
    return {
      ...state,
      loading: true
    }
  case actions.GET_SUCCESS_MANUFACTURERS_DETAILS:
    return {
      ...state,
      manufacturers: payload.result,
      loading: false
    }
  case actions.GET_FAIL_MANUFACTURERS_DETAILS:
    return {
      ...state,
      loading: false
    }

  case actions.GET_MEMBERS_DIRECTORS:
    return {
      ...state,
      loading: true
    }

  case actions.GET_MEMBERS_DIRECTORS_SUCCESS:
    return {
      ...state,
      boardMembersDirectors: payload.result.data,
      loading: false
    }

  case actions.GET_MEMBERS_DIRECTORS_FAIL:
    return {
      ...state,
      error: payload.result.error,
      loading: false
    }

  case actions.GET_ADDITIONAL_RESOURCES:
    return {
      ...state,
      loading: true
    }
  case actions.GET_ADDITIONAL_RESOURCES_SUCCESS:
    return {
      ...state,
      additionalResourcesData: payload.result.data,
      loading: false
    }

  case actions.GET_ADDITIONAL_RESOURCES_FAIL:
    return {
      ...state,
      error: payload.result.error,
      loading: false
    }

  case actions.GET_FAQ:
    return {
      ...state,
      loading: true
    }

  case actions.GET_FAQ_SUCCESS:
    return {
      ...state,
      faq: payload.result.data
    }

  case actions.GET_FAQ_FAIL:
    return {
      ...state,
      error: payload.error
    }
  default: return state
  }
}
