import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const ManufacturesPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 85px;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: 70px;
  }
  @meida ${ipadProRange} {
    padding-top: 75px;
  }
`;

export const ManufacturersCountSection = styled.h1`
  margin-left: 80px;
  display: flex;
  flex-direction: row;
  align-item: center;
  margin-bottom: 0;
  & .manufacturers-count {
    display: flex;flex-direction: row;
    align-items: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 0.62;
    letter-spacing: -0.96px;
    @media ${mobileRange} {
      font-size: 20px;
    }
  }
  & .manufacturers-text {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.35;
    padding: 0 10px 0;
    @media ${mobileRange} {
      text-align: left;
      font-size: 20px;
    }

  }
  @media ${mobileRange} {
    text-align: center;
    margin: 70px 0 0 0;
    justify-content: center;
  }
  @media ${tabletRange} {
    margin: 0 81px 75px;
  }
`;

export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 75px;
  color: ${colors.scorpion};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 82px
  }
  @media ${mobileRange} {
    margin-top: 91px;
    display: block;
  }
  @media ${ipadProRange} {
    margin-top: 70px;
  }
`;

export const BreadCrumb = styled.div`
  padding-left: 41px;
  @media ${mobileRange} {
    padding-left: 25px;
  }
  @media ${tabletRange} {
    padding-left: 25px;
  }
  @media ${ipadProRange} {
    padding-left: 25px;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  @media ${mobileRange} {
    display: block;
  }
`;

export const LINK = styled.div`
  font-size: 17px;
  line-height: 2.65;
  text-transform: capitalize;
  a {
    text-decoration: none;
    color: ${colors.bondi_blue}
  }
`;


export const ManufacturerDetail = styled.div`
  min-width: 840px
  @media ${mobileRange} {
    min-width: 320px
  }
  @media ${tabletRange} {
    min-width: 548px
  }
  @media ${ipadProRange} {
    min-width: 715px
  }
`;

export const ManufactureLogoList = styled.div`
  background: ${colors.light_darkish_blue};
  @media ${mobileRange} {
    background: transparent;
  }
`;
export const CorporatePartnership = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
`;

export const CorporatePartnerHeading = styled.div`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.34px;
  text-align: center;
  color: ${colors.sherpa_blue};
  @media ${tabletRange} {
    font-size: 22px;
  }
  @media ${mobileRange} {
    font-size: 22px;
    text-align: left;
    margin: 0 20px
  }
`;

export const CorporatePartnerDescription = styled.div`
  font-size: 18px;
  line-height: 1.61;
  text-align: center;
  color: ${colors.dove_gray};
  @media ${tabletRange} {
    font-size: 14px;
  }
  @media ${mobileRange} {
    font-size: 14px;
    text-align: left;
    margin: 0 20px
  }
`;

export const CorporatePartnerLogoWrapper = styled.div`
  display: flex;  
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  @media ${mobileRange} {
    padding-top: 30px;
  }
  @media ${tabletRange} {
    padding-top: 30px;
  }
`;

export const CorporatePartnerLogo = styled.div`
  margin: 10px 20px;
  @media ${mobileRange} {
    margin: 10px;
  }

  img {
    width: 180px;
    border: solid 1px ${colors.mercury};
    cursor: pointer;

    @media ${mobileRange} {
      width: 158px;
    }
  }
`;

export const CorporatePartnerWrapper = styled.div`
  padding: 60px 180px;
  text-align: center;
  @media ${mobileRange} {
    padding: 0 0px 50px;
  }
  @media ${tabletRange} {
    padding: 60px 80px;
  }
`;

export const ManufactureInfo = styled.div`
  display: flex;
  background: ${colors.white};
  padding-bottom: 30px;
  @media ${mobileRange} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
`;

export const ManufactureDetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background: ${colors.light_darkish_blue};
  padding-bottom: 45px;
  @media ${mobileRange} {
    padding: 0 20px 70px;
    align-items: flex-start;
    background: transparent;
  }
`;

export const Logosection = styled.div`
  width: 50%;
  text-align: center;
  margin-top: 60px;
  @media ${mobileRange} {
    width: 100%;
  }
  @media ${tabletRange} {
    margin-top: 100px;
    width: 45%;
  }
  img {
    width: 250px;
    @media ${mobileRange} {
      width: 200px;
    }
    @media ${tabletRange} {
      width: 200px;
    }
  }
`;

export const ContentSection = styled.div`
  width: 50%;
  text-align: left;
  padding-right: 150px;
  @media ${mobileRange} {
    width: 100%;
    padding: 0 25px;
  }
  @media ${tabletRange} {
    padding-right: 100px;
    width: 55%;
  }
  h2 {
    padding-top: 90px;
    letter-spacing: -0.39px;
    color: ${colors.sherpa_blue};
    font-size: 26px;
    @media ${mobileRange} {
      padding-top: 60px;
      font-size: 22px;
    }
    @media ${tabletRange} {
      font-size: 22px;
    }
  }
  p {
    letter-spacing: 0px;
    font-size: 15px;
    color: ${colors.dove_gray};
    @media ${mobileRange} {
      font-size: 14px;
    }
    @media ${tabletRange} {
      font-size: 14px;
    }
  }
  span {
    color: ${colors.bondi_blue};
    font-size: 15px;
    cursor: pointer;
    @media ${mobileRange} {
      font-size: 14px;
    }
    @media ${tabletRange} {
      font-size: 14px;
    }
  }
`;

export const GuideLineHeading = styled.h2`
  letter-spacing: -0.39px;
  font-size: 26px;
  color: ${colors.sherpa_blue};
  @media ${mobileRange} {
    font-size: 22px;
    text-align: left;
  }
  @media ${tabletRange} {
    font-size: 22px;
  }
`;

export const GuideLineDescription = styled.p`
  letter-spacing: -0.39px;
  font-size: 15px;
  color: ${colors.dove_gray};
  text-align: center;
  margin: 0 300px;
  @media ${mobileRange} {
    text-align: left;
    font-size: 16px;
    margin: 0;
  }
  @media ${tabletRange} {
    font-size: 14px;
    margin: 0 196px;
  }
`;

export const GuideLineWrapper = styled.div`
  display: flex;
  text-align: center;
  margin: 0 50px;
  padding-top: 20px;
  width: 100%;
  @media ${mobileRange} {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;
export const GuideLinesContent = styled.div`
  width: 33%;
  @media ${mobileRange} {
    width: 100%;
  }
  img {
    width: 170px;
    @media ${mobileRange} {
      width: 100px;
      height: 100px
    }
    @media ${tabletRange} {
      width: 120px;
    }
  }
  p {
    font-size: 15px;
    padding: 0 25px;
    @media ${mobileRange} {
      font-size: 14px;
      text-align: left;
    }
    @media ${tabletRange} {
      font-size: 14px;
      padding: 0 18px;
    }
  }
  h3 {
    letter-spacing: 0px;
    font-size: 18px;
    color: ${colors.sherpa_blue};
    text-align: center;
    @media ${mobileRange} {
      font-size: 18px;
      text-align: left;
      padding: 0 25px;
    }
    @media ${tabletRange} {
      font-size: 16px;
    }
  }
`;