import axios from "axios";
import {endpoint} from "../../config/app.config";

export const postUserFeedback = (userInput) => {
  return axios
    .post(`${endpoint}/api/feedback`, userInput)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFeedbackQuestion = () => {
  return axios
    .get(`${endpoint}/api/feedback/question`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
