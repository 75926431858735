import * as actions from "./actions";

const initialState = {
  covidResource: null
}

export default function covidReducer(state = initialState, { type, payload }) {
  switch (type) {
  case actions.GET_COVID_CONTENT: {
    return {
      ...state
    }
  }
  case actions.GET_COVID_CONTENT_SUCCESS: {
    return {
      ...state,
      covidResource: payload.result.data
    }
  }
  case actions.GET_COVID_CONTENT_FAIL: {
    return {
      ...state,
      covidResource: null
    }
  }
  default:
    return state;
  }
}
