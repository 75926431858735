import CloseIcon from "../../assets/images/help_close.png";
import EntellectIcon from "../../assets/images/Entellect.png";

export default {
  "poweredByText": "Powered by",
  "entellectIcon": EntellectIcon,
  "tooltipContent": `<ul>
  <li>Spend more time on discovery and development, and less time cleaning data.</li>
       <li> Entellect is a data integration platform that enables effective innovation through connecting applications and tools with quality data and metadata.</li>
       <li>Powered by a unique data linking and contextualizing engine, 
        Entellect is built on a modern architecture and Elsevier’s heritage of strong data governance.</li>
       </ul>
        `,
  "closeIcon": CloseIcon,
  "entellect_logo_alt_txt": "Powered by Entellect Logo"
}
