import React from "react";
import * as styled from "./styles";

const PiLoaderComponent = React.memo(() => {
  window.scrollTo(0, 0);
  return (
    <styled.PiDetailsLoader>
      <styled.PiDetailsHeaderLoader className="gradient" />
      <styled.ContentLoaderContainer>
        <styled.PiDetailsIndexLoader className="gradient" />
        <styled.PiDetailsContentLoader className="gradient" />
      </styled.ContentLoaderContainer>
    </styled.PiDetailsLoader>
  );
});

export default PiLoaderComponent;