import React, { memo } from "react";
import resources from "../../config/content/pageNotFound";
import withTransition from "../../components/with-transition";
import * as styled from "./style";

/**
 * PageNotFound page
 * @returns {HTMLElement} element
 */
const PageNotFound = (props) => {

  /**
   * Go to home page function
   * @returns {undefined}
   */
  const goToHome = () => {
    props.history.push("/home");
  }

  return (
    <styled.Wrapper>
      <styled.imageWrapper>
        <img src={resources.Pagenotfound_img} alt={resources.Pagenotfound_alt}></img>
      </styled.imageWrapper>
      <styled.TextWrapper>
        <h1><span>{resources.error_code}</span></h1>
        <h1>{resources.page_not_found_txt}</h1>
        <styled.ReturnButton onClick={goToHome}>{resources.return_home_txt}</styled.ReturnButton>
      </styled.TextWrapper>
    </styled.Wrapper>
  )
};

export default memo(withTransition(PageNotFound));
