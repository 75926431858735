import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";
import openArrow from "../../assets/images/openArrow.png";
import accordianClose from "../../assets/images/accordianClose.png";

export const NewsList = styled.div`
  min-width: 260px;
  background-color: ${colors.catskill_gray};
  margin-left: 40px;
  padding: 13px 0 30px;
  height: 100%;
  & .active {
    border-left: 2px solid ${colors.bondi_blue};
    color: ${colors.bondi_blue};
    padding-left: 38px;
    @media ${tabletRange} {
      padding-left: 25px;
    }
    @media ${ipadProRange} {
      padding-lefT: 25px;
    }
  }

  @media ${mobileRange} {
    display: none;
  } 

  @media ${tabletRange} {
    min-width: 220px;
    padding: 1px 0;
    margin-left: 0;
  }
  @media ${ipadProRange} {
    min-width: 309px;
    margin-left: 0;
  }
`;

export const NewsDateWrapper = styled.div`
  padding: 10px 0;
`;

export const EachNews = styled.div`
  font-size: 15px;
  padding-left: 40px;
  cursor: pointer;

  @media ${tabletRange} {
    font-size: 14px;
    padding-left: 27px;
  }

  @media ${ipadProRange} {
    font-size: 16px;
    padding-left: 27px;
  }
  @media ${mobileRange} {
    line-height: 1.44;
    letter-spacing: -0.21px;
    margin: 17px 0;
  }
`;

export const NewsListContainer = styled.div`
    @media ${mobileRange} {
      display: block;
      background-color: ${colors.catskill_gray};
    }
`;

export const margin = styled.div`
    display: none;
    @media ${mobileRange} {
      display: block;
      height: 6px;
      width: 100%;
      background-color: ${colors.white};
    }
`;

export const newsMobileList = styled.div`
    display:none;
    @media ${mobileRange} {
      display: block;
      background-color: ${colors.catskill_gray};
      overflow: scroll;
      width: 94%;
      height: ${(props) => props.expandList ? "380px" : "0" };
      padding-bottom: ${(props) => props.expandList ? "24px" : "0" };
      transition: height 0.5s ease 0s;
      .active {
          border-left: 2px solid ${colors.bondi_blue};
          color: ${colors.bondi_blue};
          padding-left: 36px;
      }
    }
`;

export const NewsMobileAccordian = styled.div`
  display: none;
  @media ${mobileRange} {
    width: 100%;
    display: block;
    background: ${props => props.isOpen ? `90% 50% no-repeat url(${accordianClose})` : `90% 50% no-repeat url(${openArrow})`};
    background-color: ${colors.catskill_gray};
    background-size: 14px 10px;
    padding: 21px 25px 0;
  }
`;

export const NewsMobileTitle = styled.div`
  font-size: 21px;
  line-height: 1.19;
  letter-spacing: -0.27px;
  color: ${colors.sherpa_blue}
`;

export const NewsMobileSelected = styled.div`
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.21px;
  color: ${colors.bondi_blue};
  margin-top: 8px;
  padding-bottom: 25px;
  border-bottom: ${props => props.isOpen ? `1px solid ${colors.geyser}` : "none"};;
`;
