import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { minLaptop, mobileRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  background: ${colors.white};
  position: absolute;
  display: ${props => props.showPopup ? "block" : "none"};
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  max-width: 500px;
  z-index: 102;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 35%
  }
  @media ${mobileRange} {
    top: 4%;
    margin: 20px;
  }
  .tourImage {
    margin: 30px 0;
    @media ${minLaptop} {
      margin: 20px 0;
    }
    img {
      width: 350px;
      @media ${mobileRange} {
        width: 200px;
      }
      @media ${minLaptop} {
        width: 250px;
      }
    }
  }
`;

export const WelcomeTextSection = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: ${colors.light_black};
  @media ${mobileRange} {
    font-size: 26px;
  }
  @media ${minLaptop} {
    font-size: 34px;
  }
`;

export const MessageSection = styled.div`
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.light_greyish_black};
  padding: 10px 20px 0;
  @media ${mobileRange} {
    font-size: 16px;
  }
  @media ${minLaptop} {
    font-size: 20px;
  }
`;

export const Buttons = styled.div`
  margin-top: 20px;
  @media ${mobileRange} {
    font-size: 16px;
  }
`;

export const StartTour = styled.button`
  border-radius: 3px;
  background-color: ${colors.sherpa_blue};
  padding: 12px 25px;
  color: ${colors.white};
`;

export const SkipTour = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin 15px 0 20px;
  font-size: 16px;
  color: ${colors.light_grey_suit};
  cursor: pointer;
  img {
    width: 12px;
  }
  div {
    padding-left: 5px;
  }
`;
