import {GET_ROLES, GET_FAIL_ROLES, GET_SUCCESS_ROLES, SEND_DEGREE, SEND_DEGREE_SUCCESS, SEND_DEGREE_FAIL } from "./actions"

const initialstate = {
  roles: {},
  loading: false
}

export default function rolesReducer(state= initialstate, {type, payload }) {
  switch (type) {
  case GET_ROLES:
    return {
      ...state,
      loading: true
    }

  case GET_SUCCESS_ROLES:
    return {
      ...state,
      roles: payload.result,
      loading: false
    }
  case GET_FAIL_ROLES:
    return {
      ...state,
      loading: false
    }
  case SEND_DEGREE:
    return {
      ...state,
      loading: true
    }
  case SEND_DEGREE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case SEND_DEGREE_FAIL:
    return {
      ...state,
      loading: false
    }
  default: return state
  }
}
