import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const FellowshipPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  font-size: 20px;
  position: relative;
  padding-top: 85px;
  @media ${tabletRange} {
    padding-top: 80px;
  }
  & .container {
  padding: 0;
  max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
  padding-top: 90px;
  }
`;

export const HeaderSection = styled.h1`
  font-size: 30px;
  font-weight: 300;
  margin-left: 75px;
  margin-bottom: 0;
  @media ${mobileRange} {
    text-align: center;
    margin: 70px 0 0;
  }
  @media ${tabletRange} {
    margin: 0 81px 0 40px;
  }
  @media ${ipadProRange} {
    margin: 0 30px;
  }
`;

export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 85px;
  color: ${colors.scorpion};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 85px
  }
  @media ${mobileRange} {
    margin-top: 91px;
    display: block;
  }
  @media ${ipadProRange} {
    margin-top: 70px;
  }
`;

export const TitleWrapper = styled.h2`
  width: 100%;
  text-align: center;
  padding: 45px 0;
  font-size: 26px;
  color: ${colors.sherpa_blue};
  margin-bottom: 0;
  @media ${mobileRange} {
    padding: 40px 0;
    font-size: 22px;
  }
  @media ${tabletRange} {
    font-size: 24px;
  }
`;

export const ContentWrapper = styled.div`
  tr {
    vertical-align: top;  
  }
  th {
    padding: 27px;
    color: ${colors.sherpa_blue};
    width: ${props => props.width};
    background: ${colors.light_darkish_blue};
    border-right: 1px solid rgba(112,112,112,0.2);
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    @media ${tabletRange} {
      padding: 20px;
      font-size: 18px;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      @media ${tabletRange} {
        font-size: 18px;
      }
    }
  }
  .description {
    border-right: none !important;
  }
  td {
    font-size: 15px;
    border-bottom: 1px solid rgba(112,112,112,0.2);
    border-right: 1px solid rgba(112,112,112,0.2);
    padding: 50px 30px;
    @media ${tabletRange} {
      padding: 35px 20px;
    }
    img {
      width: 150px;
    }
  }
  @media ${mobileRange} {
    display: none;
  }
`;

export const DescriptionWrapper = styled.div`
  word-break: break-word;
  @media ${mobileRange} {
    padding: 0 25px;
    font-size: 15px;
  }
`;
export const Content = styled.p`
  margin-bottom: 0;
  ul {
    margin-bottom: 0;
  }
`;
export const GoToProgramWrapper = styled.div`
  font-size: 16px;
  padding-top: 15px;
  a {
    color: ${colors.bondi_green};
  }
`;

export const ReadMoreWrapper = styled.div`
  font-size: 16px;
  padding: 10px 0 0;
  color: ${colors.bondi_green};
  cursor: pointer;
`;

export const ContentWrapperMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

export const TitleWrapperMobile = styled.div`
  display: flex;  
  text-align: left;
  background: ${colors.light_darkish_blue};
  margin-top: ${props => props.index === 0 ? "0" : "40px;"}
`;

export const CompanyTitle = styled.div`
  width: 40%;
  padding: 20px 0 20px 26px;
  font-size: 16px;
  color: ${colors.sherpa_blue};
`;

export const FellowshipTitle = styled.div`
  width: 60%;
  padding: 20px 0 20px 26px;
  border-left: 1px solid rgba(112,112,112,0.2);
  font-size: 16px;
  color: ${colors.sherpa_blue};
`;

export const DescriptionWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112,112,112,0.2);
`;

export const ImageWrapperMobile = styled.div`
  width: 40%;
  padding-left: 10px;
  img {
    width: 120px;
  }
`;

export const FellowshipDescTitle = styled.p`
  width: 60%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 24px;
  border-left: 1px solid rgba(112,112,112,0.2);
`;

export const DescriptionTitle = styled.div`
  padding-left: 27px;
  font-size: 16px;
  color: ${colors.sherpa_blue};
  margin: 15px 0;
`;
