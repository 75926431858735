import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL, mobileAndTabletRange } from "../../common/styles/breakpoints";
import pi_index_open from "../../assets/images/pi_index_open.png";
import accordianClose from "../../assets/images/accordianClose.png";

export const PiContainer = styled.div`
  width: 100%;
  color: ${colors.sherpa_blue};
  background: transparent;
  font-size: 20px;
  & .container {
    padding: 0;
    max-width: 100%;
  }
`;

export const NoResultsWrapper = styled.div`
  max-width: ${containerMaxWidth};
  margin: 90px auto 0;
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
`;

export const Spl = styled.div`
  // Currently moving styles into the section
`;

export const ResultsContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  padding: 0 16% 0 17%;
  @media ${ipadProRange} {
      padding: 0 6% 0 7%;
  }
  @media ${tabletRange} {
      padding: 0 3%;
  }
  @media ${mobileRange} {
      padding: 0;
  }
  @media ${minLaptop} {
      padding: 0 3%;
  }
  @media ${minLaptopL} {
    padding: 0 16% 0 17%;
  }
`;

export const IndexContainer = styled.div`
    margin-bottom: 10px;
    padding-top: 20px;
    height: ${props => props.height+"px"};
    overflow: auto;
    td {
        width: 100%;
        display: block;
    }
    .Footnote {
        display: flex;
        padding: 0 25px;
        dt,dd  {
                font-style: italic;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.21px;
                font-weight: normal;
                color: ${colors.sherpa_blue};
        }
    }
    border: solid 1px ${colors.alto_fade};
    background-color: ${colors.white};
    width: 30%;
    @media ${minLaptop} {
        margin-top: 0;
    }
    @media ${mobileAndTabletRange} {
        display: none;
    }

    td {
        padding: 0;
    }
    .link-container {
        h1, h2{
            margin-left: 0 !important;
            margin-bottom: 0!important;
            padding: 0 10px 0 29px;
            font-size: 14px;
            line-height: 2.3;
            letter-spacing: -0.21px;
            font-weight: normal;
            text-transform: uppercase;
            a {
                color: ${colors.dove_gray};
            }
        }
        &.multiple {
            .header-wrapper {
                display: flex;
                h1 {
                    width: 90%;
                }
                .subheader-wrapper {
                    width: 10%;
                    cursor: pointer;
                    background: url('${pi_index_open}');
                    background-repeat: no-repeat;
                    background-size: 10.5px 5.5px;
                    background-position: center;
                }
            }
            .subheader-wrapper {
                width: 90%;
            }
        }
        .sub-headers {
            display: none;
        }
        &.active {
            border-left: 1px solid ${colors.bondi_blue};
            display: block;
            a {
                color: ${colors.bondi_blue};
            }
            .sub-headers {
                display: block;
            }
            &.multiple {
                .header-wrapper {
                    display: flex;
                    h1 {
                        width: 90%;
                    }
                    .subheader-wrapper {
                        width: 10%;
                        cursor: pointer;
                        background: url('${accordianClose}');
                        background-repeat: no-repeat;
                        background-size: 10.5px 5.5px;
                        background-position: center;
                    }
                }
                .sub-headers {
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                }
            }
        }
    }
`;

export const ProductHeader = styled.div`
    width: 100%;
    background-color: ${colors.sherpa_blue};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1% 17%;

    @media ${mobileRange} {
        padding: 0;
        display: block;
        margin-top: 20%;
    }
    @media ${tabletRange} {
        padding: 0 34px;
    }
    @media ${ipadProRange} {
        padding: 0 72px;
    }
    @media ${minLaptop} {
        padding: 1% 4% 1% 3%;
    }
    @media ${minLaptopL} {
        padding: 1% 17%;
    }
`;

export const ProductTitle = styled.div`
    font-size: 28px;
    letter-spacing: -0.44px;
    color: ${colors.white};
    margin-right: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media ${mobileRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
        width: 100%;
        text-align: left;
        padding: 22px 0 0 24px;
    }

    @media ${tabletRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
        margin-top: 10px;
    }
    @media ${ipadProRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
        margin-top: 10px;
    }
    @media ${minLaptop} {
        font-size: 28px;
    }
    @media ${minLaptopL} {
        font-size: 28px;
    }
    .showFullTitle {
        font-size: 24px;
        font-weight: 300;,
    }
    .show_title {
        font-size: 24px;
        padding-left: 5px;
        font-weight: 300;
        text-decoration: underline;
        cursor: pointer;
    }
    .hideFullTitle {
        font-size: 24px;
        font-weight: 300;
    }
`;

export const SourceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media ${mobileRange} {
        width: 100%;
        justify-content: left;
        padding-left: 24px;
    }
`;

export const Label = styled.div`
    font-size: 17px;
    line-height: 2;
    margin-right: 14px;
    color: ${colors.white};
    &.separator {
        &:before {
            margin-right: 14px;
            content: "|";
            @media ${mobileRange} {
                margin-right: 7px;
            }
            @media ${tabletRange} {
                margin-right: 7px;
            }
            @media ${ipadProRange} {
                margin-right: 7px;
            }
            @media ${minLaptop} {
                margin-right: 10px;
            }
            @media ${minLaptopL} {
                margin-right: 14px;
            }
        }
    }
    @media ${mobileRange} {
        font-size: 12px;
        line-height: 2;
        font-weight: bold;
        margin-right: 7px
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: bold;
        line-height: 2;
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: bold;
        line-height: 2;
    }
    @media ${minLaptop} {
        font-size: 15px;
    }
    @media ${minLaptopL} {
        font-size: 17px;
    }
`;

export const SourceSeparator = styled.div`
  color: ${colors.white};
`;

export const FormValue = styled.div`
    font-size: 17px;
    font-weight: 300;
    line-height: 2;
    margin-right: 14px;
    color: ${colors.white};
    @media ${mobileRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
    }
    @media ${minLaptop} {
        margin-right: 10px;
        font-size: 15px;
    }
    @media ${minLaptopL} {
        margin-right: 14px;
        font-size: 17px;
    }
`;

export const SourceValue = styled.div`
    font-size: 17px;
    font-weight: 300;
    line-height: 2;
    color: ${colors.bondi_blue};
    @media ${mobileRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
        color: ${colors.white};
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
        color: ${colors.white};
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: 300;
        line-height: 2;
        color: ${colors.white};
    }
    @media ${minLaptop} {
        font-size: 15px;
    }
    @media ${minLaptopL} {
        font-size: 17px;
    }
`;

export const ExternalPageContainer = styled.div`
    width: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
    .active-content {
        border-left: 2px solid ${colors.bondi_blue} !important;
    }
    margin: 0 0 0 2%;
    @media ${mobileRange} {
        margin: 15px 0 0 0;
    }
    @media ${mobileAndTabletRange} {
      max-height: unset;
      width: 100%;
      margin: 0;
    }
    @media ${ipadProRange} {
        width: 70%;
        margin: 0 0 0 2%;
        height: ${props => props.height+"px"};
    }
    @media ${minLaptop} {
        height: ${props => props.height+"px"};
    }
    &.no-index {
        .Section:first-child {
            margin-top: 0;
        }
    }
    .spl {
        font-family: Lato;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        margin-right: 1em;
        @media ${tabletRange} {
            margin: 0 0.5em;
        }
        @media ${mobileRange} {
            margin: 0 1em;
        }
        .DataElementsTables {
            width: 100%;
            >table {
                width: 100%;
                display: inline-table;
                overflow-x: auto;
                @media ${mobileRange} {
                    display: block;
                }
            }
        }
    }
}
                            
@media print {
@page { 
        size:8.5in 11in; 
    margin:0;
    margin-left:0;
    margin-right:0;
    margin-top:0;
    margin-bottom:0;
    orphans:4; 
    widows:2;
}

.spl {
    font-family: Lato;
    font-size: 12pt;
    margin-left: 0in;
    margin-right: 0in;
    margin-top: 0in;
    margin-bottom: 0in;
}


.spl div.DocumentTitle {
        display: none;
}

.spl .bars {
     display:none;
}

}
.header3911 {	
    font-family: Century Gothic,sans-serif;
    width: 60%;
}
.data3911 { 
    background-color:#E8E8E8; 
    font-family: Century Gothic,sans-serif;
    width: 40%;
}
.tableContentAlign3911 { 
    width:940px;
    padding:0;
    border-spacing:0;
    border-collapse:collapse;
}

.spl .bars {

}

.spl h1, 
.spl h2, 
.spl h3, 
.spl h4, 
.spl h5, 
.spl h6 {
    page-break-before: auto;
    page-break-after: avoid;
}

.spl div.Warning {
    page-break-before: auto;
    page-break-inside: avoid;
        //GS: removed, as it interferes with Mixin hiding: display: inline-block;
}
                            
.spl h1 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-bottom: 0ex;
    text-align: left;
    color: black;
}

.spl div.Section h1 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top:4ex;
    margin-bottom: 0ex;
    text-align: left;
}

.spl div.Warning h1 {
    margin-top: 0ex;
}

.spl h2 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 2.2ex;
    margin-bottom: 0ex;
    text-align: left;
    color:black;
}

.spl h3 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: normal;
    margin-top: 2.2ex;
    margin-bottom: 0ex;
    text-align: left;
    color: black;
}

.spl h4 {	
    text-indent: 0;
    font-size: 100%;
    font-weight: normal;
    margin-top: 2.2ex;
    margin-bottom: 0ex;
    text-align: left;
    font-style: italic;
    color: black;
}

.spl div.Warning h1 {
    margin-top: 0;
    margin-bottom: 4pt;
    text-align: center;
}

.spl div.HighlightSection,
.spl div.HighlightSubSection
 {
    margin-top: 1.8ex;
    padding-bottom: .25ex;
    border-top-style: dashed; 
    border-top-width: thin;
}

.spl h1.Highlights {	
    text-align: center;
    margin-bottom: 0ex;
    margin-top: -1.6ex;
    margin-left: 5%;
    margin-right: 5%;
    white-space: nowrap;
    text-indent: 0;
}

.spl h1.Highlights span {
    background: white; 
    padding-top:.5ex;
    padding-bottom:1ex;
    padding-left:.25em; 
    padding-right:.25em;
}

.spl .HighlightsSeeReference {
    font-weight: bold;
}

.spl .HighlightsRevision {
    text-align: right;
    font-weight: bold;
    margin-bottom: 1ex;
}

.spl h1.toc {	
    text-indent: 0;
    margin-top: 0;
    margin-bottom: 0ex;
    text-align: left;
    padding-left:.25in;
    padding-right: .25in;
    text-transform: uppercase;
}

.spl h2.toc {	
    margin-top: 0ex;
    font-weight: normal;
    margin-bottom: 0ex;
    text-align: left;
    padding-left:.40in;
    padding-right: .40in;
}

.spl h3.toc {	
    font-size: 100%;
    font-weight: normal;
    margin-top: 0ex;
    margin-bottom: 0ex;
    text-align: left;
    padding-left:.55in;
    padding-right: .55in;
}

.spl h4.toc {	
    padding-left: 3em;
    margin-top: 0ex;
    margin-bottom: 0ex;
    text-align: left;
    font-style: italic;
    padding-left:.70in;
    padding-right: .70in;
}

.spl span.SectionNumber {
    position: absolute;
    left: 10pt;
    top: auto;
}

.spl .titleCase {
    text-transform: capitalize;
}

.spl .lowerCase {
    text-transform: lowercase;
}
.spl p {	
    text-indent: 0;
    margin-top: 1.2ex;
    margin-bottom: 0ex;
}

.spl td p {	
    text-indent: 0;
    margin-top: 0; 
    margin-bottom: 0;
}
                
.spl th p {	
    text-indent: 0;
    margin-top: 0; 
    margin-bottom: 0;
}
                
.spl p.DocumentTitle {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
}

.spl p.DocumentSubTitle {	
    text-indent: 0;
    font-size: 100%;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.spl .Instruction {	
    color: green;
}

.spl .EffectiveDate {
    font-size: 80%;
    margin-top: 1ex;
    margin-bottom: 1ex;
    text-align: left;
    float: left;
    position: relative;
}

.spl .DocumentMetadata {
    display:none;
    position:absolute;
    bottom:0;
    left:0;
    width:30em;
    background:white;
}

.spl .EffectiveDate:hover .DocumentMetadata {
    display:inline;
}

.tableHeader {
    background-color: ${colors.table_header};
    color: ${colors.white};
}
.tableBody  tr:nth-child(odd) {
    background-color: ${colors.table_row_color};
}
.tableBody tr td {
    border-bottom : 1px solid ${colors.black};
}

.spl .DistributorName {
    font-size: 80%;
    margin-top: 1ex;
    margin-bottom: 1ex;
    float: right;
}

.spl span.DistributorName {	
    font-size: 80%;
    margin-top: 1ex;
    margin-bottom: 1ex;
    float: right;
}

.spl span.ParagraphCaption {
    font-weight: bold;
    text-indent: 0;
}

.spl p.ListCaption {
    font-weight: bold;
    font-size: 90%
}

.spl p.MultiMediaCaption {
    margin-top: 0; 
    text-align: center;
    font-weight: bold;
    font-size: 90%
}

.spl p.MultiMediaCaptionNotCentered {
    margin-top: 0; 
    text-align: left;
    font-weight: bold;
    font-size: 90%
}

.spl p.disclaimer {
    font-style: italic;
}

.spl .Italics {	
    font-style: italic;
}.n0-
.spl .Bold {	
    font-weight: bold;
}
.spl .Underline {
     text-decoration: underline;
}

.spl .Insert {
    color: green;
    border-bottom: 3px double;
}
.spl .Delete {
       text-decoration: line-through;
}

.spl a:link    { text-decoration: none; }
.spl a:visited { text-decoration: none; }
.spl a:active  { text-decoration: none; }

/* use <span class="sub"> to avoid ugly uneven line spacing in IE */
.spl .Sub { 
    font-size: 80%;
    position: relative;
    bottom: -3px;
}
.spl .Sup {
    font-size: 80%;
    position: relative;
    top: -5px;
}

.spl table	{
    white-space: wrap;
    font-size: 100%;
/*
    border-top-style: none;
    border-bottom-style: none;
    border-right-style: none;
    border-left-style: none;
*/
    margin-top: 3ex;
    margin-bottom: 3ex;
    border-collapse: collapse;
    border-color: black;
    border-width: thin;
}

/*
.spl div.HighlightSection table {
        display: inline-block;
}
*/

.spl table caption {	
    margin-top: 10px; /* Gecko needs this, IE doesn't add any extra */
    text-align: center;
    font-weight: bold;
    font-size: 100%;
    caption-side: top;
}



                
.spl th, 
.spl td { 
    border-top-style: none;
    border-bottom-style: none;
    border-right-style: none;
    border-left-style: none;
    border-collapse: collapse;
    border-color: black;
    border-width: thin;
/*
    padding-top: 1px;
    padding-bottom: 1px;
*/
}

.spl td.twocolumn {
 width: 50%;
 text-align: left;
 vertical-align: top;
 padding-left: 0.5em; 
 padding-right: 0.5em;
}

.spl td.Warning {
    border: 1px solid black; 
    margin-top: 2ex;
    margin-bottom: 2.2ex;
    padding-left: 1em; 
    padding-right: 1em;
    padding-top: 0ex;
    padding-bottom: 2ex;
}

/* the preferred way to rules in tables */

.spl tr.Botrule td, 
.spl tr.Botrule th,
.spl th.Botrule, 
.spl td.Botrule { 
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.spl thead tr.First td,
.spl thead tr.First th {
    border-top-style: solid;
    border-top-width: medium;
}

.spl thead tr.Botrule td,
.spl thead tr.Botrule th {
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.spl thead tr.Toprule td,
.spl thead tr.Toprule th {
    border-top-style: solid;
    border-bottom-width: thin;
}

.spl thead tr.Last td, 
.spl thead tr.Last th {
        border-bottom-style: solid;
    border-bottom-width: thin;
  /* rule here might fail with rowspan headers */
} 

.spl tbody tr.First td, 
.spl tbody tr.First th  { 
        border-top-style: solid;
    border-top-width: thin;
}	 

.spl tbody.Headless tr.First td, 
.spl tbody.Headless tr.First th  { 
        border-top-style: solid;
    border-top-width: medium;
}	 

.spl tbody tr.Botrule td, 
.spl tbody tr.Botrule th { 
    border-bottom-style: solid;
    border-bottom-width: thin;
}	 


.spl tbody tr.Last td,
.spl tbody tr.Last th { 
    border-bottom-style: solid;
    border-bottom-width: medium;
}

.spl tbody tr td.Last,
.spl tbody tr th.Last { 
    border-bottom-style: solid;
    border-bottom-width: medium;
}	 
     
/* begin of combinatorics of individual rules */

/* one rule only */
.spl th.Lrule,
.spl td.Lrule { 
    border-left-style: solid;
    border-left-width: thin;
}

.spl th.Rrule, 
.spl td.Rrule { 
    border-right-style: solid;
    border-right-width: thin;
}

.spl th.Toprule, 
.spl td.Toprule { 
    border-top-style: solid;
    border-top-width: thin;
}

.spl th.Botrule, 
.spl td.Botrule { 
    border-bottom-style: solid;
    border-bottom-width: thin;
}

/* end of table rules */

.spl ul { 
    margin-top: .2ex;
    margin-left: 1.5em;
    padding-left: 0;
}
    
.spl ol { 
    margin-top: .2ex;
    padding-left: 1.5em;
}

.spl li {
   margin-top: .2ex;
   margin-left: 0;
}

.spl li p {
   margin-top: .2ex;
}

.spl div.Warning ul {
       margin-top: .2ex;
    margin-left: 1.5em;
    padding-left: 0;
    margin-bottom:.2ex;
}

.spl ul.Disc { list-style-type: disc; }
.spl ul.Circle { list-style-type: circle; }
.spl ul.Square { list-style-type: square; }

.spl ol.LittleRoman { list-style-type: lower-roman; }
.spl ol.BigRoman { list-style-type: upper-roman; }
.spl ol.LittleAlpha { list-style-type: lower-alpha; }
.spl ol.BigAlpha { list-style-type: upper-alpha; }
.spl ol.Arabic { list-style-type: arabic; }

.spl dl {
   /* display: compact; compact doesn't seem to work 

     XXX: but display: inline would work
   */
   margin-left:.9em;
}

.spl dt {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.spl dd {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    position:relative;
    margin-top:-2.2ex;
    margin-left:1.5em;
}

.spl hr.Footnoterule {
    margin-top:2ex;
    width: 30%;
    text-align: left;
    /* FIXME: make a normal line, not a grey 3d effect */		
}
.spl dl.Footnote {
    margin-left:0;
    margin-bottom:0;
    margin-top:0;
}

.spl dl.Footnote dt {
    font-size:90%;
}

.spl dl.Footnote dd {
    margin-left:1em;
    font-size:90%;
}

.spl dl.FootnoteContents {
    text-align: right;
    margin-left:0;
    margin-bottom:0;
    margin-top:0;
}
.spl dl.FootnoteContents dt {
    font-size:100%;
}
.spl dl.FootnoteContents dd {
    margin-left:1em;
    font-size:90%;
}

.spl p.Footnote {
    text-indent:-1em;
    margin-left:1em;
    font-size:90%;
}

.spl p.Footnotesize {
    margin-bottom:0ex;
    margin-top:0ex;
    font-size:90%;
}

.spl img {
    display: block;
    margin-top: 0ex; 
    margin-bottom: 0ex;
    text-align: center;
}
                            
.spl p img {
    display: inline;
    margin-top: 0ex; 
    margin-bottom: 0ex;
}
                            
.spl div.Figure {
    margin-top: 4ex; 
    margin-bottom: 4ex;
}

.spl div.Warning {
    border: 4px solid black; 
    margin-top: 2ex;
    margin-bottom: 2.2ex;
    padding-left: 1em; 
    padding-right: 1em;
    padding-top: 0ex;
    padding-bottom: 1ex;
}

.spl div.Subject {
    margin-top: 1ex;
    margin-bottom: 1ex;
    padding-top: 1ex;
    padding-bottom: 1ex;
    padding-left: .5em;
    padding-right: .5em;
    font-size: 80%;
    background-color: #E0E0E0;
    cursor: pointer;
}

.spl .contentTable {
    border: 1px solid #999999;
    background-color: #EAEAEA;
}
.spl .contentTablePetite {
    border: 1px solid #999999;
    background-color: #EAEAEA;
    font-size: 80%;
}
.spl .contentTableTitle {
    border-bottom: 1px solid #999999;
    background-color: #CCCCCC;
    font-size: 140%;
    font-weight: bold;
}
.spl .contentTableReg {
    border-bottom: none;
    background-color: #CCCCCC;
    font-size: 80%;
    font-weight: lighter;
}
.spl .formTable {
    border: 1px solid #FFFFFF;
    border-collapse: collapse;
}
.spl .formTablePetite {
    border: 1px solid #FFFFFF;
    border-collapse: collapse;
    font-size: 80%;
}
.spl .formTableMorePetite {
    border: 1px solid #FFFFFF;
    border-collapse: collapse;
    font-size: 60%;
}
.spl .formTitle {
    background-color: #CCCCCC;
    border: 1px solid #FFFFFF;
    font-size: 125%;
    font-weight: bold;
}
.spl .formHeadingTitle {
    border: none;
    background-color: #CCCCCC;
    font-size: 150%;
    font-weight: bold;
}
.spl .formHeadingReg {
    background-color: #CCCCCC;
    border: 1px solid #FFFFFF;
    font-size: 125%;
}
.spl .formTableRow {
    background-color: #FFFFFF;
}
.spl .formTableRowAlt {
    background-color: #F2F2F2;
}
.spl .formLabel {
    border: 1px solid #FFFFFF;
    background-color: #DDDDDD;
    font-size: 115%;
    font-weight: bold;
}
.spl .formItem {
    border: 1px solid #CCCCCC;
    font-size: 110%;
}
.spl .formTitle a:link {
    font-weight: bold;
    color: #006699;
    text-decoration: underline;
}
.spl .formTitle a:visited {
    /*font-family: "Arial Unicode MS"; Arial, Helvetica, sans-serif;*/
    font-weight: bold;
    color: #006699;
    text-decoration: underline;
}
.spl .formTitle a:active {
    /*font-family: "Arial Unicode MS"; Arial, Helvetica, sans-serif;*/
    font-weight: bold;
    /*color: #000000;*/
    text-decoration: none;
}
.spl .formTitle a:hover {
    /*font-family: "Arial Unicode MS"; Arial, Helvetica, sans-serif;*/
    font-weight: bold;
    /*color: #000000;*/
    text-decoration: none;
}
.spl .normalizer {
    font-size: 125%;
    font-weight: lighter;
}

.spl div.Highlights {
    font-size: 80%; 
}

.spl div.HighlightsDisclaimer {
        font-weight: bold;
}

.spl div.Index {
        margin-top: 5ex;
        margin-bottom: 5ex;
        padding-top: 1ex;
        padding-bottom: 1ex;
        border-top-style: solid;
        border-top-width: thin;
        border-bottom-style: solid;
        border-bottom-width: thin;
}

.spl .Colspan {
       -moz-column-span: all;
        -webkit-column-span: all;
        column-span: all;
}

.spl div.Index h1,
.spl div.Index h2 {
    margin-top: 0;
}

.spl div.Index h2 {
    margin-left: 1em;
    font-weight: normal;
}

.spl .Italics {	
    font-style: italic;
}
.spl .Noitalics {	
    font-style: normal;
}
.spl .Bold {	
    font-weight: bold;
}
.spl .Nobold {	
    font-weight: normal;
}
.spl p.DocumentTitle {
    color: green;
    text-align: center;
}
.spl .Highlights td, .spl .Index td {
    width: 100%;
    display: block;
}

.spl {
    .FullBoxedWarning {
        margin-bottom: 36px;
    }
}

.spl .Index h2 {
    display: none;
}

.spl .Index h1, .spl .Index h2, .spl .Index h1 a, .spl .Index h2 a {
    color: ${colors.dove_gray};
}

.spl .Index h1 {
    font-family: Lato;
    font-size: 16px;
    line-height: 2.81;
    letter-spacing: -0.21px;
    text-align: left;
} 


.spl {
    font-family: Lato;
    dd {
        margin-top: -3.2ex;
    }
    #section-highlights {
        border: solid 1px ${colors.alto_approx};
        background-color: ${colors.white};
        padding-left: 10px;
        .HighlightSection {
            table {
                td {
                    display: table-cell;
                }
            }
        }
         h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.95;
            letter-spacing: -0.26px;
            text-align: left;
            color: ${colors.sherpa_blue} !important;
            @media ${mobileRange} {
                font-size: 17px;
                background: transparent;
            }
        }
        .HighlightsDisclaimer {
            font-family: Lato;
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: -0.21px;
            text-align: left;
            color: ${colors.mako} !important;
            opacity: 0.8;
        }
        .HighlightsBoxedWarning {
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: -0.21px;
            text-align: left;
            color: ${colors.mako};
            background-color: ${colors.concrete};
            border: solid 1.5px ${colors.black};
            .Warning {
                border: 0;
            }
            div.Warning {
                border: 0;
            }
        }
        .HighlightSection {
            font-family: Lato;
            border: none;
            .Highlights {
                border: none;
            }
            h1 {
                margin-left: 0;
                text-align: left;
            }
            p {
                font-size: 16px;
                line-height: 1.69;
                letter-spacing: -0.21px;
                text-align: left;
                color: ${colors.mako};
                padding-left: 10px;
            }
            dd {
                span {
                    font-size: 16px;
                    line-height: 1.69;
                    letter-spacing: -0.21px;
                    text-align: left;
                    color: ${colors.black};
                }
            }
            dt {
                color: ${colors.black}
            }
        }
    }
    
    .HighlightsBoxedWarning, .FullBoxedWarning {
        background-color: ${colors.white};
        border: solid 1px ${colors.alto_approx};
        padding: 9px;
        .Warning {
            background-color: ${colors.concrete};
            border: solid 1.5px ${colors.black};
            margin: 0;
            h1 {
                font-size: 20px;
                font-weight: 600;
                color: ${colors.sherpa_blue};
                letter-spacing: -0.26px;
                line-height: 1.95;
                text-align: left !important; //Important added to override css 
            }
    
            dl {
                span {
                    font-size: 16px;
                    line-height: 1.69;
                    letter-spacing: -0.21px;
                    text-align: left;
                    color: ${colors.black};
                }
            }
            dt {
                color: ${colors.black}
            }

            p {
                span {
                        font-size: 16px;
                        line-height: 1.69;
                        letter-spacing: -0.21px;
                        text-align: left;
                        color: ${colors.black};
                }
            }
        }
    }
    .Section {
        border: solid 1px ${colors.alto};
        background-color: ${colors.white};
        margin-top: 32px;
        padding: 24px 9px 16px 26px;
        @media ${mobileRange} {
            padding: 24px 9px 16px 18px;
        }
        & .Section {
            border: none;
            padding: 0;
            margin: 0 !important;
        }

        ul, ul.Disc, dl {
            color: ${colors.mako};
            font-size: 16px;
            letter-spacing: -0.21px;
            text-align: left;
            line-height: 1.69;
        }

        table {
            color: ${colors.mako};
            font-size: 16px;
            letter-spacing: -0.21px;
            text-align: left;
            line-height: 1.69;
        }

        h1 {
            font-family: Lato !important;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.95;
            letter-spacing: -0.26px;
            text-align: left;
            color: ${colors.sherpa_blue};
            margin: 0 !important;
        }

        h2 {
            font-family: Lato;
            margin: 0 !important;
            border: none !important // !important added to override inline styings.
        }

        p {
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: -0.21px;
            text-align: left;
            color: ${colors.mako};
            border: none !important;     // !important added to override inline styings. 
        }
    }
    .DocumentTitle {
        display: none;
    }
    img {
        max-width: 100%;
    }

    .Warning.HighlightsBoxedWarning {
        border: 2px solid !important;
    }

    .Contents {
        padding-top: 10px;
        table {
            width: 100% !important; 
            display: block;
            overflow-x: auto;
        }
        a:not([href]):not([tabindex])::before {
            content: none;
            display: block;
            visibility: hidden;
            @media ${minLaptop} {
                height: 10px;
                margin-top: -10px;
            }
            @media ${minLaptopL} {
                height: 70px;
                margin-top: -70px;
            }
            @media ${mobileAndTabletRange} {
                height: 70px;
                margin-top: -70px;
            }
        }
    }
}
`;

export const GenericnameWrapper = styled.span`
    font-size 24px;
    font-weight: 300;
    height: ${props => props.showFullTitle ? "auto" : "45px"};
    overflow: hidden;
    b {
        font-size: 28px;
        @media ${mobileRange} {
            font-size: 24px;
        }
        @media ${tabletRange} {
            font-size: 24px;
        }
    }
    @media ${mobileRange} {
        height: ${props => props.showFullTitle ? "auto" : "29px"};
    }
    @media ${tabletRange} {
        height: ${props => props.showFullTitle ? "auto" : "29px"};
    }
    @media ${mobileAndTabletRange} {
        font-size: 22px;
    }
    @media ${ipadProRange} {
        font-size: 22px;
        height: ${props => props.showFullTitle ? "auto" : "34px"};
    }
`;