import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { isMobileOnly, isAndroid, isIOS, isMobile, isTablet,
  isBrowser, isDesktop, isChrome,
  isChromium, isEdge, isSafari, isFirefox, isOpera,
  isIE, isEdgeChromium, isYandex, isSamsungBrowser, isMobileSafari } from "react-device-detect";
import resources from "../../config/content/homePage";
import searchPageResources from "../../config/content/searchPage"
import HomeSearch from "../../components/home-search";
import ProvideSection from "../../components/provide-section";
import CodeOfPracticeSection from "../../components/codeofpractice-home/index";
import LatestNews from "../../components/latest-news";
import NewsLetter from "../../components/news-letter";
import withTransition from "../../components/with-transition";
import TourToolTip from "../../components/tour-tooltip";
import TourContainer from "../../components/tour-section";
import CorporatePartnership from "../../components/corporation-partnership";
import SearchPopUp from "../../components/search-popup";
import * as actions from "./actions";
import * as styled from "./style";
import Accordion from "../../components/accordiantwo";
import Modal from "../../components/modal";
import googlePlay from "../../assets/images/google-play.svg";
import appStore from "../../assets/images/app-store.svg";
import BlackImage from "../../assets/tour-images/black_img.jpeg";
import ModalCloseIcon from "../../assets/images/modal-close-icon.svg"

const mapStateToProps = state => {
  return {
    latestNews: state.homeReducer.latestNews,
    subscriptionMessage: state.homeReducer.message,
    toolTipIndex: state.homeReducer.toolTipIndex,
    tourStatus: state.homeReducer.tourStatus,
    partnershipData: state.homeReducer.partnershipData
  };
};

/**
 * Home page container
 *
 * @class Home
 * @extends {Component}
 * @exports Home
 */
class Home extends Component {

  state = {
    subscriptionSuccess: false,
    tourRun: false,
    showDownloadAppPopup: true
  };

  componentDidMount() {
    const { latestNews, getLatestNews, partnershipData, getPartnershipData } = this.props;
    if (_isEmpty(latestNews)) getLatestNews();
    this.setState({tourRun: true});
    if (!partnershipData) {
      getPartnershipData();
    }


    const downloadAppPopup = localStorage.getItem("downloadAppPopup");
    if (downloadAppPopup) {
      this.setState({showDownloadAppPopup: false})
    } else {
      this.setState({showDownloadAppPopup: true})
    }

    //This is added to track the Home page visit
    const imagePixel = document.createElement("img");
    imagePixel.setAttribute("src", "https://ct.ardrone.swoop.com/ct.gif?tracker_id=0e6b209b-bd3c-4fac-95af-9d8eed312ea6");
    document.body.appendChild(
      imagePixel
    )
    //This tag needs to be send only once when user lands on homepage
    this.sendVendorTag();
  }

  sendVendorTag = () => {
    const script = document.createElement("script");
    const script2 = document.createElement("script");
    script.type = "text/javascript";
    script.id = "lassosegment2";
    script.src = "https://res.lassomarketing.io/scripts/lassotag.js";
    script2.async = true;
    script2.id = "lasotagConv2";
    script2.type = "text/javascript";
    script2.src = "https://trc.lhmos.com/conv?id=8668&type=IMG&enc=true";
    if (!(document.getElementById("lassosegment2") && document.getElementById("lasotagConv2"))) {
      document.head.appendChild(script);
      document.head.appendChild(script2);
    }
  }

  /**
   * Stop key navigation function
   * @param {event} e event
   * @returns {undefined}
   */
  stopTabKeyNavigation = (e) => {
    if (e.keyCode === 9 ) {
      e.preventDefault();
    }
  }

  /**
   * Search click handler
   * @param {string} val - search string
   * @param {Array} latestSearchData tour start
   * @param {keywordInput} keywordInput string
   * @returns {undefined}
   */
  onSearchClick(val, latestSearchData, keywordInput) {
    // if (keywordInput && keywordInput.length > 0 ) {
    this.props.history.push({
      pathname: "/search",
      state: {
        userSearchInput: "",
        userSearchData: latestSearchData,
        keywordInput: keywordInput
      }
    })
    //}
    //This is added to track the search click
    const imagePixel = document.createElement("img");
    imagePixel.setAttribute("src", "https://ct.ardrone.swoop.com/ct.gif?tracker_id=02c997d6-2bdb-42f2-943e-853f219d1855");
    document.body.appendChild(
      imagePixel
    )
    this.addVendorTag();
  }

  /**
   * Function to add vendor tag on search click script should be inserted
   * @returns {undefined}
   */
  addVendorTag = () => {
    const script = document.createElement("script");
    const script2 = document.createElement("script");
    script.type = "text/javascript";
    script.id = "lassosegment";
    script.src = "https://res.lassomarketing.io/scripts/lassotag.js";
    script2.async = true;
    script2.id = "lasotagConv";
    script2.type = "text/javascript";
    script2.src = "https://trc.lhmos.com/conv?id=4859&type=IMG&enc=true";
    if (document.getElementById("lassosegment")) {
      document.getElementById("lassosegment").remove();
    }
    if (document.getElementById("lasotagConv")) {
      document.getElementById("lasotagConv").remove();
    }
    document.head.appendChild(script);
    document.head.appendChild(script2);
  }

  /**
   * Search click handler
   *
   * @param {string} email - email string
   * @returns {undefined}
   */
  submitClick = email => {
    const { email_send_type } = resources;
    if (email) {
      this.props.newsLetterSubscription({ email,
        type : email_send_type });
    }
  }

  /**
   * Timer function
   *
   * @returns {undefined}
   */
  startTimer() {
    setTimeout(() => {
      this.setState({ subscriptionSuccess: false });
    }, 3000);
  }

  componentDidUpdate(prevProps) {
    const { tourStatus, toolTipIndex } = this.props;
    const { tourRun } = this.state;
    if (prevProps.subscriptionMessage !== this.props.subscriptionMessage) {
      this.setState({ subscriptionSuccess : true});
      this.startTimer();
    }
    if (tourStatus !== tourRun) {
      this.setState({tourRun: tourStatus})
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", e => this.stopTabKeyNavigation(e));
  }

  /**
   * Next button click handler function
   * @returns {undefined}
   */
  nextButtonClick = () => {
    const { incrementTourToolTipIndex, toolTipIndex } = this.props;
    const { tourRun } = this.state;
    incrementTourToolTipIndex();
    if (tourRun) {
      this.setState({tourRun: false});
    }
    if (toolTipIndex === 1 ) {
      this.onSearchClick("saxenda dosing", true);
    }
  }

  /**
   * Suggestion list open funtion handler
   * @param {boolean} value value
   * @return {undefined}
   */
  onSuggestionlistopen = (value) => {
    const {tourRun} = this.state;
    if (value && !tourRun) {
      this.setState({tourRun: true});
    }
  }

  codeOfPracticeClick = () => {
    this.props.history.push("/code-of-practice");
  }

  searchDbClick = () => {
    this.props.history.push("/search");
  }

  humanExpertClick = () => {
    this.props.history.push("/membercompanyinformation");
  }

  showDatabaseSection = () => {
    const {searchDBLogo, humanExpertLogo, searchDBAltTxt, humanExpertAltTxt, searchDbTitle, searchDbDesc, searchDbContent1, searchDbContent2,
      searchDbButtonTxt, expertDbButtonTxt, expertTitle, expertContent1, expertDesc} = resources;

    const selfSearchDbTitle = () => {
      return (
        <styled.AccordionTitle>
          <img src={searchDBLogo} alt={searchDBAltTxt}></img>
          <div>
            <h5>{searchDbTitle}</h5>
            <p>Looking for drug information on US approved products?</p>
          </div>
        </styled.AccordionTitle>
      )
    }
    const selfSearchContent = () => {
      return (
        <styled.AccordionContent>
          <p>Use the self-search database to find:</p>
          <ul>
            <li>{searchDbContent1}</li>
            <li>{searchDbContent2}</li>
          </ul>
          <button onClick={this.searchDbClick} className="searchDbButton">{searchDbButtonTxt}</button>
        </styled.AccordionContent>
      )
    }
    const humanExpertTitle = () => {
      return (
        <styled.AccordionTitle>
          <img src={humanExpertLogo} alt={humanExpertAltTxt}></img>
          <div>
            <h5>{expertTitle}</h5>
            <p>Need assistance finding the best clinical drug information for your patient case?</p>
          </div>
        </styled.AccordionTitle>
      )
    }
    const humanExpertContent = () => {
      return (
        <styled.AccordionContent>
          <p>{expertContent1}</p>
          <button onClick={this.humanExpertClick}>{expertDbButtonTxt}</button>
        </styled.AccordionContent>
      )
    }
    const data = [{
      title: selfSearchDbTitle(),
      content: selfSearchContent()
    }, {
      title: humanExpertTitle(),
      content: humanExpertContent()
    }]

    return (
      <styled.ShowDbSection>
        <styled.DbSectionAccordion><Accordion data={data} /></styled.DbSectionAccordion>
        <styled.DBWrapper>
          <styled.DBTitle>
            {searchDbTitle}
          </styled.DBTitle>
          <div className="searchDbLogo">
            <img src={searchDBLogo} alt={searchDBAltTxt}></img>
          </div>
          <styled.DBSubTitle dangerouslySetInnerHTML={{ __html: searchDbDesc}} leftAlign={true}/>
          <ul>
            <li>{searchDbContent1}</li>
            <li>{searchDbContent2}</li>
          </ul>
          <styled.ButtonWrapper>
            <button onClick={this.searchDbClick} className="searchDbButton">{searchDbButtonTxt}</button>
          </styled.ButtonWrapper>
        </styled.DBWrapper>
        <styled.DBWrapper>
          <styled.DBTitle>
            {expertTitle}
          </styled.DBTitle>
          <div className="humanExpertLogo">
            <img src={humanExpertLogo} alt={humanExpertAltTxt}></img>
          </div>
          <styled.DBSubTitle dangerouslySetInnerHTML={{ __html: expertDesc}} />
          <styled.ExpertContent>
            {expertContent1}
          </styled.ExpertContent>
          <styled.ButtonWrapper upperMargin={true}>
            <button onClick={this.humanExpertClick}>{expertDbButtonTxt}</button>
          </styled.ButtonWrapper>
        </styled.DBWrapper>
      </styled.ShowDbSection>
    )
  }

  onDownloadAppModalClose = () => {
    this.setState({ showDownloadAppPopup: false })
    localStorage.setItem("downloadAppPopup", true);
  }

  onOpenAppLink = (url) => {
    window.open(url, "_blank")
  }

  render() {
    const news = _get(this.props.latestNews, "data", {});
    const { mainHeading1_txt, mainHeading2_txt, mainHeading3_txt, search, closeGreenIcon, closeAltTxt, closeWhiteIcon, phactmiLogo, phactmiOverviewTxt, youtubeLink,
      provide, aboutSection, asp_logo, asp_logo_alt_txt, corporate_partnership_txt, badge_logo, badge_logo_alt_txt, corporate_partnership_desc,
      codeOfPracticeButtonTxt, drug_logo, drug_txt, partial_drug_txt, database_txt, drug_logo_alt, learnMore } = resources;
    const { subscriptionMessage, history, toolTipIndex, tourStatus, partnershipData } = this.props;
    const { subscriptionSuccess, tourRun, showDownloadAppPopup } = this.state;
    const { mirpopupResource } = searchPageResources;
    const isBrowserFlag = (isChrome || isChromium
      || isEdgeChromium || isSafari || isMobileSafari || isOpera || isEdge || isEdgeChromium || isFirefox || isYandex || isIE || isSamsungBrowser)

    return (
      <styled.HomePage>
        <Container className="container">
          {showDownloadAppPopup && (isMobile || isTablet) && isBrowserFlag && <Modal>
            <div className="popup-close-icon" onClick={this.onDownloadAppModalClose}>
              <img src={ModalCloseIcon} alt="close"/>
            </div>
            <styled.DownloadAppPopupContent>
              <h4>Download the App</h4>
              <p>Get the latest phactMI app from</p>
              {(isMobile || isTablet) && <div>
                {isAndroid && <img src={googlePlay} alt="google-play-link" className="android-app-link"
                  onClick={() => this.onOpenAppLink("https://play.google.com/store/apps/details?id=com.phactmi")} />}
                {isIOS && <img src={appStore} alt="app-store-link" className="ios-app-link"
                  onClick={() => this.onOpenAppLink("https://apps.apple.com/us/app/phactmi/id6468638050")} />}
              </div>}
            </styled.DownloadAppPopupContent>
          </Modal>}
          <styled.HeaderSection>
            <styled.MainHeading>
              <styled.DrugLabelWrapper>
                <img src={drug_logo} alt={drug_logo_alt}></img>
                <span>{drug_txt}</span>
              </styled.DrugLabelWrapper>
              <styled.DrugLabelWrapperMobile>
                <div>
                  <span>{partial_drug_txt}</span>
                </div>
                <div>{database_txt}</div>
              </styled.DrugLabelWrapperMobile>
              <styled.SubHeading>
                <h1>{mainHeading1_txt}
                  <span>{mainHeading2_txt}</span>{mainHeading3_txt}
                </h1>
              </styled.SubHeading>
            </styled.MainHeading>
            <HomeSearch
              resources={search}
              onSearchClick={(val, latestSearchData, keywordInput) => this.onSearchClick(val, latestSearchData, keywordInput)}
              onSuggestionlistopen={val => this.onSuggestionlistopen(val)}
              history={history}
              showDownloadAppPopup={showDownloadAppPopup}
              isBrowserFlag={isBrowserFlag}
            />
          </styled.HeaderSection>
          <styled.MainSection>
            <ProvideSection resources={provide} history={history}/>
            {/* <CodeOfPracticeSection content={aboutSection} aboutLink="/code-of-practice" /> */}
            <styled.CodeOfPracticeButtonWrapper>
              <button onClick={this.codeOfPracticeClick}>{window.innerWidth < 768 ? learnMore : <>{codeOfPracticeButtonTxt}<span>{">"}</span></>}</button>
            </styled.CodeOfPracticeButtonWrapper>
            {this.showDatabaseSection()}
            <styled.NewsSection>
              <Row className="corporate">
                <Col className="corporate-partnership">
                  <CorporatePartnership logo={asp_logo} heading={corporate_partnership_txt} badgelogo={badge_logo}
                    badge_logo_alt_txt={badge_logo_alt_txt} description={corporate_partnership_desc} data={partnershipData}></CorporatePartnership>
                </Col>
              </Row>
            </styled.NewsSection>
          </styled.MainSection>
        </Container>
      </styled.HomePage>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(withTransition(Home)));
