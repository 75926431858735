import styled, { css } from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const Maincontainer = styled.div`
  position: relative;
  padding-top: 5px;
  @media ${tabletRange} {
    padding-top: 35px;
  }
  @media ${mobileRange} {
    padding-top: 20px;
  }
  .sticky {
    position: fixed;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    max-width: ${containerMaxWidth};
    z-index: 1;
    @media ${tabletRange} {
      top: 77px;
    }
    @media ${mobileRange} {
      top: 74px;
    }
    @media ${ipadProRange} {
      top: 77px;
    }
  }
  .non-sticky {
    position: absolute;
    top: -24px;
  }
`;
export const Content = styled.p`
  margin: 0;
  width: 450px;
  font-size: 15px;
  line-height: 1.7;
  color: ${colors.dove_darkgray};
  @media ${mobileRange} {
    width: 100%;
    font-size: 14px;
    line-height: 1.56;
    letter-spacing: normal;
  }
  @media ${tabletRange} {
    width: 374px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
  }
`;
export const NavbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: -30px;
  width: 100%;

  @media ${mobileRange} {
    display: none;
  }

`;

export const ContentNew = styled.p`
  margin: 0;
  width: 450px;
  font-size: 17px;
  line-height: 1.7;
  font-weight: bold;
  color: ${colors.sherpa_blue};
  margin-bottom: 15px;

  a {
    color: ${colors.bondi_blue};
    text-decoration: underline;
  }

  @media ${mobileRange} {
    width: 100%;
    font-size: 14px;
    line-height: 1.56;
    letter-spacing: normal;
  }
  @media ${tabletRange} {
    width: 374px;
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: normal;
  }
`;
export const Navbar = styled.div`
  display: flex;
  align-items: center;
  width: 86%;
  background-color: ${colors.sherpa_blue};
  height: 52px;
  overflow: auto;
  ::-webkit-scrollbar { 
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  div:first-child {
    padding: 0 65px;
    display: flex;
    flex-basis: 980px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex-basis: 900px;
    }
  }
  .active {
    color: ${colors.downy};
  }
  a {
    color: ${colors.white};
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: -0.21px;
    :hover {
      text-decoration: none;
    }
  }
  @media ${mobileRange} {
    width: 84%;
    div:first-child {
      padding: 0 35px;
      flex-basis: 1000px;
    }
  }
  @media ${tabletRange} {
    width: 90%;
    div:first-child {
      padding: 0 35px;
      flex-basis: 1080px;
    }
  }
  @media ${ipadProRange} {
    width: 90%;
    div:first-child {
      padding: 0 35px;
      flex-basis: 1310px;
    }
  }
`;
export const GradientWrapper = styled.div`
  position: absolute;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 0;
  }
  width: 86%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  @media ${mobileRange} {
    width: 100%;
  }
  @media ${tabletRange} {
    width: 100%;
  }
  @media ${ipadProRange} {
    width: 100%;
  }
`;
const Gradient = css`
  opacity: 0.93;
  background-image: linear-gradient(to right, rgba(0, 66, 81, 0), ${colors.sherpa_blue});
  display: none;
  height: 52px;
  background-color: ${colors.sherpa_blue};
  text-align: center;
  img {
    width: 12px;
    padding-top: 18px;
    pointer-events: auto;
  }
  @media ${tabletRange} {
    display: block;
    width: 5%;
  }
  @media ${mobileRange} {
    display: block;
    width: 8%;
  }
  @media ${ipadProRange} {
    display: block;
    width: 5%;
  }
`;
export const LeftGradient = styled.div`
  ${Gradient};
`;
export const RightGradient = styled.div`
  ${Gradient};
`;

export const MobileTabs = styled.div`
  display: none;
  @media ${mobileRange} {
    display: block;
    .active-tab, .inactive-tab {
      border: 0
    }
    .tabsd {
      overflow-y: visible;
      justify-content: flex-start;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
    } 
  }
`;