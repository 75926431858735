import * as actions from "./actions";

const initialState = {
  newsDetail: {},
  error: {},
  newsById: null,
  newsFetchfail: false,
  publicationData: {},
  publicationById: null
}

export default function newsReducer(state = initialState, { type, payload }) {
  switch (type) {
  case actions.GET_NEWS_DETAILS:
    return {
      ...state,
      loading: true
    }
  case actions.GET_SUCCESS_NEWS_DETAIL:
    return {
      ...state,
      newsDetail: payload.result,
      loading: false
    }
  case actions.GET_FAIL_NEWS_DETAIL:
    return {
      ...state,
      loading: false
    }
  case actions.SET_NEWS_DETAIL:
    return {
      ...state,
      newsDetail: {}
    }
  case actions.GET_NEWS_BY_ID:
    return {
      ...state,
      loading: true,
      newsFetchfail: false
    }
  case actions.GET_NEWS_BY_ID_SUCCESS:
    return {
      ...state,
      newsById: payload.result.data,
      loading: false
    }
  case actions.GET_NEWS_BY_ID_FAIL:
    return {
      ...state,
      newsById: null,
      loading: false,
      newsFetchfail: true
    }
  case actions.CLEAR_NEWS:
    return {
      ...state,
      newsById: null,
      // newsDetail: {},
      // publicationData: {}
      publicationById: null
    }
  case actions.GET_PUBLICATION:
    return {
      ...state,
      loading: true
    }
  case actions.GET_PUBLICATION_FAIL:
    return {
      ...state,
      loading: false
    }
  case actions.GET_PUBLICATION_SUCCESS:
    return {
      ...state,
      loading: false,
      publicationData: payload.result
    }
  case actions.GET_PUBLICATION_BY_ID:
    return {
      ...state,
      loading: true,
      newsFetchfail: false
    }
  case actions.GET_PUBLICATION_BY_ID_SUCCESS:
    return {
      ...state,
      loading: false,
      newsFetchfail: false,
      publicationById: payload.result.data
    }
  case actions.GET_PUBLICATION_BY_ID_FAIL:
    return {
      ...state,
      publicationById: null,
      newsFetchfail: true
    }
  default: return state
  }
}
