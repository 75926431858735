import axios from "axios";
import {endpoint} from "../../config/app.config";

export const getRoles = () => {
  return axios
    .get(`${endpoint}/api/user/degree`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const setRole = (payload) => {
  return axios
    .post(`${endpoint}/api/user`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}
