export const GET_LATEST_NEWS = "GET_LATEST_NEWS";
export const GET_SUCCESS_LATEST_NEWS = "GET_SUCCESS_LATEST_NEWS";
export const GET_FAIL_LATEST_NEWS = "GET_FAIL_LATEST_NEWS";
export const NEWS_LETTER_SUBSCRIPTION = "NEWS_LETTER_SUBSCRIPTION";
export const NEWS_LETTER_SUCCESS_SUBSCRIPTION = "NEWS_LETTER_SUCCESS_SUBSCRIPTION";
export const NEWS_LETTER_FAIL_SUBSCRIPTION = "NEWS_LETTER_FAIL_SUBSCRIPTION";
export const INCREMENT_TOUR_TOOL_INDEX = "INCREMENT_TOUR_TOOL_INDEX";
export const SHOW_TOUR = "SHOW_TOUR";
export const STOP_TOUR = "STOP_TOUR";
export const GET_PARTNERSHIP_DATA = "GET_PARTNERSHIP_DATA";
export const GET_PARTNERSHIP_DATA_SUCCESS = "GET_PARTNERSHIP_DATA_SUCCESS";
export const GET_PARTNERSHIP_DATA_FAIL = "GET_PARTNERSHIP_DATA_FAIL";

export function getLatestNews() {
  return {
    type: GET_LATEST_NEWS
  }
}

export function newsLetterSubscription({email, type}) {
  return {
    type: NEWS_LETTER_SUBSCRIPTION,
    payload: {
      email,
      type
    }
  }
}

export function incrementTourToolTipIndex() {
  return {
    type: INCREMENT_TOUR_TOOL_INDEX
  }
}

export function startTour() {
  return {
    type: SHOW_TOUR
  }
}

export function stopTour() {
  return {
    type: STOP_TOUR
  }
}

export function getPartnershipData() {
  return {
    type: GET_PARTNERSHIP_DATA
  }
}