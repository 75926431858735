import React from "react";
import * as styled from "./styles";
import PropTypes from "prop-types";

const List = props => {
  const { title, subtitle, desc = [] } = props.resources;

  return (
    <div>
      <styled.ListTitle>{title}</styled.ListTitle>
      <styled.ListSubTitle>{subtitle}</styled.ListSubTitle>
      {renderContent(desc)}
    </div>
  );
};

const renderContent = (desc = []) => {
  return (
    <ul>
      {desc.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};

List.prototype = {
  resources: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    desc: PropTypes.arrayOf(PropTypes.string)
  })
};

export default React.memo(List);
