import { GET_SEARCH_RESULT, GET_SUCCESS_SEARCH_RESULT, GET_FAIL_SEARCH_RESULT, SET_SEARCH_RESULT, SET_FIRST_SEARCH, SET_EXPERT,
  GET_MANUFACTURER_DETAILS, GET_SUCCESS_MANUFACTURER_DETAILS, GET_FAIL_MANUFACTURER_DETAILS, GET_SUMMARY, GET_SUMMARY_SUCCESS, GET_SUMMARY_FAIL,
  GET_SOURCE_NAME, GET_SOURCE_NAME_SUCCESS, GET_SOURCE_NAME_FAIL, SET_DUMMY_SEARCH_RESULT, REMOVE_DUMMY_SEARCH_RESULT, UNSET_EXPERT,
  GET_DATA_USING_CODENAME, GET_DATA_USING_CODENAME_FAIL, GET_DATA_USING_CODENAME_SUCCESS, CLEAR_SUMMARY, SET_SEARCH_QUERY,
  GET_PI_DETAILS, GET_PI_DETAILS_SUCCESS, GET_PI_DETAILS_FAILURE, EMPTY_PI_DETAILS, ERROR_OCCUERD, CLEAR_ERROR, CLEAR_SEARCH_QUERY } from "./actions";

const initialState = {
  searchResult: null,
  loading: false,
  firstSearch: true,
  expert: true,
  piLoading: false,
  piDetails: null,
  manufacturerDetails: null,
  companyId: [],
  srd_summary: null,
  error: null,
  sectionNameUsingDCode: null,
  searchQuery: ""
}

export default function searchResultReducer(state = initialState, { type, payload }) {
  switch (type) {
  case GET_SEARCH_RESULT:
    return {
      ...state,
      loading: true
    }

  case GET_SUCCESS_SEARCH_RESULT:
    return {
      ...state,
      searchResult: payload.result,
      loading: false
    }

  case GET_FAIL_SEARCH_RESULT:
    return {
      ...state,
      searchResult: null,
      loading: false
    }

  case SET_SEARCH_RESULT:
    return {
      ...state,
      searchResult: null
    }
  case SET_FIRST_SEARCH:
    return {
      ...state,
      firstSearch: false
    }
  case SET_EXPERT:
    return {
      ...state,
      expert: false
    }
  case UNSET_EXPERT:
    return {
      ...state,
      expert: true
    }
  case GET_MANUFACTURER_DETAILS:
    return {
      ...state
    }

  case GET_SUCCESS_MANUFACTURER_DETAILS:
    return {
      ...state,
      manufacturerDetails: payload.result
    }

  case GET_FAIL_MANUFACTURER_DETAILS:
    return {
      ...state
    }
  case SET_SEARCH_QUERY:
    return {
      ...state,
      searchQuery: payload.value
    }
  case CLEAR_SEARCH_QUERY:
    return {
      ...state,
      searchQuery: ""
    }
  // case GET_SOURCE_NAME:
  //   return {
  //     ...state,
  //     loading: true
  //   }
  // case GET_SOURCE_NAME_SUCCESS:
  //   return {
  //     ...state,
  //     companyId : payload.result,
  //     loading: false
  //   }
  // case GET_SOURCE_NAME_FAIL:
  //   return {
  //     ...state,
  //     loading: false
  //   }
  case SET_DUMMY_SEARCH_RESULT:
    return {
      ...state,
      searchResult: payload.value
    }
  case REMOVE_DUMMY_SEARCH_RESULT:
    return {
      ...state,
      searchResult: payload.value
    }
  case GET_SUMMARY:
    return {
      ...state,
      srd_summary: null
    }
  case GET_SUMMARY_SUCCESS:
    return {
      ...state,
      srd_summary: payload.result
    }
  case GET_SUMMARY_FAIL:
    return {
      ...state
    }
  case CLEAR_SUMMARY:
    return {
      ...state,
      srd_summary: null
    }
  case GET_PI_DETAILS:
    return {
      ...state,
      piDetails: null,
      piLoading: true
    }
  case GET_PI_DETAILS_SUCCESS:
    return {
      ...state,
      piLoading: false,
      piDetails: payload.result,
      error: null
    }
  case GET_PI_DETAILS_FAILURE:
    return {
      ...state,
      piDetails: "",
      error: "Error Occured while fetching data",
      piLoading: false
    }
  case EMPTY_PI_DETAILS:
    return {
      ...state,
      piDetails: null,
      error: null
    }
  case ERROR_OCCUERD:
    return {
      ...state,
      error: "Error Occured while fetching data",
      piLoading: false
    }
  case CLEAR_ERROR:
    return {
      ...state,
      error: null
    }
  case GET_DATA_USING_CODENAME:
    return {
      ...state
    }
  case GET_DATA_USING_CODENAME_SUCCESS:
    return {
      ...state,
      sectionNameUsingDCode: payload.result.data,
      error: null
    }
  case GET_DATA_USING_CODENAME_FAIL:
    return {
      ...state,
      error: "Error Occured while fetching data"
    }
  default: return state
  }
}
