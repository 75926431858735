import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const RedirectPage = styled.div`
  min-height: 700px;
  color: ${colors.sherpa_blue};
  position: relative;
  background: ${colors.white};
  & .body-container {
    max-width: ${containerMaxWidth};
    padding: 0;
    @media ${mobileRange}{
      min-height: 700px;
    }
    @media ${tabletRange} {
      margin-top: -60px
      min-height: 700px;
    }
    @media ${ipadProRange} {
      min-height: 1000px;
    }
  }
`;

export const HeadingContent = styled.div`
  background: ${colors.light_grey};
  min-height: 220px;
  @media ${mobileRange}{
    width: 100%;
    font-size: 25px;
    padding-top: 45px;
  }
  & .container {
    max-width: ${containerMaxWidth};
    @media ${mobileRange}{
      padding: 55px 0 120px;
      text-align: center;
    }
    @media ${tabletRange}{
      padding: 30px 0 90px 84px;
    }
  }
`;

export const MessageSection = styled.div`
  width: 100%;
  padding: 66px 96px 66px;
  background: ${colors.white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  margin-top: -23px;

  @media ${mobileRange}{
    padding: 94px 17px 25px ;
  }
  @media ${tabletRange}{
    padding: 25px 64px 20px 84px;
  }
`;

export const ImageSection = styled.div`
  margin-top: -147px;
  text-align: center;
  @media ${mobileRange} {
    width: 100%;
    text-align: center;
  }
  @media ${tabletRange} {
    width: 100%;
    margin-top: -87px;
  }
  img {
    width: 170px;
    @media ${mobileRange} {
      width: 205px;
    }
    @media ${tabletRange}{
      width:205px
    }
}`;

export const LeavingMessage = styled.div`
  text-align: center;
  color: ${colors.sherpa_blue};
  font-size: 26px;
`;

export const NavigationMessage = styled.div`
  text-align: center;
  color: ${colors.scorpion};
  font-size: 16px;
  line-height: 1.85;
  margin-top: 12px;
`;

export const RedirectMessage = styled.div`
  text-align: center;
  color: ${colors.scorpion};
  font-size: 16px;
  line-height: 1.85;
`;

export const ContinueButton = styled.button`
  background-color: ${colors.sherpa_blue};
  color: ${colors.white};
  font-size: 16px;
  padding: 0;
  border-color: ${colors.sherpa_blue};
  border: none;
  width: 154px;
  margin-left: 35px;
  height: 49px;
  border-radius: 3px;
  display: block;
  &:focus{
    outline: none;
  }
  @media ${mobileRange} {
    margin-left: 10px;
    width: 100px;
    height: 35px;
  }
`;

export const CancelButton = styled.button`
  background-color: ${colors.white};
  color: ${colors.sherpa_blue};
  font-size: 16px;
  padding: 0;
  border: 1px solid ${colors.sherpa_blue};
  width: 154px;
  margin-right: 35px;
  height: 49px;
  display: block;
  &:focus{
    outline: none;
  }
  @media ${mobileRange} {
    margin-right: 10px;
    width: 100px;
    height: 35px;
  }  
`;
export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 29px;
`;
