import styled from "styled-components";

import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange } from "../../../common/styles/breakpoints";

export const ResultsContainer = styled.div`
  @media ${mobileRange} {
    width: 100%;
    padding: 0;
  }
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
  display: flex;
  gap: 10px;
  align-items: center;

  .member-contact-icon {
    width: 32px;
    height: 32px;
  }

  .expand-close-icon {
    display: none;

    @media ${mobileRange} {
      display: initial;
      width: 14px;
      height: 7px;
    }
  }

  span {
    @media ${mobileRange} {
      flex-basis: 90%;
    }
  }

  @media ${mobileRange} {
    font-size: 20px;
  }

  @media ${tabletRange} {
    font-size: 20px;
  }
`;

export const MedicalLinkWrapper = styled.div`
  color: ${colors.bondi_green};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const NoResultWrapper = styled.div`
  display: ${props => props.showContactInfo ? "block" : "none"}
  color: ${colors.sherpa_blue};
  font-weight: 300;
  @media ${mobileRange} {
    text-align: left;
  }

  .member-link-icon, .member-contact-icon {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  p {
    font-size: 18px;
    color: #434545;
    line-height: 1.5;
  }

  .patient-mir-info{
    border: 1px solid #F5F9FA;
    border-bottom: 1px solid #00B59A;
    padding: 18px;
    background-color: #F5F9FA;
    border-radius: 8px;
    margin-bottom: 14px;

    .member-link {
      font-size: 16px;
      color: #00B59A;
      margin-bottom: 10px;
      word-break: break-all;
    }

    .member-name-details {
      color: #004251
      margin-bottom: 8px;
      font-size: 18px;

      @media ${mobileRange} {
        font-size: 16px
      }
    }

    p {
      color: inherit;
    }
  }

  a {
    color: ${colors.bondi_green};
  }

  .patient-mir-msg-title {
    font-size: 16px;
    margin-bottom: 18px;
    color: #434545;
  }
`;