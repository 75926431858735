import { takeLatest, put, call } from "redux-saga/effects";
import * as action from "./actions";
import { getFellowship } from "./api";

export function* getFellowshipDataWorker() {
  try {
    const data = yield call(getFellowship);
    yield put({
      type: action.GET_FELLOWSHIP_DATA_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: action.GET_FELLOWSHIP_DATA_FAIL });
  }
}

export function* getFellowshipDataWatcher() {
  yield takeLatest(action.GET_FELLOWSHIP_DATA, getFellowshipDataWorker)
}

export default {
  getFellowshipDataWatcher
}
