import axios from "axios";
import { endpoint } from "../../config/app.config";

export const getLatestNews = () => {
  return axios
    .get(`${endpoint}/api/news/latest`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const newsLetterSubscription = (payload) => {
  return axios
    .post(`${endpoint}/api/subscription`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const getpartnershipData = () => {
  return axios
    .get(`${endpoint}/api/partnership`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}
