import styled from "styled-components";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL } from "../../common/styles/breakpoints";
import { containerMaxWidth, colors } from "../../common/styles/variables";


export const FooterContent = styled.div`
  background-color: ${colors.sherpa_blue};
  .container {
    max-width: 100%;
    padding: 20px;
  }
  & .footer-content {
    width: 87%;
    padding-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media ${mobileRange} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  & .footer-content .footer-logo {
    width: 13%;
    padding: 5px 0px 5px 0;
    @media ${mobileRange} {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    @media ${tabletRange} {
      width: 21%;
      padding: 15px 0 15px;
    }
    @media ${ipadProRange} {
      padding: 5px 0;
    }
  }
  & .footer-content .footer-logo .footer-image {
    width: 112px;
    @media ${mobileRange} {
      width: 80px;
    }
    @media ${tabletRange} {
      width: 95px;
    }
    @media ${ipadProRange} {
      width: 95px;
    } 
  }

  & .footer-content .footer-logo .company-logo {
    width: 46px;
    display: none;
    @media ${mobileRange} {
      display: inline-flex;
    }
  }
  & .footer-content .footer-links {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p{
      font-size: 14px;
      font-weight: 600;
      color: #A8BDBA;
      @media ${mobileRange} {
        font-size: 12px;
      }
    }

    @media ${mobileRange} {
      width: 50%;
      padding-left: 0;
      display: block;
      line-height: 16.8px;
      margin-top: 20px;
    }
    @media ${tabletRange} {
      margin-right: 15px;
    }
  }
  & .footer-content .footer-links .footer-each-link {
    margin-bottom: 16px;
    @media ${mobileRange} {
      padding-left: 0;
    }
    @media ${tabletRange} {
      display: flex;
      align-items: center;
    }
  }
  & .footer-each-link a {
    color: ${colors.alto_gray};
    text-decoration: none;
    font-size: 16px;
    @media ${tabletRange} {
      font-size: 12px;
    }
    @media ${mobileRange} {
      font-size: 12px;
    }
}`;
export const FooterDisclaimer = styled.div`
  .footer-text {
    border-top: 1px solid ${colors.light_blue};
    text-align: center;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    font-size: 10px;
    padding: 10px 0 16px;
    color: ${colors.white};
    @media ${mobileRange} {
      font-size: 10px;
      padding: 24px 20px 24px 20px;
      text-align: center;
      color: #9EA1A1;
    }
    @media ${tabletRange} {
      font-size: 10px;
      padding: 3px 0 5px;
    }
    @media ${ipadProRange} {
      font-size: 10px;
    }
  }
}`;
export const CopyRightText = styled.div`
  color: #BBC2C1;
  font-size: 10px;
  @media ${mobileRange} {
    font-size: 7px;
    align-self: flex-start;
  }
  @media ${tabletRange} {
    font-size: 6px;
  }
  @media ${ipadProRange} {
    font-size: 6px;
  }
`;


export const SocialMediaSection = styled.div`
  p {
    color: #A8BDBA;
    font-size: 14px;
  }
  @media ${mobileRange} {
    display: none;
  }
`;

export const SocialMediaWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 12%;
  img {
    margin: 0 10px;
  }
  @media ${mobileRange} {
    display: none;
  }
`;

export const SocialMediaWrapperMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    width: 50%;
    display: flex;
    img {
      margin: 0 12px;
    }
    justify-content: flex-end;
    margin: 5px 0;
  }
`;

export const ContactUs = styled.div`
  font-size: 14px;
  a {
    color: ${colors.bondi_blue}
  }
  p {
    color: #A8BDBA;
    margin: 0;
  }
  .address {
    color: ${colors.alto_gray};
    margin: 12px 0;
  }
  .contact-us-header {
    margin-bottom: 16px;
  }
  @media ${mobileRange} {
    width: 50%;
    margin-top: 20px;
    font-size: 12px;
  }
`;

export const SeparatorMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: block;
    border-bottom: 1px solid #307180;
    width: 100%;
    margin-top: 20px;
  }
`;

export const DownloadAppSectionMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: block;
    width: 100%;
    padding-top: 12px;
    p {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      color: #A8BDBA;
    }
    .android-link, .ios-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      p {
        color: #00B59A;
        margin: 0;
      }
    }
    .android-link {
      margin-right: 60px;
    }
    .download-app {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;