import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getNewsDetail, getNewsById, getPublication, getPublicationById } from "./api";

export function* getNewsDataWorker({payload}) {
  try {
    const data = yield call(getNewsDetail);
    yield put({
      type: actions.GET_SUCCESS_NEWS_DETAIL,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_FAIL_NEWS_DETAIL });
  }
}

export function* getNewsDataWatcher() {
  yield takeLatest(actions.GET_NEWS_DETAILS, getNewsDataWorker);
}

export function* getNewsByIdDataWorker({payload}) {
  try {
    const data = yield call(getNewsById, payload.title);
    yield put({
      type: actions.GET_NEWS_BY_ID_SUCCESS,
      payload: {
        result: data
      }
    })
  } catch (e) {
    yield put({ type: actions.GET_NEWS_BY_ID_FAIL})
  }
}

export function* getNewsByIdDataWatcher() {
  yield takeLatest(actions.GET_NEWS_BY_ID, getNewsByIdDataWorker);
}

export function* getPublicationDataWorker() {
  try {
    const data = yield call(getPublication);
    yield put ({
      type: actions.GET_PUBLICATION_SUCCESS,
      payload: {
        result: data
      }
    })
  } catch (e) {
    yield put({ type: actions.GET_PUBLICATION_FAIL});
  }
}

export function* getPublicationDataWatcher() {
  yield takeLatest(actions.GET_PUBLICATION, getPublicationDataWorker)
}

export function* getPublicationByIDDataWorker({payload}) {
  try {
    const data = yield call(getPublicationById, payload.title);
    yield put ({
      type: actions.GET_PUBLICATION_BY_ID_SUCCESS,
      payload: {
        result: data
      }
    })
  } catch (e) {
    yield put({ type: actions.GET_PUBLICATION_BY_ID_FAIL});
  }
}

export function* getPublicationByIDDataWatcher() {
  yield takeLatest(actions.GET_PUBLICATION_BY_ID, getPublicationByIDDataWorker)
}

export default {
  getNewsDataWatcher,
  getNewsByIdDataWatcher,
  getPublicationDataWatcher,
  getPublicationByIDDataWatcher
};
