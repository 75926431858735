/* eslint-disable max-len */
import AboutSection from "../../assets/images/About_section.png";
import WhoWeAre from "../../assets/images/Who_we_are.png";
import CodeOfPractice from "../../assets/images/Code_of_practice.png";
import mail from "../../assets/images/about/writeMail.png";
import location from "../../assets/images/about/location.png";
import leftArrowActive from "../../assets/images/leftArrowActive.png";
import leftArrowInactive from "../../assets/images/leftArrowInactive.png";
import rightArrowActive from "../../assets/images/rightArrowActive.png";
import rightArrowInactive from "../../assets/images/rightArrowInactive.png";
import membership from "../../assets/images/MembershipInformation.png";

export default {
  "mainHeading1_txt": "Doing Well for ",
  "mainHeading2_txt": "Patients",
  "mainHeading3_txt": "...Together",
  "aboutSection": {
    "img_src": AboutSection,
    "img_alt_txt": "About phactMI",
    "title": "About phactMI",
    "desc_txt1": "Is your organization interested in becoming a member of phactMI? Interested in joining a network of Medical Information Professionals that are interested in advancing Medical Information to provide quality patient care?",
    "desc_txt": "phactMI, a 501 (c)(6) corporation, is a collaboration of pharmaceutical company Medical Information (Ml) leaders that are dedicated to supporting healthcare professionals in their commitment to provide quality patient care. At phactMI, we understand that healthcare professionals have a wide range of medical information needs, and we are supportive of the spectrum of drug information providers.",
    "nav_list": [
      {
        label: "About",
        href:"aboutElm",
        url: ""
      },
      {
        label: "Resources",
        href: "ARElm",
        url: "#additional-resources"
      },
      // {
      //   label: "Member Organizations",
      //   href:"manufacturersElm",
      //   url: "#members"
      // },
      {
        label: "Code of Practice",
        href:"COPElm",
        url: "#code-of-practice"
      },
      {
        label: "FAQs",
        href:"FAQElm",
        url: "#faqs"
      }
      // {
      //   label: "Contact Us",
      //   href:"contatctElm",
      //   url: "#contact-us"
      // }
    ],
    "arrow_section": {
      "left_arrow_active": leftArrowActive,
      "left_arrow_Inactive": leftArrowInactive,
      "right_arrow_active": rightArrowActive,
      "right_arrow_Inactive": rightArrowInactive,
      "left_arrow_alt": "Left Arrow",
      "right_arrow_alt": "Right Arrow"
    }
  },
  "whoWeAre": {
    "img_src": WhoWeAre,
    "img_alt_txt": "Who are we - phactMI",
    "title": "Our Why",
    "sub_title": "We Believe…",
    "desc1_txt": [
      "We are the primary source of unbiased medical information developed by healthcare professionals for healthcare professionals.",
      "Access to timely and accurate answers to your medication questions matters…",
      "Our collective efforts provide comprehensive drug information that empower healthcare professionals to make decisions that impact patient care."
    ],
    "learnmore_link": "Learn more"
  },
  "codeOfPractice": {
    "img_src": CodeOfPractice,
    "img_alt_txt": "Code of Practice - phactMI",
    "title": "Code of Practice",
    "desc1_txt": "The Medical Information (MI) function within the pharmaceutical industry has a significant role in supporting the information needs of the medical community, patients, and caregivers. MI professionals from their respective companies have access to the most accurate and up-to-date data on their products. By sharing this information when requested, MI facilitates the safe and effective use of medicines and informs healthcare decision-making, which has a positive impact on patient care.",
    "desc2_txt": "MI departments should adhere to high standards of practice throughout the development and dissemination of product, device and disease information.",
    "core_elements_heading": "The three core elements that define the MI Code of Practice are:",
    "desc3_txt": [
      "Clinical and Pharmaceutical Expertise",
      "Scientific Balance of Medical Responses",
      "Quality Standards"
    ],
    "learn_more_txt": "Learn more"
  },
  "board_members": {
    "header": "Board Members",
    "subtitle": "You can contact the phactMI organization using the information below"
  },
  "directors": {
    "header": "Directors"
  },
  "additionalResources": {
    "header": "Resources",
    "subHeader": "To be added text",
    "image_alt_text": "resources"
  },
  "contactUsData": {
    "contactUsTitle": "Contact Us",
    "contactUsDescription": "You can contact phactMI using the information below",
    "mail_to": "mailto",
    "contactUsImageAssests": [
      {
        "email": {
          "img_src": mail,
          "img_alt_txt": "Email Us - phactMI"
        }
      },
      {
        "address": {
          "img_src": location,
          "img_alt_txt": "Contact Us - phactMI"
        }
      },
      {
        "membership": {
          "img_src": membership,
          "img_alt_txt": "Membership Information"
        }
      }
    ]
  },
  "faq": {
    title: "Frequently Asked Questions",
    "expand_txt": "EXPAND",
    "collapse_txt": "COLLAPSE"
  },
  "manufacturers_heading": "Member Organizations"
}
