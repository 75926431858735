export const POST_USER_FEEDBACK = "POST_USER_FEEDBACK";
export const POST_SUCCESS_USER_FEEDBACK = "POST_SUCCESS_USER_FEEDBACK";
export const POST_FAIL_USER_FEEDBACK = "POST_FAIL_USER_FEEDBACK";
export const SET_USER_FEEDBACK = "SET_USER_FEEDBACK";
export const GET_FEEDBACK_QUESTION = "GET_FEEDBACK_QUESTION";
export const GET_FEEDBACK_QUESTION_SUCCESS = "GET_FEEDBACK_QUESTION_SUCCESS";
export const GET_FEEDBACK_QUESTION_FAIL = "GET_FEEDBACK_QUESTION_FAIL";

export function postUserFeedback(userInput) {
  return {
    type: POST_USER_FEEDBACK,
    payload: userInput
  }
}

export function setUserFeedback() {
  return {
    type: SET_USER_FEEDBACK
  }
}

export function getFeedbackQuestion() {
  return {
    type: GET_FEEDBACK_QUESTION
  }
}
