import React, { Component } from "react";
import _get from "lodash/get";
import error from "../../assets/images/Error.png";
import { newslettercontent, submitText } from "./newsletter.content";
//import NewsSubscription from "./styles";
import success from "../../assets/images/Success.png";
import submit from "../../assets/images/Submit.png";
import * as styled from "./styles";

class EmailSubscription extends Component {

  state = {
    email: "",
    isEmailValid: true
  }

  onSubmit = () => {
    const { email } = this.state;
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email.toLowerCase())) {
      this.setState({ "isEmailValid": true });
      this.props.clickSubmit(email);
    } else {
      this.setState({ "isEmailValid": false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriptionSuccess === true && this.props.subscriptionSuccess === false) {
      this.setState({ "email": "" });
    }
  }

  handleChange = (e) => {
    this.setState({ "email": e.target.value });
  }

  render() {
    const { isEmailValid, email } = this.state;
    const { message, subscriptionSuccess } = this.props;
    const successMessage = _get(message, "message", "");
    return (
      // <NewsSubscription>
      //   <div className="news-letter">
      //     <div>
      //       <div className="news-letter-title">
      //         {newslettercontent.title}
      //       </div>
      //       <span className="news-letter-tag">
      //         {newslettercontent.subTag}
      //       </span>
      //     </div>
      //     <div className="news-letter-email">
      //       <input type="email" placeholder="Email Address" className="email-input" value={this.state.email} onChange={e => this.handleChange(e)} />
      //       <button type="button" className="email-submit" onClick={this.onSubmit}>
      //         <span>{submitText}</span>
      //         <img src={submit} alt={submitText} />
      //       </button>
      //     </div>
      //     <div className="email-validation-error">
      //       {!isEmailValid &&
      //         <React.Fragment>
      //           <img src={error} className="error-image" alt='' />
      //           <div className="error-message">{newslettercontent.emailValidationError}</div>
      //         </React.Fragment>
      //       }
      //     </div>
      //     {isEmailValid && subscriptionSuccess && <div className="email-submitted-success" >
      //       <img src={success} className="success-image" alt='' />
      //       <div className="success-message">{successMessage}</div>
      //     </div>
      //     }
      //     <div className="news-letter-purpose">
      //       {newslettercontent.purpose}
      //     </div>
      //   </div>
      // </NewsSubscription>
      <styled.MainContainer>
        <styled.Heading>
          <h3>{newslettercontent.title}</h3>
          <p>{newslettercontent.subTag}</p>
        </styled.Heading>
        <styled.EmailWrapper>
          <input type="email" className="email-input" placeholder={newslettercontent.email_placeholder} value={email} onChange={this.handleChange} />
          <button type="button" onClick={this.onSubmit} className="email-submit"><span>{submitText}</span><img src={submit} alt={submitText} /></button>
          {
            !isEmailValid &&
            <React.Fragment>
              <img src={error} className="error-image" alt=""></img>
              <p>{newslettercontent.emailValidationError}</p>
            </React.Fragment>
          }
          {
            isEmailValid && subscriptionSuccess &&
            <div className="email-submitted-success">
              <img src={success} className="success-image" alt=""></img>
              <p>{successMessage}</p>
            </div>
          }
        </styled.EmailWrapper>
        <p>{newslettercontent.purpose}</p>
      </styled.MainContainer>
    );
  }
}

export default EmailSubscription;