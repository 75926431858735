import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const Content = styled.div`
margin: 0;
width: 450px;
font-size: 16px;
line-height: 1.7;
color: ${colors.dove_darkgray};
ul:first-child {
  margin: 0;
  padding: 0 0 0 18px;
}
ul {
  padding: 0 0 0 20px;
}
@media ${mobileRange} {
  width: 100%;
  font-size: 14px;
  line-height: 1.56;
  letter-spacing: normal;
}
@media ${tabletRange} {
  width: 374px;
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: normal;
}
a {
  font-size: 16px;
  color: ${colors.bondi_green};
  padding: 0 0 0 20px;
  @media ${tabletRange} {
    font-size: 18px;
  }
  @media ${mobileRange} {
    font-size: 14px;
  }
}
`;

export const SubTitle = styled.h2`
  font-size: 22px;
  color: ${colors.sherpa_blue};
  padding-top: 20px;
  @media ${mobileRange} {
    font-size: 19px;
    padding-top: 0;
  }
`;

export const MainContainer = styled.div`
  @media ${mobileRange} {
    padding: 30px 0
  }
`;
