import axios from "axios";
import { endpoint } from "../../config/app.config";

export const getFellowship = () => {
  return axios
    .get(`${endpoint}/api/fellowship`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
