import React from "react";
import PropTypes from "prop-types";
import NewsListLoader from "./news-list-loader";
import moment from "moment";
import * as styled from "./styles";

/**
 * News List - display date list for news container
 *
 * @exports NewsListLoader
 */

const News = React.memo((props) => {
  const { data = [], selectedNewsDate, loader, onNewsDateClick, showMobileNews, header, accordianClick } = props;

  if (loader) {
    return (
      <NewsListLoader />
    );
  } else {
    return (
      <styled.NewsListContainer>
        <styled.margin />
        <styled.NewsMobileAccordian onClick={accordianClick} isOpen={showMobileNews}>
          <styled.NewsMobileTitle>{header}</styled.NewsMobileTitle>
          <styled.NewsMobileSelected isOpen={showMobileNews}>{selectedNewsDate && moment(selectedNewsDate).format("MMMM D, YYYY")}</styled.NewsMobileSelected>
        </styled.NewsMobileAccordian>
        {<styled.newsMobileList expandList={showMobileNews}>
          {data.map((datum, index) => (
            <styled.EachNews key={index} onClick={(e) => onNewsDateClick(e, Object.keys(datum)[0])} className={selectedNewsDate === Object.keys(datum)[0] ? "active" : ""}>
              {Object.keys(datum)[0]}
            </styled.EachNews>
          ))}
        </styled.newsMobileList>}
        <styled.NewsList>
          {data.map((datum, index) => (
            <styled.NewsDateWrapper key={index} >
              <styled.EachNews onClick={(e) => onNewsDateClick(e, Object.keys(datum)[0])} className={selectedNewsDate === Object.keys(datum)[0] ? "active" : ""}>
                {Object.keys(datum)[0]}
              </styled.EachNews>
            </styled.NewsDateWrapper>
          ))}
        </styled.NewsList>
      </styled.NewsListContainer>
    );
  }
});

News.propTypes = {
  resources: PropTypes.shape({
    data: PropTypes.object,
    selectedNewsDate: PropTypes.string,
    loader: PropTypes.bool
  })
};
export default News;
