import React, { memo } from "react";
import PropTypes from "prop-types";
import { colors } from "../../common/styles/variables";
import { Link } from "react-router-dom";
import SectionWithImage from "../section-with-image"
import * as styled from "./styles";

const renderBody = (descArray, learn_more_txt, sub_title) => {
  return (
    <styled.Content>
      <styled.SubTitle>
        {sub_title}
      </styled.SubTitle>
      <ul>
        {descArray.map((item, index) => (
          <li key={index} >{item}</li>
        ))}
      </ul>
      <Link to="/our-why">{learn_more_txt}</Link>
    </styled.Content>
  )
};
const WhoWeAre = props => {
  const { img_src, img_alt_txt, title, desc1_txt = [], learnmore_link, sub_title } = props.resources;
  return (
    <styled.MainContainer>
      <SectionWithImage
        img_src={img_src}
        img_alt_txt={img_alt_txt}
        title={title}
        body={renderBody(desc1_txt, learnmore_link, sub_title )}
        imageToRight={true}
        bgColor={colors.white}
        hideImageMobile={false}
      /></styled.MainContainer>
  );
};

WhoWeAre.propTypes = {
  resources: PropTypes.shape({
    img_src: PropTypes.string,
    img_alt_txt: PropTypes.string,
    title: PropTypes.string,
    desc1_txt: PropTypes.array
  })
};

export default memo(WhoWeAre);