import React, { memo } from "react";
import * as styled from "./style";

const itemLoader = () => {
  const itemsArr = [];
  for (let i = 0; i < 4; i++) {
    itemsArr.push(
      <div key={i}>
        <styled.ItemLoader>
          <styled.Mask70 />
          <styled.Mask100 />
        </styled.ItemLoader>
        <styled.MaskV90 />
        {(i%3 ===1)? <React.Fragment><styled.ItemLoaderSmall>
          <styled.Mask70 />
          <styled.Mask100 />
          <styled.Mask1px />
          <styled.Mask100 />
        </styled.ItemLoaderSmall>
        <styled.MaskV90 /></React.Fragment>: ""}
      </div>
    )
  }
  return itemsArr;
};

const ManufacturerLoader = () => {
  return (
    <styled.LoaderWrapper>
      {itemLoader()}
    </styled.LoaderWrapper>
  )
};

export default memo(ManufacturerLoader);
