import styled, { keyframes } from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, mobileAndTabletRange, ipadProRange, minLaptopL } from "../../common/styles/breakpoints";

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`
export const LoaderWrapper = styled.div`
  overflow: hidden;
  height: ${props => props.firstsearch ? "576px" : "1094px"};
  margin-top: ${props => props.marginTop ? (props.marginTop - (props.marginTop + 80) + "px") : "0"};
  background-color: ${colors.white};
  @media ${mobileRange} {
    margin-top: 20px;
    height: ${props => props.firstsearch ? "1020px" : "661px"};
  }
  @media ${tabletRange} {
    margin-top:  ${props => props.marginTop ? (props.marginTop - (props.marginTop + 80) + "px") : "0"};
    height: ${props => props.firstsearch ? "720px" : "678px"};
  }
  @media ${ipadProRange} {
    height: ${props => props.firstsearch ? "650px" : "1094px"};
  }
`;

export const ItemPadding = styled.div`
  width: 100%;
  height: 106px;
  @media ${mobileRange} {
    height: 46px;
  }
  @media ${tabletRange} {
    height: 41px;
  }
`;
export const ItemLoader = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: ${colors.alabaster};
  background: linear-gradient(to right, ${colors.gallery} 8%, ${colors.alto} 18%, ${colors.gallery} 33%);
  background-size: 1000px 104px;
  height: 348px;
  position: relative;
  overflow: hidden;
  @media ${mobileAndTabletRange} {
    height: 273px;
  }
`;
export const Mask1 = styled.div`
  background: ${colors.white};
  width: 50%;
  height: 54px;
  position: absolute;
  top: 0;
  right: 0;
  @media ${mobileAndTabletRange} {
    display: none;
  }
`;
export const Mask2 = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 22px;
  position: absolute;
  top: 54px;
  @media ${mobileAndTabletRange} {
    height: 14px;
    top: 43px;
  }
`;
export const Mask3 = styled.div`
  background: ${colors.white};
  width: 82%;
  height: 54px;
  position: absolute;
  top: 74px;
  right: 0;
  @media ${mobileRange} {
    height: 43px;
    top: 57px;
    width: 61%;
  }
  @media ${tabletRange} {
    height: 43px;
    top: 57px;
    width: 69%;
  }
`;
export const Mask4 = styled.div`
  background: ${colors.white};
  width: 100%;
  height: 22px;
  position: absolute;
  top: 126px;
  @media ${mobileAndTabletRange} {
    height: 14px;
    top: 100px;
  }
`;