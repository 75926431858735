export default {
  "title": "phactMI <b>Fellowship</b>",
  "fellowship_sub_title": "Fellowship Directory",
  "company_txt": "Company",
  "fellowship_txt": "Fellowship",
  "desc_txt": "Description",
  "program_web_txt": "Go to Program Website",
  "read_more_txt": "Read More",
  "read_less_txt": "Read Less"
}
