import styled from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange } from "../../../common/styles/breakpoints";

export const ContactusSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 0 30px 0;
    @media ${mobileRange} {
      padding: 51px 0 10px;
    }
    @media ${tabletRange} {
      padding: 48px 0 30px;
    }
`;

export const ContactusHeading = styled.h2`
    font-size: 26px;
    color: ${colors.sherpa_blue};
    letter-spacing: -0.39px;
    margin: 0;
    @media ${mobileRange} {
      font-size: 26px;
      line-height: 1.23;
    }
    @media ${tabletRange} {
      font-size: 28px;
      line-height: 1.23;
    }
`;

export const ContactusDescription = styled.div`
    font-size: 16px;
    color: ${colors.dove_gray};
    letter-spacing: -0.39px;
    width: 310px;
    padding-top: 12px;
    text-align: center;
    line-height: 1.61;
    @media ${mobileRange} {
      font-size: 16px;
      line-height: 1.56;
      width: 319px;
      padding-bottom: 16px;
    }
    @media ${tabletRange} {
      font-size: 16px;
      line-height: 1.56;
      width: 350px;
      padding-bottom: 16px;
    }
`;

export const ContactusContent = styled.div`
  display: flex;
  text-align: center;
  & .contact-us {
    width: 50%;
    padding: 0 50px;
    @media ${mobileRange} {
      width: 100%
      padding: 0;
    }
    @media ${tabletRange} {
      padding: 0 10px;
      text-align: center;
    }
  }
  @media ${mobileRange} {
    flex-direction: column;
  }
`;

export const ContactusImageSection = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 5px;
  img {
    width: 129px;
    height: 124px;
  }
  @media ${mobileRange} {
    margin-top: 0;
  }
  @media ${tabletRange} {
    margin-top: 5px;
    margin-bottom: 2px;
  }
`;

export const ContactusImageSectionDesc = styled.div`
  line-height: 1.45;
  font-size: 16px;
  padding-bottom:20px;
  @media ${mobileRange} {
    margin: 0 108px 10px;
    font-size: 16px;
    line-height: 1.56;
  }
  @media ${tabletRange} {
    font-size: 18px;
    width: 180px;
    margin: 0 30px;
    line-height: 1.57;
  }
  a {
    color: ${colors.bondi_blue};
    text-decoration: none;
    font-size: 16px;
    @media ${mobileRange} {
      font-size: 18px;
    }
    @media ${tabletRange} {
      font-size: 18px;
    }
  }
`;