import React from "react";
import * as styled from "./style";
import BlackImage from "../../assets/tour-images/black_img.jpeg"

const Modal = (props) => {
  return (<>
   <styled.Overlay>
     <img src={BlackImage}></img>
   </styled.Overlay>
    <styled.ModalContainer>
      <styled.ModalWrapper {...props}>{props.children}</styled.ModalWrapper>
    </styled.ModalContainer>
  </>)
}

export default Modal;