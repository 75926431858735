import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  text-align: center;
  box-shadow: ${props => props.fromPI ? "none" : "0 0px 0px 0 rgba(0,0,0,0.35)"};
  background-color: ${colors.white};
  margin-bottom: ${props => props.fromPI ? "0" : "3px"};
  min-height: ${props => props.fromPI ? "625px" : "0"};
  padding: ${props => props.fromPI ? "30px 30px 50px" : "0"};
  p {
    margin-bottom: 0;
  }
  @media ${mobileRange} {
    margin-top: -64px;
    padding: 0;
    box-shadow: ${props => props.fromPI ? "none" : "0 30px 50px 0 rgba(0,0,0,0.1)"};
  }
  @media ${tabletRange} {
    margin-top: -15px;
    padding: 0;
  }
`;

export const Section1 = styled.div`
  min-height: 550px;
  img {
    width: 160px;
  }
  @media ${mobileRange} {
    min-height: 0;
    img {
      width: 128px;
    }
  }
  @media ${tabletRange} {
    min-height: 650px;
    img {
      width: 167px;
    }
  }
`;

export const Section1P1 = styled.p`
  font-size: 20px;
  text-align: left;
  color: ${colors.bondi_blue};
  margin-bottom: 14px !important;
  font-weight: bold;
  letter-spacing: -0.39px;
  margin-top: 15px;
  word-break: break-word;
  @media ${mobileRange} {
    font-size: 22px;
    line-height: 1.55;
    letter-spacing: -0.29px;
    padding: 0 20px;
    margin-top: 8px;
  }
`;

export const Section1P2 = styled.p`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  letter-spacing: -0.33px;
  @media ${mobileRange} {
    padding: 6px 20px 0;
    font-size: 19px;
    line-height: 1.26;
    letter-spacing: -0.25px;
  }
`;

export const ContentSection = styled.div`
  padding: ${props => props.fromPI ? "50px 100px" : "0"};
  @media ${mobileRange} {
    margin-top: 50px;
  }
`;