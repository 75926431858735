import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : "transparent")};
  display: flex;
  flex-direction: ${props => (props.imageToRight ? "row-reverse" : "row")};
  padding: 65px 0 65px;
  @media ${mobileRange} {
    flex-direction: column;
    padding: 0;
    padding: 0 20px 0;
  }
  @media ${tabletRange} {
    padding: 48px 0;
  }
`;
export const LeftContent = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: center;

  @media ${mobileRange} {
    display: ${props => props.hideImageMobile ? "none" : "block"};
  }

`;
export const ImageWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-left: ${props => (props.imageToRight ? "-12%" : "12%")};
  @media ${mobileRange} {
    margin-left: 0;
    justify-content: center;
    img {
      width: 250px;
    }
  }
  @media ${tabletRange} {
    margin-left: ${props => (props.imageToRight ? "-4px" : "4px")};
    img {
      width: 218px;
    }
  }
`;
export const RightContent = styled.div`
  flex-basis: 100%
  div:first-child {
    margin-left: ${props => (props.imageToRight ? "29%" : "0")};
    @media ${mobileRange} {
      margin-top: 30px;
      margin-left: 0
    }
    @media ${tabletRange} {
      margin: ${props => (props.imageToRight ? "0 21%" : "0 -10%")};
    }
  }
`;
export const H2 = styled.h2`
  margin: 0 0 10px;
  color: ${colors.sherpa_blue};
  line-height: 1.2;
  letter-spacing: -0.39px;
  font-size: 26px;
  @media ${mobileRange} {
    margin-left: 0;
    font-size: 22px;
    line-height: 1.23;
    letter-spacing: -0.34px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  @media ${tabletRange} {
    margin-left: 0;
    font-size: 24px;
    line-height: 1.21;
    letter-spacing: -0.31px;
  }
`;