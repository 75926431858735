import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const FeedbackToolTipContainer = styled.div`
    display: flex;
`;

export const Tooltip = styled.div`
  width: 200px;
  background-color: ${colors.aqua_haze};
  border: solid 1px ${colors.sherpa_blue};
  border-radius: 4px;
  position: fixed;
  display: none;
  z-index: 1;
  bottom: 79px;
  right: 10px;
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.46;
  text-align: center;
  padding: 10px 0;
  pointer-events: none;
  color: ${colors.tundora_approx};
  }

  ::after {
    content: "";
    position: absolute;
    bottom: -19px;
    right: 24px;
    border-width: 10px;
    border-style: solid;
    border-color: ${colors.aqua_haze} transparent transparent transparent;
  }

  ::before {
    content: "";
    position: absolute;
    bottom: -20px;
    right: 24px;
    border-width: 10px;
    border-style: solid;
    border-color: ${colors.sherpa_blue} transparent transparent transparent;
  }
`;

export const TooltipWrapper = styled.div`
:hover ${Tooltip} {
  display: block;
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    display: none;
  }
  @media ${ipadProRange} {
    display: none;
  }
}
`;

export const FeedbackImage = styled.div`
  display: block;
  position: fixed;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  img {
    width: 70px;
  }
`;
