/* eslint-disable max-len */
import Code_Of_Practice from "../../assets/images/Code_of_practice.png"

export default {
  header:"phactMI Medical Information Code of Practice",
  img_src:Code_Of_Practice,
  contentheading:
    "Medical Information Code of Practice for Responding to Healthcare Professional Requests",
  desc1_txt:
    "The Medical Information (MI) function within the pharmaceutical industry has a significant role in supporting the information needs of the medical community, patients, and caregivers. MI professionals from their respective companies have access to the most accurate and up-to-date data on their products.  By sharing this information when requested, MI facilitates the safe and effective use of medicines and informs healthcare decision-making, which has a positive impact on patient care.",
  desc2_txt:
    "MI departments should adhere to high standards of practice throughout the development and dissemination of product, device and disease information.  The three core elements that define the MI Code of Practice are:",
  listtitle: [
    "Clinical and Pharmaceutical Expertise",
    "Scientific Balance of Medical Responses",
    "Quality Standards"
  ],
  section: [
    {
      list: {
        title: "Clinical and Pharmaceutical Expertise",
        subtitle: "Key concepts:",
        desc: [
          "MI professionals should have healthcare degrees or relevant scientific training and background",
          "MI professionals should have training in MI Science skills"
        ]
      }
    },
    {
      content:
        "MI professionals provide customers with accurate, scientifically-balanced and current data about products and related disease states. As such, MI professionals should be individuals with healthcare degrees or relevant scientific training and background. This allows for a keen understanding of questions posed by healthcare professionals (HCP), and ensures that the information provided is relevant to patient care. In addition to the advanced degrees and/or clinical training, MI professionals should be trained in MI Science skills including literature searching, literature evaluation (including study designs, statistical methodology, and clinical product/device training), information synthesis and medical writing. MI professionals should be able to critically evaluate scientific literature, formulate a medical response, and effectively communicate the information to the requesting HCP to assist them in making a clinical decision.",
      list: {
        title: "Scientific Balance of Medical Responses",
        subtitle: "Key concepts:",
        desc: [
          "MI responses should be evidence-based and balanced",
          "MI responses should be accurate, truthful, and non-misleading",
          "MI responses should be non-promotional"
        ]
      }
    },
    {
      content:
        "Responses to unsolicited inquiries from HCPs should be scientifically balanced, accurate, truthful, and free of commercial bias. A comprehensive literature search should be utilized to provide an evidence-based response, inclusive of positive and negative trial results. Important contextual information, such as study design, should be clearly presented when applicable to assist with proper interpretation of study methodology. Presentation of trial efficacy results should be balanced with safety information. To the extent possible, the information contained within the response should include data from published, peer-reviewed journals.",
      list: {
        title: "Quality Standards",
        subtitle: "Key concepts:",
        desc: [
          "MI responses should be updated regularly to ensure accuracy and currency",
          "MI responses should undergo a review and approval process"
        ]
      }
    }
  ],
  desc3_txt:
    "MI responses should be updated regularly to ensure quality, relevance and currency. The availability of any new information that may substantially impact the body of evidence should initiate an update. Additional quality standards, inclusive of data accuracy and scientific balance, should be verified through a review and approval process."
};
