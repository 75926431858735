import React from "react";
import PropTypes from "prop-types";
import TableComponent from "../table/table";
import Separator from "../separator/separator";
import { Header, MembersDirectorsContainer } from "./styles";

const BoardMembers = React.memo((props) => {
  const { boardMember, director } = props.data;
  const { membersHeader, directorsHeader } = props;
  return (
    <MembersDirectorsContainer>
      <Separator />
      <Header>{membersHeader}</Header>
      <TableComponent data={boardMember} columnNumber={3} />
      <Separator />
      <Header>{directorsHeader}</Header>
      <TableComponent data={director} columnNumber={4} />
    </MembersDirectorsContainer>
  );
});

BoardMembers.propTypes = {
  data: PropTypes.object
}

export default BoardMembers;
