/* eslint-disable max-len */
import Terms from "../../assets/images/Conditions.png";
export default {
  "heading": "Terms & Conditions",
  "img_src": Terms,
  "img_alt_txt": "phactMI - Terms & Conditions",
  "conditions": [
    "By using this website you acknowledge that PhaCT-MI, Inc. (“PhaCT-MI”) and its member companies are providing the material for general informational and educational purposes only. Please read and review these Terms and Conditions carefully before accessing or using this website. By using this website, you are representing your consent to these Terms and Conditions",
    "All content of this website is owned or controlled by PhaCT-MI, Inc. (“PhaCT-MI”), its member companies and others and is protected by worldwide copyright laws. You may download material from this website only for personal, non-commercial use, and this website may not be copied, distributed, downloaded, modified, reused, reposted, or otherwise exploited in any way. ",
    "PhaCT-MI strives to include up-to-date and accurate information in this website, but PhaCT-MI and its member companies make no representations, warranties, or assurances as to the accuracy, currency, suitability, or completeness of any information provided. PhaCT-MI and its member companies shall not be liable for any damages or injury resulting from your access to, or inability to access, this website, or from your reliance on any information provided at this Internet site.",
    "This website may provide links or references to other websites but PhaCT-MI has no responsibility for the content of such other websites and shall not be liable for any damages or injury arising from that content. Such other websites may be governed by their own terms of use and privacy policies for which PhaCT-MI has no control. PhaCT-MI provides links to other websites as a convenience to users of this website, and access to any other websites linked to this website is at your own risk.",
    "The trademarks, trade names, logos and service marks (collectively the “Trademark”) displayed on this Internet site are registered and unregistered Trademarks of PhaCT-MI, its member companies and others. The use or misuse of these Trademarks or any other materials, except as permitted herein, is expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and libel, the law of privacy and publicity, and communications regulations and statutes. Your use of the Trademarks displayed on this Internet site, or any other content on this Internet site, except as provided in these terms and conditions, is strictly prohibited. Please be advised that PhaCT-MI and its member companies actively and aggressively enforces its intellectual property rights to the fullest extent of the law.",
    "Any personally identifiable information in electronic communications to this website is governed by this website's Privacy Policy. PhaCT-MI and its member companies are free to use without limitation and without compensation to you any ideas, inventions, concepts, know-how, techniques or the like contained in any communication you send to PhaCT-MI and its member companies for any purpose, including, but not limited to, developing, manufacturing and/or marketing goods or services.",
    "The sender of any communications to this website or otherwise to PhaCT-MI and its member companies shall be responsible for the content and information contained therein, including its truthfulness and accuracy.",
    "Users may not link to the PhaCT-MI home page without first obtaining PhaCT-MI’s prior written consent. Following such consent any such link must be presented in a way that is fair and legal and does not damage our reputation or take advantage of it. Users must not establish a link in such a way as to suggest any form of association, approval or endorsement on the part of PhaCT-MI or its member companies where none exists. Users must not establish a link from any website that is not owned by the user. PhaCT-MI reserves the right to withdraw linking permission without notice. The website from which you are linking must comply in all respects with these Terms and Conditions.",
    "In the event that any provision of these Terms and Conditions, in whole or in part, be determined to be invalid, unenforceable, or void for any reason, the remainder of these Terms and Conditions shall not be affected in any way.",
    "PhaCT-MI may revise these Terms and Conditions from time to time without further notice by updating this posting."
  ]
}
