import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const InformativePopupContent = styled.div`
  background-color: #EDEEEF80;
  padding: 20px 20px;
  text-align: left;
  border-radius: 12px;
  p {
    color: ${colors.scorpion_gray};
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
  }
  h6 {
    color: ${colors.sherpa_blue}
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }
`;