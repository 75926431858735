import React, { Component } from "react";
import * as styled from "./styles";

/**
 * Error popup class
 * @extends Component
 * @class ErrorPopUp
 */
class ErrorPopUp extends Component {

  componentDidMount() {
    if (document.querySelector(".overlay")) {
      document.querySelector(".overlay").style.display = "block";
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    if (document.querySelector(".overlay")) {
      document.querySelector(".overlay").style.display = "none";
    }
  }

  /**
   * Display Error Function
   * @returns {HTMLElement} html element
   */
  displayError = () => {
    const { resources: { alt_txt, button_txt, description, heading, logo } } = this.props
    return (
      <styled.ErrorContainer>
        <styled.logoWrapper>
          <img src={logo} alt={alt_txt}></img>
        </styled.logoWrapper>
        <styled.ErrorContent>
          <h1>{heading}</h1>
          <p dangerouslySetInnerHTML={{__html: description}}></p>
          <styled.OkButton onClick={this.handleClick}>{button_txt}</styled.OkButton>
        </styled.ErrorContent>
      </styled.ErrorContainer>
    );
  }

  /**
   * Go To member page click
   * @returns {undefined}
   */
  handleClick = () => {
    const { fromNews } = this.props;
    if (fromNews) {
      window.location.assign("/news");
    }
    else {
      window.location.assign("/members");
    }
  }

  render() {
    return (
      <styled.MainContainer>
        {this.displayError()}
      </styled.MainContainer>
    )
  }
}

export default React.memo(ErrorPopUp);
