import { takeLatest, put, call } from "redux-saga/effects";
import { POST_USER_FEEDBACK, POST_SUCCESS_USER_FEEDBACK, POST_FAIL_USER_FEEDBACK,
  GET_FEEDBACK_QUESTION, GET_FEEDBACK_QUESTION_SUCCESS, GET_FEEDBACK_QUESTION_FAIL } from "./actions";
import { postUserFeedback, getFeedbackQuestion } from "./api";

export function* postUserFeedbackDataWorker({ payload }) {
  try {
    const data = yield call(postUserFeedback, payload);

    yield put({
      type: POST_SUCCESS_USER_FEEDBACK,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: POST_FAIL_USER_FEEDBACK });
  }
}

export function* postUserFeedbackDataWatcher() {
  yield takeLatest(POST_USER_FEEDBACK, postUserFeedbackDataWorker);
}

export function* getFeedbackQuestionDataWorker({ payload }) {
  try {
    const data = yield call(getFeedbackQuestion, payload);
    yield put({
      type: GET_FEEDBACK_QUESTION_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FEEDBACK_QUESTION_FAIL });
  }
}

export function* getFeedbackQuestionDataWatcher() {
  yield takeLatest(GET_FEEDBACK_QUESTION, getFeedbackQuestionDataWorker);
}

export default {
  postUserFeedbackDataWatcher,
  getFeedbackQuestionDataWatcher
};
