import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _head from "lodash/head";
import { newscontent } from "./latest-news.content";
import NewsContent from "./styles";

const LatestNews = (props) => {
  const newsToFetch = _head(props.news);
  const { title, postingDate} = newsToFetch || {};
  const modifiedDate = postingDate && moment(postingDate).format("MMMM D, YYYY")

  return (
    <NewsContent>
      <div className="news-content">
        <div className="latest-news-title">
          {newscontent.title}
        </div>
        <span className="news-latest-date">
          {modifiedDate}
        </span>
        <p className="news-description">{title}</p>
        <span className="read-more-link">
          <Link to="/news">Read More</Link>
        </span>
        <div className="border-bottom"></div>
      </div>
    </NewsContent>
  )
};
export default React.memo(LatestNews);
