import React, { memo } from "react";
import * as styled from "./style";

const itemLoader = () => {
  const itemsArr = [];
  for (let i = 0; i < 3; i++) {
    itemsArr.push(
      <div key={i}>
        <styled.ItemLoader>
          <styled.Mask1 />
          <styled.Mask2 />
          <styled.Mask3 />
          <styled.Mask4 />
        </styled.ItemLoader>
        <styled.ItemPadding />
      </div>
    )
  }
  return itemsArr;
};

const SearchLoader = props => {
  return (
    <styled.LoaderWrapper firstsearch = {props.firstsearch} marginTop={props.marginTop}>
      { itemLoader() }
    </styled.LoaderWrapper>
  )
};

export default memo(SearchLoader);
