import React from "react";
import PropTypes from "prop-types";
import * as styled from "./style";

const LeagalMessage = React.memo(props => {
  const { data } = props;

  if (data && data.legalMessage) {
    const prefix = "/redirect?url=";
    let description = data.legalMessage.split("href=\"");
    description = description.map((data, index) => (index ? data = prefix + data : data));
    description = description.join("href=\"");
    return (
      <styled.LeagalMessage dangerouslySetInnerHTML={{__html: description}} />
    );
  }
  else return null;
});
LeagalMessage.propTypes = {
  resources: PropTypes.shape({
    legalMessage: PropTypes.string
  })
};
export default LeagalMessage;