import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange } from "../../common/styles/breakpoints";

export const AccordianHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.isExpanded ? colors.bondi_blue: colors.sherpa_blue};
  }
  img {
    width: 20px;
    margin-left: 10px;
    vertical-align: baseline;
  }
  @media ${mobileRange} {
    span {
      display: none;
    }
  }
`;

export const AccordianAnswerCard = styled.div`
  overflow: hidden;
  max-height: ${props => props.isActive ? "700px" : "0"};
  transition: max-height 0.5s;
`;
