import * as action from "./actions";

const initialState = {
  fellowshipData: null
}

export default function fellowshipReducer(state = initialState, { type, payload }) {
  switch (type) {
  case (action.GET_FELLOWSHIP_DATA):
    return {
      ...state
    }
  case (action.GET_FELLOWSHIP_DATA_SUCCESS):
    return {
      ...state,
      fellowshipData: payload.result.data
    }
  case (action.GET_FELLOWSHIP_DATA_FAIL):
    return {
      ...state,
      fellowshipData: null
    }
  default: return state
  }
}
