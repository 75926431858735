import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange } from "../../common/styles/breakpoints";

export const AccordionCard = styled.div`
    border-radius: 9px;
    border: 1px solid #00B59A;
    background: #FFF;
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 14px 16px;
    margin-bottom: 20px;
    :last-child {
        margin-bottom: 0;
    }
`;

export const AccordianHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.isExpanded ? colors.bondi_blue: colors.sherpa_blue};
    img {
        width: 28px;
        margin-left: 10px;
        vertical-align: baseline;
    }
  }
  @media ${mobileRange} {
    span {
      display: none;
    }
  }
`;

export const AccordianContentCard = styled.div`
  overflow: hidden;
  max-height: ${props => props.isActive ? "auto" : "0"};
  transition: max-height 0.5s;
  overflow-y: scroll
`;