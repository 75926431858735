import leftArrowActive from "../../assets/images/leftArrowActive.png";
import leftArrowInactive from "../../assets/images/leftArrowInactive.png";
import rightArrowActive from "../../assets/images/rightArrowActive.png";
import rightArrowInactive from "../../assets/images/rightArrowInactive.png";

export default {
  "filterImages": {
    "left_arrow_active": leftArrowActive,
    "left_arrow_Inactive": leftArrowInactive,
    "right_arrow_active": rightArrowActive,
    "right_arrow_Inactive": rightArrowInactive
  },
  "additional_resource_txt": "Additional Resource"
}
