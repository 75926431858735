import styled from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../../common/styles/breakpoints";

export const ManufacturersSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MobileHeading = styled.p`
    display: none;
    @media ${mobileRange} {
        font-size: 14px;
        font-weight: normal;
        display: block;
    }
`;

export const ManufacturersHeading = styled.h2`
    font-size: 26px;
    color: ${colors.sherpa_blue};
    letter-spacing: -0.39px;
    padding: 60px 0 45px;
    margin-bottom: 0;
    p {
        font-size: 15px;
        letter-spacing: -0.39px;
        color: ${colors.dove_gray};
        text-align: center;
        margin: 0;
        padding-top: 5px;
    }
    @media ${mobileRange} {
        padding: 50px 0 15px;
        font-size: 26px;
        display: none;
    }
    @media ${tabletRange} {
        padding: 50px 0 0;
        font-size: 22px;
    }
    @media ${ipadProRange} {
        padding: 50px 0 0;
        font-size: 24px;
    }
`;

export const ManufacturersContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 9px 180px 65px 180px;
    @media ${mobileRange} {
        padding: 10px 0 49px 0;
    }
    @media ${tabletRange} {
        padding: 21px 80px 48px 80px;
    }
    @media ${ipadProRange} {
        padding: 21px 80px 63px 80px;
    }
`;

export const ManufacturersGrid = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media ${mobileRange} {
        padding: 0;
    }
`;

export const ManufacturersGridItem = styled.div`
    width: 168.3px;
    border: solid 1px ${colors.mercury};
    text-align: center;
    padding: 5px;
    margin: 7px;
    height: 77px;
    background: ${colors.white};

    @media ${mobileRange} {
        border: 1px solid ${colors.mercury};
        text-align: center;
        padding: 10px;
        margin: 6px;
        width: 158px;
        height: 71px;
    }
    @media ${tabletRange} {
        width: 140px;
        height: 56px;
        margin: 5px;
    }
    @media ${ipadProRange} {
        width: 199px;
        height: 80px;
        margin: 5px;
    }
    &:hover {
        box-shadow: 0 0 15px 0 ${colors.alto};
        transform: scale3d(1.15, 1.35, 1.4);
        border: solid 0 ${colors.mercury};
        transition-duration: 300ms;
        cursor: pointer;
    }
    img {
        height: 100%;
        font-size: 15px;
        width: 100%;
        &:hover {
            width:100%
        }
    }
`;

