import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const PillWrapper = styled.div`
  display: flex;
  border: solid 1px rgba(0, 66, 81, 0.5);
  background-color: ${colors.white};
  border-radius: 25px;
  padding: 10px 0 10px 15px;
  font-size: 16px;
  color: ${colors.sherpa_blue};
  line-height: 1.4;
  margin: 12px 5px;
  :hover {
    background-color: #ddf1ee
  }
  span {
    cursor: pointer;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    white-space: nowrap;
  }
  @media ${tabletRange} {
    font-size: 11px;
    padding: 5px 0 5px 15px;
    margin: 5px;
  }
  @media ${mobileRange} {
    padding: 5px 0 5px 15px;
    margin: 8px 5px;
    align-items: center;
  }
`;

export const ClearInputButton = styled.button`
  border: none;
  margin-right: 4px;
  margin-left: 10px;
  background: transparent;
  :hover {
    background-color: #ddf1ee
  }
  &:focus{
    outline: none;
  }
  img {
    width: 10px;

    @media ${mobileRange} {
      width: 16px;
    }
    
  }
`;

export const ToolTipWrapper = styled.div`
`;