import GeneralErrorLogo from "../../assets/images/generalerror.png";
export default {
  "phactmi_txt": "phactMI",
  "news_heading_main": " News",
  "mobile_news_txt": "News by dates",
  "alinkplaceholder": "PortalResource",
  "phactmiurl": "phactmi.org",
  "email_heading": "Stay up to date on phactMI",
  "email_content": "Never miss an update from us…",
  "email_purpose": "Delivered conveniently to your inbox",
  "read_more_txt": "READ MORE",
  "read_less_txt": "READ LESS",
  "accordian_alt_txt": "expand logo",
  "email_send_type": "PhactMiUpdates",
  "generalError": {
    "logo": GeneralErrorLogo,
    "alt_txt": "error_logo",
    "heading": "Error!",
    "description": "The application has encountered an error. Please navigate to the ‘News’ page to find additional resources or contact" +
     "<a href='mailto:admin@phactmi.org'>admin@phactmi.org</a> for additional assistance.",
    "button_txt": "Go to News Page"
  },
  "read_desc": "Read and download the full article here",
  "published_on_txt": "Published On: ",
  "publication_txt": " Publications"
}
