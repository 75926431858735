import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const ErrorContainer = styled.div`
  background: ${colors.white};
  padding: 40px;
  font-size: 16px;
  width: 478px;
  z-index: 1002;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  @media ${mobileRange} {
    width: 260px;
    padding: 15px;
  }
`;

export const OkButton = styled.button`
  color: ${colors.white};
  border-radius: 5px;
  background-color: ${colors.sherpa_blue};
  padding: 15px 30px;
  font-size: 15px;
  @media ${mobileRange} {
    padding: 15px 15px;
  }
`;

export const MainContainer = styled.div`
  min-height: 750px;
`;

export const ErrorContent = styled.div`
  padding-top: 29px;
  color: ${colors.sherpa_blue};
  font-size: 26px;
  p {
    color: ${colors.scorpion};
    font-size: 15px;
    padding-top: 7px;
    a {
      color: ${colors.bondi_blue};
    }
  }
  @media ${mobileRange} {
    font-size: 22px;
  }
`;

export const logoWrapper = styled.div`
  img {
    width: 250px;
    @media ${mobileRange} {
      width: 147px;
    }
  }
`;