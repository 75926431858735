import * as actions from "./actions";

const initialState = {
  memberContactResource: null
}

export default function memberContactReducer(state = initialState, { type, payload }) {
  switch (type) {
  case actions.GET_MEMBER_COMPANY_INFO: {
    return {
      ...state
    }
  }
  case actions.GET_MEMBER_COMPANY_INFO_SUCCESS: {
    return {
      ...state,
      memberContactResource: payload.result.data
    }
  }
  case actions.GET_MEMBER_COMPANY_INFO_FAIL: {
    return {
      ...state,
      memberContactResource: null
    }
  }
  default:
    return state;
  }
}
