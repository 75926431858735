import React from "react";
import { SeparatorContainer } from "./styles";

const Separator = React.memo(() => {
  return (
    <SeparatorContainer>
      <div></div>
    </SeparatorContainer>
  );
});

export default Separator;