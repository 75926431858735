import { takeLatest, put, call } from "redux-saga/effects";
import { getRoles, setRole} from "./api";
import {GET_ROLES, GET_FAIL_ROLES, GET_SUCCESS_ROLES, SEND_DEGREE, SEND_DEGREE_FAIL, SEND_DEGREE_SUCCESS } from "./actions";

export function* getRolesDataWorker({ payload }) {
  try {
    const data = yield call(getRoles, payload);

    yield put ({
      type: GET_SUCCESS_ROLES,
      payload: {
        result: data
      }
    });

  } catch (e) {
    yield put ({ type: GET_FAIL_ROLES });
  }
}

export function* degreeDataWorker({ payload }) {
  try {
    const data = yield call(setRole, payload);
    yield put({
      type: SEND_DEGREE_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: SEND_DEGREE_FAIL });
  }

}
export function* getRolesDataWatcher() {
  yield takeLatest(GET_ROLES, getRolesDataWorker);
  yield takeLatest(SEND_DEGREE, degreeDataWorker)
}

export default {
  getRolesDataWatcher
};
