import styled, { keyframes } from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileAndTabletRange, mobileRange, minLaptop, minLaptopL } from "../../common/styles/breakpoints";

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`;

export const PiDetailsLoader = styled.div `
  height: 800px;
  width: 100%
  background: white;
  .gradient {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: ${colors.alabaster};
    background: linear-gradient(to right, ${colors.gallery} 8%, ${colors.alto} 18%, ${colors.gallery} 33%);
    overflow: hidden;
  }
`;

export const PiDetailsHeaderLoader = styled.div `
  height: 100px;
  width: 100%;
  @media ${mobileRange} {
      height: 150px;
  }
`;

export const PiDetailsIndexLoader = styled.div`
  height: 600px;
  width: 30%;
  @media ${mobileAndTabletRange} {
    display: none;
  }
`;

export const PiDetailsContentLoader = styled.div`
  height: 600px;
  width: 70%;
  margin-left: 10px;
  @media ${mobileAndTabletRange} {
    width: 100%;
  }
`;

export const ContentLoaderContainer = styled.div`
  margin-top: 10px;
  padding: 0 17%;
  display: flex;
  @media ${minLaptop} {
    padding: 0 3%;
  }
  @media ${minLaptopL} {
    padding: 0 17%;
  }
`;
