import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { tabletRange, mobileRange, ipadProRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  text-align: center;
  @media ${mobileRange} {
    margin-left: 0;
    text-align: center;
  }
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  color: ${colors.sherpa_blue};
  padding-top: 18px;
  @media ${mobileRange} {
    font-size: 26px;
    padding-top: 0;
  }
  @media ${tabletRange} {
    line-height: 1.0;
    font-size: 24px;
  }
  @media ${ipadProRange} {
    line-height: 1.0;
    font-size: 26px;
  }
`;

export const BadgeLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobileRange} {
    display: none;
  }
`;

export const BadgeLogo = styled.div`
  margin-top: -76px;
  @media ${mobileRange} {
    margin-top: -67px;
  }
  @media ${tabletRange} {
    margin-top: -73px;
  }
  @media ${ipadProRange} {
    margin-top: -68px;
  }
  img {
    width: 248px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${colors.scorpion};
  padding: 10px 200px;
  @media ${mobileRange} {
    padding: 10px;
  }
  @media ${tabletRange} {
    padding: 10px 115px;
  }
`;

export const CorporateLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .asp_logo {
    padding-top: 40px;
    margin: 0 30px;
    cursor: pointer;
    img {
      width: 182px;
      @media ${mobileRange} {
        width: 100%;
      }
    }
    @media ${mobileRange} {
      margin: 0;
      flex-basis: 45%;
      padding-top: 15px;
    }
    &:nth-child(odd) {
      @media ${mobileRange} {
        margin-right: 15px;
      }
    }
  }
`;