import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const MemberPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 45px;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: 60px;
  }
  @meida ${ipadProRange} {
    padding-top: 45px;
  }
`;

export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 42px;
  color: ${colors.scorpion};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 62px
  }
  @media ${mobileRange} {
    margin-top: 70px;
    display: block;
  }
  @media ${ipadProRange} {
    margin-top: 42px;
  }
`;

export const Description = styled.p`
  padding-top: 25px;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: ${colors.dove_gray};
`;

export const ContactListWrapper = styled.div`
`;

export const InfoWrapper = styled.div`
  padding: 0 68px 25px;
  @media ${mobileRange} {
    padding: 0 20px;
  }
`;

export const ManufactureListWrapper = styled.div``;

export const LINK = styled.div`
  font-size: 15px;
  line-height: 2.65;
  letter-spacing: -0.22px;
  text-transform: capitalize;
  color: ${colors.mako};
  a {
    text-decoration: none;
    color: ${colors.bondi_blue}
  }
  span {
    text-decoration: none;
    color: ${colors.bondi_blue};
    cursor: pointer;
  }
  span:nth-child(2){
    color: ${colors.mako};
    padding: 0 3px;
`;

export const BreadCrumb = styled.div`
  padding: 12px 0 12px 68px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  @media ${mobileRange} {
    padding: 12px 25px;
    text-align: center;
  }
`;

export const CompanyLogoWrapper = styled.div`
  @media ${mobileRange} {
    padding-top: 70px;
    text-align: center;
  }
  @media ${tabletRange} {
    margin-left: 70px;
  }
  @media ${ipadProRange} {
    margin-left: 70px;
  }
  img {
    width: 250px;
    @meddia ${mobileRange} {
      width: 147px;
    }
    @media ${tabletRange} {
      width: 147px;
    }
  }
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  img {
    width: 147px;
  }
`;