import React from "react";
import { Container } from "react-bootstrap";
import { endpoint } from "../../config/app.config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withTransition from "../../components/with-transition";
import resources from "../../config/content/covidPage";
import * as styled from "./style";
import * as actions from "./actions";

const mapStateToProps = state => {
  return {
    covidResource: state.covidReducer.covidResource
  };
};

class Covid extends React.Component {

  componentDidMount() {
    const { covidResource, getCovidContent, fromMemberContact } = this.props;
    if (!covidResource && !fromMemberContact) {
      getCovidContent();
    }
  }

  displayContent = () => {
    const { call_icon, call_icon_alt, mail_icon, mail_icon_alt } = resources;
    const { covidResource, memberResource, fromMemberContact } = this.props;
    if (covidResource || memberResource) {
      const covidData = [];
      if (fromMemberContact && memberResource) {
        memberResource.map((element) => {
          if (element.linkValue && element.linkValue.length > 0) {
            covidData.push(element)
          }
        })
      }
      else {
        if (covidResource) {
          covidResource.map((element) => {
            if (element.linkValue && element.linkValue.length > 0) {
              covidData.push(element)
            }
          })
        }
      }
      const covidDataSize = covidData.length;
      return (
        covidData.map((ele, index) => {
          return (
            <styled.ContentWrapper key={index} showRightBorder={index % 2 == 0 ? true : false} showMobileBottomBorder={index === covidDataSize - 1 ? false : true}
              showBottomBorder={covidDataSize % 2 === 0 ? (index === covidDataSize - 2 || index === covidDataSize - 1) ? false : true : index === covidDataSize - 1 ? false : true}>
              <styled.MemberImage>
                <img src={`${endpoint}/api/assets/images?id=${ele.companyLogo}`}></img>
              </styled.MemberImage>
              <styled.Content>
                <styled.MailWrapper>
                  {
                    ele.linkValue ?
                      <React.Fragment>
                        <img src={mail_icon} alt={mail_icon_alt}>
                        </img>
                        <div><a href={ele.linkValue}>{ele.linkValue}</a></div>
                      </React.Fragment>
                      : null
                  }
                </styled.MailWrapper>
                {
                  ele.contactLabel &&
                  <styled.CallWrapper>
                    <img src={call_icon} alt={call_icon_alt}></img>
                    <span><a href={`tel://${ele.contactValue}`}>{ele.contactLabel}</a></span>
                  </styled.CallWrapper>
                }
              </styled.Content>
            </styled.ContentWrapper>
          )
        })
      )
    }
  }

  render() {
    const { banner_title, banner_content, description } = resources;
    const { bannerTitle, fromMemberContact } = this.props;
    return (
      <styled.CovidPage fromMemberContact={fromMemberContact}>
        <Container>
          <styled.BannerTitle fromMemberContact={fromMemberContact} dangerouslySetInnerHTML={{ __html: fromMemberContact ? bannerTitle : banner_title }}>
          </styled.BannerTitle>
          {
            !fromMemberContact &&
            <styled.BannerContent>
              {banner_content}
            </styled.BannerContent>
          }
          {
            !fromMemberContact &&
            <styled.BannerContentMobile>
              <h2>{banner_content}</h2>
            </styled.BannerContentMobile>
          }
          <styled.CovidContent fromMemberContact={fromMemberContact}>
            <styled.CovidContentWrapper>
              {
                !fromMemberContact &&
              <p className="covid-description">{description}</p>
              }
              {this.displayContent()}
            </styled.CovidContentWrapper>
          </styled.CovidContent>
        </Container>
      </styled.CovidPage>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(withTransition(Covid)));
