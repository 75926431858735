import axios from "axios";
import { endpoint } from "../../config/app.config";
import ReactGA from "react-ga";
import resources from "../../config/content/searchPage";
const { googleAnalytics : {search_txt}} = resources;
ReactGA.set({ anonymizeIp: true });
const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
export const getSearchResult = ({ userInput, termInput, from, degree, description, sessionId, aId, fromOprx, fromAds = false, fromHcpPopup}) => {
  // const query = termInput ? termInput.length > 0 ? `${userInput} ${termInput}` : userInput : userInput;
  const query = (userInput.trim().replaceAll("|", " ")) + (termInput.trim().length > 0 ? " " : "") + termInput.trim();
  let degreeRole = degree;
  if (degreeRole.toLowerCase().includes("patient") || degreeRole.toLowerCase().includes("consumer") || degreeRole.toLowerCase().includes("caregiver")) {
    degreeRole = "PT" // PT -> patient in other
  }
  return axios
    .get(`${endpoint}/api/search/substances`, {
      params: {
        query: userInput.trim(),
        term: termInput.trim(),
        from,
        description,
        sessionId,
        aId,
        degree: (degreeRole === "PT"|| degreeRole === "NE") ? "patient" : degreeRole
      }
    })
    .then((response) => {
      let companyName = "";
      window.dataLayer = window.dataLayer || [];
      if (response && response.data && response.data.spl && response.data.searchResults) {
        response.data.spl.forEach((ele) => {
          if (!companyName.includes(ele.producer))
            companyName = companyName + (companyName.length > 0 ? " " : "") + ele.producer;
        });
        if (GADegrees.includes(degreeRole)) {
          if (fromAds) {
            window.dataLayer.push({
              event: "PhactMI Custom Event",
              phactmiEventData: {
                "eventCategory": !fromHcpPopup ? "" : degreeRole,
                "eventAction": "aanpwidgetsearch", // redirect from aanp ads
                "eventLabel": query.trim(),
                "cd1": !fromHcpPopup ? "" : degreeRole,
                "cd2": "aanpwidgetsearch", // redirect from aanp ads
                "cd3": userInput.trim(), // drug name
                "cd4": query.trim(),
                "cd6": `${response.data.spl.length}`,
                "cd7": `${response.data.searchResults.length}`,
                "cd8": "Null",
                "cd5": companyName.trim(),
                "cd16": termInput.trim()
              }
            })
          } else {
            window.dataLayer.push({
              event: "PhactMI Custom Event",
              phactmiEventData: {
                "eventCategory": !fromHcpPopup ? "" : degreeRole,
                "eventAction": search_txt,
                "eventLabel": query.trim(),
                "cd1": !fromHcpPopup ? "" : degreeRole,
                "cd2": search_txt,
                "cd3": query.trim(),
                "cd6": `${response.data.spl.length}`,
                "cd7": `${response.data.searchResults.length}`,
                "cd8": "Null",
                "cd5": companyName.trim()
              }
            })
          }
        }
        else {
          if (fromAds) {
            window.dataLayer.push({
              event: "PhactMI Custom Event",
              phactmiEventData: {
                "eventCategory": !fromHcpPopup ? "" : degree,
                "eventAction": "aanpwidgetsearch", // redirect from aanp ads
                "eventLabel": query.trim(),
                "cd1": !fromHcpPopup ? "" : "Other",
                "cd2": "aanpwidgetsearch", // redirect from aanp ads
                "cd3": userInput.trim(), // drug name
                "cd4": query.trim(),
                "cd6": `${response.data.spl.length}`,
                "cd7": `${response.data.searchResults.length}`,
                "cd8": "Null",
                "cd5": companyName.trim(),
                "cd16": termInput.trim()
              }
            })
          } else {
            window.dataLayer.push({
              event: "PhactMI Custom Event",
              phactmiEventData: {
                "eventCategory": !fromHcpPopup ? "" : degreeRole,
                "eventAction": search_txt,
                "eventLabel": query.trim(),
                "cd1": !fromHcpPopup ? "" : "Other",
                "cd2": search_txt,
                "cd3": query.trim(),
                "cd6": `${response.data.spl.length}`,
                "cd7": `${response.data.searchResults.length}`,
                "cd8": !fromHcpPopup ? "" : degree,
                "cd5": companyName.trim()
              }
            })
          }
        }
      }
      return response.data;
    })
    .catch((err) => {
      window.dataLayer = window.dataLayer || [];
      if (GADegrees.includes(degreeRole)) {
        if (fromAds) {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory":!fromHcpPopup ? "" : degreeRole,
              "eventAction": "aanpwidgetsearch", // redirect from aanp ads
              "eventLabel": query.trim(),
              "cd1": !fromHcpPopup ? "" : degreeRole,
              "cd2": "aanpwidgetsearch", // redirect from aanp ads
              "cd3": userInput.trim(), // drug name
              "cd4": query.trim(),
              "cd6": "0",
              "cd7": "0",
              "cd8": "Null",
              "cd16": termInput.trim()
            }
          })
        } else {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": !fromHcpPopup ? "" : degreeRole,
              "eventAction": search_txt,
              "eventLabel": query.trim(),
              "cd1": !fromHcpPopup ? "" : degreeRole,
              "cd2": search_txt,
              "cd3": query.trim(),
              "cd6": "0",
              "cd7": "0",
              "cd8": "Null"
            }
          })
        }
      }
      else {
        if (fromAds) {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory":!fromHcpPopup ? "" : degree,
              "eventAction": "aanpwidgetsearch", // redirect from aanp ads
              "eventLabel": query.trim(),
              "cd1": !fromHcpPopup ? "" : "Other",
              "cd2": "aanpwidgetsearch", // redirect from aanp ads
              "cd3": userInput.trim(), // drug name
              "cd4": query.trim(),
              "cd6": "0",
              "cd7": "0",
              "cd8": "Null",
              "cd16": termInput.trim()
            }
          })
        } else {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": !fromHcpPopup ? "" : degree,
              "eventAction": search_txt,
              "eventLabel": query.trim(),
              "cd1": !fromHcpPopup ? "" : "Other",
              "cd2": search_txt,
              "cd3": query.trim(),
              "cd6": "0",
              "cd7": "0",
              "cd8": !fromHcpPopup ? "" : degree
            }
          })
        }
      }
      throw err;
    });
};

export const getProductDetails = ({ productId, degree, description, sessionId, version }) => {
  return axios
    .get(`${endpoint}/api/search/spl/${productId}`, {
      params: {
        degree,
        description,
        sessionId,
        version
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getManufacturerDetails = () => {
  return axios
    .get(`${endpoint}/api/manufacturer`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSummary = ({summaryUrl}) => {
  return axios
    .get(`${endpoint}/api/search${summaryUrl}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const getSectionNameUsingDataCode = () => {
  return axios
    .get(`${endpoint}/api/sectionheading`)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      throw err;
    });
}
