import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL, mobileAndTabletRange } from "../../common/styles/breakpoints";

export const ProductHeader = styled.div`
    width: 100%;
    background-color: ${colors.sherpa_blue};
    display: flex;
    justify-content: space-between;
    padding: 1% 17%;

    @media ${mobileRange} {
        margin-top: 67.7px;
        padding: 3% 5%;
        display: block;
    }

    @media ${ipadProRange} {
        padding: 1% 6% 1% 7%;
    }
    @media ${tabletRange} {
        padding: 1% 3%;
    }
    @media ${minLaptop} {
        padding: 1% 3%;
    }
    @media ${minLaptopL} {
      padding: 1% 16% 1% 17%;
    }
`;

export const ProductTitle = styled.div`
    font-size: 28px;
    letter-spacing: -0.44px;
    color: ${colors.white};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media ${mobileRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
        width: 100%;
        text-align: left;
    }

    @media ${tabletRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
    }
    @media ${ipadProRange} {
        font-size: 24px;
        line-height: 1.12;
        letter-spacing: -0.34px;
    }
    @media ${minLaptop} {
        font-size: 28px;
    }
    @media ${minLaptopL} {
        font-size: 28px;
    }
    .showFullTitle {
        font-size: 24px;
        font-weight: 300;,
    }
    .show_title {
        font-size: 24px;
        padding-left: 5px;
        font-weight: 300;
        text-decoration: underline;
        cursor: pointer;
    }
    .hideFullTitle {
        font-size: 24px;
        font-weight: 300;
    }
`;

export const SourceContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media ${mobileRange} {
        width: 100%;
    }
`;

export const Label = styled.span`
    margin-right: 14px;
    font-size: 17px;
    color: ${colors.white};
    &.separator {
        &:before {
            margin-right: 14px;
            content: "|";
            @media ${mobileRange} {
                margin-right: 7px;
            }
            @media ${tabletRange} {
                margin-right: 7px;
            }
            @media ${ipadProRange} {
                margin-right: 7px;
            }
            @media ${minLaptop} {
                margin-right: 10px;
            }
            @media ${minLaptopL} {
                margin-right: 14px;
            }
        }
    }
    @media ${mobileRange} {
        font-size: 12px;
        font-weight: bold;
        margin-right: 7px
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: bold;
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: bold;
    }
    @media ${minLaptop} {
        font-size: 15px;
    }
    @media ${minLaptopL} {
        font-size: 17px;
    }
`;

export const SourceValue = styled.span`
    font-size: 17px;
    font-weight: 300;
    text-decoration: underline;
    color: ${colors.bondi_blue};
    &:hover{
        cursor: pointer
    }
    @media ${mobileRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${minLaptop} {
        font-size: 15px;
    }
    @media ${minLaptopL} {
        font-size: 17px;
    }
`;

export const FormValue = styled.span`
    font-size: 17px;
    font-weight: 300;
    color: ${colors.white};
    @media ${mobileRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${tabletRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${ipadProRange} {
        font-size: 12px;
        font-weight: 300;
    }
    @media ${minLaptop} {
        font-size: 15px;
    }
    @media ${minLaptopL} {
        font-size: 17px;
    }
`;

export const ProductContent = styled.div`
  background-color: ${colors.white};
  display: flex;
  &.iframe{
    border:none
  }
`;

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items:flex-start
`
export const ValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items:flex-start
`

export const Tooltip = styled.span`
    position: relative;
    cursor: pointer;

    &:hover span{
        visibility: visible
        
    }
`

export const TooltipText = styled.span`
    font-size: 14px;
    visibility: hidden;
    background-color: ${colors.aqua_haze};
    border: solid 1px ${colors.sherpa_blue};
    color: ${colors.tundora_approx};
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 130%;
    left: -900%;
    line-height: 1.4;
    white-space: normal;

    ::after {
        content: "";
        position: absolute;
        bottom: 99%;
        right: 24px;
        margin-left: -4px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.aqua_haze} transparent;
        @media ${mobileRange} {
          right: 7%;
        }
      }
    
      ::before {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 24px;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.sherpa_blue} transparent;
        @media ${mobileRange} {
          right: 7%;
        }
      }

      @media (max-width:425px) {
        left: -800%;
        width: 150px;
        padding-left: 8px;
        padding-right: 8px
      }

      @media (min-width: 426px) and (max-width:768px) {
        left: -1000%;
        width: 180px;
        padding-left: 8px;
        padding-right: 8px
      }

`
export const ProductTooltipText = styled.span`
    font-size: 14px;
    visibility: hidden;
    background-color: ${colors.aqua_haze};
    border: solid 1px ${colors.sherpa_blue};
    color: ${colors.tundora_approx};
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 180%;
    right: -300%;
    line-height: 1.4;
    white-space: normal;

    ::after {
        content: "";
        position: absolute;
        bottom: 99%;
        right: 24px;
        margin-left: -4px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.aqua_haze} transparent;
        right: 7%;
        @media ${mobileRange} {
          right: 80%;
        }
      }
    
      ::before {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 24px;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${colors.sherpa_blue} transparent;
        right: 7%;
        @media ${mobileRange} {
          right: 80%;
        }
      }

    @media ${mobileRange} {
        left: -100%;
        width: 150px;
    }
`
