import axios from "axios";
import { endpoint } from "../../config/app.config";

export const getMemberCompanyInfoAPi = () => {
  return axios
    .get(`${endpoint}/api/memberContact`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}
