import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { colors } from "../../common/styles/variables";
import SectionWithImage from "../section-with-image"
import * as styled from "./styles";

const renderBody = (para1, para2, list, learn_more_txt, core_elements_heading) => (
  <styled.Content>
    <p>{para1}</p>
    <styled.ListHeading><p>{para2}</p></styled.ListHeading>
    <styled.ListHeading><b>{core_elements_heading}</b></styled.ListHeading>
    <ul>
      {list.map((item, index) => (
        <li key={index} >{item}</li>
      ))}
    </ul>
    <Link to="/code-of-practice">{learn_more_txt}</Link>
  </styled.Content>
);

const CodeOfPractice = forwardRef((props, ref) => {
  const { img_src, img_alt_txt, title, desc1_txt, desc2_txt, desc3_txt, learn_more_txt, core_elements_heading} = props.resources;

  return (
    <SectionWithImage
      ref={ref}
      img_src={img_src}
      img_alt_txt={img_alt_txt}
      title={title}
      body={renderBody(desc1_txt, desc2_txt, desc3_txt, learn_more_txt, core_elements_heading)}
      imageToRight={false}
      bgColor={colors.catskill_gray}
    />
  );
});

CodeOfPractice.propTypes = {
  resources: PropTypes.shape({
    img_src: PropTypes.string,
    img_alt_txt: PropTypes.string,
    title: PropTypes.string,
    desc1_txt: PropTypes.string,
    desc2_txt: PropTypes.string,
    desc3_txt: PropTypes.array
  })
};

export default memo(CodeOfPractice);