import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { tabletRange, mobileRange, ipadProRange } from "../../common/styles/breakpoints";

const NewsContent = styled.div`
    line-height: 1.0;
    & .news-content {
        margin-left: 175px; 
        @media ${mobileRange} {
          margin-left: 0;
          text-align: center;
        }
      @media ${tabletRange} {
        margin-left: 81px;
      }
    }
    & .latest-news-title {
        color: ${colors.sherpa_blue}; 
        font-size: 26px;
        line-height: 1.0;
        padding-bottom: 22px;
        @media ${mobileRange} {
          padding-bottom: 15px;
        }
        @media ${tabletRange} {
          font-size: 24px;
          padding-bottom: 13px;
        }
    }
    & .news-latest-date {
        font-size: 14px;
        line-height: 1;
        color: ${colors.sherpa_blue};
        opacity: 0.5;
        @media ${tabletRange} {
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: -0.16px;
        }
    }
    & .news-description {
        font-size: 16px;
        width: 80%;
        padding-top: 5px;
        margin: 0 0 13px 0;
        line-height: 1.50;
        @media ${mobileRange} {
          width: 100%;
          padding: 6px 10px 14px 10px;
          margin: 0;
        }
        @media ${tabletRange} {
          width: 90%;
          font-size: 14px;
          line-height: 1.64;
          letter-spacing: normal;
          margin-bottom: 20px;
          padding-top: 2px;
        }
        @media ${ipadProRange} {
          width: 95%;
          font-size: 16px;
          line-height: 1.64;
          letter-spacing: normal;
          margin-bottom: 20px;
          padding-top: 2px;
        }
    }
    & .read-more-link a {
        font-size: 14px;
        color: ${colors.bondi_blue};
        text-decoration: none;
        @media ${tabletRange} {
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: -0.21px;
        }
    }
    & .news-content .border-bottom {
      display: none;

      @media ${mobileRange} {
        display: block;
        border: 1px solid ${colors.gumbo};
        width: 50%;
        margin: auto;
        margin-top: 29px;
      }
    }
}`;

export default NewsContent;