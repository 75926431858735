import React, { Component } from "react";
import _get from "lodash/get";
import { newslettercontent, submitText } from "./newsletter.content";
import NewsSubscription from "./styles";
import error from "../../assets/images/Error.png";
import success from "../../assets/images/Success.png";
import submit from "../../assets/images/Submit.png"

class NewsLetter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isEmailValid: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSubmit() {
    const { email } = this.state;

    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email.toLowerCase())) {
      this.setState({"isEmailValid": true});
      this.props.clickSubmit(email);
    } else {
      this.setState({"isEmailValid": false});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriptionSuccess === true && this.props.subscriptionSuccess === false) {
      this.setState({"email": ""});
    }
  }

  handleChange(e) {
    this.setState({"email": e.target.value});
  }

  render() {
    const { isEmailValid } = this.state;
    const { message, subscriptionSuccess, fromSearchpage } = this.props;
    const successMessage = _get(message, "message", "");
    return (
      <NewsSubscription fromsearchpage={fromSearchpage}>
        <div className="news-letter">
          {fromSearchpage ? null
            :
            <div>
              <div className="news-letter-title">
                {newslettercontent.title}
              </div>
              <span className="news-letter-tag">
                {newslettercontent.subTag}
              </span>
            </div>
          }
          <div className="news-letter-email">
            <input type="email" placeholder="Email Address" className="email-input" value={this.state.email} onChange={e => this.handleChange(e) }/>
            <button type="button" className="email-submit" onClick={this.onSubmit}>
              <span>{submitText}</span>
              <img src={submit} alt={submitText} />
            </button>
          </div>
          <div className="email-validation-error">
            {!isEmailValid &&
            <React.Fragment>
              <img src={error} className="error-image" alt='' />
              <div className="error-message">{newslettercontent.emailValidationError}</div>
            </React.Fragment>
            }
          </div>
          { isEmailValid && subscriptionSuccess && <div className="email-submitted-success" >
            <img src={success} className="success-image" alt='' />
            <div className="success-message">{successMessage}</div>
          </div>
          }
          {fromSearchpage ? null
            :
            <div className="news-letter-purpose">
              {newslettercontent.purpose}
            </div>
          }
        </div>
      </NewsSubscription>
    );
  }
}

export default React.memo(NewsLetter);
//successMessage.length > 0 &&