import * as actions from "./actions";

const initialState = {
  manufacturerDetail: {},
  corporatePartnerDetail: {},
  error: null,
  corporatePartner: {}
}

export default function manufacturerReducer(state = initialState, { type, payload }) {
  switch (type) {
  case actions.GET_MANUFACTURER_DETAIL:
    return {
      ...state,
      loading: true,
      error: null
    }
  case actions.GET_SUCCESS_MANUFACTURER_DETAIL:
    return {
      ...state,
      manufacturerDetail: payload.result,
      loading: false
    }
  case actions.GET_FAIL_MANUFACTURER_DETAIL:
    return {
      ...state,
      loading: false,
      error: payload.result
    }
  case actions.SET_MANUFACTURER_DETAIL:
    return {
      ...state,
      manufacturerDetail: {}
    }
  case actions.GET_CORPORATE_PARTNER_DETAIL:
    return {
      ...state
    }
  case actions.GET_SUCCESS_CORPORATE_PARTNER_DETAIL:
    return {
      ...state,
      corporatePartnerDetail: payload.result
    }
  case actions.GET_FAIL_CORPORATE_PARTNER_DETAIL:
    return {
      ...state
    }
  case actions.GET_CORPORATE_PARTNER:
    return {
      ...state
    }
  case actions.GET_CORPORATE_PARTNER_SUCCESS:
    return {
      ...state,
      corporatePartner: payload.result
    }
  case actions.GET_CORPORATE_PARTNER_FAIL:
    return {
      ...state,
      corporatePartner: {}
    }
  default: return state
  }
}
