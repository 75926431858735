import React, { Component } from "react";
import resources from "../../config/content/tourPage";
import { Container } from "react-bootstrap";
import * as styled from "./styles";

/**
 *  TourToolTip Class for showing tour in webite
 * @class TourToolTip
 * @extends Component
 * @returns {HTMLElement} return html
 */
class TourToolTip extends Component {

  state = {
    top: 0,
    left: 0,
    currentIndex: null,
    tipMargin: 0,
    pointerClass: "top"
  }

  componentDidUpdate() {
    const { currentIndex } = this.state;
    const { data } = resources;
    const { index } = this.props;
    const element = document.querySelector(data[index].target);
    if (element) {
      const x = element.getBoundingClientRect();
      if (currentIndex !== index) {
        setTimeout(() => {
          if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
            switch (index) {
            case 0: {
              document.body.style.overflow = "hidden";
              this.setState({
                top: x.top - 40 + "px",
                left: x.left + "px",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "20%"
              });
              break;
            }
            case 1: {
              this.setState({
                top: "17%",
                left: x.left + element.offsetWidth + 40 + "px",
                currentIndex: index,
                pointerClass: "left",
                tipMargin: "5%"
              });
              break;
            }
            case 2: {
              this.setState({
                top: "0px",
                left: x.left + 140 + "px",
                currentIndex: index,
                pointerClass: "bottom",
                tipMargin: "20%"
              })
              break;
            }
            case 3: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: "45%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "13%"
              })
              break;
            }
            case 4: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: "55%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "25%"
              });
              break;
            }
            case 5: {
              window.scrollTo(0, 100);
              this.setState({
                top: "330px",
                left: "51%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "80%"
              });
              break;
            }
            case 6: {
              window.scrollTo(0, 120);
              this.setState({
                top: "460px",
                left: x.left - 430 + "px",
                currentIndex: index,
                pointerClass: "right",
                tipMargin: "8%"
              });
              break;
            }
            case 7: {
              this.setState({
                top: "10px",
                left: "50%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "44%"
              });
              break;
            }
            default: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: x.left + "px",
                currentIndex: index
              });
            }
            }
          }
          else if (window.innerWidth >= 1440) {
            switch (index) {
            case 0: {
              document.body.style.overflow = "hidden";
              this.setState({
                top: x.top - 40 + "px",
                left: x.left + "px",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "20%"
              });
              break;
            }
            case 1: {
              this.setState({
                top: "17%",
                left: x.left + element.offsetWidth + 40 + "px",
                currentIndex: index,
                pointerClass: "left",
                tipMargin: "5%"
              });
              break;
            }
            case 2: {
              this.setState({
                top: "0px",
                left: x.left + 140 + "px",
                currentIndex: index,
                pointerClass: "bottom",
                tipMargin: "20%"
              })
              break;
            }
            case 3: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: "45%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "13%"
              })
              break;
            }
            case 4: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: "55%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "25%"
              });
              break;
            }
            case 5: {
              this.setState({
                top: "330px",
                left: "51%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "80%"
              });
              break;
            }
            case 6: {
              this.setState({
                top: "460px",
                left: x.left - 430 + "px",
                currentIndex: index,
                pointerClass: "right",
                tipMargin: "8%"
              });
              break;
            }
            case 7: {
              this.setState({
                top: "10px",
                left: "50%",
                currentIndex: index,
                pointerClass: "top",
                tipMargin: "44%"
              });
              break;
            }
            default: {
              window.scrollTo(0, 0);
              this.setState({
                top: x.top + "px",
                left: x.left + "px",
                currentIndex: index
              });
            }
            }
          }
        }, 0);
      }
    }
  }

  /**
   *  Function for creaction of markup
   *  @param {text} text string
   *  @returns {html} html return html for text
   */
  createMarkup = (text) => {
    return { __html: text };
  }

  /**
   * Next button click funtion
   * @returns {undefined}
   */
  nextButtonClick = () => {
    const { nextButtonClick } = this.props;
    nextButtonClick();
  }

  /**
   * Skip tour fuction
   * @returns {undefined}
   */
  skipTour = () => {
    const { skipTour } = this.props;
    skipTour();
  }

  render() {
    const { index, tourRun } = this.props;
    const { data, skip_button_logo, next_txt, close_txt, skip_tour_txt } = resources;
    const maxIndex = data ? data.length : 0;
    const { top, left, pointerClass, tipMargin } = this.state;
    const currentPointerClass = `pointer-${pointerClass}`;
    return (
      <styled.MainContainer top={top} left={left} tourRun={tourRun}>
        {pointerClass === "top" ? <styled.ToolPointer tipMargin={tipMargin} leftPosition={true}><div className={currentPointerClass}></div></styled.ToolPointer> : null}
        <styled.ToolTipWrapper>
          {pointerClass === "left" ? <styled.ToolPointer tipMargin={tipMargin} topPosition={true}><div className={currentPointerClass}></div></styled.ToolPointer> : null}
          <styled.ToolTipContainer>
            <styled.CountLabel>
              {index + 1} of {maxIndex}
            </styled.CountLabel>
            <styled.HeadingSection>
              {data[index].heading}
            </styled.HeadingSection>
            <styled.ContentSection dangerouslySetInnerHTML={this.createMarkup(data[index].content)}>
            </styled.ContentSection>
            <styled.Buttons>
              <styled.SkipButton onClick={this.skipTour}>
                <img src={skip_button_logo} alt="skip_button_logo"></img>
                <div>{skip_tour_txt}</div>
              </styled.SkipButton>
              {
                index === maxIndex - 1 ?
                  <styled.NextButton >
                    <button onClick={this.skipTour}>{close_txt}</button>
                  </styled.NextButton>
                  :
                  <styled.NextButton >
                    <button onClick={this.nextButtonClick}>{next_txt}</button>
                  </styled.NextButton>
              }

            </styled.Buttons>
          </styled.ToolTipContainer>
          {pointerClass === "right" ? <styled.ToolPointer tipMargin={tipMargin} topPosition={true}><div className={currentPointerClass}></div></styled.ToolPointer> : null}
        </styled.ToolTipWrapper>
        {pointerClass === "bottom" ? <styled.ToolPointer tipMargin={tipMargin} leftPosition={true}><div className={currentPointerClass}></div></styled.ToolPointer> : null}
      </styled.MainContainer>
    );
  }
}

export default React.memo(TourToolTip);
