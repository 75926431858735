import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { tabletRange, mobileRange, ipadProRange } from "../../common/styles/breakpoints";

const NewsSubscription = styled.div`
  line-height: 1.0;
  margin-left: ${props => props.fromsearchpage ? "-5px" : "0"};
  & .news-letter {
    margin-left: 4px;
    @media ${mobileRange} {
      text-align: center;
    }
    @media ${tabletRange} {
      margin-left: 5px;
    }
  }
  & .news-letter-title {
    color: ${colors.sherpa_blue};
    font-size: 26px;
    line-height: 1.0;
    padding-bottom: 4px;
    @media ${tabletRange} {
      font-size: 24px;
      letter-spacing: -0.31px;
      padding-bottom: 13px;
    }
    @media ${mobileRange} {
      padding-bottom: 0;
    }
  }
  & .news-letter-tag {
    color: ${colors.gumbo};
    font-size: 14px;
    line-height: 1;
    @media ${tabletRange} {
      font-size: 12px;
      letter-spacing: -0.16px;
    }
  }
  & .news-letter-email {
    margin-top: 23px;
    display: flex;
    background-color: ${colors.white};
    border: solid 1px ${colors.regent_gray};
    height: 45px;
    width: ${props => props.fromsearchpage ? "100%" : "74%"};
    border-radius: 3px;
    @media ${mobileRange} {
      width: 100%
    }
    @media ${tabletRange} {
      margin-top: 6px;
      width: 81%;
    }
    & .email-input {
      border: none;
      color: ${colors.black};
      font-size: 17px;
      flex: 2;
      padding: 10px 0 12px 17px;
      &:focus{
        outline: none;
      }
      ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder , :-moz-placeholder  {
        color: ${colors.gray}
        opacity: 0.6;
      }
      @media ${tabletRange} {
        padding: 13px 15px 15px;
        font-size: 14px;
      }
    }
    
    & .email-submit {
      background-color: ${colors.sherpa_blue};
      color: ${colors.white};
      font-size: 17px;
      padding: 0;
      border-color: ${colors.sherpa_blue}
      border: none;
      width: 105px;
      height: 44px;
      border-radius: 3px;
      &:focus{
        outline: none;
      }
      img {
        display: none;
      }
      @media ${tabletRange} {
        width: 45px;
      }
      @media ${tabletRange}{
        width: 55px;
        span {
          display: none;
        }
        img {
          display: initial;
          height: 43px;
          width: 45px;
        }
      }
      @media ${mobileRange} {
        width: 55px;
        span {
          display: none;
        }
        img {
          display: initial;
          height: 43px;
          width: 45px;
        }
      }
      @media ${ipadProRange}{
        width: 55px;
        span {
          display: none;
        }
        img {
          display: initial;
          height: 43px;
          width: 45px;
        }
      }
    }
  }
  & .news-letter-purpose {
      padding-top: 20px;
      font-size: 16px;
      padding-top: 6px;
      color: ${colors.scorpion};
  }
  & .email-validation-error {
    color: ${colors.japonica};
    font-size: 12px;
    padding: 4px 10px 0 20px;
    display: flex;
    align-items: flex-end;
    height: 25px;
  }
  & .email-validation-error .error-image {
    height: 14px;
    width: 17px;
  }
  & .email-validation-error .error-message {
    padding-right: 5px;
    padding-left: 6px;
  }
  & .email-submitted-success {
    color: ${colors.atlantis};
    font-size: 12px;
    padding: 4px 10px 0 20px;
    display: flex;
    align-items: flex-end;
    margin-top: -15px;
  }
  & .email-submitted-success .success-image {
    height: 16px;
    width: 17px;
  }
  & .email-submitted-success .success-message {
    padding-right: 5px;
    padding-left: 6px;
  }
}`;

export default NewsSubscription;