import { all, fork } from "redux-saga/effects";
import { getLatestNewsDataWatcher } from "../containers/home/saga";
import { getContactUsDataWatcher, getMembersDirectorsWatcher, getAdditionalResourcesWatcher, getFaqListWatcher } from "../containers/about/saga";
import { getAutoSuggestDataWatcher } from "../components/auto-suggest-search/saga";
import { getManufacturerDataWatcher } from "../containers/manufacturers/saga";
import { getNewsDataWatcher, getNewsByIdDataWatcher, getPublicationDataWatcher, getPublicationByIDDataWatcher } from "../containers/news/saga";
import { getSearchResultDataWatcher, getManufacturerDetailsDataWatcher, getSourceNameDataWatcher, getSummaryDataWatcher,
  getPiDetailsWatcher, getDataUsingDataCodeWatcher } from "../containers/search/saga";
import { postUserFeedbackDataWatcher, getFeedbackQuestionDataWatcher } from "../components/search-did-this-help/saga";
import { getCareerDataWatcher } from "../containers/careers/saga";
import { getRolesDataWatcher } from "../components/search-popup/saga";
import { getCovidResourceDataWatcher } from "../containers/covid-19/saga";
import { getFellowshipDataWatcher } from "../containers/fellowship/saga";
import { getPodcastDataWatcher } from "../containers/podcast/saga";
import { getMemberCompanyInfoDataWatcher } from "../containers/member-company-info/saga";

export function* rootSaga() {
  // yield all - runs all the actions concurrent
  yield all([
    getLatestNewsDataWatcher,
    getContactUsDataWatcher,
    getMembersDirectorsWatcher,
    getAdditionalResourcesWatcher,
    getAutoSuggestDataWatcher,
    getManufacturerDataWatcher,
    getFaqListWatcher,
    getSearchResultDataWatcher,
    getNewsDataWatcher,
    getNewsByIdDataWatcher,
    getPublicationDataWatcher,
    getPublicationByIDDataWatcher,
    getPiDetailsWatcher,
    postUserFeedbackDataWatcher,
    getManufacturerDetailsDataWatcher,
    getCareerDataWatcher,
    getRolesDataWatcher,
    getFeedbackQuestionDataWatcher,
    getDataUsingDataCodeWatcher,
    getSummaryDataWatcher,
    getCovidResourceDataWatcher,
    getFellowshipDataWatcher,
    getPodcastDataWatcher,
    getMemberCompanyInfoDataWatcher
  ].map(fork));
}
