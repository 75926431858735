import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";
import _get from "lodash/get";
import NewsList from "../../components/news/news";
import NewsLoader from "../../components/news/news-loader";
import NewsDetails from "../../components/news/news-details/news-details";
import NewsLetter from "../../components/news-letter";
import EmailSubscription from "../../components/newspage-email";
import resources from "../../config/content/newsPage";
import withTransition from "../../components/with-transition";
import URLRewriter from "../../components/urlRewriter";
import { endpoint } from "../../config/app.config";
import { NEWS_LETTER_SUBSCRIPTION } from "../home/actions";
import accordianClose from "../../assets/images/accordianClose.png";
import accordianOpen from "../../assets/images/accordianOpen.png";
import store from "../../store/store";
import * as styled from "./style";
import * as actions from "./actions";
import DisplayNewsAndPublication from "../../components/newspage";

const mapStateToProps = state => {
  return {
    newsDetail: state.newsReducer.newsDetail,
    newsLoader: state.newsReducer.loading,
    subscriptionMessage: state.homeReducer.message,
    newsById: state.newsReducer.newsById,
    publicationData: state.newsReducer.publicationData
  };
};

/**
 * News page container
 * @class News
 * @extends {Component}
 * @exports News
 */
class News extends Component {
  state = {
    selectedNewsDate: null,
    showNewsListLoader: true,
    showMobileNews: false,
    subscriptionSuccess: false,
    newsArray: [],
    readMore: false,
    selectedNews: {},
    publicationApiCalled: false,
    publicationPage: false,
    loadMoreClicked: 1
  };

  componentDidMount() {
    const { alinkplaceholder, phactmiurl } = resources;
    const { newsDetail, getNewsList, newsLoader, history, publicationData, getPublication } = this.props;
    const fromPublication = window.location.href.includes("publications");
    if (fromPublication) {
      this.setState({
        publicationPage: true
      })
    }
    if (fromPublication && _isEmpty(publicationData)) {
      if (_isEmpty(publicationData)) {
        getPublication();
        this.setState({
          publicationApiCalled: true
        })
      }
    }
    else if (_isEmpty(newsDetail) && !fromPublication) {
      getNewsList();
    }
    else {
      const newsDataArray = [];
      const newsData = fromPublication ? _get(publicationData, "data") : _get(newsDetail, "data");
      newsData.map((element) => {
        const currentElement = element;
        currentElement.isExpanded = false
        newsDataArray.push(currentElement);
      })
      this.setState({
        newsArray: newsDataArray
      }, () => {
        setTimeout(() => {
          const tags = document.querySelectorAll("#news-details-container a");
          for (const tag of tags) {
            let currenthref = tag.href;
            if (tag && !(currenthref.startsWith("tel") || currenthref.startsWith("mailto"))) {
              if (currenthref.includes(alinkplaceholder) && currenthref.split(alinkplaceholder).length > 0) {
                currenthref = endpoint + currenthref.split(alinkplaceholder)[1];
              }
              else {
                if (!currenthref.includes(phactmiurl)) {
                  currenthref = "/redirect?url=" + currenthref;
                }
              }
            }
            tag.href = currenthref;
            tag.addEventListener("click", this.linkRedirect);
          }
        }, 200)
      }
      )
    }
  }

  componentWillReceiveProps() {
    const tags = document.querySelectorAll("#news-details-container a");
    for (const tag of tags) {
      tag.removeEventListener("click", this.linkRedirect);
    }
  }

  linkRedirect(event) {
    const hrefElement = event.currentTarget.getAttribute("href");
    event.preventDefault();
    window.open(hrefElement);
  }

  componentDidUpdate(prevProps) {
    const { newsDetail, publicationData, getPublication} = this.props;
    const { publicationApiCalled } = this.state;
    const fromPublication = window.location.href.includes("publications");
    const { alinkplaceholder, phactmiurl } = resources;
    const tags = document.querySelectorAll("#news-details-container a");
    if (fromPublication && _isEmpty(publicationData) && !publicationApiCalled) {
      getPublication()
      this.setState({
        publicationApiCalled: true
      })
    }
    if (((prevProps.newsDetail !== this.props.newsDetail) && this.props.newsDetail && !fromPublication)) {
      const newsDataArray = [];
      const newsData = _get(newsDetail, "data");
      newsData.map((element) => {
        const currentElement = element;
        currentElement.isExpanded = false
        newsDataArray.push(currentElement);
      })
      this.setState({
        newsArray: newsDataArray
      }, () => {
        setTimeout(() => {
          const tags = document.querySelectorAll("#news-details-container a");
          for (const tag of tags) {
            let currenthref = tag.href;
            if (tag && !(currenthref.startsWith("tel") || currenthref.startsWith("mailto"))) {
              if (currenthref.includes(alinkplaceholder) && currenthref.split(alinkplaceholder).length > 0) {
                currenthref = endpoint + currenthref.split(alinkplaceholder)[1];
              }
              else {
                if (!currenthref.includes(phactmiurl)) {
                  currenthref = "/redirect?url=" + currenthref;
                }
              }
            }
            tag.href = currenthref;
            tag.addEventListener("click", this.linkRedirect);
          }
        }, 200)
      }
      )
    }
    if ((prevProps.publicationData !== this.props.publicationData) && fromPublication) {
      const newsDataArray = [];
      const newsData = _get(publicationData, "data");
      newsData.map((element) => {
        const currentElement = element;
        currentElement.isExpanded = false
        newsDataArray.push(currentElement);
      })
      this.setState({
        newsArray: newsDataArray
      }, () => {
        setTimeout(() => {
          const tags = document.querySelectorAll("#news-details-container a");
          for (const tag of tags) {
            let currenthref = tag.href;
            if (tag && !(currenthref.startsWith("tel") || currenthref.startsWith("mailto"))) {
              if (currenthref.includes(alinkplaceholder) && currenthref.split(alinkplaceholder).length > 0) {
                currenthref = endpoint + currenthref.split(alinkplaceholder)[1];
              }
              else {
                if (!currenthref.includes(phactmiurl)) {
                  currenthref = "/redirect?url=" + currenthref;
                }
              }
            }
            tag.href = currenthref;
            tag.addEventListener("click", this.linkRedirect);
          }
        }, 200)
      }
      )
    }
    if (prevProps.subscriptionMessage !== this.props.subscriptionMessage) {
      this.setState({ subscriptionSuccess: true });
      this.startTimer();
    }
  }

  /**
   * Timer function
   *
   * @returns {undefined}
   */
  startTimer() {
    setTimeout(() => {
      this.setState({ subscriptionSuccess: false });
    }, 3000);
  }

  /**
   * Submit of email click function
   * @param {string} email email address
   * @returns {undefined}
   */
  submitClick = (email) => {
    const { email_send_type } = resources;
    if (email) {
      store.dispatch({
        type: NEWS_LETTER_SUBSCRIPTION,
        payload: {
          email: email,
          type: email_send_type
        }
      })
    }
  }

  displayNews = () => {
    const { newsArray } = this.state;
    const contentArray = [];
    const { read_more_txt, read_less_txt, accordian_alt_txt } = resources;
    if (newsArray) {
      if (window.innerWidth < 768) {
        newsArray.filter(newsEl => newsEl.active && (new Date(newsEl.expirationDate) >= new Date())).slice(0, this.state.loadMoreClicked * 5).map((element, index) => {
          const [day, month, year] = element.postingDate.split("-");
          contentArray.push(
            <styled.NewsContentSection key={element._id}>
              <styled.NewsContentWrapper>
                <styled.DateWrapper>
                  <styled.Date isExpanded={element.isExpanded}>
                    <styled.DateMonth isExpanded={element.isExpanded}>
                      <div className="date">
                        {day}
                      </div>
                      <div className="month">
                        {month}
                      </div>
                    </styled.DateMonth>
                    <styled.DateYear isExpanded={element.isExpanded}>
                      {year}
                    </styled.DateYear>
                  </styled.Date>
                </styled.DateWrapper>
                <styled.Content isExpanded={element.isExpanded}>
                  <styled.NewsDescriptionWrapper>
                    <styled.NewsTitle>
                      {element.title}
                    </styled.NewsTitle>
                    <styled.NewsDescription content={element.bodyText.length > 1 ? element.bodyText[0] : element.bodyText}
                      dangerouslySetInnerHTML={{ __html: element.bodyText[0] }} isExpanded={element.isExpanded}>
                    </styled.NewsDescription>
                  </styled.NewsDescriptionWrapper>
                  <styled.ExpandCollapseSection>
                    <button type="button" onClick={() => { this.readMoreClick(element) }}>
                      <span>
                        {
                          read_more_txt
                        }
                      </span>
                      {/* <img src={element.isExpanded ? accordianClose : accordianOpen} alt={accordian_alt_txt} /> */}
                    </button>
                  </styled.ExpandCollapseSection>
                </styled.Content>
              </styled.NewsContentWrapper>
              <styled.MobileExpandCollapseSection>
                <button type="button" onClick={() => { this.readMoreClick(element) }}>
                  <span>
                    {
                      read_more_txt
                    }
                  </span>
                  {/* <img src={element.isExpanded ? accordianClose : accordianOpen} alt={accordian_alt_txt} /> */}
                </button>
              </styled.MobileExpandCollapseSection>
            </styled.NewsContentSection>
          )
        })
      } else {
        newsArray.filter(newsEl => newsEl.active && (new Date(newsEl.expirationDate) >= new Date())).map((element, index) => {
          const [day, month, year] = element.postingDate.split("-");
          contentArray.push(
            <styled.NewsContentSection key={element._id}>
              <styled.NewsContentWrapper>
                <styled.DateWrapper>
                  <styled.Date isExpanded={element.isExpanded}>
                    <styled.DateMonth isExpanded={element.isExpanded}>
                      <div className="date">
                        {day}
                      </div>
                      <div className="month">
                        {month}
                      </div>
                    </styled.DateMonth>
                    <styled.DateYear isExpanded={element.isExpanded}>
                      {year}
                    </styled.DateYear>
                  </styled.Date>
                </styled.DateWrapper>
                <styled.Content isExpanded={element.isExpanded}>
                  <styled.NewsDescriptionWrapper>
                    <styled.NewsTitle>
                      {element.title}
                    </styled.NewsTitle>
                    <styled.NewsDescription content={element.bodyText.length > 1 ? element.bodyText[0] : element.bodyText}
                      dangerouslySetInnerHTML={{ __html: element.bodyText[0] }} isExpanded={element.isExpanded}>
                    </styled.NewsDescription>
                  </styled.NewsDescriptionWrapper>
                  <styled.ExpandCollapseSection>
                    <button type="button" onClick={() => { this.readMoreClick(element) }}>
                      <span>
                        {
                          read_more_txt
                        }
                      </span>
                      {/* <img src={element.isExpanded ? accordianClose : accordianOpen} alt={accordian_alt_txt} /> */}
                    </button>
                  </styled.ExpandCollapseSection>
                </styled.Content>
              </styled.NewsContentWrapper>
              <styled.MobileExpandCollapseSection>
                <button type="button" onClick={() => { this.readMoreClick(element) }}>
                  <span>
                    {
                      read_more_txt
                    }
                  </span>
                  {/* <img src={element.isExpanded ? accordianClose : accordianOpen} alt={accordian_alt_txt} /> */}
                </button>
              </styled.MobileExpandCollapseSection>
            </styled.NewsContentSection>
          )
        })
      }
      return (
        contentArray
      )
    }
  }

  readMoreClick = (selectedNewss) => {
    const { newsArray } = this.state;
    const currentNewsArray = newsArray;
    this.setState({
      readMore: true,
      selectedNews: selectedNewss
    });
    if (selectedNewss) {
      if (this.props.fromPublication) {
        this.props.history.replace({pathname: `publications/${selectedNewss.articleTitleId}`})
      }
      else {
        this.props.history.replace({pathname: `news/${selectedNewss.articleTitleId}`})
      }
      this.props.clearNews();
    }
  }

  onClickLoadMore = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        loadMoreClicked: prevState.loadMoreClicked + 1
      }
    })
  }

  render() {
    const { mobile_news_txt, news_heading_main, phactmi_txt, email_heading, email_content, email_purpose, publication_txt } = resources;
    const { newsDetail, newsLoader, subscriptionMessage, newsById } = this.props;
    const { selectedNewsDate, showNewsListLoader, showMobileNews, subscriptionSuccess, newsArray, selectedNews, readMore, publicationPage } = this.state;
    return (
      <styled.NewsPage>
        <Container className="container">
          <styled.NewsHeaderSection>
            <styled.NewsHeaderMain>
              {phactmi_txt}
              <span>{publicationPage ? publication_txt : news_heading_main}</span>
            </styled.NewsHeaderMain>
          </styled.NewsHeaderSection>
          <styled.MainSection id="news-details-container" publicationPage={publicationPage}>
            {newsLoader && <NewsLoader></NewsLoader>}
            {
              !newsLoader &&
              <React.Fragment>
                {
                  !publicationPage &&
                  <styled.NewsEmailWrapper>
                    <styled.NewWrapper>
                      <h2>{email_heading}</h2>
                      <p>{email_content}</p>
                    </styled.NewWrapper>
                    <styled.EmailWrapper>
                      <NewsLetter clickSubmit={this.submitClick} fromSearchpage={true} message={subscriptionMessage}
                        subscriptionSuccess={subscriptionSuccess}></NewsLetter>
                      <p>{email_purpose}</p>
                    </styled.EmailWrapper>
                  </styled.NewsEmailWrapper>
                }
                {
                  !readMore &&
                  <styled.NewsContent>
                    {this.displayNews()}
                    {Math.ceil(this.state.newsArray.filter(newsEl => newsEl.active && (new Date(newsEl.expirationDate) >= new Date())).length/5) !== this.state.loadMoreClicked &&
                      <styled.LoadMoreButton type="button" onClick={this.onClickLoadMore}>Load More</styled.LoadMoreButton>}
                  </styled.NewsContent>
                }
              </React.Fragment>
            }
          </styled.MainSection>
        </Container>
      </styled.NewsPage>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(News));

