import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getManufacturerDetail, getCorporatePartnerDetail, getCorporatepartner } from "./api";

export function* getManufacturerDataWorker({ payload }) {
  try {
    const data = yield call(getManufacturerDetail, payload.companyName);
    yield put({
      type: actions.GET_SUCCESS_MANUFACTURER_DETAIL,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({
      type: actions.GET_FAIL_MANUFACTURER_DETAIL,
      payload: {
        result: e.response ? e.response.data : null
      }
    });
  }
}

export function* getCorporatePartnerDetailDataWorker() {
  try {
    const data = yield call(getCorporatePartnerDetail);
    yield put({
      type: actions.GET_SUCCESS_CORPORATE_PARTNER_DETAIL,
      payload:{
        result: data
      }
    });
  } catch (error) {
    yield put({
      type: actions.GET_FAIL_CORPORATE_PARTNER_DETAIL,
      payload: error
    })
  }
}

export function* getCorporatepartnerDataWorker({payload}) {
  try {
    const data = yield call(getCorporatepartner, payload.companyName);
    yield put({
      type: actions.GET_CORPORATE_PARTNER_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (error) {
    yield put({
      type: actions.GET_CORPORATE_PARTNER_FAIL,
      payload: error
    })
  }
}

export function* getManufacturerDataWatcher() {
  yield takeLatest(actions.GET_MANUFACTURER_DETAIL, getManufacturerDataWorker);
  yield takeLatest(actions.GET_CORPORATE_PARTNER_DETAIL, getCorporatePartnerDetailDataWorker);
  yield takeLatest(actions.GET_CORPORATE_PARTNER, getCorporatepartnerDataWorker);
}

export default {
  getManufacturerDataWatcher
};