/* eslint-disable max-len */
export default {
  "data" : {
    "about": {
      "title": "About Us | phactMI",
      "meta": "At phactMI, we understand that healthcare professionals have a wide range of medical information needs, and we are supportive of the spectrum of drug information providers."
    },
    "home": {
      "title": "Home | phactMI",
      "meta": "phactMI is a collaboration of pharmaceutical industry Medical Information (MI) leaders who are dedicated to support healthcare professionals in their commitment to provide quality patient care."
    },
    "members": {
      "title": "phactMI Member Organizations | phactMI",
      "meta": "phactMI is proud to collaborate with outstanding organizations that support our mission and vision to help shape the future of Medical Information."
    },
    "covid-19": {
      "title": "Support during the COVID-19 crisis | phactMI",
      "meta": "phactMI understands the uncertainty that the current COVID-19 crisis is having with healthcare providers, their patients, and our member companies."
    },
    "search": {
      "title": "Search any product information | phactMI",
      "meta": "Looking for product information or a clinical search?​ Type a query in the search box to reach our full library of product labels & drug information responses."
    },
    "news": {
      "title": "Latest News | phactMI",
      "meta": "Get all the latest and updated news & stay up to date | phactMI"
    },
    "careers": {
      "title": "Careers | phactMI",
      "meta": "Find the latest career openings across phactMI member company Medical Information Departments."
    },
    "terms-conditions": {
      "title": "Terms & Conditions | phactMI",
      "meta": "Please read and review these Terms and Conditions carefully before accessing or using this website. By using this website, you are representing your consent to these Terms and Conditions."
    },
    "privacy-policy": {
      "title": "Privacy Policy | phactMI",
      "meta": "phactMi developed this Privacy Policy to tell you about our practices regarding collection, use, and disclosure of information that you may provide via this website."
    },
    "code-of-practice": {
      "title": "Medical Information Code of Practice | phactMI ",
      "meta": "phactMi facilitates the safe and effective use of medicines and informs healthcare decision-making, which has a positive impact on patient care."
    },
    "redirect": {
      "title": "Page Redirection | phactMI",
      "meta": "You are now leaving phactMI.org and navigating to a page that is not owned by the phactMI organization."
    },
    "fellowship": {
      "title": "Fellowship Directory - phactMI",
      "meta": "Visit our Fellowship page and get all the details of our fellowship members."
    },
    "our-why": {
      "title": "Why Choose US | phactMI",
      "meta": "Choose phactMI because we are the primary source of unbiased medical information developed by healthcare professionals for healthcare professionals"
    },
    "podcast": {
      "title": "Podcast Series | phactMI",
      "meta": "Get the latest podcast series of phactmi"
    },
    "membercompanyinformation": {
      "title": "Member Company Information | phactMI",
      "meta": "Get the member company information that are associated with phactmi"
    }
  }
}
