import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";


export const HomeSearchConatiner = styled.div`
  @media ${mobileRange} { 
    margin: auto;
    width: 93%;
  }
`;

export const SearchHeading = styled.div`
  margin: 35px 0 10px;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: ${colors.prussian_blue};
  @media ${mobileRange} {
    font-size: 16px;
    margin: 10px 0 20px;
    width: 100%;
    display: flex;
    align-self: center;
    line-height: 1.44;
    letter-spacing: -0.21px;
    text-align: left;
  }
  @media ${tabletRange} {
    font-size: 15px;
    margin: 35px 0 11px;
    display: flex;
    align-self: center;
    letter-spacing: -0.2px;
  }
`;
export const SearchContainer = styled.div`
  display: flex;
  max-width: 1050px;
  width: 92%;
  border-radius: 3px;
  z-index: ${props => props.tourRun ? "10001" : "unset"};
  pointer-events: ${props => props.tourRun ? "none" : "auto"};
  position: relative;
  @media ${mobileRange} {
    width: 100%;
    align-self: center;
    border: none;
    background-color: transparent;
  }
  @media ${tabletRange} {
    width: 100%;
  }
`;

export const TooltipSection = styled.div`
  margin-left: 16px;
  @media ${mobileRange} {
    display: block;
    width: 95%;
    padding-top: 10px;
    margin-left: 0;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  @media ${mobileRange} {
    flex-direction: column;
  }
`;

export const SearchWrapper = styled.div`
  display:flex;
  width:100%;
  @media ${mobileRange} {
    flex-direction: column;
    justify-content: none
  }
`;

export const PoweredBySection= styled.div`  
  width: 20%;
  display: block;
  @media ${mobileRange} {
    display:none
  }
  @media ${ipadProRange} {
    margin-left: 0;
  }
  @media ${tabletRange} {
    width: 30%;
  }
`;

export const PoweredBySectionMobile = styled.div`
display: none;
@media ${mobileRange} {
  display:flex
  width: 60%;
}
`;

export const HelpToolTipSection = styled.div`
  margin-top: 15px;
  @media ${mobileRange} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    margin-top: 0;
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.error ? "465px" : "900px"};
  @media ${tabletRange} {
    width: ${props => props.error ? "296px" : "90%"};
  }
  @media ${ipadProRange} {
    width: ${props => props.error ? "calc(50% - 102px)" : "76%"};
  }
`;

export const SearchPillWrapper = styled.div`
  display: flex;
  @media ${mobileRange} {
    display: none;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  width: 435px;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-left: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 16px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    padding: 7px 0;
    display: none;
  }
  @media ${tabletRange} {
    width: 302px;
  }
  @media ${ipadProRange} {
    width: 353px;
  }
`;

export const SearchPillWrapperMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: flex;
  }
`;

export const ToolTipWrapperMobile = styled.div`
  display: flex;
  @media ${mobileRange} {
    display: none;
  }
`;

export const ErrorWrapperMobile = styled.div`
  display: none;
  width: 315px;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-left: 10px;
  align-items: center;
  img {
    width: 16px;
    height: 13px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    padding: 7px 0;
    display: flex;
    margin-left: 6.5%;
    text-align: left;
    padding-top: 0;
    font-size: 12px;
    align-items: center;
  }
  @media ${tabletRange} {
    width: 245px;
  }
`;

export const SearchCard = styled.div`
  @media ${mobileRange} {
    border-radius: 8px;
    border: 1px solid #CFCFCF;
    background: #FFF;
    box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.15);
    padding: 22px 14px;
    display: block;
  }
`;