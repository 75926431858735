/* eslint-disable no-useless-escape */
import React from "react";
import { connect } from "react-redux";
import { endpoint } from "../../config/app.config";
import store from "../../store/store";
import newsLoader from "../news/news-loader";
import withTransition from "../with-transition";
import NewsLoader from "../../components/news/news-loader";
import ErrorPopUp from "../errorPopUp/index";
import resources from "../../config/content/newsPage";
import { NEWS_LETTER_SUBSCRIPTION } from "../../containers/home/actions";
import NewsLetter from "../news-letter";
import { Container } from "react-bootstrap";
import * as styled from "./styles";
import * as actions from "../../containers/news/actions";
import axios from "axios"

const mapStateToProps = state => {
  return {
    newsLoader: state.newsReducer.loading,
    newsById: state.newsReducer.newsById,
    newsFetchfail: state.newsReducer.newsFetchfail,
    subscriptionMessage: state.homeReducer.message,
    publicationById: state.newsReducer.publicationById
  };
};

class DisplayNewsAndPublication extends React.Component {

  state = {
    subscriptionSuccess: false
  }

  componentDidMount() {
    const lastSegment = window.location.href.split("/").pop();
    actions.getNewsById(lastSegment);
    const publicationPage = window.location.href.includes("publications");
    if (publicationPage) {
      store.dispatch({
        type: actions.GET_PUBLICATION_BY_ID,
        payload: {
          title: lastSegment
        }
      })
    }
    else {
      store.dispatch({
        type: actions.GET_NEWS_BY_ID,
        payload: {
          title: lastSegment
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { fromPublications } = this.props;
    if (prevProps.subscriptionMessage !== this.props.subscriptionMessage) {
      this.setState({ subscriptionSuccess: true });
      this.startTimer();
    }
    if (prevProps.newsById !== this.props.newsById) {
      this.updateLinks();
    }
    if (fromPublications && prevProps.publicationById !== this.props.publicationById) {
      this.updateLinks();
    }
  }

  updateLinks = () => {
    const { alinkplaceholder, phactmiurl } = resources;
    setTimeout(() => {
      const tags = document.querySelectorAll("#newsWrapper a");
      for (const tag of tags) {
        let currenthref = tag.href;
        if (tag && !(currenthref.startsWith("tel") || currenthref.startsWith("mailto"))) {
          if (currenthref.includes(alinkplaceholder) && currenthref.split(alinkplaceholder).length > 0) {
            currenthref = endpoint + currenthref.split(alinkplaceholder)[1];
          }
          else {
            if (!currenthref.includes(phactmiurl)) {
              currenthref = "/redirect?url=" + currenthref;
            }
          }
        }
        tag.href = currenthref;
        tag.addEventListener("click", this.linkRedirect);
      }
    }, 1000)
  }

  /**
   * Timer function
   * @returns {undefined}
   */
  startTimer() {
    setTimeout(() => {
      this.setState({ subscriptionSuccess: false });
    }, 3000);
  }

  /**
   *
   * @param {string} link link
   * @returns {undefined}
   */
  articleLinkClicked = (link, data) => {
    if (data.enableTracking) {
      const publicationPage = window.location.href.includes("publications");
      let email = prompt("Please enter your Email to download");
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email == null) {
        return;
      }
      while (email == "" || !emailRegex.test(email)) {
        email = prompt("Please enter your valid Email to download");
        if (email == null) {
          return;
        }
      }
      axios.post(`${endpoint}/api/feedback/emailsave`, {
        articleType: !publicationPage ? "news" : "publications",
        articleId: data._id,
        email
      }).then(data => {
        const { alinkplaceholder, phactmiurl } = resources;
        if (link.includes(alinkplaceholder) && link.split(alinkplaceholder).length > 0) {
          link = endpoint + link.split(alinkplaceholder)[1];
          window.open(link);
        }
        else {
          window.open(`/redirect?url=${link}`);
        }
      })
    } else {
      const { alinkplaceholder, phactmiurl } = resources;
      if (link.includes(alinkplaceholder) && link.split(alinkplaceholder).length > 0) {
        link = endpoint + link.split(alinkplaceholder)[1];
        window.open(link);
      }
      else {
        window.open(`/redirect?url=${link}`);
      }
    }
  }

  /**
   * Back Button Click function
   * @return {undefined}
   */
  backButtonClick = () => {
    const publicationPage = window.location.href.includes("publications");
    if (publicationPage) {
      this.props.history.push({
        pathname: "/publications"
      })
    }
    else {
      this.props.history.push({
        pathname: "/news"
      })
    }
  }

  /**
   * Content funciton
   * @returns {HTMLElement} html
   */
  showContent = () => {
    const backbuttontxt = "< BACK";
    const { newsById, fromPublications, publicationById } = this.props;
    const { read_desc, published_on_txt } = resources;
    const publicationPage = window.location.href.includes("publications");
    const databyId = publicationPage ? publicationById : newsById;
    if (databyId) {
      const [day, month, year] = databyId.postingDate.split("-");
      return (
        <React.Fragment>
          <styled.MainSection className="mainsection">
            <styled.BackButton onClick={this.backButtonClick}>
              {backbuttontxt}
            </styled.BackButton>
            <styled.Title>
              {databyId.title}
            </styled.Title>
            <styled.Published>
              <span>{published_on_txt}</span>
              <span>{
                `${day} ${month} 20${year}`
              } </span>
            </styled.Published>
            {
              databyId.articleWriter && <styled.Author>
                {databyId.articleWriter}
              </styled.Author>
            }
            <styled.Content dangerouslySetInnerHTML={{ __html: databyId.bodyText }} />
            {
              databyId.articleUrl &&
              <styled.ButtonWrapper>
                <button onClick={() => this.articleLinkClicked(databyId.articleUrl, databyId)}>{read_desc}</button>
              </styled.ButtonWrapper>
            }
          </styled.MainSection>
        </React.Fragment>
      )
    }
  }

  /**
   * Submit of email click function
   * @param {string} email email address
   * @returns {undefined}
   */
  submitClick = (email) => {
    const { email_send_type } = resources;
    if (email) {
      store.dispatch({
        type: NEWS_LETTER_SUBSCRIPTION,
        payload: {
          email: email,
          type: email_send_type
        }
      })
    }
  }

  /**
   * show email function
   * @returns {undefined}
   */
  showEmail = () => {
    const { email_heading, email_content, email_purpose } = resources;
    const { subscriptionSuccess } = this.state;
    const { newsLoader, subscriptionMessage } = this.props;
    if (!newsLoader) {
      return (
        <styled.NewsEmailWrapper>
          <styled.NewWrapper>
            <h2>{email_heading}</h2>
            <p>{email_content}</p>
          </styled.NewWrapper>
          <styled.EmailWrapper>
            <NewsLetter clickSubmit={this.submitClick} fromSearchpage={true} message={subscriptionMessage}
              subscriptionSuccess={subscriptionSuccess}></NewsLetter>
            <p>{email_purpose}</p>
          </styled.EmailWrapper>
        </styled.NewsEmailWrapper>
      )
    }
  }

  render() {
    const { newsLoader, newsFetchfail } = this.props;
    const { generalError, phactmi_txt, news_heading_main, publication_txt } = resources;
    const publicationPage = window.location.href.includes("publications");
    if (!newsFetchfail) {
      return (
        <styled.NewsPage>
          <Container className="container">
            <styled.NewsHeaderSection>
              <styled.NewsHeaderMain>
                {phactmi_txt}
                <span>{publicationPage ? publication_txt : news_heading_main}</span>
              </styled.NewsHeaderMain>
            </styled.NewsHeaderSection>
            <styled.ContentWrapper id="newsWrapper">
              {!publicationPage && this.showEmail()}
              {this.showContent()}
            </styled.ContentWrapper>
            {newsLoader && <NewsLoader></NewsLoader>}
          </Container>
        </styled.NewsPage>
      )
    }
    else {
      return (
        <React.Fragment>
          <ErrorPopUp resources={generalError} fromNews={true}></ErrorPopUp>
        </React.Fragment>
      )
    }
  }
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(DisplayNewsAndPublication));