import React, { Component } from "react";
import PropTypes from "prop-types";
import accordianClose from "../../assets/images/accordianClose.png";
import accordianOpen from "../../assets/images/accordianOpen.png";
import { AccordianAnswerCard, AccordianHeader } from "./styles";

/**
 * Accordian Component
 * @class Accordian
 * @extends {Component}
 * @exports Accordian
 */
class Accordian extends Component {

  static propTypes = {
    data: PropTypes.array,
    resources: PropTypes.object
  };

  state= { activeIndex: null };

  createMarkup(text) {
    return {__html: text};
  }

  renderAccordianAnswer(content, index) {
    const { activeIndex } = this.state;
    return (
      <section>
        {content && content.length> 0 && <AccordianAnswerCard isActive={ activeIndex === index }>
          <div className="accordian-content" key={index} dangerouslySetInnerHTML={this.createMarkup(content)}/>
        </AccordianAnswerCard>}
      </section>
    );
  }

  openAccordian(index) {
    this.setState({ activeIndex: this.state.activeIndex === index ? -1 : index });
  }

  render() {
    const { data, resources: { expand_txt, collapse_txt} } = this.props;
    const { activeIndex } = this.state;
    return (
      <section className="accordian-wrapper">
        {data && data.length > 0 && <div>
          {data.map((item, index) => (
            <div key={index} className="accordian-card" onClick={() => this.openAccordian(index)}>
              <AccordianHeader isExpanded={ index === activeIndex }>
                <div className="accordian-question"
                  dangerouslySetInnerHTML={this.createMarkup(item.label)}
                />
                <div>
                  <button type="button">
                    <span>
                      {index === activeIndex ? collapse_txt : expand_txt}
                    </span>
                    <img src={index === activeIndex ? accordianClose : accordianOpen} alt={index === activeIndex ? collapse_txt : expand_txt} />
                  </button>
                </div>
              </AccordianHeader>
              {this.renderAccordianAnswer(item.content, index)}
            </div>
          ))}
        </div>}
      </section>
    );
  }
}

export default Accordian;