import React from "react";
import _size from "lodash/size";
import { withRouter } from "react-router-dom";
import * as styled from "./styles";
import {endpoint} from "../../../config/app.config";

const redirectToManufacturer = (manufacturerId, manufacturerName, history, companyURLTitle) => {
  history.push({
    pathname: `/members/${companyURLTitle}`,
    state: {
      _id: manufacturerId,
      _from: "members"
    }
  })
}
const ManufacturersImageList = React.memo((props) => {
  const { data, heading, subheading, history } = props;
  const alt_txt_placeholder = "Member organization - ";
  if (_size(data) > 0) {
    return (
      <styled.ManufacturersSection>
        <styled.MobileHeading>{subheading}</styled.MobileHeading>
        <styled.ManufacturersHeading>
          {heading}
          <p>
            {subheading}
          </p>
        </styled.ManufacturersHeading>
        <styled.ManufacturersContainer>
          <styled.ManufacturersGrid>
            {data.map((datum, index) => (
              <styled.ManufacturersGridItem key={index} onClick={() => { redirectToManufacturer(datum._id, datum.companyName, history, datum.urlTitle) }} >
                <img src={`${endpoint}/api/assets/images?id=${datum.companyLogo}`} alt={`${alt_txt_placeholder}${datum.companyName}`} />
              </styled.ManufacturersGridItem>
            ))}
          </styled.ManufacturersGrid>
        </styled.ManufacturersContainer>
      </styled.ManufacturersSection>
    );
  } else {
    return null;
  }
});
export default withRouter(ManufacturersImageList);