import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL } from "../../common/styles/breakpoints";

export const PoweredByContainer = styled.div`
  display: flex;
  padding: 8px 0 0;
  margin-left: 20px;
  @media ${tabletRange} {
    padding: 6px 0 10px;
    margin-left: ${props => props.fromsearchpage ? "0" : "10px"};
  }
  @media ${minLaptop} {
    padding: 8px 0 12px;
  }
  @media ${ipadProRange} {
    padding: 6px 0 12px;
  }
  @media ${mobileRange} {
    padding: 0;
  }
  @media ${minLaptopL} {
    padding: 8px 0 12px;
  }
`;

export const CloseButton = styled.span`
  display: none;
  width: 15px;
  height: 15px;
  background: ${colors.white};
  position: absolute;
  top: 20px;
  right: 25px;
  background: ${props => (`url(${props.bgImage}) no-repeat 0 0`)};
  opacity: 0.5;
  cursor: pointer;
  @media ${tabletRange} {
    display: block;
    top: 20px;
    right: 18px;
  }
  @media ${mobileRange} {
    display: block;
    top: 15px;
    right: 10px;
  } 
  @media ${ipadProRange} {
    display: block;
  } 
`;

export const Tooltip = styled.div`
  width: 380px;
  background-color: ${colors.aqua_haze};
  border: solid 1px ${colors.sherpa_blue};
  border-radius: 4px;
  padding: 36px 18px 25px 22px;
  position: absolute;
  z-index: 1;
  top: ${ props => ( props.top + 20 + "px" )};
  left: ${props => props.fromsearchpage ? "850px" : "820px"};
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.46;
  text-align: left;
  color: ${colors.tundora_approx};
  display: none;
  ul {
    padding-left: 15px;
    margin: 0;
  }
  ul li {
    padding-bottom: 22px;
    @media ${mobileRange} {
      padding-bottom: 15px;
    }
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 99%;
    left: 40%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.aqua_haze} transparent;
    @media ${mobileRange} {
     left: 30%;
    }
    @media ${tabletRange} {
      left: 65%;
    }
    @media ${ipadProRange} {
      left: 90%;
    }
  }

  ::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 40%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.sherpa_blue} transparent;
    @media ${mobileRange} {
      left: 30%;
    }
    @media ${tabletRange} {
      left: 65%;
    }
    @media ${ipadProRange} {
      left: 90%;
    }
  }
  @media ${tabletRange} {
    width: 375px;
    padding: 35px 59px 24px 25px;
    left: ${props => props.fromsearchpage ? "50%" : "53%"};
  }
  @media ${ipadProRange} {
    width: 375px;
    padding: 35px 59px 24px 25px;
    left: ${props => props.fromsearchpage ? "50%" : "53%"};
  }
  @media ${mobileRange} {
    max-width: 350px;
    padding: 31px 41px 18px 28px;
    top: ${ props => ( props.top + 20 + "px")};
    left: 4%;
  }
`;

export const PoweredByButton = styled.div`
  margin-left: 8px;
  justify-content: center;
  display: flex;
  font-family: ArialRoundedMT;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.sherpa_blue};
  align-items: center;
  :hover ${Tooltip} {
    display: block;
  }
  @media ${mobileRange} {
    width: 56px;
    height: 26px;
    border-radius: 50px;
    font-size: 11px;
  }
`;

export const EntellectText = styled.div`
  cursor: default;
  img{
    height: 14px;
    @media ${tabletRange} {
      height: 10px;
    }
  }
`;

export const PoweredByText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: ${colors.gray};  
  padding-top: 2px;
  @media ${mobileRange} {
    padding-right: 8px;
  }
  @media ${tabletRange} {
    font-size: 10px;
    padding-top: 6px;
  }
`;
