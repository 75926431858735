import styled from "styled-components";

import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL } from "../../../common/styles/breakpoints";
import { spl } from "../../../components/search-accordian/spl";
import { srd } from "../../../components/search-accordian/srd";


export const ResultsContainer = styled.div`

`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
  display: flex;
  gap: 10px;
  align-items: center;

  .expand-close-icon {
    display: none;

    @media ${mobileRange} {
      display: initial;
      width: 14px;
      height: 7px;
    }
  }

  span {
    @media ${mobileRange} {
      flex-basis: 90%;
    }
  }

  @media ${mobileRange} {
    font-size: 20px;
  }

  @media ${tabletRange} {
    font-size: 20px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media ${mobileRange} {
    display: ${props => props.showSrdResult ? "block" : "none"}
  }
`;

export const DataAccordion = styled.div`
  padding: 20px;
  background-color: ${colors.catskill_white_gray};;
  border: 1px solid ${colors.catskill_white_gray};;
  border-radius: 8px;
  border-bottom: 1px solid ${colors.bondi_blue};

  @media ${mobileRange} {
    padding: 16px;
  }

  @media ${tabletRange} {
    padding: 16px;
  }
`;

export const DataAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.isExpanded ? colors.bondi_blue: colors.sherpa_blue};

    img {
        width: 28px;
        margin-left: 10px;
        vertical-align: baseline;

        @media ${mobileRange} {
          width: 24px;
        }

        @media ${tabletRange} {
          width: 24px;
        }
    }
  }
`;

export const DataAccordionTitleText = styled.h4`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  @media ${mobileRange} {
    font-size: 16px;
  }

  @media ${tabletRange} {
    font-size: 16px;
  }
`;

export const DataAccordionTitleContent = styled.p`
  font-size: 16px;
  color: #434545;
  margin: 16px 0 0;

  @media ${mobileRange} {
    font-size: 14px;
    margin: 12px 0 0;
  }

  @media ${tabletRange} {
    font-size: 14px;
  }
`;

export const DataAccordionTitleSubContent =styled.p`
  font-size: 14px;
  color: #434545;
  margin: 16px 0;

  span {
    color: ${colors.bondi_blue};
    cursor: pointer;
  }

  @media ${mobileRange} {
    margin: 12px 0;
  }
`;

export const AccordionExpandContent = styled.div`
  font-size: 16px;
  overflow-x: scroll;
  ${props => props.usage === "srd" ? srd : spl };

  @media ${mobileRange} {
    font-size: 14px;
  }
`;

export const DataAccordionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media ${tabletRange} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${mobileRange} {
    flex-direction: column;
    align-items: flex-start;
  }
  
  button {
    background: ${colors.catskill_white_gray};
    z-index: unset;
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bondi_blue};
    border: none;
    outline: none;
    border-bottom: 1px solid ${colors.bondi_blue};

    @media ${mobileRange} {
      font-size: 12px;
    }
  }
`;

export const SRDWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  @media ${mobileRange} {
    flex-direction: column;
  }

  .mir-desc-patient {
    width: 100%;
  }

  .mir-title {
    display: none;
    @media ${mobileRange} {
      display: block;
      font-size: 22px;
      color: ${colors.sherpa_blue};
      margin-top: 20px;
      text-align: center;
    }
  }
`;

export const NoResultWrapper = styled.div`
  color: ${colors.sherpa_blue};
  font-weight: 300;
  @media ${mobileRange} {
    text-align: left;
  }

  .member-link-icon, .member-contact-icon {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  p {
    font-size: 18px;
    color: #434545;
    line-height: 1.5;

    @media ${tabletRange} {
      font-size: 16px
    }
  }

  .patient-mir-info{
    border: 1px solid #F5F9FA;
    border-bottom: 1px solid #00B59A;
    padding: 18px;
    background-color: #F5F9FA;
    border-radius: 8px;
    margin-bottom: 14px;

    .member-link {
      font-size: 16px;
      color: #00B59A;
      margin-bottom: 10px;
      word-break: break-all;

      @media ${mobileRange} {
        font-size: 14px;
      }
    }

    .member-name-details {
      color: #004251
      margin-bottom: 8px;
      font-size: 18px;

      @media ${tabletRange} {
        font-size: 16px
      }

      @media ${mobileRange} {
        font-size: 16px
      }
    }

    p {
      color: inherit;
    }
  }

  a {
    color: ${colors.bondi_green};
  }

  .patient-mir-msg-title {
    font-size: 16px;
    margin-bottom: 18px;
    color: #434545;
  }
`;

export const ZeroResult = styled.div`
  display: flex;
  font-size: 20px;
  padding-bottom: 16px;
  @media ${mobileRange} {
    justify-content: center;
    padding: 20px 0;
    font-size: 20px;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 0;
  }  
  .zero_txt {
    font-size: 20px;
    font-weight: 500;
    padding-left: 5px;
    text-decoration: underline;
`;

export const MedicalLinkWrapper = styled.div`
  color: ${colors.bondi_green};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const NonMemberMessage = styled.p`
  margin: 0;
  padding: 0 0 30px;
  @media ${tabletRange} {
    padding: 0 0 20px;
  }
`;

export const ImgDesc = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  @media ${mobileRange} {
    flex-direction: column;
    margin: 0;
    text-align: center;
  }
`;

export const ImgDescContainer = styled.div`
  width: 396px;
  @media ${mobileRange} {
    width: auto;
  }
`;

export const ImgDesc2 = styled.p`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.26px;
  margin-bottom: 0;
  width: 100%
  @media ${mobileRange} {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 14px;
  }
  @media ${tabletRange} {
    margin-right: 40px;
    font-size: 16px;
    padding: 10px 0;
  }
`;

export const NoProductMessage = styled.p`
  margin-top: 70px;
  font-size: 20px;
  @media ${mobileRange} {
    margin: 60px 0 30px;
  }
`;