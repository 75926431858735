import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const ListTitle = styled.div`
  line-height: 1.28;
  letter-spacing: -0.33px;
  text-align: left;
  color: ${colors.sherpa_blue};
  font-size: 22px
  padding-top: 20px
  @media ${mobileRange}{
    font-size: 22px
    padding-top: 25px
  }

  @media ${tabletRange}{
    padding-top: 25px
  }
`;

export const ListSubTitle = styled.div`
  padding-top: 2px;
`;
