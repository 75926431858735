import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const NewsPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 85px;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: 70px;
  }
  @media ${ipadProRange} {
    padding-top: 80px;
  }
`;

export const NewsHeaderSection = styled.h1`
  margin: 0 0 80px 82px;
  @media ${mobileRange} {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 70px 0 0 0;
  }
  @media ${tabletRange} {
    margin: 0 81px 82px;
  }
  @media ${mobileRange} {
    margin: 70px 0 90px;
  }
`;

export const NewsHeaderMain = styled.div`
    font-size: 30px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.96px;
    color: ${colors.sherpa_blue};
    @media ${mobileRange} {
      text-align: center;
      font-size: 30px;
    }
    @media ${tabletRange} {
      font-size: 30px;
    }
    span {
      font-weight: normal;
    }
`;

export const MainSection = styled.div`
  width: 100%;
  min-height: 500px;
  background: ${colors.white};
  color: ${colors.sherpa_blue};
  font-size: 20px;
	padding: 30px 77px 77px;
  @media ${mobileRange} {
    padding: 30px 35px 50px;
  }
  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${tabletRange} {
    padding: 30px 45px 60px;
  }
  @media ${ipadProRange} {
    padding-top: 80px;
  }
`;

export const Title = styled.div`
	font-size: 32px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: -0.42px;
	text-align: left;
	color: ${colors.sherpa_blue};
  @media ${tabletRange} {
    font-size: 24px;
  }
  @media ${mobileRange} {
    font-size: 24px;
  }
`;

export const BackButton = styled.div`
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: left;
	color: ${colors.bondi_blue};
  cursor: pointer;
  margin-bottom: 30px;
`;

export const Published = styled.div`
  font-size: 16px;
  color: ${colors.gryish_black};
  padding: 10px 0 0;
  @media ${tabletRange} {
    font-size: 15px;
  }
`;

export const Author = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.gryish_black};
  padding: 20px 0 30px;
  @media ${tabletRange} {
    font-size: 15px;
  }
`;

export const Content = styled.div`
  color: ${colors.dove_gray};
  font-size: 15px;
  line-height: 1.53;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 57px;
  button {
    padding: 13px 25px;
    border-radius: 3px;
    background: ${colors.bondi_green};
    color: ${colors.white};
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    @media ${tabletRange} {
      font-size: 16px;
    }
    @media ${mobileRange} {
      font-size: 16px;
      padding: 13px 15px;
    }
  }
`;

export const EmailWrapper = styled.div`
  width: 48%;
  p {
    color: ${colors.scorpion_dark};
    font-size: 18px;
    text-align: left;
  }
  @media ${mobileRange} {
    width: 100%;
  }
`;

export const NewsEmailWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.light_darkish_blue};
  align-items: center;
  padding: 40px 68px 0;
  justify-content: center;
  @media ${mobileRange} {
    padding: 30px 35px;
    flex-direction: column;
  }
`;

export const NewWrapper = styled.div`
  width: 370px;
  color: ${colors.sherpa_blue};
  font-size: 30px;
  letter-spacing: -0.39px;
  text-align: left;
  padding-right: 50px;
  p {
    letter-spacing: -0.22px;
    opacity: 0.5;
    font-size: 17px;
  }
  @media ${mobileRange} {
    width: 100%;
  }
  h2 {
    font-size: 1.75rem;
  }
`;

export const ContentWrapper = styled.div`
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
`;
