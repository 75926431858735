import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";
import tick from "../../assets/images/tick.png";

export const MainContainer = styled.div`
  label {
    line-height: 2.5;
    font-size: 16px;
    @media ${ipadProRange} {
      font-size: 16px;
    }
  }
  input[type="radio"] {
    border: 0; 
    clip: rect(0 0 0 0); 
    height: 0;
    margin: -1px; 
    overflow: hidden; 
    padding: 0; 
    position: absolute; 
    width: 0;
    }
  input[type="radio"] + span {
    display: block;
    cursor: pointer;
    }
  input[type="radio"] + span:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 19px;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid ${colors.nobel_grey};
    padding: 0.25rem;
    background-color: transparent;
    background-clip: content-box;
    @media ${ipadProRange} {
      margin-right: 6px;
      }
    }
  input[type="radio"]:checked + span:before {
    background-color: ${colors.downy};
    border-color: ${colors.downy};
    }
  input[type="radio"]:focus + span:after {
    line-height: 1;
    vertical-align: -0.125em;
   }
}
`;

export const SearchPopContainer = styled.div`
  background: ${colors.white};
  border-radius: 16px;
  display: flex;
  width: 100%;
  padding: 0 0 30px 32px;
  text-align: left;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  @media ${tabletRange} {
    flex-direction: column;
    padding: 60px 40px 0;
  }
  @media${mobileRange} {
    flex-direction: column;
    padding: 0 15px 30px 15px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 58%;
    margin: 0 5%;
  }
`;

export const Heading1Section = styled.div`
  font-size: 20px;
  color: ${colors.sherpa_blue}
  padding-right: 30px;
  img{ 
    width: 25px;
  }
  .image{
    width: 25px;
    height: 25px; 
    margin-right: 16px;
  }
  @media ${tabletRange} {
    width: 100%;
    padding: 0;
    font-size: 24px;
  }
  @media ${mobileRange} {
  width: 100%;
  font-size: 16px;
  padding: 0;
  display:flex;
  align-items: flex-start;
  flex-direction: column
  }
  @media ${ipadProRange} {
    padding-right: 0;
    font-size: 24px;
  }
`;

export const RoleSection = styled.div`
font-size: 18px;
text-align: left;
color: ${colors.scorpion};
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: 1fr;
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  label {
    width: 33%;
  }
}
@media (max-width: 425px) {
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

@media (min-width: 426px) and (max-width: 767px) {
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

@media (min-width: 620px) and (max-width: 1024px) {
  width: 100%;
  font-size: 16px;
}
@media ${mobileRange} {
  margin-bottom: 10px;
  }
`;

export const Heading2Section = styled.div`
  background: #E2F7E8;
  border-radius: 0 16px 0 0;
  width: 49%;
  padding: 0 48px 0 30px;
  font-size: 20px;
  input[type="radio"] + span:before {
    width: 23px;
    height: 23px;
    margin-right: 16px;
    }
  @media ${mobileRange} {
    width: 100%
    padding: 25px 0 0; 
    font-size: 18px;
  }
  @media ${tabletRange} {
    width: 100%;
    padding: 30px 0;
    font-size: 24px;
  }
  @media ${ipadProRange} {
    padding-left: 25px;
    font-size: 24px;
    padding: 0 35px 0 30px;
  }
`;

export const HealthcareButton = styled.button`
  border-radius: 10px;
  background-color: ${colors.sherpa_blue};
  padding: 0;
  border: none;
  height: 48px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: ${colors.white};
  font-size: 16px;
  img{
    width: 14px;
    height: 10px;
    margin: 4px;
  }

  @media ${tabletRange} {
    height: 48px; 
  }

  @media ${mobileRange} {
      height: 48px;
      margin-right: 10px;
      margin-bottom: 10px;  
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 14%;
  }
`;

export const PatientButton = styled.button`
  border-radius: 10px;
  background-color: ${colors.sherpa_blue};
  padding: 0;
  border: none;
  height: 48px;
  width: 240px;
  margin-right: 20px;
  color: ${colors.white};
  font-size: 16px;
  img{
    width: 14px;
    height: 10px;
    margin: 4px;
  }
  @media ${mobileRange} {
      height: 44px;
      min-width: 128px; 
      margin-right: 10px;
      margin-bottom: 10px;   
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 14%;
  }
`;

export const NotHealthcareButton = styled.button`
  border-radius: 10px;
  background-color: ${colors.reduced_sky};
  padding: 0;
  border: none;
  height: 77px;
  width: 252px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: ${colors.sherpa_blue};
  font-size: 16px;
  span{
    border-bottom: 1px solid ${colors.sherpa_blue};
  }
  @media ${mobileRange} {
      min-height: 40px;
      min-width: 102px;    
  }
  @media (min-width: 475px) and (max-width: 1023px) {
    margin-left: 20px;  
    width: 420px;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 14%;
  }
`;

export const Captcha = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;   
  @media ${mobileRange} {
      margin-top: 25px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 70%;
  }
`;

export const Recaptcha = styled.div`
  @media ${mobileRange} {
    transform: scale(0.92);
    transform-origin: 0;
    width: 273px;
    }
`;

export const ReadioHeader = styled.div`
  font-size: 16px;

  p:first-child {
    margin-bottom: 40px;
  }

  @media (max-width: 474px) {
    display:none;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: 90%;
  }
`;

export const ReadioHeaderMobile = styled.div`
  display: flex;
  @media (max-width: 474px) {
    flex-direction: column
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: 90%;
  }
`;

export const NotProfessional = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  height: 100%;

  .patient-caregiver-img {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 425px) {
      justify-content: space-between;
      width: 70%;
    }

    img {
      height: 160px;
      width: 164px;
      margin-bottom: 8px;
    }
  
    span {
      font-size: 16px;
      line-height: 24px;
      color: #004251;
      text-align: center;
    }
  }

  // @media (min-width: 485px) and (max-width: 1023px) {
  //   width: 70%;
  //   flex-direction: row;
  //   justify-content: flex-start;
  // }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: 90%;
  }

  @media (min-width:320px) and (max-width: 1023px) {
    align-items: center;
  }
`;

export const ImageSection = styled.div`
  width: 20px;
  height: 25px; 
  margin-right: 16px;
`;

export const CustomRoleSection = styled.div`
  width:100%
  input[type=text] {
    width: 276px;
    height: 45px;
    padding: 0;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${colors.sherpa_blue};  
    padding: 12px;
    font-size: 18px;
    color: ${colors.sherpa_blue};
    margin-right:20px;
  }
  input:disabled {
    opacity: 0.5;
  }
  input[type=text]:focus {
    border: 1px solid ${colors.sherpa_blue};  
  }
  input[type=text]::-ms-clear {
    display: none;
  }

  @media (max-width: 767px){
    input[type=text]{
      width: 265px;
      margin-right:10px;
    }
  }
  @media ${ipadProRange} {
    font-size: 17px;
  }
`;

export const SubmitButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 65%;
  }
`;

export const PopUpWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${mobileRange} {
    flex-direction: column;
  }
  @media ${tabletRange} {
    flex-direction: column;
  }
`;

export const Disclaimer = styled.div`
  font-size: 14px;
  text-align: left;
  color: ${colors.solid_grey};
  line-height: 1.43;
  padding: 30px 0 10px;
  
  .disclaimer-highlight {
    color: red;
  }
  
  p {
    margin-bottom: 5px;
  }
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    display: none;
  }
`;

export const DisclaimerIpadMobile = styled.div`
  display: none;
  font-size: 11px;
  text-align: left;
  color: ${colors.solid_grey};
  line-height: 1.43;
  border-bottom: 1px solid ${colors.light_dove_grey};
  p {
    margin-bottom: 5px;
  }
  @media ${mobileRange} {
    display: block;
    padding: 10px 0 10px;
    border-bottom: none;
  }
  @media ${tabletRange} {
    display: block;
    border-bottom: none;
    padding: 25px 0 10px;
  }
`;

export const Description = styled.div`
  display: flex;
  color: ${colors.dove_gray};
  p {
    text-align: left;
    margin: 0;
    font-size: 17px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: 50px !important;
    }
  }
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    display: none;
  }
`;

export const DescriptionWrapper = styled.div`
  padding-top: 10px;
  font-size: 17px;
  p {
    margin: 0;
    padding-bottom: 10px;
  }
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    display: none;
  }
`;

export const DescriptionWrapperMobile = styled.div`
  padding-top: 10px;
  display: none;
  p {
    margin: 0;
    padding-bottom: 10px;
  }
  @media ${mobileRange} {
    display: block;
    font-size: 12px;
  }
  @media ${tabletRange} {
    display: block;
    font-size: 15px;
  }
`;
export const RememberActions = styled.div`
  display: flex;
  align-items: center;
  /* The custom checkbox container */
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -27px;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray};
    border-radius: 3px;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    border-color: ${colors.bondi_blue};
    background: ${colors.bondi_blue} url(${tick}) no-repeat center center;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
`;

export const RememberMe = styled.input.attrs({ type: "checkbox" })`
  height: 25px;
  width: 25px;
  &:checked {
    background: ${colors.bondi_blue} !important;
  }
`;

export const RememberMeText = styled.div`
  padding-top: 3px;
  font-size: 12px;
  color: ${colors.gray};
`;

export const DescriptionMobileIpad = styled.div`
  display: none;
  color: ${colors.dove_gray};
  p {
    text-align: left;
    margin: 0;
    font-size: 15px;
    @media ${mobileRange} {
      font-size: 12px;
    }
  }
  @media ${mobileRange} {
    display: flex;
  }
  @media ${tabletRange} {
    display: flex;
  }
`;

export const DisclaimerContentWrapper = styled.div`
  padding-right: 33px;
`;

export const BorderLine = styled.div`
  border: 1px solid ${colors.light_dove_grey}

  @media ${mobileRange}{
    margin-top: 20px
  }

  @media ${tabletRange}{
    margin-top: 20px
  }
`;

export const HeathCareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
`

export const HealthcareHeadingMobile = styled.div`
  display: flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom: 12.5px
  @media (min-width:475px){
    display:none
  }
`

export const HealthcareImageMobile = styled.div`
  display: inline-block;
  img{
    width: 82.7px;
    height: 94.6px;
  }
 
`

export const HealthcareImage = styled.div`
  @media (max-width: 474px){
    display: none
  }
`

export const SubmitButton = styled.button`
  border-radius: 10px;
  background-color: ${colors.bondi_blue};
  padding: 0;
  border: none;
  height: 44px;
  width: 128px;
  margin: 20px 20px 20px 0;
  color: ${colors.white};
  font-size: 16px;
  :disabled, [disabled]{
     cursor: not-allowed;
     opacity: 0.5;
  }

  @media (max-width: 768px){
    margin: 10px 10px 10px 0;
  }
`