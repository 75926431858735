export const GET_CONTACTUS_DETAILS = "GET_CONTACTUS_DETAILS";
export const GET_SUCCESS_CONTACTUS_DETAILS = "GET_SUCCESS_CONTACTUS_DETAILS";
export const GET_FAIL_CONTACTUS_DETAILS = "GET_FAIL_CONTACTUS_DETAILS";
export const GET_MANUFACTURERS_DETAILS = "GET_MANUFACTURERS_DETAILS";
export const GET_SUCCESS_MANUFACTURERS_DETAILS = "GET_SUCCESS_MANUFACTURERS_DETAILS";
export const GET_FAIL_MANUFACTURERS_DETAILS = "GET_FAIL_MANUFACTURERS_DETAILS";
export const GET_MEMBERS_DIRECTORS = "GET_MEMBERS_DIRECTORS";
export const GET_MEMBERS_DIRECTORS_SUCCESS = "GET_MEMBERS_DIRECTORS_SUCCESS";
export const GET_MEMBERS_DIRECTORS_FAIL = "GET_MEMBERS_DIRECTORS_FAIL";
export const GET_ADDITIONAL_RESOURCES = "GET_ADDITIONAL_RESOURCES";
export const GET_ADDITIONAL_RESOURCES_SUCCESS = "GET_ADDITIONAL_RESOURCES_SUCCESS";
export const GET_ADDITIONAL_RESOURCES_FAIL = "GET_ADDITIONAL_RESOURCES_FAIL";
export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAIL = "GET_FAQ_FAIL";

export function getContactUsDetails() {
  return {
    type: GET_CONTACTUS_DETAILS
  }
}

export function getManufacturersDetails() {
  return {
    type: GET_MANUFACTURERS_DETAILS
  }
}

export function getMembersDirectors() {
  return {
    type: GET_MEMBERS_DIRECTORS
  }
}

export function getAdditionalResources() {
  return {
    type: GET_ADDITIONAL_RESOURCES
  }
}

export function getFaq() {
  return {
    type: GET_FAQ
  }
}
