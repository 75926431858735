import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { isMobileOnly, isAndroid, isIOS, isMobile, isTablet,
  isBrowser, isDesktop, isChrome,
  isChromium, isEdge, isSafari, isFirefox, isOpera,
  isIE, isEdgeChromium, isYandex, isSamsungBrowser, isMobileSafari } from "react-device-detect";
import { altText, footerLinks, footerText, footerCopyRightText, footerSocialMediaLogo } from "../../config/content/homePage";
import footerlogo from "../../assets/images/Phactmi_Footer_Logo.png";
import companylogo from "../../assets/images/company_logo.png";
import { FooterContent, FooterDisclaimer, CopyRightText, SocialMediaWrapper,
  SocialMediaWrapperMobile, ContactUs, SocialMediaSection, SeparatorMobile, DownloadAppSectionMobile } from "./styles";
import playStoreWhiteIcon from "../../assets/images/play-store-white-icon.svg";
import appStoreWhiteIcon from "../../assets/images/app-store-white-icon.svg";

/**
   * Scroll handler
   *
   * @returns {undefined}
   */
const goToTop = () => {
  window.scrollTo({
    top: 0
  });
}


/**
 * Footer Component
 *
 * @constant Footer
 * @exports Footer
 * @returns {HTMLElement} element
 */
const Footer = () => {

  const footerLinkClick = (item) => {
    const {link} = item;
    const externalclicks_txt = "externellinkclicks";
    const selectedDegree = JSON.parse(sessionStorage.getItem("selectedDegree"));
    let role = selectedDegree ? selectedDegree.role : "NE";
    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"]; // PT -> patient
    if (role.toLowerCase().includes("patient") || role.toLowerCase().includes("caregiver") || role.toLowerCase().includes("consumer")) {
      role = "PT"
    }
    window.dataLayer = window.dataLayer || [];
    if (GADegrees.includes(role)) {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": role,
          "eventAction": externalclicks_txt,
          "eventLabel": link,
          "cd1": role,
          "cd2": externalclicks_txt,
          "cd3": link,
          "cd8": "Null"
        }
      })
    }
    else {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": role,
          "eventAction": externalclicks_txt,
          "eventLabel": link,
          "cd1": "Other",
          "cd2": externalclicks_txt,
          "cd3": link,
          "cd8": role
        }
      })
    }
    window.open(link, "__blank");
  }

  const onOpenAppLink = (url) => {
    window.open(url, "_blank")
  }

  const isBrowserFlag = (isChrome || isChromium
    || isEdgeChromium || isSafari || isMobileSafari || isOpera || isEdge || isEdgeChromium || isFirefox || isYandex || isIE || isSamsungBrowser)

  return (
    <React.Fragment>
      <FooterContent className="footer-container">
        <Container>
          <div className="footer-content">
            <div className="footer-logo">
              <Link to="/home">
                <img src={footerlogo} className="footer-image" alt={altText} onClick={goToTop}/>
              </Link>
              <CopyRightText>&copy; {new Date().getFullYear()} {footerCopyRightText}</CopyRightText>
            </div>
            <SocialMediaWrapperMobile>
              {
                footerSocialMediaLogo.map((item, key) => {
                  return (
                    <span onClick={() => footerLinkClick(item)} key={key}>
                      <a target="_blank" href={item.link} key={key}><img src={item.logo} alt={item.logo_txt}></img></a>
                    </span>
                  );
                })
              }
            </SocialMediaWrapperMobile>
            <SeparatorMobile />
            <div className="footer-links">
              <p>QUICK LINKS</p>
              {footerLinks.map((item, key) =>
                <div key={key} className="footer-each-link">
                  {item.link ? <Link to={{ pathname: item.link === "/contact" ? "/about/#contact-us" : item.link,
                    state: { fromFooter: true }}}>{item.label}</Link> : <a target="_blank" rel="noopener noreferrer" href={item.href}>{item.label}</a>}
                </div>
              )}
            </div>
            <ContactUs>
              <p className="contact-us-header">CONTACT US</p>
              <a href={"mailto:admin@phactmi.org"}>admin@phactmi.org</a>
              <p className="address">5931 NW 1st Place<br/>Gainesville, FL 32607</p>
              <div>
                <p>Membership Information</p>
                <a href={"mailto:membership@phactmi.org"}>membership@phactmi.org</a>
              </div>
            </ContactUs>
            {((isMobile && isBrowserFlag) || (isTablet && isBrowserFlag)) &&<>
              <SeparatorMobile />
              <DownloadAppSectionMobile>
                <p>DOWNLOAD THE APP</p>
                <div className="download-app">
                  <div className="android-link" onClick={() => onOpenAppLink("https://play.google.com/store/apps/details?id=com.phactmi")}>
                    <img src={playStoreWhiteIcon} alt="play-store" />
                    <p>Google Play</p>
                  </div>
                  <div className="ios-link" onClick={() => onOpenAppLink("https://apps.apple.com/us/app/phactmi/id6468638050")}>
                    <img src={appStoreWhiteIcon} alt="app-store" />
                    <p>App Store</p>
                  </div>
                </div>
              </DownloadAppSectionMobile></>}
            <SocialMediaSection>
              <p>FOLLOW US</p>
              <SocialMediaWrapper>
                {
                  footerSocialMediaLogo.map((item, key) => {
                    return (
                      <span onClick={() => footerLinkClick(item)} key={key}>
                        <a target="_blank" href={item.link} key={key}><img src={item.logo} alt={item.logo_txt}></img></a>
                      </span>
                    );
                  })
                }
              </SocialMediaWrapper>
            </SocialMediaSection>
          </div>
          <FooterDisclaimer>
            <div className="footer-text">
              {footerText}
            </div>
          </FooterDisclaimer>
        </Container>
      </FooterContent>
    </React.Fragment>
  )
};

Footer.propTypes = {
  altText : PropTypes.string,
  footerText: PropTypes.string,
  footerCopyRightText : PropTypes.string,
  footerLinks : PropTypes.arrayOf(PropTypes.object),
  footerlogo : PropTypes.string,
  company_logo : PropTypes.string
}
export default Footer;
