export const GET_MEMBER_COMPANY_INFO = "GET_MEMBER_COMPANY_INFO";
export const GET_MEMBER_COMPANY_INFO_SUCCESS = "GET_MEMBER_COMPANY_INFO_SUCCESS";
export const GET_MEMBER_COMPANY_INFO_FAIL = "GET_MEMBER_COMPANY_INFO_FAIL";


export function getMemberCompanyInfo() {
  return {
    type: GET_MEMBER_COMPANY_INFO
  }
}
