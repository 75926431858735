import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const TabContainer = styled.div`
    margin-top: ${props => props.isLoading || props.showPiResults ? "0px" : 0 }
`;

export const TabDetails = styled.div`
    margin-left: 5px;
`;

export const TabTitleContainer = styled.div`
    display: flex;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.33px;
    color: ${colors.sherpa_blue};
    background: ${colors.sky_dark_blue};
    padding: 16px 0;
    cursor: pointer;
    width: 50%;
    justify-content: center;
    box-shadow: 0px 4px 3px -3px rgba(0,0,0,.35);
    border: 1px solid ${colors.light_sky_grey};
    &:last-child{
        @media ${mobileRange} {
            margin-right: 0
        } 
    }

    @media ${mobileRange} {
        width: 100%;
        border-radius: 8px 8px 0 0;
        border-bottom: 0;
        font-size: 15px;
        margin-right: 9px
    }

    .tab-title { 
        @media ${mobileRange} {
            width: 100%;
            height: 100%;
            padding: 12px 10px;
            background: #FFF;
            border: 1px solid #a0b2b8;
            border-radius: 8px 8px 0 0;
            border-bottom: 0
        }
    }

    &.active-tab {
        z-index: 10;
        border: none;
        border-top: 2px solid ${colors.bondi_blue};
        color: ${colors.bondi_blue};
        box-shadow: 0 7px 0 ${colors.white}, 0 1px 6px rgba(0,0,0,.35);
        background: ${colors.white};
        @media ${mobileRange} {
            border: 0;
            border-bottom: 0;
            box-shadow: none;
            padding: 0;
            align-items: center;
            z-index: auto;

            .tab-title{ 
                margin-bottom: -4px;
                border: 2px solid #00B59A;
                border-bottom: 0
            }
        }
    }
    &.inactive-tab {
        z-index: 10;
        @media ${mobileRange} {
            background-color: ${colors.white};
            color: #004250;
            box-shadow: none;
            padding: 0;
            align-items: center;
            z-index: auto;
            font-weight: 500;
        }
    }
    @media ${tabletRange} {
        font-size: 22px;
    }
`;

export const TabHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    @media ${mobileRange} {
        font-size: 15px;
        padding: 0 16px;
        text-align: center;
        border-bottom: 2px solid #00B59A;
    }
`;

export const Tab = styled.div`
    display: ${props => props.isActive ? "block" : "none"};
`;
