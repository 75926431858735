import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop } from "../../common/styles/breakpoints";

export const BackToTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 150px;
  right: 110px;
  z-index: 10;
  button {
    display: ${props => props.isVisible ? "initial" : "none"}
    outline: none;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 3px;
    background-color: ${colors.sherpa_blue};
    padding: 0;
    opacity: 0.6;

    @media ${mobileRange} {
      border-radius: 22px;
    }
  }
  @media ${ipadProRange} {
    display: ${props => props.isVisible && props.shallRender ? "initial" : "none"};
    bottom: 120px;
    right: 20px;
  }
  @media ${tabletRange} {
    bottom: 110px;
    right: 18px;
  }
  @media ${mobileRange} {
    bottom: 400px;
    right: 18px;
  }
  @media ${minLaptop} {
    display: ${props => props.isVisible && props.shallRender ? "initial" : "none"};
  }
`;
