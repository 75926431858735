import React, { memo, forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { TabBar, Tab, TabTitleContainer, TabTitle, TabDetails, TabHeader } from "../../components/tabs";
import { colors } from "../../common/styles/variables";
import SectionWithImage from "../section-with-image"
import * as styled from "./styles";

const renderBody = (desc_txt, desc_txt1) => (
  <>
    <styled.Content>
      {desc_txt}
    </styled.Content>
    <styled.ContentNew>
      {desc_txt1}
    </styled.ContentNew>
    <styled.ContentNew>
      <strong>Contact phactMI today at</strong> <a href={"mailto:membership@phactmi.org"}>membership@phactmi.org</a>
    </styled.ContentNew>
  </>
);

const AboutSection = forwardRef((props, ref) => {

  const { onAnchorClick, onClickLeftArrow, onClickRightArrow, showLeftArrow, showRightArrow, activeAnchor } = props;
  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setIsActive] = useState(activeAnchor);
  const [ activeTabIndex, setActiveTabIndex ] = useState(0)
  const navElm = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    setIsActive(parseInt(activeAnchor));
  });

  const handleScroll = () => {
    const margin = window.innerWidth > 767 ? 59 : -30;
    const headerHeight = window.innerWidth > 1024 ? 90 : window.innerWidth > 767 ? 89 : 74;
    const navTop = navElm.current.offsetParent.offsetTop + margin - headerHeight;
    if (window.pageYOffset > navTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }

  const anchorClick = (e, item) => {
    onAnchorClick(e, item.url, parseInt(e.target.id.slice(1)));
  }

  const setActiveTab = (e, tabIndex, count, item ) => {
    window.scrollTo(0, 0);
    if (activeTabIndex === tabIndex) return;
    setActiveTabIndex(tabIndex)
    onAnchorClick(e, item.url, tabIndex, "#"+item.href);
  }

  const { img_src, img_alt_txt, title, desc_txt, nav_list, arrow_section, desc_txt1 } = props.resources;
  const { left_arrow_active, left_arrow_Inactive, right_arrow_active, right_arrow_Inactive, left_arrow_alt, right_arrow_alt} = arrow_section;
  return (
    <styled.Maincontainer ref={ref}>
      <styled.NavbarWrapper className={isSticky? "sticky" : "non-sticky"}>
        <styled.Navbar id="items-list">
          <div>
            {nav_list.map((item, index) => (
              <a className={isActive === index ? "active" : ""}
                id={`a${index}`}
                key={index}
                href={`#${item.href}`}
                onClick={(e) => anchorClick(e, item)}>
                {item.label}
              </a>
            ))}
          </div>
        </styled.Navbar>
        <styled.GradientWrapper>
          <styled.LeftGradient>
            {showLeftArrow ?
              <img src={left_arrow_active} alt={left_arrow_alt} onClick={onClickLeftArrow} />
              :
              <img src={left_arrow_Inactive} alt={left_arrow_alt} />
            }
          </styled.LeftGradient>
          <styled.RightGradient>
            {showRightArrow ?
              <img src={right_arrow_active} alt={right_arrow_alt} onClick={onClickRightArrow} />
              :
              <img src={right_arrow_Inactive} alt={right_arrow_alt}/>
            }
          </styled.RightGradient>
        </styled.GradientWrapper>
      </styled.NavbarWrapper>
      <styled.MobileTabs>
        <TabHeader>
          {
            nav_list.map((tab, index) => (
              <TabTitleContainer
                setActiveTab={(e) => setActiveTab(e, index, "", tab)}
                key={index} index={index} activeIndex={activeTabIndex}>
                <TabTitle>{tab.label}</TabTitle>
              </TabTitleContainer>
            ))
          }
        </TabHeader>
      </styled.MobileTabs>
      <SectionWithImage
        ref={navElm}
        img_src={img_src}
        img_alt_txt={img_alt_txt}
        title={title}
        body={renderBody(desc_txt, desc_txt1)}
        imageToRight={false}
        bgColor={colors.white}
        hideImageMobile={true}
      />
    </styled.Maincontainer>
  );
});

AboutSection.propTypes = {
  resources: PropTypes.shape({
    img_src: PropTypes.string,
    img_alt_txt: PropTypes.string,
    title: PropTypes.string,
    desc_txt: PropTypes.string
  })
};

export default memo(AboutSection);