import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getMemberCompanyInfoAPi } from "./api";

export function* getMemberCompanyInfoDataWorker() {
  try {
    const data = yield call(getMemberCompanyInfoAPi);
    yield put({
      type: actions.GET_MEMBER_COMPANY_INFO_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_MEMBER_COMPANY_INFO_FAIL });
  }
}

export function* getMemberCompanyInfoDataWatcher() {
  yield takeLatest(actions.GET_MEMBER_COMPANY_INFO, getMemberCompanyInfoDataWorker);
}

export default {
  getMemberCompanyInfoDataWatcher
};
