import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const PodcastPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 30px;
  font-weight: 300;
  padding-top: 83px;
  margin-left: 80px;
  span {
    font-weight: normal;
  }
  @media ${tabletRange} {
    margin-left: 87px;
  }
  @media ${mobileRange} {
    text-align: center;
    margin-left: 0;
  }
`;

export const MainContainer = styled.div`
  background-color: ${colors.white};
  margin-top: 80px;
  color: ${colors.scorpion};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding: 70px 70px 150px;
  @media ${tabletRange} {
    margin-top: 82px
    padding: 40px 40px 131px;
  }
  @media ${mobileRange} {
    margin-top: 91px;
    display: block;
    padding: 22px 22px 136px;
  }
  @media ${ipadProRange} {
    margin-top: 70px;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: ${colors.sherpa_blue};
  @media ${tabletRange} {
    font-size: 24px;
  }
  @media ${mobileRange} {
    font-size: 22px;
  }
`;

export const EposideWrapper = styled.div`
  color: ${colors.sherpa_blue};
  margin-top: 40px;
  @media ${mobileRange} {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
`;

export const EposindeNumber = styled.span`
  background-color: ${colors.light_transparent_green};
  padding: 10px 22px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 1.44;
  border-left: 4px solid rgba(0,66,81,0.7);
  @media ${mobileRange} {
    width: 115px;
  }
`;

export const EposideTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-left: 14px;
  @media ${tabletRange} {
    font-size: 18px;
  }
  @media ${mobileRange} {
    margin-left: 0;
    font-size: 16px;
    padding: 10px 0 0;
  }
`;

export const PodcastUrlWrapper = styled.div`
  margin-top: 30px;
`;

export const Content = styled.div`
  margin-top: 20px;
  font-size: 15px;
  color: ${colors.dove_gray};
  .title {
    font-size: 24px;
    color: ${colors.sherpa_blue};
    font-weight: bold;
    @media ${tabletRange} {
      font-size: 22px;
    }
    @media ${mobileRange} {
      font-size: 20px;
    }
  }
  .subtitle {
    font-size: 22px;
    font-weight: bold;
    color: ${colors.sherpa_blue};
    @media ${mobileRange} {
      font-size: 20px;
    }
  }
  a {
    color: ${colors.sherpa_blue};
    text-decoration: underline;
  }
  ul {
    list-style: none;
    padding-left: 10px;
  }
  ul li::before {
      color: ${colors.sherpa_blue};
      font-weight: bold;
      display: inline-block; 
      width: 8px;
      height: 8px;
      margin-left: -1em;
      content: "• ";
  }
  ul li {
    margin-bottom: 20px;
  }
  span {
    padding-left: 10px;
  }
`;

export const ReferenceWrapper = styled.ul`
  color: ${colors.dove_gray};
  font-size: 15px;
  list-style: none;
  padding-left: 10px;
  margin-bottom: 0;
`;

export const ReferenceList = styled.li`
  padding-top: 20px;
  &::before {
    color: ${colors.sherpa_blue};
    font-weight: bold;
    display: inline-block; 
    width: 8px;
    height: 8px;
    margin-left: -1em;
    content: "• ";
  }
  span {
    padding-left: 10px;
    a {
      color: ${colors.sherpa_blue};
      text-decoration: underline;
    }
  }
`;

export const ReferenceTitle = styled.div`
  font-size: 22px;
  color: ${colors.sherpa_blue};
  padding: 30px 0 10px;
  @media ${tabletRange} {
    font-size: 20px;
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  @media ${mobileRange} {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const AuthorContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
  max-width: 50%;
  color: ${colors.dove_gray};
  font-size: 15px;
  @media ${tabletRange} {
    min-width: 240px;
    max-width: 100%;
  }
  @media ${mobileRange} {
    max-width: 100%;
    padding-right: 0;
  }
  .authorIcon {
    padding-right: 15px;
    @media ${mobileRange} {
      padding-right: 10px;
    }
    img {
      width: 80px;
      @media ${mobileRange} {
        width: 60px;
      }
   }
  }
  .authorTitle {
    color: ${colors.sherpa_blue};
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Border = styled.div`
  height: 1px;
  background-color: ${colors.light_gray};
  margin-top: 35px;
`;
