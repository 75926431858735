import React from "react";
import * as styled from "./style";

const InformativePopups = (props) => {
  return (
    <styled.InformativePopupContent>
      <p>Step {props.stepNumber} of {props.totalSteps}</p>
      <h6>
        {props.children}
      </h6>
    </styled.InformativePopupContent>
  )
}

export default InformativePopups