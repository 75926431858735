import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BacktoTopImage from "../../assets/images/back_to_top.png";
import { backToTopAltTxt } from "../../config/content/homePage";
import * as styled from "./style";

/**
 * Scroll page to top
 * @returns {undefined}
 */

const scrollPageToTop = () => {
  const verticalStart = window.pageYOffset;
  const verticalStop = 0;
  const scrollDistance = verticalStop > verticalStart ? verticalStop - verticalStart : verticalStart - verticalStop;
  if (scrollDistance < 100) {
    window.scrollTo(0, verticalStop);
    return;
  }
  const scrollValue = Math.round(scrollDistance / 25);
  const verticalVal = verticalStop > verticalStart ? verticalStart + scrollValue : verticalStart - scrollValue;
  scrollWithWait(verticalVal, verticalStop, scrollValue);
}

const scrollWithWait = (verticalVal, verticalStop, scrollValue) => {
  setTimeout(() => {
    window.scrollTo(0, verticalVal);
    verticalVal -= scrollValue;
    if (verticalVal < verticalStop) {
      verticalVal = verticalStop;
      window.scrollTo(0, 0);
    }
    if (verticalVal > 0) {
      scrollWithWait(verticalVal, verticalStop, scrollValue);
    }
  }, 8);
}

/**
 * BackToTop Component
 * @constant BacktoTopImage
 * @param {object} props props-object
 * @exports BackToTopComponent
 * @returns {HTMLElement} element
 */
const BackToTopComponent = (props) => {
  const { piDetails, location } = props;
  const buttonShallRender = !piDetails && !(location.pathname.includes("manufacturers") && (location.pathname.split("/").length > 3)) && location.pathname !== "/dashboard";
  const [ isVisible, setIsVisible ] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll);

    return () => window.removeEventListener("scroll", onPageScroll);
  }, []);

  /**
   * Window scroll handler
   * @returns {undefined}
   */
  const onPageScroll = () => {
    if (window.innerWidth < 766 ) {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
    else {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }
  return (
    <styled.BackToTopContainer isVisible={isVisible} shallRender={buttonShallRender}>
      <button type="button" onClick={scrollPageToTop}>
        <img src={BacktoTopImage} alt={backToTopAltTxt} />
      </button>
    </styled.BackToTopContainer>
  );
};

const BackToTop = connect(state => ({
  piDetails: state.searchResultReducer.piDetails
})
)(BackToTopComponent);

export default withRouter(memo(BackToTop));
