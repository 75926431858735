import styled from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../../common/styles/breakpoints";

export const NewsDetails = styled.div`
    padding: 25px 25px 20px;
    width: 806px;
    min-height: auto;
    @media ${mobileRange} {
        width: 100%;
        font-size: 30px;
        padding: 20px 25px 20px;
    }
    @media ${tabletRange} {
        padding: 10px 25px 20px;
        width: 100%;
    }
    @media ${ipadProRange} {
        padding: 30px 25px 20px;
        width: 100%;
    }
`;

export const NewsHeading = styled.div`
  width: auto;
  height: auto;
  font-size: 26px;
  line-height: 1.38;
  letter-spacing: 0.39px;
  text-align: left;
  color: ${colors.sherpa_blue}; 
  @media ${mobileRange} {
      font-size: 21px;
  }
`;

export const NewsSubHeading = styled.div`
  width: auto;
  height: 32px;
  font-size: 16px;
  line-height: 1.61;
  letter-spacing: -0.23px;
  margin: 10px 0 10px 0;
  color: ${colors.bondi_blue};
    @media ${mobileRange} {
        font-size: 21px;
    }
    @media ${ipadProRange} {
        margin-bottom: 5px;
    }
`;

export const NewsDescription = styled.div`
    font-size: 15px;
    line-height: 1.88;
    letter-spacing: 0.1px;
    @media ${mobileRange} {
        margin: 0 20px 30px 0;
        a {
            word-wrap: break-word;
        }
    }
    @media ${tabletRange} {
        margin: 0 10px 3px 0;
    }
    @media ${ipadProRange} {
        margin: 0 10px 17px 0;
    }
`;

export const margin = styled.div`
    ::before {
        content: " ";
        width: 100%;
        height: 1px;
        margin: 50px 0;
        background: ${colors.silver};
        display: ${props => props.displayTopBorder ? "block" : "none"};
    }
    @media ${mobileRange} {
      display: block;
      height: 6px;
      width: auto;
      height:auto;
      background-color: ${colors.white};
    }
`;
