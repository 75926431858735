import styled from "styled-components";
import { BackToTopContainer } from "../../components/back-to-top/style";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";
import { colors } from "../../common/styles/variables";

export const MainContainer = styled.div`
  background: ${props => (`url(${props.bgImageArr.desktop}) no-repeat center top`)};
  background-position-y: -10px;
  background-size: contain;
  overflow: hidden;
  &.page-login, &.page-forgot-password, &.page-change-password {
    .footer-container {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
  &.page-dashboard {
    background-size: 100% 370px;
    ${BackToTopContainer} {
      display: none;
    }
  }
  @media ${mobileRange} {
    background: ${props => (`url(${props.bgImageArr.mobile}) no-repeat center top`)};
    overflow-x: hidden;
    &.page-covid-19 {
      background-size: 100% 300px;
      background-position-y: 65px;
    }
  }
  @media ${tabletRange} {
    background: ${props => (`url(${props.bgImageArr.tablet}) no-repeat center top`)};
    background-size: 100%;
    overflow-x: hidden;
    &.page-covid-19 {
      background-size: 100%;
    }
  }
  .overlay_image {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
  }
`;

export const OverLayWrapper = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${colors.black};
  opacity: 0.3;
  z-index: 1001;
  top: 0;
  bottom: 0;
`;
