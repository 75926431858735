import styled from "styled-components";

import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange } from "../../../common/styles/breakpoints";
import { spl } from "../../../components/search-accordian/spl";
import { srd } from "../../../components/search-accordian/srd";

export const ResultsContainer = styled.div`
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
  display: flex;
  gap: 10px;
  align-items: center;

  .expand-close-icon {
    display: none;

    @media ${mobileRange} {
      display: initial;
      width: 14px;
      height: 7px;
    }
  }

  span {
    @media ${mobileRange} {
      flex-basis: 90%;
    }
  }

  @media ${mobileRange} {
    font-size: 20px;
  }

  @media ${tabletRange} {
    font-size: 20px;
  }
`;

export const ProductResultDropdown = styled.div`
  position: relative;
`;

export const ProductDropdownSelectedValue = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  border: 1px solid ${colors.alto_gray};
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 14px;
    height: 7px;
  }

  @media ${mobileRange} {
    padding: 12px;
    font-size: 14px;
  }

  @media ${tabletRange} {
    padding: 14px;
    font-size: 16px;
  }
`;

export const ProductResultDropdownList = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: ${colors.white};
  padding: 0 16px;
  border: 1px solid ${colors.alto_gray};
  border-radius: 9px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
  margin-top: 8px;

  @media ${mobileRange} {
    padding: 0 12px;
  }

  @media ${tabletRange} {
    padding: 0 14px;
    font-size: 16px;
  }

  p {
    cursor: pointer;
    margin: 0;
    padding: 16px 0;
    border-bottom: 1px solid #E1E1E1;

    &:hover {
      background-color: ${colors.light_grey}
    }

    &:last-child {
      border: none;
    }
    @media ${mobileRange} {
      font-size: 14px;
    }
  }
`;

export const SelectedPIData = styled.div`
  overflow-x: scroll;
  border: 1px solid ${colors.bondi_blue};
  border-radius: 8px;
  padding: 20px;
  margin-top: 16px;

  @media ${mobileRange} {
    padding: 16px;
  }

  @media ${tabletRange} {
    padding: 16px;
  }
`;

export const PIDataTitle = styled.h4`
  font-size: 20px;
  color: #434545;
  cursor: pointer;

  @media ${mobileRange} {
    font-size: 18px;
  }

  @media ${tabletRange} {
    font-size: 18px;
  }
`;

export const PIDataSubTitle = styled.p`
  font-size: 14px;
  margin-bottom: 12px;

  span {
    cursor: pointer;
  }

  .source-link {
    color: ${colors.bondi_blue}
  }
`

export const DataAccordionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media ${tabletRange} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  @media ${mobileRange} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  `;

export const ViewPiDocument = styled.button`
  background: ${colors.white};
  z-index: unset;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.bondi_blue};
  border: none;
  outline: none;
  border-bottom: 1px solid ${colors.bondi_blue};

  &:focus {
    outline: none;
  }
`;

export const DataContainer = styled.div`
 
  @media ${mobileRange} {
    display: ${props => props.showPIResult ? "block" : "none"}
  }
`;

export const AccordionExpandContent = styled.div`
  font-size: 16px;

  @media ${mobileRange} {
    font-size: 14px;
  }
`;

export const PISectionsDetails = styled.div`
  ${props => props.usage === "srd" ? srd : spl };
`;

export const PISection = styled.div`
   border-bottom: 1px solid #E1E1E1;
   padding: 12px 0 8px;

   &:last-child {
    border: none;
   }
`;

export const PISectionTitleText = styled.h4`
  font-size: 16px;
  font-style: normal !important;
  margin: 0 !important;
`;

export const PISectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.isExpanded ? colors.bondi_blue: colors.sherpa_blue};

    img {
        width: 24px;
        margin-left: 10px;
        vertical-align: baseline;

        @media ${mobileRange} {
          width: 20px;
        }

        @media ${tabletRange} {
          width: 20px;
        }
    }
  }
`;