import styled from "styled-components";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const MainContainer = styled.div`
  opacity: ${props => props.containerOpacity};
  transition: opacity 1.5s;
  padding-top: 85px;
  @media ${mobileRange} {
    padding-top: 0;
  }
  @media ${tabletRange} {
    padding-top: 70px;
  }
`;
