import React from "react";
import News from "../news";

class Publication extends React.Component {
  render () {
    return (
      <News fromPublication={true} history={this.props.history}></News>
    )
  }
}

export default Publication;