import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import * as styled from "./styles";

const SectionWithImage = forwardRef((props, ref) => {
  const { img_src, img_alt_txt, title, body, bgColor, imageToRight, hideImageMobile } = props;
  return (
    <styled.MainContainer ref={ref} bgColor={bgColor} imageToRight={imageToRight} >
      <styled.LeftContent hideImageMobile={hideImageMobile}>
        <styled.ImageWrapper imageToRight={imageToRight} >
          <img alt={img_alt_txt} src={img_src} />
        </styled.ImageWrapper>
      </styled.LeftContent>
      <styled.RightContent imageToRight={imageToRight} >
        <div>
          <styled.H2>
            {title}
          </styled.H2>
          {body}
        </div>
      </styled.RightContent>
    </styled.MainContainer>
  );
});
SectionWithImage.propTypes = {
  img_src: PropTypes.string,
  img_alt_txt: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.object,
  bgColor: PropTypes.string,
  imageToRight: PropTypes.bool,
  hideImageMobile: PropTypes.bool
};

export default memo(SectionWithImage);