import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { tabletRange, mobileRange } from "../../common/styles/breakpoints";

export const FaqContainer = styled.div`
    padding: 65px 190px 65px;
    background: ${colors.white};
    @media ${tabletRange} {
        padding: 47px 80px;
    }
    @media ${mobileRange} {
        padding: 42px 20px;
    }
    .accordian-card {
        border-bottom: 1px solid ${colors.geyser};
        padding: 20px 0;
        &:last-child {
            border: none;
        }
        .accordian-content {
            color: ${colors.dove_gray};
            font-size: 16px;
            line-height: 1.94;
            text-align: left;
            padding-right: 110px;
            margin-top: 10px;
            a {
                color: ${colors.bondi_blue};
            }
            &:first-child {
                margin-top: 0;
            }
            @media ${tabletRange} {
                line-height: 1.56;
            }
            @media ${mobileRange} {
                font-size: 16px;
                line-height: 1.44;
                margin-top: 12px;
                padding-right: 32px;
            }
        }
        .accordian-question {
            font-size: 16px;
            line-height: 1.94;
            text-align: left;
            padding-right: 8%;
            color: ${colors.sherpa_blue};
            @media ${tabletRange} {
                line-height: 1.35;
            }
            @media ${mobileRange} {
                font-size: 16px;
                line-height: 1.35;
            }
        }
    }
    .accordian-wrapper {
        @media ${mobileRange} {
            padding: 0;
        }
    }
`;

export const FaqTitle = styled.h2`
    color: ${colors.sherpa_blue};
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: -0.39px;
    text-align: left;
    margin: 0;
    @media ${mobileRange} {
        font-size: 22px;
        line-height: 1.23;
        letter-spacing: -0.34px;
        text-align: center;
    }
`;


