import axios from "axios";
import { endpoint } from "../../config/app.config";

export async function getContactDetails() {
  return axios
    .get(`${endpoint}/api/contact`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getManufacturersDetails() {
  return axios
    .get(`${endpoint}/api/manufacturer`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const getMembersDirectors = () => {
  return axios
    .get(`${endpoint}/api/employee`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAdditionalResources = () => {
  return axios
    .get(`${endpoint}/api/resource`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const getFaqData = () => {
  return axios
    .get(`${endpoint}/api/faq`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}
