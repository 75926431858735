import axios from "axios";
import { endpoint } from "../../config/app.config";

export async function getNewsDetail() {
  return axios
    .get(`${endpoint}/api/news`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getNewsById(title) {
  return axios
    .get(`${endpoint}/api/news/${title}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}

export async function getPublication() {
  return axios
    .get(`${endpoint}/api/publication`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}

export async function getPublicationById(title) {
  return axios
    .get(`${endpoint}/api/publication/${title}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}