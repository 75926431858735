import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getContactDetails, getManufacturersDetails, getMembersDirectors, getAdditionalResources, getFaqData } from "./api";


function* getMembersDirectorsWorker({ payload }) {
  try {
    const data = yield call(getMembersDirectors, payload);

    yield put({
      type: actions.GET_MEMBERS_DIRECTORS_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_MEMBERS_DIRECTORS_FAIL });
  }
}

function* getAdditionalResourcesWorker({ payload }) {
  try {
    const data = yield call(getAdditionalResources, payload);

    yield put({
      type: actions.GET_ADDITIONAL_RESOURCES_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_ADDITIONAL_RESOURCES_FAIL });
  }
}


export function* getContactUsDataWorker() {
  try {
    const data = yield call(getContactDetails);
    yield put({
      type: actions.GET_SUCCESS_CONTACTUS_DETAILS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_FAIL_CONTACTUS_DETAILS });
  }
}

export function* getManufacturersDataWorker() {
  try {
    const data = yield call(getManufacturersDetails);
    yield put({
      type: actions.GET_SUCCESS_MANUFACTURERS_DETAILS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: actions.GET_FAIL_MANUFACTURERS_DETAILS });
  }
}

export function* getFaqWorker() {
  try {
    const data = yield call(getFaqData);
    yield put({
      type: actions.GET_FAQ_SUCCESS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({
      type: actions.GET_FAQ_FAIL,
      payload: {
        error : new Error("get faq error")
      }
    })
  }
}

export function* getContactUsDataWatcher() {
  yield takeLatest(actions.GET_CONTACTUS_DETAILS, getContactUsDataWorker);
  yield takeLatest(actions.GET_MANUFACTURERS_DETAILS, getManufacturersDataWorker);
}

export function* getMembersDirectorsWatcher() {
  yield takeLatest(actions.GET_MEMBERS_DIRECTORS, getMembersDirectorsWorker);
}

export function* getAdditionalResourcesWatcher() {
  yield takeLatest(actions.GET_ADDITIONAL_RESOURCES, getAdditionalResourcesWorker);
}

export function* getFaqListWatcher() {
  yield takeLatest(actions.GET_FAQ, getFaqWorker);
}

export default {
  getContactUsDataWatcher,
  getMembersDirectorsWatcher,
  getAdditionalResourcesWatcher,
  getFaqListWatcher
};
