import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AboutContainer, H2, P, LINK } from "./styles";
import AboutPhactMiImg from "../../assets/images/Code_of_practice.png";

const createMarkup = (text) => {
  return {__html: text};
}

const CodeOfPracticeSection = React.memo(({ content, aboutLink }) => {
  const { img_alt_txt, title, body, link } = content;
  return (
    <AboutContainer>
      <Row>
        <Col>
          <div className="about-image">
            <img alt={img_alt_txt} src={AboutPhactMiImg} />
          </div>
        </Col>
        <Col>
          <H2 dangerouslySetInnerHTML={createMarkup(title)} />
          <P>
            {body}
          </P>
          <LINK><Link to={{pathname: aboutLink}}>{link}</Link></LINK>
        </Col>
      </Row>
    </AboutContainer>
  );
});
export default CodeOfPracticeSection;