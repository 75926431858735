import React, { memo } from "react";
import PropTypes from "prop-types";
import BreadCrumbs from "../breadCrumbs";
import * as styled from "./style";

const NoResultsFound = props => {
  const { userInput, fromPI, resources: { no_product_txt, please_ensure_txt, no_results_img, no_results_alt_txt },
    manufacturerData, drugName, resource, showSearchDetails, setId, showDownload} = props;
  return (
    <styled.MainContainer fromPI={fromPI}>
      {fromPI &&
      <BreadCrumbs
        manufacturerData={manufacturerData}
        drugName={drugName}
        resources={resource}
        showSearchDetails={showSearchDetails}
        setId={setId}
        showDownload={showDownload}
      />}
      <styled.ContentSection>
        <styled.Section1>
          {/* <div>
            <img src={no_results_img} alt={no_results_alt_txt}></img>
          </div> */}
          <div>
            <styled.Section1P1>{no_product_txt} {userInput ? "\"" + userInput + "\"" : ""}</styled.Section1P1>
            <styled.Section1P2>{please_ensure_txt}</styled.Section1P2>
          </div>
        </styled.Section1>
        {props.children}
      </styled.ContentSection>
    </styled.MainContainer>
  )
};

NoResultsFound.propTypes = {
  userInput: PropTypes.string,
  resources: PropTypes.shape({
    phone_img: PropTypes.string,
    phone_alt_txt: PropTypes.string,
    heading1_txt: PropTypes.string,
    cant_txt: PropTypes.string,
    call_txt: PropTypes.string,
    number_txt: PropTypes.string,
    no_product_txt: PropTypes.string,
    please_ensure_txt: PropTypes.string,
    no_results_img: PropTypes.string,
    no_results_alt_txt: PropTypes.string
  })
};
export default memo(NoResultsFound);