import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, minLaptop } from "../../common/styles/breakpoints";

export const AboutContainer = styled.div`
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .about-image {
        margin-top: 47.4px;
        text-align: center;
        @media ${mobileRange} {
            text-align: center;
            margin: 0;
            margin-top: 48px;
        }
        @media ${tabletRange} {
            text-align: left;
        }
        img {
            width: 280px;
            @media ${mobileRange} {
                width: 245px;
            }
            @media ${tabletRange} {
                width: 200px;
                padding: 15px 15px 0 0;
                margin-left: 126px;
            }
            @media ${minLaptop} {
                margin-left: 126px;
            }
        }
    }
    padding-bottom: 48px;
    background-color: ${colors.catskill_white}
    @media ${mobileRange} {
        text-align: center;
        padding-bottom: 37px;
    }
    @media ${tabletRange} {
        margin: 0;
        text-align: right;
    }
`;

export const H2 = styled.h2`
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: -0.39px;
    text-align: left;
    color: ${colors.sherpa_blue};
    margin-top: 77.2px;
    @media ${mobileRange} {
        text-align: center;
        font-size: 26px;
        line-height: 1.23;
        letter-spacing: -0.34px;
        margin-top: 43px;
    }
    @media ${tabletRange} {
        font-size: 24px;
        line-height: 1.21;
        letter-spacing: -0.31px;
    }
`;

export const LINK = styled.span`
    font-size: 16px;
    line-height: 1.22;
    letter-spacing: -0.23px;
    text-align: left;
    
    &:hover {
        color: ${colors.bondi_blue};
    }
    @media ${mobileRange} {
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: -0.34px;
    }
    @media ${tabletRange} {
        font-size: 14px;
        display: block;
        margin-top: 10px;
        line-height: 1.21;
        letter-spacing: -0.18px;
    }
    a {
        color: ${colors.bondi_blue};
        text-decoration: none;
        cursor: pointer; 
    }
`;

export const P = styled.p`
    font-size: 16px;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: ${colors.scorpion};
    margin-right: 70px;
    @media ${mobileRange} {
        margin: 0 0 16px 0;
        font-size: 16px;
        line-height: 1.81;
        padding: 0 38px;
        text-align: center;
    }
    @media ${tabletRange} {
        font-size: 14px;
        line-height: 1.64;
        margin: 0;
        padding-right: 23px;
    }
    @media ${minLaptop} {
        padding-right: 38px;
    }
`;