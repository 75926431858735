import { GET_CAREER, GET_SUCCESS_CAREER, GET_FAIL_CAREER } from "./actions";

const initialState = {
  careers: null,
  loading: false
}

export default function careerReducer(state = initialState, { type, payload }) {
  switch (type) {

  case GET_CAREER:
    return {
      ...state,
      loading: true
    }

  case GET_SUCCESS_CAREER:
    return {
      ...state,
      careers: payload.result,
      loading: false
    }

  case GET_FAIL_CAREER:
    return {
      ...state
    }

  default: return state
  }
}
