import React, { useState } from "react";
import resources from "../../config/content/tourPage";
import * as styled from "./styles";

/**
 * TourContainer function for managing tour in website
 * @param {props} props property passed
 * @returns {HTMLElement} html html is returned
 */
const TourContainer = props => {

  const { tour_logo, skip_button_logo, welcome_txt_tour, message_txt, start_touring_txt, skip_tour_txt } = resources;
  const [showPopup, changePopup] = useState(true);

  /**
   * Skip tour funtion
   * @returns {undefined}
   */
  const skipTour = () => {
    changePopup(false);
    props.skipTour();
  }

  /**
   * Start Tour Function
   * @returns {undefined}
   */
  const startTour = () => {
    const {startTour} = props;
    changePopup(false);
    startTour();
  }

  return (
    <styled.MainContainer showPopup={showPopup}>
      <div className="tourImage">
        <img src={tour_logo}></img>
      </div>
      <styled.WelcomeTextSection>{welcome_txt_tour}</styled.WelcomeTextSection>
      <styled.MessageSection>{message_txt}</styled.MessageSection>
      <styled.Buttons>
        <styled.StartTour onClick={startTour}>{start_touring_txt}</styled.StartTour>
        <styled.SkipTour onClick={skipTour}>
          <img src={skip_button_logo} alt="skip_logo"></img>
          <div>{skip_tour_txt}</div>
        </styled.SkipTour>
      </styled.Buttons>
    </styled.MainContainer>
  )
}

export default React.memo(TourContainer)
