import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop, minLaptopL } from "../../common/styles/breakpoints";
import tour_logo from "../../assets/images/About_hero_mobile.png"

import searchBg from "../../assets/images/phactmi-search-bg.png";

export const SearchPage = styled.div`
  .overlay_image {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    left: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
  }
  width: 100%;
  color: ${colors.sherpa_blue};
  background: ${colors.white};
  font-size: 20px;
  position: relative;
  .results-cont {
    margin-top: 95px;
    @media ${tabletRange} {
      margin-top: 50px;
    }
    @media ${mobileRange} {
      margin-top: 30px;
    }
  }
  .srd {
    max-height: none !important;
    margin-bottom: 3px;
    margin-top: 140px

    @media ${mobileRange}{
      margin-top: 0
    }
  }
  .overlay_image {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
  }
`;

export const SearchContainer = styled.div`
  z-index: 10;
  width: 100%;
  left: 50%;
  max-width: 1110px;
  display: flex;
  margin: 0 16px 0 0;
  border-radius: 3px;
  @media ${mobileRange} {
    margin: 0 auto;
    border: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.usage ? `${colors.alabaster}` : `${colors.white}`};
  padding: 0 98px;
  overflow-y: auto;
  pointer-events: ${props => props.showTour ? "none" : "auto"};
  margin-top: ${props => props.expert ? "155px" : "15px"};
  margin-bottom: 3px;
  max-height: ${props => props.height+"px"};
  @media ${mobileRange} {
    padding: 0;
    max-height: none;
    margin-top: 0 !important;
  }
  @media ${tabletRange} {
    padding: 0;
    margin-top: 0;
  }
  @media ${ipadProRange} {
    margin-top: ${props => props.expert ? "81px" : "15px"};
    padding: 30px 100px 0;
  }
  .search-accordion {
    margin: 40px 0;
    @media ${tabletRange} {
      margin: 30px 30px;
    }

    @media ${mobileRange} {
      margin-top: 0;
      margin-bottom: 20px
    }
    @media ${minLaptop} {
      margin: 30px 0;
    }
    @media ${ipadProRange} {
      margin: 30px 0;
    }
    @media ${minLaptopL} {
      margin: 30px 0;
    }
  }
  .scroll {
  }
  .lookingproduct{
    margin-top: 100px !important;
  }
`;

export const SRDContainer = styled.div`
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);
  background: ${colors.white};
  padding: 40px 96px 34px 99px;
  margin-top: ${props => props.usage ? "140px" : "55px" };
  margin-bottom: 3px;
  @media ${mobileRange} {
    margin-top: 0;
    padding: 30px 0 10px;
    max-height: none;
  }
  @media ${tabletRange} {
    padding: 20px 80px 0px;
    margin-top: 60px;
  }
  @media ${ipadProRange} {
    margin-top: 81px;
  }
  @media ${minLaptop} {
    margin-top: ${props => props.usage ? "44px" : "55px" };
  }
`;

export const Title = styled.div`
  font-size: 26px;
  line-height: 0.8;
  letter-spacing: -0.39px;
  margin-bottom: 40px;
  display: none;
  @media ${mobileRange} {
    font-size: 22px;
    letter-spacing: -0.29px;
    text-align: center;
    margin-bottom: 34px;
    display: block;
  }
`;

export const LookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 480px;
  padding: 70px 0 0;
  min-height: max-content;
  img {
    width: 150px;
  }
  @media ${tabletRange} {
    min-height: 520px;
    img {
      width: auto;
    }
  }
  @media ${mobileRange} {
    min-height: 0;
    padding-bottom: 40px;
    img {
      width: auto;
    }
    height: 600px;
  }
`;

export const LookingPara = styled.h1`
  font-size: 25px;
  letter-spacing: -0.36px;
  margin-bottom: 0;
  padding-top: ${props => props.underMaintenance ? "30px": "0"}
  @media ${mobileRange} {
    width: 85%;
  }
`;

export const TypePara = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: -0.26px;
  margin-bottom: 20px;
  width: 510px;
  padding-top: ${props => props.underMaintenance ? "20px": "0"}
  @media ${mobileRange} {
    width: 80%;
  }
`;

export const ImgDesc = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  @media ${mobileRange} {
    flex-direction: column;
    margin: 0;
    text-align: center;
  }
`;

export const EmptySRDTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 41px;
  @media ${mobileRange} {
    flex-direction: column;
    padding: 20px 0 22px;
    text-align: center;
  }
`;

export const SRDTitle = styled.p`
  display: none;
  font-size: 26px;
  letter-spacing: -0.39px;
  margin-bottom: 0;
  @media ${mobileRange} {
    display: block;
    font-size: 22px;
    letter-spacing: -0.29px;
    margin-bottom: 8px;
    font-size: 22px;
  }
  @media ${tabletRange} {
    font-size: 26px;
  }
`;

export const SRDResult = styled.p`
  font-size: 22px;
  letter-spacing: -0.3px;
  color: ${colors.sherpa_blue};
  display: flex;
  align-items: center;
  span {
    border-bottom: 1px solid ${colors.sherpa_blue};
    margin-left: 7px;
  }
  @media ${mobileRange} {
    font-size: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
`;

export const ImgDescContainer = styled.div`
  width: 396px;
  @media ${mobileRange} {
    width: auto;
  }
`;

export const ImgDesc1 = styled.p`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.36px;
  margin: -6px 0 0;
  @media ${mobileRange} {
    font-size: 16px;
    margin: 6px 0 8px;
    line-height: 1.5;
    letter-spacing: -0.27px;
  }
  @media ${tabletRange} {
    font-size: 16px;
    padding: 10px 0;
  }
`;

export const ImgDesc2 = styled.p`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.26px;
  margin-bottom: 0;
  width: 100%
  @media ${mobileRange} {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 14px;
  }
  @media ${tabletRange} {
    margin-right: 40px;
    font-size: 16px;
    padding: 10px 0;
  }
`;

export const ImgWrapper = styled.div`
  img {
    width: 230px;
  }
  @media ${mobileRange} {
    display: flex;
    justify-content: center;
    img {
      width: 221px;
    }
  }
`;

export const TextWrapper = styled.div`
  width: 265px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 20px 20px;
  margin-left: 14%;
  text-align: left;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
  @media ${tabletRange} {
    margin-left: 7%;
    padding: 0;
    width: 250px;
  }
  @media ${mobileRange} {
    text-align: center;
    width: 250px;
    padding: 0;
    margin: 0 auto 44px;
  }
`;

export const Section2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${props => props.bgWhite ? colors.white : colors.catskill_gray};
  padding: 55px 0 26px;
  font-size: 18px;
  letter-spacing: -0.23px;
  color: ${colors.boulder};
  span {
    padding-left: 5px;
    color: ${colors.persian_green};
  }
  @media ${mobileRange} {
    flex-direction: column-reverse;
    padding: 63px 0 48px;
    font-size: 18px;
    line-height: 1.33;
    background-color: ${colors.catskill_gray};
  }
  @media ${tabletRange} {
    padding: 50px 0 51px;
    background-color: ${colors.catskill_gray};
  }
`;

export const Section2P1 = styled.p`
  font-size: 26px;
  line-height: 1.47;
  letter-spacing: -0.39px;
  color: ${colors.sherpa_blue};
  @media ${mobileRange} {
    font-size: 26px;
    line-height: 1.35;
    letter-spacing: -0.34px;
  }
  @media ${tabletRange} {
    font-size: 26px;
    line-height: 1.35;
    letter-spacing: -0.34px;
  }
`;

export const SRDContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mobileRange} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
`;

export const HomePopSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 7%;
  z-index: 101;

  .Hcp_popup {
    padding: 0 115px;
    max-width: ${containerMaxWidth};

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 0;
    }
    @media ${tabletRange} {
      padding: 0 48px;
    }
    @media ${mobileRange} {
      padding: 0 10px;
    }
    @media ${ipadProRange} {
      padding: 0 80px;
    }
  }
  @media ${mobileRange} {
    top: 9%;
  }
  @media ${ipadProRange} {
    top: 7%;
  }
  @media ${minLaptop} {
    top: 7%;
  }
`;

export const SrdNoResultImg = styled.img`
  width: 280px;
  @media ${mobileRange} {
    width: 221px;
    margin: 0 0 50px 0;
  }
  @media ${tabletRange} {
    width: 250px;
    margin-left: 0;
  }
`;

export const SrdNoResultImgContainer = styled.div`
  width: 50%;
  padding-top: 60px;
  text-align: center;
  @media ${mobileRange} {
    width: 100%;
    min-height: 0;
    padding-top: 0;
  }
  @media ${tabletRange} {
    padding-left: 15px;
    padding-top: 60px;
  }
  @media ${ipadProRange} {
    padding-top: 60px;
  }
`;

export const PatientSrdImgContainer = styled.div`
  width: 35%;
  padding-top: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    padding-left: 15px;
    padding-top: 60px;
  }
  @media ${ipadProRange} {
    padding-top: 60px;
  }
`;

export const TooltipSection = styled.div`
  @media ${mobileRange} {
    display: none;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  position: fixed;
  top: 80px;
  padding: 16px 22px 7px;
  z-index: 10;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${containerMaxWidth};
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.changebackground ? colors.white : "transparent"};
  @media ${mobileRange} {
    position: initial;
    transform: translateX(0);
    border-radius: 8px;
    border: 1px solid #CFCFCF;
    background: #FFF;
    box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.15);
    padding: 16px;
    display: block;
    margin: 54px auto 0;
    width: 93%;
  }
  @media ${tabletRange} {
    top: 60px;
    padding: 25px 0 0;
  }
  @media ${ipadProRange} {
    top: 75px;
    padding: 16px 50px 9px;
  }
`;

export const SearchContainerImage = styled.div`
    background: none;
  @media ${mobileRange} {
    background: url('${tour_logo}');
    padding-top: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
  }
`;

export const PoweredBySection= styled.div`  
  display: block;
  padding-bottom: 8px;
  @media ${mobileRange} {
    display: none;
  }
  @media ${minLaptop} {
    padding-bottom: 1px;
  }
  @media ${minLaptopL} {
    padding-bottom: 6px;
  }
  @media ${tabletRange} {
    width: 30%;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
  @media ${mobileRange} {
    flex-direction: column;
    justify-content: none;
    padding-bottom: 0;
    margin-bottom: 20px
  }
  @media ${tabletRange} {
    justify-content: space-between;
    margin: auto
  }
`;

export const PoweredBySectionMobile = styled.div`
display: none;
@media ${mobileRange} {
  display: flex;
  width: 60%;
}
`;

export const HelpToolTipSection = styled.div`
  margin-top: 15px;
  @media ${mobileRange} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 0;
  }
`;

export const HelpAndFeedbackSection = styled.div`
  padding: 0;
  position: relative;
  display: ${props => props.isPiLoaded ? "none" : "block"};
  @media ${mobileRange} {
    background: ${colors.catskill_white_gray};
  }
  @media ${tabletRange} {
    background: ${colors.catskill_white_gray};
    margin-top: 20px;
  }
  @media ${minLaptop} {
  }
  @media ${ipadProRange} {
    padding: 0;
  }
  @media ${minLaptopL} {
  }
`;

export const TabBarContainer = styled.div`
  display: ${props => props.firstSearch || props.isLoading ? "none" : "block"};
  box-shadow: 0 -6px 0 ${colors.catskill_white_gray}, 0 1px 6px rgba(0, 0, 0, .35);
  background-color: ${colors.white};
  max-width: ${containerMaxWidth};
  margin-left: auto;
  margin-right: auto;
  @media ${tabletRange} {
    box-shadow: none;
    background-color: ${colors.catskill_white_gray};
  }
`;

export const TabHeaderContainer = styled.div`
  background-color: ${colors.catskill_white_gray};
  max-width: ${containerMaxWidth};
  position: fixed;
  width: 100%;
  top: 231px;
  background: white;
  pointer-events: auto;
  z-index: 2;
  @media ${tabletRange} {
    top: 202px;
    width: 100%;
  }
  @media ${minLaptop} {
    background: transparent;
  }
  @media ${ipadProRange} {
    background: transparent;
    top: 228px
  }
  @media ${mobileRange} {
    position: initial;
    background-color: ${colors.catskill_white_gray};
  }
`;

export const LookingParaWrapper = styled.div`
  background: ${colors.white};
  @media ${ipadProRange} {
    margin-top: 15px;
  }
`;

export const FeedBackIcon = styled.div`
  display: block;
  position: fixed;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  img {
    width: 70px;
  }
`;

export const SearchBg = styled.div`
  height: 240px;
  margin-top: -1px;
  background: url(${searchBg});
  background-repeat: no-repeat;
  background-size: cover;
  @media ${mobileRange} {
    height: 233px;
    margin-top: 78px;
    background-position-x: center;
  }
  @media ${tabletRange} {
    height: 265px;
  }
`;

export const CantFindTextWrapper = styled.div`
  font-weight: 300;
  margin-top: 67px;
  span {
    padding-left: 5px;
    color: ${colors.persian_green};
  }
  @media ${mobileRange} {
    margin-top: 0;
  }
  @media ${tabletRange} {
    margin-top: 50px;
  }
`;

export const CantFindContent = styled.p`
  font-size: 26px;
  line-height: 1.47;
  letter-spacing: -0.39px;
  font-weight: normal;
  color: ${colors.sherpa_blue};
`;

export const SearchPopup = styled.div`
display: flex;
width: 100%;
max-width: ${containerMaxWidth};
position: absolute;
top: 12%;
padding: 0 115px;
z-index: unset;
.Hcp_popup {
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  padding: 0;
}
@media ${tabletRange} {
  padding: 0 48px;
}
@media ${mobileRange} {
  top: 9%;
  padding: 0 20px;
}
@media ${ipadProRange} {
  top: 12%;
  padding: 0 80px;
}
@media ${minLaptop} {
  top:  12%;
}
`;

export const SRDResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${mobileRange} {
    align-items: center;
  }
`;

export const MIRLimited = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.dove_darkgray};
  padding-left: 5px;
  margin-bottom: 5px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 920px;
  }
  @media ${mobileRange} {
    padding: 5px 22px;
    font-weight: 400;
  }
  @media ${tabletRange} {
    font-size: 13px;
  }
`;

export const NoResultWrapper = styled.div`
  padding-top: 30px;
  color: ${colors.sherpa_blue};
  font-weight: 300;
  @media ${mobileRange} {
    text-align: center;
    padding: 20px 0;
  }
  @media ${tabletRange} {
    padding-top: 30px;
  }
  .zero_txt {
    font-size: 26px;
    padding-left: 5px;
    text-decoration: underline;
    @media ${mobileRange} {
      font-size: 20px;
    }
  }

  .member-link-icon, .member-contact-icon {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  p, .patient-mir-info {
    font-size: 18px;
    color: #434545;
    line-height: 1.5;

    .member-name {
      margin-bottom: 8px;
    }

    @media ${mobileRange} {
      font-size: 16px;
    }
    @media ${tabletRange} {
      font-size: 16px;
    }
  }

  .patient-mir-info{
    color: #004250;
    margin-bottom: 10px;

    hr {
      margin: 1.5rem 0;
    }

    .member-link {
      margin-bottom: 10px;
    }

    .member-name-details {
      margin: 14px 0;
    }

    p {
      color: inherit;
    }
  }

  a {
    color: ${colors.bondi_green};
  }

  .patient-mir-msg-title {
    font-size: 18px;
    margin-bottom: 14px;
  }
`;

export const ZeroResult = styled.div`
  display: flex;
  font-size: 26px;
  padding-bottom: 20px;
  @media ${mobileRange} {
    justify-content: center;
    padding: 20px 0;
    font-size: 20px;
  }
  h2 {
    font-weight: 300;
    font-size: 26px;
    line-height: 1.4;
    margin-bottom: 0;
    @media ${mobileRange} {
      font-size: 20px;
    }
  }  
`;

export const PopupWrapper = styled.div`
  width: 506px;
  height: 407px;
  position: absolute;
  background: white;
  z-index: 10000;
  top: 135px;
  left: 50%;
  transform: translateX(-50%); 
  border-radius: 10px;
  font-size: 22px;
  text-align: center;
  padding: 50px 0 0 0;
  img {
    width: 180px;
    padding: 0 0 20px 0;
  }
  .leavingMessage {
    font-size: 18px;
  }
  p {
    padding: 0 20px;
  }

  @media ${mobileRange} {
    width: 90%;
    padding: 16px 0;

    .leavingMessage {
      font-size: 16px;
    }
  }

`;

export const PopUpButton = styled.button`
  width: 150px;
  padding: 10px 40px;
  font-size: 18px;
  background: ${props => props.changeBg ? colors.sherpa_blue : colors.white};  
  border: 1px solid ${colors.sherpa_blue};
  color: ${props => props.changeBg ? colors.white : colors.sherpa_blue};  
  margin: 0 10px;

  @media ${mobileRange} {
    width: 100px;
    padding: 8px 0;
    font-size: 16px;
  }
`;

export const PopUpButtonWrapper = styled.div`
  text-align: center;
`;

export const MedicalLinkWrapper = styled.div`
  color: ${colors.bondi_green};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const MaintMessageWrapper = styled.div`
  padding-top: 80px;
  font-size: 18px;
  font-weight: normal;
  @media ${mobileRange} {
    padding: 25px;
  }
  @media ${tabletRange} {
    padding-top: 70px;
  }
`;

export const LookingWrapperMaint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 480px;
  padding: 70px 0 0;
  min-height: max-content;
  img {
    width: 200px;
  }
  @media ${tabletRange} {
    min-height: 520px;
  }
  @media ${mobileRange} {
    min-height: 0;
    padding-bottom: 40px;
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.error ? "465px" : "900px"};
  padding-top: 10px;
  padding-bottom: 10px;
  @media ${tabletRange} {
    width: ${props => props.error ? "265px" : "85%"}
  }
  @media ${ipadProRange} {
    width: ${props => props.error ? "40%" : "80%"}
  }
`;

export const SearchPillWrapper = styled.div`
  display: flex;
  @media ${mobileRange} {
    margin-left: 20px;
  }
  @media ${tabletRange} {
    margin: 0 58px;
  }
  @media ${ipadProRange} {
    margin-left: -20px;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  width: 435px;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-left: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 16px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    width: 280px;
  }
  @media ${ipadProRange} {
    width: 362px;
  }
`;

export const ErrorWrapperMobile = styled.div`
  display: none;
  width: 100%;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-left: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 16px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    display: flex;
    margin-left: 6.5%;
    padding: 10px 0 5px;
    width: 92%;
  }
`;

export const InfoMessage = styled.p`
  color: ${colors.scorpion_gray};
  font-size: 18px;
  span {
    color: ${colors.sherpa_blue};
  }
  a {
    color: ${colors.bondi_green};
  }
  @media ${mobileRange} {
    text-align: center;
  }
  @media ${tabletRange} {
    font-size: 16px;
  }
`;

export const NonMemberMessage = styled.p`
  margin: 0;
  padding: 50px 0 30px;
  @media ${tabletRange} {
    padding: 30px 0 20px;
  }
`;

export const NoProductMessage = styled.p`
  margin-top: 70px;
  font-size: 20px;
  @media ${mobileRange} {
    margin: 60px 0 30px;
  }
`;

export const ScrollContainer = styled.div`
  margin-top: 30px;
  @media ${mobileRange} {
    margin-top: 0;
    min-height: ${props => props.spllength > 0 ? "450px" : "0"};
    padding: 24px 16px; 
  }
  @media ${tabletRange} {
    padding: 0 50px;
  }
  @media ${ipadProRange} {
    margin-top: 30px;
  }
`;

export const PatientMirInfo = styled.div`
  font-size: 18px;
  color: #004250;
  line-height: 1.5;
  margin-bottom: 24px;
  @media ${mobileRange} {
    font-size: 16px;
  }
  @media ${tabletRange} {
    font-size: 16px;
  }

  .member-link-icon, .member-contact-icon {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }

  .member-name-details {
    margin-top: 14px;
  }

  p {
    color: inherit;
  }
`;

export const SearchHeading = styled.div`
  display: none;
  @media ${mobileRange} {
    font-size: 16px;
    margin: 0 0 20px;
    width: 100%;
    display: flex;
    align-self: center;
    line-height: 1.44;
    letter-spacing: -0.21px;
    text-align: left;
  }
`;


// New styles

export const Container = styled.div`
  margin: auto;
  width: 85%;
  margin-top: -194px;

  @media ${mobileRange} {
    width: 92%;
  }

  @media ${tabletRange} {
    margin-top: -194px;
    margin: 0 40px;
    width: 92%;
  }

  @media ${ipadProRange} {
    width: 90%;
    margin: 0 60px;
    margin-top: -194px;
  }
`;

export const SearchContainerCard = styled.div`
  padding: 40px 48px;
  background-color: ${colors.white};
  margin-bottom: 60px;
  -webkit-box-shadow: 0 0 10px #0000001A;
  box-shadow: 0 0 10px #0000001A;

  @media ${mobileRange} {
    padding: 20px 16px;
  }

  @media ${tabletRange} {
    margin-top: -194px;
    padding: 40px 24px;
  }

  @media ${ipadProRange} {
    padding: 40px 24px;
  }
`;

export const SearchResultsContainer = styled.div`
  display: flex;

  @media ${mobileRange} {
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

export const NoPiSrdMobileAlert = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.bondi_blue};
  padding: 8px 12px;
  background: #EAFFFC;
  display: none;

  @media ${mobileRange} {
    display: block;
  }

  img {
    margin-right: 8px;
  }
`;

export const MemberContactInfoContainer = styled.div`
  display: none;

  @media ${mobileRange} {
    display: block;
  }
`;

export const HorizonalDividerMobile = styled.div`
  border-bottom: 1px solid #E0E0E0;
  display: none;

  @media ${mobileRange} {
    display: block;
  }
`;

export const VerticalDividerMobile = styled.div`
  border-left: 1px solid #E0E0E0;
  display: block;

  @media ${mobileRange} {
    display: none;
  }
`;

export const SRDsContainer = styled.div`
  display: block;
  width: 50%;
  padding-left: 40px;

  @media ${mobileRange} {
    display: ${props => props.mobileHide ? "none" : "block"}
    width: 100%;
    padding: 0;
  }
`;

export const PIContainer = styled.div`
  display: block;
  width: 49%;
  padding-right: 40px;

  @media ${mobileRange} {
    display: ${props => props.mobileHide ? "none" : "block"}
    width: 100%;
    padding: 0;
  }
`;