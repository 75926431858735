import styled from "styled-components";

export const ModalContainer = styled.div`
    padding: 32px 24px;
    position: fixed;
    z-index: 101;
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    top: 5%;
    left: 0;
`;

export const ModalWrapper = styled.div`
    background-color: #FFF;
    padding: 6px 24px 32px;
    border: 1px solid #CFCFCF;
    border-radius: 8px;
`;

export const Overlay = styled.div `
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    left: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
`;