import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";


export const NewsPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 85px;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: 70px;
  }
  @media ${ipadProRange} {
    padding-top: 80px;
  }
`;

export const NewsHeaderMain = styled.div`
    font-size: 30px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.96px;
    color: ${colors.sherpa_blue};
    @media ${mobileRange} {
      text-align: center;
      font-size: 30px;
    }
    @media ${tabletRange} {
      font-size: 30px;
    }
    span {
      font-weight: normal;
    }
`;

export const NewsHeader = styled.div`
    font-size: 30px;
    font-weight: 300;
    line-height: 1.35;
    padding-left: 4px;
    margin-top: -14px;
    color: ${colors.sherpa_blue};
    @media ${mobileRange} {
      margin-left: 0 0 0 25px;
    }
    @media ${tabletRange} {
      margin: 0;
    }
    @media ${ipadProRange} {
      margin: 0;
    }
`;

export const NewsHeaderSection = styled.h1`
  margin-left: 82px;
  margin-bottom: 0;
  @media ${mobileRange} {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 70px 0 0 0;
  }
  @media ${tabletRange} {
    margin: 0 81px 0px;
  }
`;


export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 80px;
  color: ${colors.scorpion};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 82px
  }
  @media ${mobileRange} {
    margin-top: 91px;
    display: block;
    padding-top: ${props => props.publicationPage ? "30px" : "0"};
    padding-bottom: 60px;
  }
  @media ${ipadProRange} {
    margin-top: 70px;
  }
`;

export const NewsDetail = styled.div`
min-width: 1000px;

@media ${mobileRange} {
  min-width: 320px
}
@media ${tabletRange} {
  min-width: 548px
}
@media ${ipadProRange} {
  min-width: 715px;
  min-height: 838px;
}
`;

export const NewWrapper = styled.div`
  width: 370px;
  color: ${colors.sherpa_blue};
  font-size: 30px;
  letter-spacing: -0.39px;
  text-align: left;
  padding-right: 50px;
  p {
    letter-spacing: -0.22px;
    opacity: 0.5;
    font-size: 17px;
  }
  @media ${mobileRange} {
    width: 100%;
  }
  h2 {
    font-size: 1.75rem;
  }
`;

export const EmailWrapper = styled.div`
  width: 48%;
  p {
    color: ${colors.scorpion_dark};
    font-size: 18px;
    text-align: left;
  }
  @media ${mobileRange} {
    width: 100%;
  }
`;

export const NewsEmailWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.light_darkish_blue};
  align-items: center;
  padding: 40px 68px 0;
  justify-content: center;
  @media ${mobileRange} {
    padding: 30px 35px;
    flex-direction: column;
  }
`;

export const NewsContent = styled.div``;

export const NewsContentWrapper = styled.div`
  display: flex;
  @media ${mobileRange} {
    flex-direction: row;
    margin: 32px 30px;
  }
  @media ${tabletRange} {
    margin: 32px 69px 0;
  }
`;

export const DateWrapper = styled.div`
  width: 75px;
  @media ${mobileRange} {
    display: flex;
    width: 30%;
  }
`;

export const Date = styled.div`
  display: block;
  text-align: center;
  padding: 16px 11px;
  background: ${props => props.isExpanded ? colors.bondi_blue : colors.alabaster};
  height: 100%;
  @media ${mobileRange} {
    text-align: left;
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70px;
  }
`;
export const DateMonth = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 31px;
  font-weight: 600;
  text-align: left;
  color: ${props => props.isExpanded ? colors.white : colors.scorpion_gray};
  text-transform: uppercase;
  .month {
    padding-top: 10px;
    font-size: 11px;
    font-weight: 400;
    transform: matrix(0, -1, 1, 0, 0, 0);
    min-width: 25px;
    text-align: center;

    @media ${mobileRange} {
      padding-top: 5px;
    }

  }
  .date {
    padding-left: 4px;
    @media ${mobileRange} {
      padding-left: 0;
      font-size: 24px;
    }
  }
`;
export const DateYear = styled.div`
  font-size: 44px;
  font-weight: 600;
  color: ${ props => props.isExpanded ? colors.white : colors.scorpion_gray};
  opacity: ${props => props.isExpanded ? "0.4" : "0.2"};
  margin-top: -18px;

  @media ${mobileRange} {
    margin-top: -10px;
    font-size: 18px;
  }

`;

export const Content = styled.div`
  display: flex;
  padding-left: 20px;
  color: ${colors.scorpion_gray};
  padding-top: 11px;
  width: 100%;
  height: ${props => props.isExpanded ? "unset" : "111px"};
  overflow: hidden;
  @media ${mobileRange} {
    height: unset;
    padding-left: 0;
    padding-top: 0;
  }
  @media ${tabletRange} {
    height: ${props => props.isExpanded ? "unset" : "105.3px"};
  }
`;
export const NewsTitle = styled.div`
  text-align: left;
  font-size: 24px;
  letter-spacing: -0.31px;
  color: ${colors.sherpa_blue}; 
  @media ${mobileRange} {
    font-size: 20px;
  }
  @media ${tabletRange} {
    font-size: 22px;
  }
`;
export const NewsDescription = styled.div`
  font-size: 15px;
  letter-spacing: 0px;
  color: ${colors.gryish_black};
  @media ${mobileRange} {
    height: ${props => (props.content.length > 0 && props.content[0].length === 0)|| props.content.length === 0 ? "unset" : "105px;"};
    font-size: 18px;
    margin-top: 10px;
  }
  @media ${tabletRange} {
    font-size: 18px;
  }
`;
export const NewsDescriptionWrapper = styled.div`
  width: 85%;
  padding-right: 20px;
  @media ${mobileRange} {
    display: flex;
    flex-direction: column;
  }
  @media ${tabletRange} {
    width: 100%;
  }
  @media ${ipadProRange} {
    width: 84%;
  }
`;
export const ExpandCollapseSection = styled.div`
  display: flex;
  align-items: center;
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    display: none;
  }
  button {
    font-size: 14px;
    font-weight: bold;
    background: none;
    border-radius: 3px;
    border: none;
    outline: none;
    background-color: ${colors.sherpa_blue};
    color: ${colors.white};
    padding: 13px 20px;
  }
  img {
    width: 16px;
    margin-left: 10px;
    vertical-align: baseline;
  }
`;

export const MobileExpandCollapseSection = styled.div`
  display: none;
  @media ${tabletRange} {
    display: block;
    margin: -7px 0 0 158px;
  }
  @media ${mobileRange} {
    display: block;
    margin-left: 25%;
  }
  button {
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    color: ${colors.white};
    border-radius: 6px;
    background-color: ${colors.sherpa_blue};
    padding: 13px 20px;
  }
  img {
    width: 16px;
    margin-left: 10px;
    vertical-align: baseline;
  }
`;

export const NewsContentSection = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  margin: 32px 69px 0;
  padding-bottom: 32px;
  @media ${mobileRange} {
    margin: 0;
  }
  @media ${tabletRange} {
    margin: 0;
  }
`;

export const LoadMoreButton = styled.button`
  display: none;
  @media ${mobileRange} {
    display: block;
    outline: none;
    color: #000;
    border-radius: 8px;
    border: 1px solid #A7A4A4;
    background: #E9E9E9;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin: auto;
    padding: 12px 36px;
    cursor: pointer;
    margin-top: 12px;
  }
`;
