export const GET_MANUFACTURERS_DETAILS = "GET_MANUFACTURERS_DETAILS";
export const GET_MANUFACTURER_DETAIL = "GET_MANUFACTURER_DETAIL";
export const GET_SUCCESS_MANUFACTURER_DETAIL = "GET_SUCCESS_MANUFACTURER_DETAIL";
export const GET_FAIL_MANUFACTURER_DETAIL = "GET_FAIL_MANUFACTURER_DETAIL";
export const SET_MANUFACTURER_DETAIL= "SET_MANUFACTURER_DETAIL";
export const GET_CORPORATE_PARTNER_DETAIL = "GET_CORPORATE_PARTNER_DETAIL";
export const GET_SUCCESS_CORPORATE_PARTNER_DETAIL = "GET_SUCCESS_CORPORATE_PARTNER_DETAIL";
export const GET_FAIL_CORPORATE_PARTNER_DETAIL = "GET_FAIL_CORPORATE_PARTNER_DETAIL";
export const GET_CORPORATE_PARTNER = "GET_CORPORATE_PARTNER";
export const GET_CORPORATE_PARTNER_SUCCESS = "GET_CORPORATE_PARTNER_SUCCESS";
export const GET_CORPORATE_PARTNER_FAIL = "GET_CORPORATE_PARTNER_FAIL";

export function getManufacturersList() {
  return {
    type: GET_MANUFACTURERS_DETAILS
  }
}

export function getManufacturerDetail(companyName) {
  return {
    type: GET_MANUFACTURER_DETAIL,
    payload: {
      companyName
    }
  }
}

export function setManufacturerDetail() {
  return {
    type: SET_MANUFACTURER_DETAIL
  }
}

export function getCorporatePartnerDetail() {
  return {
    type: GET_CORPORATE_PARTNER_DETAIL
  }
}

export function getCorporatepartner() {
  return {
    type: GET_CORPORATE_PARTNER
  }
}
