/* eslint-disable max-len */
import leftArrowActive from "../../assets/images/leftArrowActive.png";
import leftArrowInactive from "../../assets/images/leftArrowInactive.png";
import rightArrowActive from "../../assets/images/rightArrowActive.png";
import rightArrowInactive from "../../assets/images/rightArrowInactive.png";
import ManufactureSectionImage from "../../assets/images/Manufacture_Section.png";
import Medical from "../../assets/images/Medical.png";
import Education from "../../assets/images/Education.png";
import Information from "../../assets/images/Information.png";
import GeneralErrorLogo from "../../assets/images/generalerror.png";

export default {
  "manufacturers_txt": "Member Organizations",
  "productList": {
    "productlist_heading": "Product List",
    "products_show_more": "More Info",
    "eDossier_txt1": "FormularyDecisions eDossier Product Page",
    "eDossier_txt2": "(Registration Required)"
  },
  "contact_txt": "Contact",
  "about_txt": "About",
  "increment_symbol": "+",
  "mobile_manufacturers_txt": "Our Member Organizations",
  "manufacture_sub_heading": "(Click on the company icon for more information)",
  "filterImages": {
    "left_arrow_active": leftArrowActive,
    "left_arrow_Inactive": leftArrowInactive,
    "right_arrow_active": rightArrowActive,
    "right_arrow_Inactive": rightArrowInactive
  },
  "alinkplaceholder": "PortalResource",
  "section_logo": ManufactureSectionImage,
  "section_logo_alt_txt": "phactMI Member Organizations",
  "manufacture_content_info": {
    "heading": "phactMI Member Organizations",
    "info": "phactMI is a collaboration of pharmaceutical industry Medical Information (MI) leaders who are dedicated to supporting healthcare professionals in their commitment to provide quality patient care."
  },
  "guidelines_content": [
    {
      logo: Information,
      alt_txt: "Medical Information - logo",
      heading: "Medical Information Functions",
      description: "Have and/or support medical information functions that strive to provide accurate, timely, appropriate, fair-balanced medical and scientific information in response to unsolicited questions in a compliant fashion."
    },
    {
      logo: Medical,
      alt_txt: "Medically Qualified Staff - logo",
      heading: "Medically Qualified Staff",
      description: "Ensure that such information is created and maintained by appropriately medically qualified individuals (i.e., PharmD, MD, etc.)."
    },
    {
      logo: Education,
      alt_txt: "Ineligible - logo",
      heading: "Not Excluded or Ineligible",
      description: "Are not debarred, suspended, excluded, or otherwise declared ineligible from any State or Federal agency or program."
    }
  ],
  "guidelines_heading": "Eligibility Requirements",
  "guidelines_desc_txt": "In order to participate in phactMI, Member Organizations must meet the following eligibility requirments:",
  "generalError": {
    "logo": GeneralErrorLogo,
    "alt_txt": "error_logo",
    "heading": "Error!",
    "description": "The application has encountered an error. Please navigate to the ‘Member Organizations’ page to find additional resources or contact <a href='mailto:admin@phactmi.org'>admin@phactmi.org</a> for additional assistance.",
    "button_txt": "Go to Member Organizations"
  },
  "metaTitle": {
    "title_placeholder": " - Our Member Organization | phactMI",
    "meta_prefix": "phactMI is proud to collaborate with our member organization - ",
    "meta_suffix": ", that support our mission & vision to help shape the future of Medical Information. Start Searching Now!"
  },
  "alt_txt_placeholder": "Member organization - ",
  "corporate_partners_heading": "Corporate Partners",
  "corporate_partners_description": "Organizations that support Medical Information functions",
  "corporate_partner_txt": "Corporate Partners"
}
