import React, { memo } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import resources from "../../config/content/privacyPolicy";
import withTransition from "../../components/with-transition";
import * as styled from "./style";

/**
 * Privacy Policy Container
 *
 * @constant PrivacyPolicy
 * @exports PrivacyPolicy
 * @returns {HTMLElement} element
 */
const PrivacyPolicy = ({ history }) => {
  window.scrollTo({top: 0});
  const { sections = [], img_src, header, img_alt_txt } = resources
  return (
    <styled.PrivacyPage>
      <styled.HeadingContent>
        <Container className="container">
          {header}
        </Container>
      </styled.HeadingContent>
      <Container className="body-container">
        <styled.ConditionSection>
          <styled.ImageSection>
            <img src={img_src} alt={img_alt_txt} />
          </styled.ImageSection>
          {renderSections(sections, history)}
        </styled.ConditionSection>
      </Container>
    </styled.PrivacyPage>
  );
};

/**
 * Renders section
 *
 * @param  {Array} sections=[]
 * @param  {object} history route history
 * @returns {HTMLElement} element
 */
const renderSections = (sections = [], history) => {
  return (
    <React.Fragment>
      {
        sections.map((item, key) => {
          if (item.subHeader) {
            return <styled.PrivacyContent key={key}>
              <h3 className="contact-us">{item.title}</h3>
              {renderContents(item.content, history)}
            </styled.PrivacyContent>
          }
          else {
            return <styled.PrivacyContent key={key}>
              <styled.SectionTitle>{item.title}</styled.SectionTitle>
              {renderContents(item.content, history)}
            </styled.PrivacyContent>
          }
        })
      }
    </React.Fragment>
  );
}

/**
 * Renders content
 *
 * @param  {Array} content=[]
 * @param  {object} history route history
 * @returns {HTMLElement} element
 */
const renderContents = (content = [], history) => {
  return (
    <div>
      {
        content.map((item, key) => {
          return <React.Fragment key={key}>
            <styled.ContentTitle>{item.title}</styled.ContentTitle>
            <div className="privacy-policy-content" dangerouslySetInnerHTML={createMarkup(item.content)} onClick={(e) => redirectToContact(e, history)}/>
          </React.Fragment>
        })
      }
    </div>
  );
}

const createMarkup = (text) => {
  return {__html: text};
}

const redirectToContact = (e, history) => {
  // TODO : Use !== instead of !=
  if (e.target.className.toUpperCase() != "CONTACT-US-LINK") return;
  history.push({ pathname: "/about/#contact-us",
    state: { fromFooter: true }});
}

PrivacyPolicy.prototype = {
  resources: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.object),
    img_src: PropTypes.string,
    header: PropTypes.string
  })
}

export default memo(withTransition(PrivacyPolicy));
