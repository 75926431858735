import React, { Component } from "react";
import ReactGA from "react-ga";
import resources from "../../config/content/searchPage";
import { MainContainer } from "./style";

/**
 * withTransition HOC
 * @param {componentDidMount} WrappedComponent - react component
 * @exports withTransition
 * @returns {Component} Component
 */
export default function withTransition(WrappedComponent) {
  return class extends Component {

    state = {
      containerOpacity: 0
    };

    componentDidMount() {
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState({ containerOpacity: 1 });
      }, 10);
    }

    linkRedirect(event) {
      const hrefElement = event.currentTarget.getAttribute("href");
      event.preventDefault();
      window.open(hrefElement);
    }

    socialmediaclick(event) {
      event.preventDefault();
    }

    componentWillReceiveProps() {
      const tags = document.getElementsByTagName("a");
      for (const tag of tags) {
        tag.removeEventListener("click", this.linkRedirect);
        tag.removeEventListener("click", this.socialmediaclick);
      }
    }

    componentDidUpdate() {
      const urlOrigin = window.location.origin;
      // if (!window.location.href.includes("/members")) {
      //   const SelectedAlphabet = sessionStorage.getItem("selectedAlphabet");
      //   if (SelectedAlphabet) {
      //     sessionStorage.removeItem("selectedAlphabet");
      //   }
      // }
      if (!window.location.href.includes("/news") && (!window.location.href.includes("/members"))
      &&(!window.location.href.includes("/podcast"))) {
        setTimeout(() => {
          const tags = document.getElementsByTagName("a");
          for (const tag of tags) {
            if (tag && tag.href && !tag.href.startsWith(urlOrigin) && !(tag.href.startsWith("tel") || tag.href.startsWith("mailto"))) {
              if (tag.href.includes("facebook") || tag.href.includes("twitter") || tag.href.includes("linkedin")) {
                tag.addEventListener("click", this.socialmediaclick)
              }
              else {
                if (!tag.href.includes("www.phactmi.org")) {
                  tag.href = `/redirect?url=${tag.href}`;
                  tag.addEventListener("click", this.linkRedirect);
                }
              }
            }
          }
        }, 1500);
      }
      else if (window.location.href.includes("/podcast")) {
        const tags = document.getElementsByTagName("a");
        for (const tag of tags) {
          if (tag && tag.href && !tag.href.startsWith(urlOrigin) && !(tag.href.startsWith("tel") || tag.href.startsWith("mailto"))) {
            if (!tag.href.includes("phactmi.org")) {
              tag.href = `/redirect?url=${tag.href}`;
              tag.addEventListener("click", this.linkRedirect)
            }
            else {
              tag.addEventListener("click", this.linkRedirect)
            }
          }
        }
      }
      else {
        const tags = document.getElementsByTagName("a");
        for (const tag of tags) {
          if (tag.href.includes("facebook") || tag.href.includes("twitter") || tag.href.includes("linkedin")) {
            tag.addEventListener("click", this.socialmediaclick)
          }
        }
      }
    }

    componentWillUnmount() {
      const tags = document.getElementsByTagName("a");
      for (const tag of tags) {
        tag.removeEventListener("click", this.linkRedirect);
      }
    }

    render() {
      return (
        <MainContainer containerOpacity={this.state.containerOpacity} >
          <WrappedComponent {...this.props} />
        </MainContainer>
      )
    }
  }
}
