import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const CovidPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 75px;
  font-size: 20px;
  position: relative;
  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: ${props => props.fromMemberContact ? "73px" : "32px"};
  }
  @media ${ipadProRange} {
    padding-top: 80px;
  }
`;

export const BannerTitle = styled.h1`
  font-size: 30px;
  line-height: 1.35;
  letter-spacing: -0.44px;
  text-align: left;
  color: ${colors.sherpa_blue};
  font-weight: 300;
  @media ${mobileRange} {
    font-size: 24px;
    padding-top: 36px;
    padding-top: ${props => props.fromMemberContact ? "75px" : "36px"};
    text-align: center;
  }
  @media ${tabletRange} {
    padding-left: 62px;
    font-size: 24px;
  }
  @media ${ipadProRange} {
    padding-left: 62px;
  }
`;

export const BannerContent = styled.h2`
  line-height: 1.36;
  letter-spacing: -0.29px;
  text-align: left;
  color: ${colors.scorpion};
  font-size: 22px;
  width: 750px;
  padding-top: 39px;
  font-weight: 300;
  @media ${mobileRange} {
    display: none
  }
  @media ${tabletRange} {
    padding: 13px 0 0 62px;
    font-size: 16px;
    width: 415px;
  }
  @media ${ipadProRange} {
    padding-left: 62px;
  }
`;

export const CovidContent = styled.div`
  background: ${colors.white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  min-height: 700px;
  margin-top: 75px;
  @media ${mobileRange} {
    margin-top: ${props => props.fromMemberContact ? "75px" : "40px"};
  }
  @media ${tabletRange} {
    margin-top: ${props => props.fromMemberContact ? "75px" : "50px"};
  }
`;

export const ContentWrapper = styled.div`
  width: 50%;
  border-bottom: ${props => props.showBottomBorder ? `solid 1px ${colors.light_grey_black}` : "none"};
  min-height: 130px;
  display: flex;
  align-items: center;
  border-right: ${props => props.showRightBorder ? `solid 1px ${colors.light_grey_black}` : "none"};
  @media ${mobileRange} {
    display: block;
    border-right: none;
    width: 100%;
    padding: 30px 0;
    border-bottom: ${props => props.showMobileBottomBorder ? `solid 1px ${colors.light_grey_black}` : "none"};
  }
  @media ${tabletRange} {
    display: block;
    min-height: 157px;
  }
`;

export const MemberImage = styled.div`
  padding-left: 5px;
  @media ${tabletRange} {
    padding-top: 7px;
  }
  img {
    width: 150px;
  }
`;
export const Content = styled.div`
  padding-left: 15px;
`;

export const CallWrapper = styled.div`
font-size: 18px;
ine-height: 1.22;
letter-spacing: -0.23px;
text-align: left;
@media ${tabletRange} {
  font-size: 16px;
}
  img {
    width: 17px;
  }
  span {
    padding-left: 9px;
    a {
      text-decoration: underline;
      color: ${colors.nav_blue};
    }
    a:hover {
      color: ${colors.bondi_blue};
    }
  }
`;

export const MailWrapper = styled.div`
  font-size: 18px;
  ine-height: 1.22;
  letter-spacing: -0.23px;
  text-align: left;
  color: ${colors.bondi_blue};
  width: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media ${tabletRange} {
    font-size: 16px;
  }
  img {
    width: 17px;
  }
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    padding-left: 12px;
    a {
      color: ${colors.bondi_blue}
    }
  }
`;

export const CovidContentWrapper = styled.div`
  display: flex;
  padding: 40px 50px 60px;
  flex-wrap: wrap;
  @media ${mobileRange} {
    padding: 0 35px 30px; 
  }
  @media ${tabletRange} {
    padding: 40px 38px 40px;
  }
  .covid-description {
    font-size: 22px;
    color: ${colors.scorpion};
    text-align: center;
    padding: 0 20px 40px;
    margin: 0;
    @media ${mobileRange} {
      padding: 40px 0 0;
      font-size: 16px;
    }
    @media ${tabletRange} {
      font-size: 20px;
    }
  }
`;

export const BannerContentMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    h2 {
      font-size: 14px;
      font-weight: 300;
      width: 300px;
      line-height: 1.57;
      text-align: left;
      letter-spacing: -0.18px;
      color: ${colors.scorpion};
    }
  }
`;
