import _get from "lodash/get";

const URLRewriter = (browserHistory, hash) => {
  if (browserHistory) {
    let newURL;
    const location = _get(browserHistory, "location.pathname", "");
    if (hash !== undefined) {
      hash = encodeURIComponent(hash).replace("%23", "#");
      if (location && location.lastIndexOf("/") > 0)
        newURL = location.slice(0, location.lastIndexOf("/"));
      else
        newURL = location;
      if (location.includes("contact"))
        browserHistory.replace(`${newURL}/${hash}`);
      else
        browserHistory.replace(`${newURL}/${hash}`, browserHistory.location.state);
    }
    else
      browserHistory.replace(`${location}`, browserHistory.location.state);
  }
}

export default URLRewriter;
