import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const CareerPage = styled.div`
  width: 100%;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 90px;
  font-size: 20px;
  position: relative;
  height: 100%;
  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${tabletRange} {
    padding-top: 76px;
  }
  @media ${ipadProRange} {
    padding-top: 80px;
  }
`;

export const HeaderSection = styled.h1`
  margin-left: 75px;
  @media ${mobileRange} {
    margin: 70px 0 0;
    text-align: center;
  }
`;

export const HeaderMain = styled.div`
  font-size: 30px;
  font-weight: 300;
  line-height: 0.92;
  text-align: left;
  color: ${colors.sherpa_blue};
  @media ${mobileRange} {
    font-size: 30px;
    line-height: 1.42;
    text-align: center;
  }
  @media ${tabletRange} {
    font-size: 30px;
    line-height: 0.72;
  }
  span {
    font-weight: normal;
  }
`;

export const HeaderSub = styled.div`
  font-size: 30px;
  font-weight: 300;
  line-height: 1.35;
  text-align: left;
  color: ${colors.sherpa_blue};
  margin: 10px 0 0 2px;
  @media ${mobileRange} {
    font-size: 40px;
    line-height: 0.73;
    margin: 0;
    text-align: center;
  }
  @media ${tabletRange} {
      font-size: 31px;
      line-height: 1.48;
      margin: 6px;
  }
`;

export const MainSection = styled.div`
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  margin-top: 88px;
  a {
    color: ${colors.bondi_blue};
    text-decoration: none;
  }
  @media ${ipadProRange} {
    margin-top: 80px;
  }
`;

export const OpenPositionsSection = styled.div`
  text-align: center;
  padding: 60px 0 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${mobileRange} {
    padding: 30px 0 50px;
  }
  @media ${tabletRange} {
    padding: 30px 0 65px;
  }
`;

export const OpenPositionHeading = styled.h2`
  font-size: 26px;
  line-height: 1.2;
  color: ${colors.sherpa_blue};
  margin-bottom: 0;
  @media ${mobileRange} {
    font-size: 22px;
  }
  @media ${tabletRange} {
    font-size: 22px;
  }
`;

export const OpenPositionDescription = styled.p`
  font-size: 16px;
  line-height: 1.48;
  max-width: 410px;
  letter-spacing: 0.58px;
  color: ${colors.dove_gray};
  margin-bottom: 0;
  @media ${mobileRange} {
    font-size: 18px;
    line-height: 1.61;
    max-width: 310px;
  }
  @media ${tabletRange} {
    font-size: 18px;
    line-height: 1.61;
  }
`;

export const PositionSection = styled.div`
  text-align: left;
  padding: 0 94px 56px;
  @media ${mobileRange} {
    padding: 0 13px 56px 26px;
  }
  @media ${tabletRange} {
    padding: 0 46px 56px;
  }
  ::before {
    content: " ";
    width: 100%;
    height: 1px;
    margin-bottom: 56px;
    background: ${colors.silver};
    display: ${props => props.displayTopBorder ? "block" : "none"};
  }
`;

export const PositionHeading = styled.div`
  font-size: 20px;
  line-height: 1.44;
  color: ${colors.sherpa_blue};
  @media ${mobileRange} {
    font-size: 18px;
  }
  @media ${tabletRange} {
    font-size: 18px;
    line-height: 1;
  }
`;

export const Company = styled.div`
  font-size: 18px;
  line-height: 1.71;
  margin: 6px 0;
  @media ${mobileRange} {
    font-size: 16px;
    line-height: unset;
    margin: 0;
  }
  @media ${tabletRange} {
    font-size: 16px;
    line-height: 2.25;
    margin: 0;
  }
`;

export const ContactSection = styled.div`
  font-size: 16px;
  line-height: 1.83;
  display: flex;
  flex-direction: row;
  color: ${colors.dove_gray};
  @media ${mobileRange} {
    flex-direction: column;
    font-size: 15px;
    line-height: 2.2;
    padding: 5px;
  }
  @media ${tabletRange} {
    font-size: 15px;
    line-height: unset;
  }
`;

export const SingleContact = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  ::before {
    content: " ";
    width: 2px;
    height: 50%;
    background: ${colors.silver};
    align-self: center;
    display: ${props => props.displayLeftBorder ? "block" : "none"};
    @media ${mobileRange} {
      display: none;
    }
  }
  @media ${mobileRange} {
    margin-right: 0;
  }
`;

export const TitleWrapper = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  width: 590px;
  @media ${mobileRange} {
    margin-right: 0;
    width: 100%;
  }
  @media ${tabletRange} {
    width: 100%;
  }
`;

export const LocationImage = styled.img`
  height: 25px;
  margin-right: 10px;
  @media ${mobileRange} {
    height: 17px;
    margin-right: 10px;
  }
  @media ${tabletRange} {
    height: 17px;
    margin-right: 10px;
  }
`;

export const PostedImage = styled.img`
  height: 23px;
  margin: 0 10px 0 20px;
  @media ${mobileRange} {
    height: 14px;
    margin-left: 0;
  }
  @media ${tabletRange} {
    height: 16px;
  }
`;

export const ContactImage = styled.img`
  height: 20px;
  margin: 0 10px 0 20px;
  @media ${mobileRange} {
    height: 12px;
    margin-left: 0;
  }
  @media ${tabletRange} {
    height: 13px;
  }
`;

export const RoleSection = styled.div`
  margin-top: 40px;
  @media ${mobileRange} {
    margin-top: 15px;
  }
  @media ${tabletRange} {
    margin-top: 30px;
  }
`;

export const SectionHeading = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.71;
  color: ${colors.sherpa_blue};
`;

export const SectionDescription = styled.div`
  font-size: 16px;
  line-height: 1.67;
  color: ${colors.dove_gray};
  ol, ul {
    padding-left: 20px;
  }
  ol > li {
    margin-bottom: inherit;
  }
  @media ${mobileRange} {
    font-size: 16px;
    line-height: 1.69;
  }
  @media ${tabletRange} {
    font-size: 16px;
    line-height: 1.69;
  }
`;

export const QualificationSection = styled.div`
  margin-top: 40px;
  @media ${mobileRange} {
    margin-top: 33px;
  }
  @media ${tabletRange} {
    margin-top: 30px;
  }
`;

export const ApplyButtonSection = styled.div`
  @media ${mobileRange} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0;
  }
`;

export const ApplyButton = styled.span`
  border-radius: 3px;
  border: none;
  background-color: ${colors.sherpa_blue};
  font-size: 18px;
  padding: 10px 28px;
  text-align: center; 
  text-decoration: none;
  display: inline-block;
  color: ${colors.white} !important;
  cursor: pointer;
  @media ${mobileRange} {
    padding: 10px 20px;
  }
  @media ${tabletRange} {
    padding: 10px 18px;
  }
`;

export const NoResultContainer = styled.div`
  @media ${mobileRange} {
    margin-top: 60px;
  }
`;
