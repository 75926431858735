import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptopL } from "../../common/styles/breakpoints";

export const SearchInput1 = styled.input`
  border: none;
  margin-left: ${props => props.forKeyword ? "0" : "25px"};
  font-size: 16px;
  color: ${colors.sherpa_blue};
  flex: 2;
  letter-spacing: -0.23px;
  padding-left: ${props => props.forKeyword ? "25px" : "0"};
  width: ${props => props.forKeyword ? "calc(100% - 160px)" : "520px"};
  margin-right: ${props => props.clearButton ? "0" : "39px"};
  max-width: ${props => props.forKeyword ? "calc(100% - 160px)": "520px"};
  &:focus{
    outline: none;
  }
  ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder , :-moz-placeholder  {
    color: ${colors.gray}
    opacity: 0.6;
  }
  ::-ms-clear {
    display: none;
}
  @media ${mobileRange} {
    font-size: 12px;
    margin-left: 8px;
    letter-spacing: -0.16px;
    padding-left: 0;
    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px;
      padding-left: 0;
      ::-webkit-input-placeholder {
        font-size: 12px;
        margin-left: 0;
        letter-spacing: -0.16px;
        line-height: inherit;
        padding: 3px 0;
      }
    }
  }
  @media ${tabletRange} {
    font-size: 15px;
    letter-spacing: -0.2px;
    margin-left: ${props => props.forKeyword ? "0" : "15px"};
    padding-left: ${props => props.forKeyword ? "15px" : "0"};
    width: ${props => props.forKeyword ? "calc(100% - 136px)" : "230px"};
    max-width: 100%;
  }
  @media ${ipadProRange} {
    width: ${props => props.forKeyword ? "calc(100% - 136px)" : "230px"};
    max-width: 100%;
  }
  @media ${mobileRange} {
    font-size: 15px;
    margin-left: 15px;
    letter-spacing: -0.2px;
    max-width: ${props => props.forKeyword ? "calc(100% - 10px)": "95%"};
    min-height: 45px;
  }
`;
export const ClearInputButton = styled.button`
  border: none;
  margin-right: 14px;
  background-color: ${colors.white};
  &:focus{
    outline: none;
  }
  img {
    width: 13px;
  }
`;
export const SearchButton = styled.button`
  width: 160px;
  background-color: ${colors.sherpa_blue};
  color: ${colors.white};
  font-size: 19px;
  height: 70px;
  padding: 0;
  border: 1px solid ${colors.sherpa_blue};
  border-radius: 7px;
  letter-spacing: -0.26px;
  &:focus{
    outline: none;
  }
  img {
    display: none;
  }
  @media ${mobileRange} {
    width: 40%;
    height: 42px;
    &.mobileSearch {
      border-radius: 7px;
    }
  }
  @media ${tabletRange} {
    height: 50px;
    width: 76px;
    letter-spacing: -0.25px;
    font-size: 14px;
  }
  :disabled, [disabled]{
    color: ${colors.gumbo_approx};
    cursor: not-allowed;
  }
`;
export const SuggestionWrapper = styled.ul`
  position: absolute;
  border-radius: 3px;
  border: solid 1px ${colors.sherpa_blue};
  background-color: ${colors.white};
  list-style: none;
  margin-top: ${props => props.topMargin + "px"};
  max-height: 208px;
  overflow-y: auto;
  padding: 0;
  z-index: 10;
  width: 97%;
  .normal-text {
    pointer-events: none;
    font-weight: normal;
    color: ${colors.sherpa_blue};
  }
  @media ${mobileRange} {
    margin-top: 55px;
    width: ${props => props.fromHomePage ? "100%" : "90%"};
  }
  @media ${tabletRange} {
    margin-top: 53px;
    width: 97%;
  }
  @media ${ipadProRange} {
    width: 97%;
  }
`;
export const SuggestionItem = styled.li`
  font-size: 18px;
  padding: 10px 20px;
  line-height: 1.61;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  color: ${colors.black};
  word-break: break-all;
  :hover, :focus, &.active-suggestion {
    background-color: rgba(109,204,191,.2);
    outline: none;
  }
  @media ${mobileRange} {
    font-size: 14px;
    padding: 4px 20px;
  }
`;
export const KeyWordSearchWrapper = styled.div`
  display: flex;
  border-left: 1px solid ${colors.black};
  width: 56%;
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    width: 60%;
  }
  @media ${ipadProRange} {
    width: ${props => props.fromHomePage ? "56%" : "58%"}
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: unset;
  }
`;

export const KeyWordSearchWrapperMobile = styled.div`

  display: none;
  @media ${mobileRange} {
    display: block;
    margin-top: 5px;
  }
`;

export const KeywordInputMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: flex;
    border: 1px solid ${colors.sherpa_blue};
    width: 100%;
    border-radius: 7px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #F9F9F9;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  @media ${mobileRange} {
    flex-direction: column;
    background: ${colors.white};
  }
  .searchMainContainer {
    display: flex;
    @media ${mobileRange} {
      display: block;
      flex-wrap: wrap;
    }
  }
  .mainSearch {
    display: flex;
    width: 45%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 47%;
    }
    @media ${tabletRange} {
      width: 40%;
    }
    @media ${ipadProRange} {
      width: ${props => props.fromHomePage ? "44%" : "42%"}
    }
    @media ${mobileRange} {
      border: 1px solid ${colors.sherpa_blue};
      border-radius: 3px;
      width: 100%;
    }
  }
`;

export const InputBoxWrapper = styled.div`
  margin-right: 12px;
  box-shadow: 0px 3px 6px #84959D4A;
  border: 1px solid #004251;
  background: ${colors.white};
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  height: ${props => props.changeHeight ? "auto" : "70px"};
  align-items: center;
  @media ${tabletRange} {
    height: 50px;
  }
  @media ${ipadProRange} {
    height: 70px;
  }
  @media ${mobileRange} {
    width: 100%;
    min-height: 50px;
    height: auto !important;
    padding-left:0;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #F9F9F9;
  }
`;

export const InputBoxDiv = styled.div`
  position: relative;
  width: 40%;
  @media ${ipadProRange} {
    width: 41.5%;
  }
  @media ${tabletRange} {
    width: 44%;
  }
  @media ${mobileRange} {
    width: 100%;
  }
  @media ${minLaptopL} {
    width: 42%;
  }
`;
export const KeywordBoxWrapper = styled.div`
  width: 70%;
  box-shadow: 0px 3px 6px #84959D4A;
  border: 1px solid #004251;
  background: ${colors.white};
  border-radius: 7px;
  margin-right: 12px;
  display: flex;
  height: 70px;
  @media ${tabletRange} {
    height: 50px;
    width: 100%;
  }
  @media ${ipadProRange} {
    width: 67%;
  }
`;
export const SearchButtonWrapper = styled.div`
  width: 20%;
`;
export const KeywordInput = styled.input`
  border: none;
  margin: 10px;
  width: 90%;
  font-size: 16px;
  color: rgb(0, 66, 81);
  padding-left: 10px;
  &:focus{
    outline: none;
  }
  ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder , :-moz-placeholder  {
    color: ${colors.gray}
    opacity: 0.6;
  }
  ::-ms-clear {
    display: none;
}
@media ${tabletRange} {
  font-size: 12px;
} 
`;
export const SearchInput = styled.input`
  border: none;
  margin: 10px 5px 10px 0;
  font-size: 16px;
  color: rgb(0, 66, 81);
  width: 100%;
  &:focus{
    outline: none;
  }
  ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder , :-moz-placeholder  {
    color: ${colors.gray}
    opacity: 0.6;
  }
  ::-ms-clear {
    display: none;
  }
  @media ${tabletRange} {
    font-size: 12px;
  }

  @media ${mobileRange} {
    margin: 10px 0 10px 5px;
    background: #F9F9F9;
    color: #121313;
    font-size: 14px;
    font-weight: 400;
    ::placeholder {
      color: #878787;
    }
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const SearchInputWrapper = styled.div`
  display: flex;
  width: 95%;
`;
export const KeywordWrapper = styled.div`
  width: 60%;
  @media ${mobileRange} {
    width: 100%;
  }
  @media ${tabletRange} {
    width: 55%;
  }
  @media ${ipadProRange} {
    width: 55%;
  }
  @media ${minLaptopL} {
    width: 58%;
  }
`;
export const BoxWrapper = styled.div`
  display: flex;
  @media ${mobileRange} {
    display: none;
  }
  @media ${tabletRange} {
    justify-content: space-between;
  }
`;
export const PoweredBySection= styled.div`  
  width: 30%;
  display: block;
  @media ${mobileRange} {
    display: none;
  }
  @media ${ipadProRange} {
    margin-left: 0;
    width: 39%;
  }
  @media ${tabletRange} {
    width: 43%;
  }
`;
export const ErrorWrapper = styled.div`
  display: flex;
  width: 435px;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-left: 2px;
  align-items: center;
  margin-top: 8px;
  min-height: 30px;
  img {
    width: 20px;
    height: 16px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    padding: 7px 0;
    display: none;
  }
  @media ${tabletRange} {
    padding-top: 6px;
    width: 75%;
    font-size: 12px;
  }
  @media ${ipadProRange} {
    width: 75%;
    font-size: 14px
  }
`;

export const ErrorDropWrapper = styled.div`
  display: flex;
  width: 98%;
  color: ${colors.dark_red};
  font-size: 14px;
  padding-top: 10px;
  padding-left:4px;
  backgroundColor: ${props => props.fromHomePage ? colors.catskill_white_gray : "transparent"}
  img {
    margin-top: 3px;
    width: 20px;
    height: 16px;
  }
  p {
    padding-left: 5px;
    margin: 0;
  }
  @media ${mobileRange} {
    width: 100%
    text-align:left;
    padding-bottom: 5px;
    backgroundColor: #FFF;
  }
  @media ${tabletRange} {
    margin-top: 7px
    padding-top: 6px;
    width: 98%;
    font-size: 12px;
  }
  @media ${ipadProRange} {
    width: 98%;
    font-size: 14px;
  }
`;

export const PoweredSectionWrapper = styled.div`
  display: flex;
`;

export const ToolTipWrapperMobile = styled.div`
  display: flex;
`;

export const HelpToolTipSection = styled.div`
  margin-top: 15px;
  @media ${mobileRange} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    margin-top: 0;
  }
`;

export const ButtonHelpSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

export const JaanpErrorPopup = styled.div`
  position: absolute;
  background-color: #004251;
  color: white;
  font-size: 14px;
  font-weight: normal;
  width: 95%;
  margin-top: 16px;
  padding: 16px 16px 24px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  ::before {
    content: "";
    position: absolute;
    bottom: 99%;
    left: 24px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.sherpa_blue} transparent;
    @media ${mobileRange} {
      left: 5%;
    }
  }
  p{
    margin: 0;
  }
  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
`;