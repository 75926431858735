/* eslint-disable max-len */
import SkipButtonLogo from "../../assets/tour-images/skip_button.png";
import TourLogo from "../../assets/tour-images/tour_logo.png";

export default {
  data: [
    {
      "heading": "Looking for information about a specific medication?",
      "content": "You can start your experience by entering your <span>search terms</span> here.",
      "target": ".searchAccordian"
    },
    {
      "heading": "Product label lists",
      "content": "We provide prescribing information for a <span>comprehensive list</span> of US manufactured and approved pharmaceutical products.",
      "target": ".suggestionWrapper"
    },
    {
      "heading": "Which one are you?",
      "content": "During the initial search only, we require medical professionals to self-identify. <span>No further log in is required.</span>",
      "target": ".Hcp_popup"
    },
    {
      "heading": "After entering search terms, find information here",
      "content": "Search results contain both <span>Prescribing Information</span> as well as <span>Drug Information Responses</span> focused on a specific topic.",
      "target": ".Tabs"
    },
    {
      "heading": "More on medical information",
      "content": "Click this tab to see Drug Information Responses. Your <span>search terms</span> will be <span>highlighted in yellow.</span>"+
      "<br><br>Note : This feature is new to the PhactMI website and we are continuously adding member content starting in May 2020.",
      "target": ".active-tab"
    },
    {
      "heading": "Expand Section Preview",
      "content": "A portion of a section of the Medical Information Response will be displayed." +
      "<br><br>Click ‘<span>Expand Section</span>’...",
      "target": ".expandAccordian"
    },
    {
      "heading": "View Full Document",
      "content": "Select the ‘<span>View Full Document</span>’ button to display the entire Medical Information Response.",
      "target": ".viewFullDocumentButton"
    },
    {
      "heading": "Members Information",
      "content": "In addition, you can find information about individual phactMI member companies by clicking the <span>'Member Organizations'</span> tab. Here you can get access to each member company's Medical Information department.",
      "target": ".member-tour"
    }
  ],
  tool_tip_position: [
    {
      "top": "400px",
      "left": "300px"
    }
  ],
  "skip_button_logo": SkipButtonLogo,
  "tour_logo": TourLogo,
  "welcome_txt_tour": "Welcome to phactMI.org!",
  "message_txt": "We are a non-profit consortium that supports the safe and effective use of our member company medicines.",
  "start_touring_txt": "Start touring",
  "skip_tour_txt": "Skip Tour",
  "close_txt": "Finish",
  "next_txt": "Next"
}
