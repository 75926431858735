import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const AboutPage = styled.div`
  width: 100%;
  color: ${colors.sherpa_blue};
  background: transparent;
  padding-top: 80px;
  font-size: 20px;
  position: relative;

  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
    padding-top: 75px;
  }
  @media ${tabletRange} {
    padding-top: 80px;
  }
`;
export const HeaderSection = styled.div`
  margin-left: 84px;
  @media ${mobileRange} {
    text-align: center;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }
  @media ${tabletRange} {
    margin: 0 81px 97px 81px;
  }
`;
export const MainHeading = styled.div`
  font-size: 30px;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: -0.44px;
  color: ${colors.sherpa_blue};
  h1 {
    width: 42%;
    margin: 0;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.35;
    letter-spacing: -0.44px;
    @media ${ipadProRange} {
      width: 50%
    }
    @media ${tabletRange} {
      font-size: 24px;
      line-height: 1.38;
    }
    @media ${mobileRange} {
      font-size: 24px;
      line-height: 1.54;
    }
  }
  span {
    font-weight: normal;
  }
  @media ${mobileRange} {
    font-size: 24px;
    display: flex;
    justify-content: center;
    line-height: 1.54;
    letter-spacing: -0.31px;
    h1 {
      width: 90%;
    }
  }
  @media ${tabletRange} {
    font-size: 24px;
    line-height: 1.38;
    letter-spacing: -0.31px;
    h1 {
      width: 57%;
    }
  }
`;
export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 90px;
  color: ${colors.scorpion}
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 82px
  }
  @media ${mobileRange} {
    margin-top: 91px;
  }
`;
export const Manufacturers = styled.div`
  min-height: 250px;
`;
export const FAQ = styled.div`
  background-color: ${colors.catskill_white};
  min-height: 250px;
`;
export const ContactUs = styled.div`
  background-color: ${colors.catskill_gray};
  min-height: 250px;

  @media ${mobileRange} {
    display: none;
  }
`;
export const AdditionalResources = styled.div`
  min-height: 250px;
`;
