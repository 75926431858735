import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import * as styled from "./style";
import resources from "../../config/content/poweredbytip";

/**
 * Tooltip component
 *
 * @class PoweredByToolTip
 * @extends {Component}
 * @exports PoweredByToolTip
 */
class PoweredByToolTip extends Component {

  state = {
    leftPositionTooltip: "0%",
    topPositionTooltip: "112%"
  }

  componentDidMount() {
    this.changeToolTipPosition();
    window.addEventListener("resize", this.changeToolTipPosition);
    window.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changeToolTipPosition);
    window.removeEventListener("touchstart", this.handleClickOutside);
  }

  /**
   * @param  {Object} event Click event
   * @returns {undefined}
   */
  handleClickOutside= (event) => {
    if (!this.entellectText.contains(event.target)) {
      if ( ("ontouchstart" in window) && this.toolTip && this.toolTip.style.display !== "none" && this.toolTip.style.display !== "" ) {
        this.toolTip.style.display = "none";
      }
    }
  }

  /**
   * Change position of tooltip relative to entellectText.
   * @returns {undefined}
   */
  changeToolTipPosition = () => {
    if (this.entellectText) {
      this.setState({
        leftPositionTooltip: this.entellectText.offsetLeft,
        topPositionTooltip: this.entellectText.offsetTop + this.entellectText.offsetHeight
      });
    }
  }

  /**
   * Display or hide tooltip for touch devices.
   * @param  {Event} e - Event created on click.
   * @returns {undefined}
   */
  onCloseTooltip = (e) => {
    if ( "ontouchstart" in window ) {
      this.toolTip.style.display = (this.toolTip.style.display === "block") ? "none" : "block";
      e.stopPropagation();
    }
  }

  render() {
    const { leftPositionTooltip, topPositionTooltip } = this.state;
    const { fromsearchpage, searchError } = this.props;
    const { poweredByText, tooltipContent, closeIcon, entellectIcon, entellect_logo_alt_txt } = resources;
    return (
      <styled.PoweredByContainer fromsearchpage={fromsearchpage}>
        <styled.PoweredByText>
          {poweredByText}
        </styled.PoweredByText>
        <styled.PoweredByButton ref={elm => { this.entellectText = elm }} onTouchEnd={this.onCloseTooltip}>
          <styled.EntellectText ><img src={entellectIcon} alt={entellect_logo_alt_txt}></img> </styled.EntellectText>
          <styled.Tooltip fromsearchpage={fromsearchpage} left={leftPositionTooltip} top={ searchError ? topPositionTooltip + 30 : topPositionTooltip}
            ref={elm => { this.toolTip = elm }} onClick={(e) => { e.stopPropagation(); }}>
            <styled.CloseButton bgImage={closeIcon} onTouchEnd={this.onCloseTooltip}></styled.CloseButton>
            <span dangerouslySetInnerHTML={{ __html: tooltipContent }} onTouchEnd={(e) => { e.stopPropagation(); }}></span>
          </styled.Tooltip>
        </styled.PoweredByButton>
      </styled.PoweredByContainer>
    )
  }
}

PoweredByToolTip.propTypes = {
  poweredByText: PropTypes.string,
  tooltipContent: PropTypes.string,
  closeIcon: PropTypes.string,
  entellectIcon: PropTypes.string
}

export default memo(PoweredByToolTip);
