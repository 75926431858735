import React from "react";
import Covid from "../covid-19";
import { endpoint } from "../../config/app.config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withTransition from "../../components/with-transition";
import resources from "../../config/content/covidPage";
import * as actions from "./actions";

const mapStateToProps = state => {
  return {
    memberContactResource: state.memberContactReducer.memberContactResource
  };
};

class MemberContactInfo extends React.Component {

  componentDidMount() {
    const { memberContactResource, getMemberCompanyInfo } = this.props;
    if (!memberContactResource) {
      getMemberCompanyInfo();
    }
  }

  render() {
    const { memberContactResource } = this.props;
    const bannerTitle = "phactMI <b>Member Company Information</b>";
    return (
      <Covid memberResource={memberContactResource} fromMemberContact={true} bannerTitle={bannerTitle}></Covid>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(MemberContactInfo));
