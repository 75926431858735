import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import { getPodcastApi } from "./api";

export function* getPodcastDataWorker() {
  try {
    const data = yield call(getPodcastApi);
    yield put({
      type: actions.GET_PODCAST_SUCCESS,
      payload: {
        result: data.data
      }
    })
  } catch (err) {
    yield put({
      type: actions.GET_PODCAST_FAIL
    })
  }
}

export function* getPodcastDataWatcher() {
  yield takeLatest(actions.GET_PODCAST, getPodcastDataWorker)
}

export default {
  getPodcastDataWatcher
}
