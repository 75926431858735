import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import Recaptcha from "react-recaptcha";
import _get from "lodash/get";
import resources from "../../config/content/homePopUp";
import * as styled from "./styles";
import * as actions from "./actions";
import healthcareProfessionals from "../../assets/images/Healthcare_professionals.png";
import tick from "../../assets/images/tick-2.svg";
import patientCaregiver from "../../assets/images/patient-caregiver.png";

const mapStateToProps = state => {
  return {
    roles: state.rolesReducer.roles
  };
};

/**
 * SearchPopUp component
 * @class SearchPopUp
 * @extends {Component}
 * @exports SearchPopUp
 */
class SearchPopUp extends Component {

  static proptype = {
    radio_header1_txt : PropTypes.string,
    radio_header2_txt: PropTypes.string,
    success_icon: PropTypes.s,
    alt_icon: PropTypes.string,
    button_txt: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.object)
  }

  state = {
    showinputbox: false,
    showimage: false,
    rolesvalue: "",
    sorrymessage: false,
    isSubmitButtonDisable: true,
    roleDescription: "",
    checkboxChecked: false,
    recaptchaChecked: true,
    checkboxFlag: false,
    degreeSelectFlag: false
  }

  componentDidMount() {
    const { roles, getRoles, popUpCalled } = this.props;
    window.scrollTo(0, 0);
    if (_isEmpty(roles)) {
      getRoles();
    }
    popUpCalled();
  }

  /**
    * Radio button change handler
    * @param {string} e target value
    * @param {string} description target value
    * @returns {undefined}
    */
   handlechange = (value, description) => {
     const { radio_header2_txt, other } = resources;
     const { recaptchaChecked, checkboxChecked, degreeSelectFlag } = this.state;
     const { onSubmitHandle } = this.props;
     if (value.toUpperCase() === other.toUpperCase()) {
       this.setState({
         showinputbox: true,
         showimage: true,
         roleDescription: description,
         isSubmitButtonDisable: true
       })
     }
     else if (value.toUpperCase() === radio_header2_txt.toUpperCase()) {
       this.setState({
         sorrymessage: true
       })
       this.handlesubmit(value, description)
     }
     else {
       this.setState({
         rolesvalue: value,
         showinputbox: false,
         showimage: true,
         roleDescription: description,
         isSubmitButtonDisable: false
       })
       this.handlesubmit(value, description)
     }
   }

  /**
    * input change handler
    * @param {string} e target value
    * @returns {undefined}
    */
  handleinput = e => {
    const rolesvalue = e.target.value;
    const whitespaceRegex = /^\s*$/;
    if (rolesvalue && whitespaceRegex.test(rolesvalue)) {
      this.setState({
        rolesvalue: e.target.value,
        submitdata: false,
        isSubmitButtonDisable: true
      })
      return;
    }

    if ( rolesvalue && rolesvalue.length > 2) {
      this.setState({
        rolesvalue: e.target.value.trim(),
        submitdata: true,
        isSubmitButtonDisable: false
      })
    }
    else {
      this.setState({
        rolesvalue: e.target.value,
        submitdata: false,
        isSubmitButtonDisable: true
      })
    }
  }

  /**
    * submit button handler
    * @returns {undefined}
    */
    handlesubmit = (rolesvalue, roleDescription) => {
      const { radio_header2_txt } = resources
      const { onSubmitHandle, setRole, roleClicked } = this.props
      const { degreeSelectFlag } = this.state;
      if (roleClicked) {
        roleClicked()
      }
      if (rolesvalue.toUpperCase() === radio_header2_txt.toUpperCase() || rolesvalue.toLowerCase().includes("patient")
      || rolesvalue.toLowerCase().includes("consumer") || rolesvalue.toLowerCase().includes("caregiver")) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "No degree selected"
        });
        this.setState({
          sorrymessage: true
        })
        if (onSubmitHandle) {
          onSubmitHandle(false, "NE");
        }
      }
      else if (rolesvalue) {
        if (!degreeSelectFlag) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "degree selected"
          });
          this.setState({
            degreeSelectFlag: true
          })
        }
        setRole(rolesvalue)
        if (onSubmitHandle) {
          onSubmitHandle(true, rolesvalue, roleDescription)
        }
      }

      if ( rolesvalue && roleDescription) {
        localStorage.setItem("selectedDegree", `{"role": "${rolesvalue}", "roleDescription": "${roleDescription}"}`);
        sessionStorage.setItem("selectedDegree", `{"role": "${rolesvalue}", "roleDescription": "${roleDescription}"}`);
      }
    }

   /**
    * recaptcha handler
    * @returns {undefined}
    */
   recaptchaLoaded = () => {
     const { checkboxChecked, rolesvalue } = this.state;
     if (checkboxChecked && rolesvalue && rolesvalue.length > 0) {
       this.setState({
         submitdata: true,
         isSubmitButtonDisable: false,
         recaptchaChecked: true
       })
     }
     else {
       this.setState({
         recaptchaChecked: true
       })
     }
   }

   /**
   * recaptcha reset handler
   * @returns {undefined}
   */
  resetcaptcha = () => {
    this.setState({
      submitdata: false,
      isSubmitButtonDisable: true
    })
  }

  handleCheckBox = () => {
    const { recaptchaChecked, checkboxChecked, rolesvalue, checkboxFlag } = this.state;
    if (recaptchaChecked) {
      if (!checkboxChecked && rolesvalue.length > 0) {
        this.setState({
          checkboxChecked: !checkboxChecked,
          submitdata: true,
          isSubmitButtonDisable: false
        })
      }
      else {
        this.setState({
          checkboxChecked: !checkboxChecked,
          submitdata: false,
          isSubmitButtonDisable: true
        })
      }
      if (!checkboxFlag) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "consent checkbox selected"
        });
        this.setState({
          checkboxFlag: true
        });
      }
    }
    else {
      this.setState({
        checkboxChecked: !checkboxChecked
      });
    }
  }

  render() {
    const role = _get(this.props.roles, "data", {});
    const { verify_text, radio_header1_txt, no, yes, radio_header2_txt, success_icon, alt_icon, button_txt, sitekey, disclaimer, disclaimer_txt, other} = resources;
    const { sorrymessage, showimage, showinputbox, isSubmitButtonDisable, rolesvalue, roleDescription} = this.state;
    const { data : {description, heading} } = this.props;

    return (
      <div style={{width: "100%"}}>
        {!sorrymessage &&
                  <styled.MainContainer className="mcont">
                    <styled.SearchPopContainer className="scont">
                      <styled.PopUpWrapper>
                        <styled.HeathCareSection>
                          <styled.Heading1Section>
                            <styled.ReadioHeader>
                              <p>{verify_text}</p>
                              <p>{radio_header1_txt}</p>
                            </styled.ReadioHeader>

                            <styled.HealthcareHeadingMobile>
                              <styled.ReadioHeaderMobile>
                                <p>{verify_text}</p>
                                <p>{radio_header1_txt}</p>
                              </styled.ReadioHeaderMobile>
                              {/* <styled.HealthcareImageMobile>
                                <img src={healthcareProfessionals} alt="healthcare professional image" />
                              </styled.HealthcareImageMobile> */}
                            </styled.HealthcareHeadingMobile>

                            <styled.RoleSection id="hcp-degree-container">
                              {Object.keys(role).map((index) =>
                                role[index].uiFlag ?
                                  <styled.HealthcareButton key={role[index]._id}
                                    onClick={() => this.handlechange(role[index].name, role[index].description)}>
                                    {(showinputbox && role[index].name == other) && <img src={tick} />} {role[index].name}
                                  </styled.HealthcareButton>
                                  : null
                              )}
                            </styled.RoleSection>
                            {showinputbox && <styled.CustomRoleSection><input type="text" onChange={this.handleinput} />
                              <styled.SubmitButton onClick={() => this.handlesubmit(rolesvalue, roleDescription)} disabled={isSubmitButtonDisable}>
                                Submit
                              </styled.SubmitButton>
                            </styled.CustomRoleSection>}
                          </styled.Heading1Section>
                          {/* <styled.HealthcareImage>
                            <img src={healthcareProfessionals} alt="healthcare professional image" />
                          </styled.HealthcareImage> */}
                        </styled.HeathCareSection>
                        {/* {
                          <styled.DescriptionWrapperMobile>
                            <p>{heading}</p>
                            <styled.DescriptionMobileIpad>
                              <styled.RememberActions>
                                <label className="checkbox-container">
                                  <input type="checkbox" ref={this.rememberUserRef} onChange={this.handleCheckBox} id="confirmation_checkbox"/>
                                  <span className="checkmark"></span>
                                </label>
                              </styled.RememberActions>
                              <p>
                                {description}
                              </p>
                            </styled.DescriptionMobileIpad>
                          </styled.DescriptionWrapperMobile>
                        } */}
                        {/* <styled.BorderLine /> */}
                        <styled.Heading2Section>
                          <styled.NotProfessional>
                            <div className="patient-caregiver-img">
                              <img src={patientCaregiver} alt="patient-image" />
                              <span>No, I am not a US Healthcare Professional. I am a </span>
                            </div>
                            <styled.PatientButton onClick={() => this.handlechange(radio_header2_txt, "")}>{radio_header2_txt}</styled.PatientButton>
                          </styled.NotProfessional>
                        </styled.Heading2Section>
                      </styled.PopUpWrapper>
                      <styled.DisclaimerIpadMobile>
                        <p>{disclaimer_txt}</p>
                        {disclaimer}
                      </styled.DisclaimerIpadMobile>
                      <styled.DisclaimerContentWrapper>
                        <styled.Disclaimer>
                          <p>{disclaimer_txt}<strong className="disclaimer-highlight">as either an HCP or patient based on your selection above.</strong></p>
                          <div>{disclaimer}</div>
                        </styled.Disclaimer>
                        {/* {
                          <styled.DescriptionWrapper>
                            <p>{heading}</p>
                            <styled.Description>
                              <styled.RememberActions>
                                <label className="checkbox-container">
                                  <input type="checkbox" ref={this.rememberUserRef} onChange={this.handleCheckBox} id="confirmation_checkbox"/>
                                  <span className="checkmark"></span>
                                </label>
                              </styled.RememberActions>
                              <p>
                                {description}
                              </p>
                            </styled.Description>
                          </styled.DescriptionWrapper>
                        } */}
                      </styled.DisclaimerContentWrapper>
                    </styled.SearchPopContainer>
                    <styled.Captcha>
                      {/* {showimage &&
                      <styled.Recaptcha>
                        <Recaptcha
                          sitekey={sitekey}
                          verifyCallback={this.recaptchaLoaded}
                          expiredCallback={this.resetcaptcha}
                        />
                      </styled.Recaptcha>
                      } */}
                    </styled.Captcha>
                  </styled.MainContainer>
        }
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(SearchPopUp));