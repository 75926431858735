import React from "react";
import PropTypes from "prop-types";
import { endpoint } from "../../config/app.config";
import * as styled from "./styles";
import NewTag from "../../assets/images/new-tag.png";
import PlayWhiteIcon from "../../assets/images/play-white-bg.svg";

/**
 * Additional Resource Card function
 * @param {object} data data
 * @returns {HTMLElement} element
 */
const AdditionalResourceCard = React.memo(({ data, history, newResource, hasImage, onPlayPodcast }) => {

  /**
   * Card clicj function
   * @param {String} resourceId id of resource click
   * @param {String} resourceUrl url of resource
   * @param {String} pageUrl url of page if present
   * @returns {undefined}
   */
  const cardClick = (data) => {
    const { resourceId, resourceUrl, pageUrl } = data
    if (pageUrl) {
      history.push(pageUrl);
    }
    else if (resourceUrl) {
      window.open(resourceUrl)
    } else if (resourceId.toLowerCase().includes("mp3")) {
      onPlayPodcast()
    } else {
      window.open(`${endpoint}/api/assets/files/${resourceId}`);
    }
  }

  /**
   * Card Display function
   * @returns {html} html
   */
  const displayCard = () => {
    return (
      <React.Fragment>
        <styled.CardWrapper onClick={ () => cardClick(data)} hasImage={hasImage}>
          {newResource && <img src={NewTag} alt="new-resource" className="new-tag" />}
          {hasImage && <div style={{ position: "relative"}}>
            <styled.ResourceImage src={`${endpoint}/api/assets/files/${data.posterId}`} alt="podcast-image" />
            <img src={PlayWhiteIcon} alt="play-podcast" style={{ position: "absolute",
              bottom: "0",
              right: "16px"}} onClick={onPlayPodcast} />
          </div>}
          <h3 dangerouslySetInnerHTML={{__html: data.displayName}}></h3>
        </styled.CardWrapper>
        {/* <styled.Description>
          {data.resourceTitle}
        </styled.Description> */}
      </React.Fragment>
    )
  }
  return (
    <styled.MainContainer>
      {displayCard()}
    </styled.MainContainer>
  )
})

AdditionalResourceCard.propTypes = {
  data: PropTypes.object
};

export default React.memo(AdditionalResourceCard);