import React from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import resources from "../../config/content/podCastPage";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import * as styled from "./styles";
import withTransition from "../../components/with-transition";

const mapStateToProps = state => {
  return {
    podCastData: state.podcastReducer.podCastData
  };
};

class Podcast extends React.Component {

  componentDidMount() {
    const { podCastData, getPodcast } = this.props;
    if (podCastData && podCastData.length === 0) {
      getPodcast()
    }
    window.scroll(0, 0);
  }

  displayEposide = (number, title) => {
    const { eposide_txt } = resources;
    return (
      <React.Fragment>
        <styled.EposideWrapper>
          <styled.EposindeNumber>
            {`${eposide_txt} ${number}`}
          </styled.EposindeNumber>
          <styled.EposideTitle>{title}</styled.EposideTitle>
        </styled.EposideWrapper>
      </React.Fragment>
    )
  }

  displayMember = (authorData) => {
    return (
      <styled.AuthorWrapper>
        {
          authorData.map((ele, index) => {
            return (
              <styled.AuthorContent key={index}>
                <span className="authorIcon">
                  <img src={ele.icon}></img>
                </span>
                <div>
                  <div className="authorTitle">{ele.name}</div>
                  <div dangerouslySetInnerHTML={{__html: ele.position}}></div>
                </div>
              </styled.AuthorContent>
            )
          })
        }
      </styled.AuthorWrapper>
    )
  }

  displayPodcastUrl = (url) => {
    return (
      <styled.PodcastUrlWrapper dangerouslySetInnerHTML={{ __html: url }}>
      </styled.PodcastUrlWrapper>
    )
  }

  displayContent = (data, totalsize, index) => {
    return (
      <React.Fragment>
        {this.displayEposide(data.episode, data.title)}
        {this.displayMember(data.author)}
        {this.displayPodcastUrl(data.podcastUrl)}
        <styled.Content dangerouslySetInnerHTML={{__html: data.bodyText}}/>
        {this.displayReference(data.reference)}
        {
          (index < totalsize - 1) &&
          <styled.Border className="border"/>
        }
      </React.Fragment>
    )
  }

  displayReference = (reference) => {
    const { reference_txt } = resources;
    return (
      <React.Fragment>
        {
          reference && reference.length > 0 &&
          <><styled.ReferenceTitle>
            {reference_txt}
          </styled.ReferenceTitle><styled.ReferenceWrapper className="ref">
            {reference.map((ele, index) => {
              return (
                <styled.ReferenceList key={index}>
                  <span dangerouslySetInnerHTML={{__html: ele}}></span>
                </styled.ReferenceList>
              );
            })}
          </styled.ReferenceWrapper></>
        }
      </React.Fragment>
    )
  }

  render() {
    const { podCastData } = this.props;
    const { phactmi_txt, podcast_txt, welcome_txt } = resources;
    return (
      <styled.PodcastPage>
        <Container className="container">
          <styled.TitleWrapper>
            {phactmi_txt}
            <span>{podcast_txt}</span>
          </styled.TitleWrapper>
          <styled.MainContainer>
            <styled.Title>
              {welcome_txt}
            </styled.Title>
            {
              podCastData.map((ele, index) => {
                return (
                  <React.Fragment key={index}>
                    {this.displayContent(ele, podCastData.length, index)}
                  </React.Fragment>
                )
              })
            }
          </styled.MainContainer>
        </Container>
      </styled.PodcastPage>
    )
  }
}

Podcast.propTypes = {
  resources: PropTypes.shape({
    phactmi_txt: PropTypes.string,
    podcast_txt: PropTypes.string,
    welcome_txt: PropTypes.string,
    eposide_txt: PropTypes.string,
    reference_txt: PropTypes.string
  })
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(Podcast));
