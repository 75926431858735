const size = {
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  mobileMin: "310px",
  mobileMax: "767px",
  tabletMin: "768px",
  tabletMax: "1023px",
  tabletProMin: "1024px",
  tabletProMax: "1299px"
};

export const minTablet = `(min-width: ${size.tablet})`;
export const minIPadPro = `(min-width: ${size.tabletProMax})`
export const minLaptop = `(min-width: ${size.laptop})`;
export const minLaptopL = `(min-width: ${size.laptopL})`;
export const mobileRange = `(min-width: ${size.mobileMin}) and (max-width: ${size.mobileMax})`;
export const tabletRange = `(min-width: ${size.tabletMin}) and (max-width: ${size.tabletMax})`;
export const mobileAndTabletRange = `(min-width: ${size.mobileMin}) and (max-width: ${size.tabletMax})`;
export const ipadProRange = `(min-width: ${size.tabletProMin}) and (max-width: ${size.tabletProMax})`;
export const minLaptopRange = `(min-width: ${size.laptop}) and (max-width: ${size.laptopL})`;
