import SuccessImage from "../../assets/images/Success.png";
import Emoji from "../../assets/images/Emoji.png";

/**
 * Site key is google recatpcha key need for recaptcha V2
 */
export default {
  "no": "NO, ",
  "yes": "YES, ",
  "verify_text": "We need to verify that you are a U.S. Healthcare Professional to view this website. Please select the option below that best describes you.",
  "radio_header1_txt":"Yes, I am a US Healthcare Professional.",
  "radio_header2_txt":"Patient/Caregiver/Consumer",
  "success_icon": SuccessImage,
  "alt_icon":"success icon image",
  "emoji":Emoji,
  "alt_emoji":"No Information - logo",
  "title":"We Are Sorry!",
  "subtitle":"This information is only available for Healthcare Professionals",
  "message":"The information contained on phactmi.org is intended for healthcare professionals in the United States only.",
  "sitekey":"6LfnErkUAAAAAJujCugqb_x_DDsWDB-jtNDFh7to",
  "button_txt":"Submit",
  "other": "Other",
  "disclaimer": "By searching, I confirm that my query is unsolicited. Search results may include information that is not consistent with the product’s"
   +" Full Prescribing Information. phactMI does not recommend the use of any product that is inconsistent with the Full Prescribing Information.",
  "disclaimer_txt": "This information is provided as a professional courtesy for informational purposes only. It is not intended to provide medical"+
  " advice for a particular patient. The information is intended to assist you in forming your own conclusions and clinical decisions "
}