import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange } from "../../common/styles/breakpoints";

export const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    div {
        width: 60px;
        height: 1px;
        background-color: ${colors.geyser};
    }
    @media ${mobileRange} {
        display: none;
    }
`;