import CloseIcon from "../../assets/images/help_close.png";

export default {
  "helpTextDesktop": "?",
  "helpTextMobile": "Help ?",
  "tooltipContent": `<ul>
        <li>
        Your search will retrieve the Prescribing Information. In addition,
        one or more Drug Information Responses may be returned.
      These documents are summaries of available scientific data relating to a specific topic and a phactMI Member product.
        </li>
        <li>
      Search results will return documents that match on title, keyword(s) or part of the document.
        </li>
      </ul>`,
  "closeIcon": CloseIcon,
  "youtubelink": "https://www.youtube.com/watch?v=u3TJHlBgPLM"
}
