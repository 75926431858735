import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import List from "../../components/list";
import withTransition from "../../components/with-transition";
import resources from "../../config/content/codeOfPractice";
import * as styled from "./style";

/**
 * Code of practice page
 *
 * @param {object} props properties from parent
 * @returns {HTMLElement} element
 */
const CodeOfPractice = props => {
  window.scrollTo({top: 0});
  const {
    header,
    img_src,
    contentheading,
    desc1_txt,
    desc2_txt,
    listtitle,
    section = [],
    desc3_txt
  } = resources;
  return (
    <styled.CodeOfPracticePage>
      <styled.HeadingContent>
        <Container className="container">{header}</Container>
      </styled.HeadingContent>
      <Container className="body-container">
        <styled.ConditionSection>
          <styled.ImageSection>
            <img src={img_src} alt={header} />
          </styled.ImageSection>
          {renderContent(
            contentheading,
            desc1_txt,
            desc2_txt,
            listtitle,
            section,
            desc3_txt
          )}
        </styled.ConditionSection>
      </Container>
    </styled.CodeOfPracticePage>
  );
};

/**
 * Renders content
 *
 * @param  {string} contentheading string
 * @param  {string} desc1_txt string
 * @param  {string} desc2_txt string
 * @param  {string} listtitle string
 * @param  {string} section string
 * @param  {string} desc3_txt string
 * @returns {HTMLElement} element
 */
const renderContent = (
  contentheading,
  desc1_txt,
  desc2_txt,
  listtitle,
  section,
  desc3_txt
) => {
  return (
    <styled.PracticeSection>
      <styled.ContentHeading>
        <p>{contentheading}</p>
      </styled.ContentHeading>
      <styled.ContentSection>
        <p>{desc1_txt}</p>
        <p>{desc2_txt}</p>
        <ul className="title-list">
          {listtitle.map((value, key) => {
            return <li key={key}>{value}</li>;
          })}
        </ul>
        {section.map((item, index) => {
          return (
            <div key={index} className={index % 2 ? "odd-index" : "even-index"}>
              <div className="para">{item.content}</div>
              <List resources={item.list} />
            </div>
          );
        })}
        <div>{desc3_txt}</div>
      </styled.ContentSection>
    </styled.PracticeSection>
  );
};

CodeOfPractice.prototype = {
  resources: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.object),
    img_src: PropTypes.string,
    header: PropTypes.string,
    contentheading: PropTypes.string,
    desc1_txt: PropTypes.string,
    desc2_txt: PropTypes.string,
    listtitle: PropTypes.arrayOf(PropTypes.string),
    desc3_txt: PropTypes.string
  })
}
export default React.memo(withTransition(CodeOfPractice));
