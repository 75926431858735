import styled from "styled-components";
import { mobileRange, tabletRange, ipadProRange} from "../../common/styles/breakpoints";
import { colors } from "../../common/styles/variables";

export const MainContainer = styled.div`
  background: ${colors.catskill_gray};
  padding: 65px 100px;

  .audio-player-modal {
    background-color: rgb(0, 66, 81);
    padding: 16px 24px 32px;
    @media ${mobileRange} {
      padding: 16px 4px;
    }
  }

  @media ${mobileRange} {
    margin-top: 40px;
    background: ${colors.white}
    padding: 0 20px;
  }
  @media ${tabletRange} {
    padding: 48px 0;
  }
  @media ${ipadProRange} {
    padding: 48px 0;
  }
`;

export const AdditionalResourceTitle = styled.h2`
  text-align: center;
  @media ${mobileRange} {
    margin-bottom: 20px;
    text-align: left;
    font-size: 22px;
  }
`;

export const TabWrapper = styled.div`
  border-bottom: 2px solid ${colors.bondi_green};
  display: flex;
  justify-content: center;
  margin: 25px 0;
  @media ${mobileRange} {
    display: block;
    margin: 40px 0 0;
  }
  @media ${tabletRange} {
    padding: 0 30px;
    margin: 50px 0 0;
  }
  @media ${ipadProRange} {
    padding: 0 30px;
    margin: 50px 0 0;
  }
`;

export const Tab = styled.div`
  display: flex;
  padding: 0 45px;
  font-size: 15px;
  cursor: pointer;
  color: ${props => props.active ? colors.white : colors.dove_gray};
  height: ${props => props.active ? "64px" : "54px"};
  margin-top: ${props => props.active ? "0" : "10px"};
  text-align: center;
  align-items: center;
  background: ${props => props.active ? colors.bondi_green : colors.white};
  border: 1px solid ${props => props.active ? colors.bondi_green : colors.light_dove_gray};
  border-radius: 5px 5px 0px 0px;
  transition: 0.6s;
  &: hover {
    background: ${props => props.active ? colors.bondi_green : colors.light_bondi_green};
  }
  @media ${mobileRange} {
    margin: 0;
    font-size: 16px;
    padding: 0;
    justify-content: center;
    height: ${props => props.active ? "64px" : "58px"};

  }
  @media ${tabletRange} {
    font-size: 16px;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
  }
  @media ${ipadProRange} {
    font-size: 16px;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const AdditonalCardContent = styled.div`
  width: 32%;
  display: flex;
  justify-content: center;
  @media ${mobileRange} {
    width: 100%;
  }
  @media ${tabletRange} {
    width: 45%;
  }
  @media ${ipadProRange} {
    width: 37%;
  }

`;
export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${props => props.isPodcast ? "16px" : "0"};
  @media ${mobileRange} {
    display: block;
    margin-top: 50px;
  }
  @media ${tabletRange} {
    margin-top: 50px;
  }
  @media ${ipadProRange} {
    margin-top: 50px;
  }
`;


export const ResourceContent = styled.div`
  @media ${mobileRange} {
    display: none;
  }
`;

export const MobileResourceContent = styled.div`
  display: none;
  @media ${mobileRange} {
    display: block;
  }
`;
