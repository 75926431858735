import LocationImage from "../../assets/images/icon_location.png";
import PostedImage from "../../assets/images/icon_date.png";
import ContactImage from "../../assets/images/icon_email.png";
import NoResults from "../../assets/images/no_results_found.png";

export default {
  "applyButtonText": "Apply Now",
  "phactmi_txt": "phactMI",
  "mainHeadingText": " Careers",
  "openPositionHeading": "Open Positions",
  "openPositionDescription": "The latest career openings across phactMI member company Medical Information Departments",
  "locationText": "Location: ",
  "contactSeperator": "|",
  "postedText": "Posted: ",
  "contactText": "Contact: ",
  "roleDescriptionText": "Role Description",
  "qualificationText": "Desired Qualifications",
  "locationImage": LocationImage,
  "postedImage": PostedImage,
  "contactImage": ContactImage,
  "locationImage_alt_txt": "Job Location - phactMI",
  "postedImage_alt_txt": "Job Posted Date - phactMI",
  "contactImage_alt_txt": "Contact Us Image - phactMI",


  "noResults": {
    "no_product_txt": "There are no open positions at this time.",
    "please_ensure_txt": "Please check this page again soon for new positions.",
    "no_results_img": NoResults,
    "no_results_alt_txt": "No Opening Image - phactMI"
  }
};
