import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const ManufacturersSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.light_darkish_blue};
    padding: 0 68px 25px;
    @media ${mobileRange} {
        padding: 0 20px;
    }
`;

export const ManufacturersHeading = styled.h3`
    font-size: 26px;
    color: ${colors.sherpa_blue};
    letter-spacing: -0.39px;
    padding: 60px 0 0;
    margin-bottom: 0;
    @media ${mobileRange} {
        padding: 50px 0 15px;
        font-size: 24px;
    }
    @media ${tabletRange} {
        padding: 50px 0 0;
        font-size: 22px;
    }
    @media ${ipadProRange} {
        padding: 50px 0 0;
        font-size: 24px;
    }
`;

export const ManufacturersContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    align-items: center;
    padding: 0 43px;
    @media ${mobileRange} {
        padding: 10px 0 49px 0;
    }
    @media ${tabletRange} {
        padding: 0 41px;
    }
    @media ${ipadProRange} {
        padding: 0 41px;
    }
`;

export const ManufacturersGrid = styled.div`
    display: flex;
    width: 100%;    
    overflow: auto;
    margin: 0 15px;
    scroll-behavior: smooth;
    height: 130px;
    padding: 0 17px;
    align-items: center;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media ${mobileRange} {
        padding: 0;
    }
`;

export const ManufacturersGridItem = styled.div`
    border: solid 1px ${colors.mercury};
    text-align: center;
    padding: 5px;
    margin: 7px;
    height: 77px;
    background: ${colors.white};
    @media ${mobileRange} {
        border: 1px solid ${colors.mercury};
        text-align: center;
        padding: 10px;
        margin: 6px;
        width: 158px;
        height: 71px;
    }
    @media ${tabletRange} {
        width: 140px;
        height: 56px;
        margin: 5px;
    }
    @media ${ipadProRange} {
        width: 199px;
        height: 80px;
        margin: 5px;
    }
    &:hover {
        box-shadow: 0 0 15px 0 ${colors.alto};
        transform: scale3d(1.15, 1.35, 1.4);
        border: solid 0 ${colors.mercury};
        transition-duration: 300ms;
        cursor: pointer;
    }
    img {
        height: 100%;
    }
`;

export const Arrow = styled.div`
    cursor: pointer;
    img {
        width: 15px;
    }
`;

export const MobileLogGrid = styled.div`
    display: flex;
    overflow: auto;
    height: 230px;
    width: 160px;
    margin: 0 30px;
`;

export const Row = styled.div`
    display: block;
`;

export const Column = styled.div`
    display: block;
    img {
        width: 160px;
    }
`;