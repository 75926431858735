import { colors } from "../../common/styles/variables";

export const srd = `
  &  * {
      font-family: Lato !important;
      text-indent: 0pt !important;
      color: ${colors.tundora};
    }
  & hi {
    background-color: ${colors.yellow_color};
  }
`;