import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import * as styled from "./styles";
import save from "../../assets/images/save.png";
import BreadCrumbs from "../breadCrumbs";

const SrdPage = (props) => {

  const {srdItemDetails, urlForSrdIFrame, resources, manufacturerDetails, history } = props;
  const { download_txt, source, product } = resources;

  const jsonUrl = srdItemDetails.linkToJson;
  const linkToPdfDownload = srdItemDetails.linkToPdf;
  const sourceName = srdItemDetails.sourceDisplayName;
  const products = srdItemDetails && srdItemDetails.products && srdItemDetails.products.join(", ");
  const {title} = srdItemDetails;
  let timer = null;

  const headerRef = useRef();
  const [id, setId] = useState("");

  useEffect(() => {
    const scrollToTop = (event) => {
      timer = setTimeout(() => {
        window.scrollTo(0, 0)
      }, 300);
      const role = JSON.parse(sessionStorage.getItem("selectedDegree")) ? JSON.parse(sessionStorage.getItem("selectedDegree")).role : "";
      window.dataLayer = window.dataLayer || [];
      if (!event.data.value) {
        return
      } else if (event.data.message == "sendData" && event.data.value != undefined ) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "webSrdClicks",
            "eventLabel": event.data.value.docName,
            "cd1": role,
            "cd2": "webSrdClicks",
            "cd3": event.data.value.docName, // doc name
            "cd5": event.data.value.sourceName, // source name
            "cd12": event.data.value.productName, // product name
            "cd13": "Cross reference", // link type
            "cd14": event.data.value.link, // link url
            "cd15": event.data.value.name // link name
          }
        })
        // setId(event.data.value.id)
        // window.scrollTo(0, 0)
      } else if (event.data.message == "sendNavData" && event.data.value.name != undefined) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "webSrdClicks",
            "eventLabel": event.data.value.docName,
            "cd1": role,
            "cd2": "webSrdClicks",
            "cd3": event.data.value.docName, // doc name
            "cd5": event.data.value.sourceName, // source name
            "cd12": event.data.value.productName, // product name
            "cd13": "Navbar link", // link type
            "cd14": "", // link url
            "cd15": event.data.value.name // link name
          }
        })
        // setId(event.data.value.id)
        // window.scrollTo(0, 0)
      } else if (event.data.message == "sendExternalLink" && event.data.value != undefined ) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "webSrdClicks",
            "eventLabel": event.data.value.docName,
            "cd1": role,
            "cd2": "webSrdClicks",
            "cd3": event.data.value.docName, // doc name
            "cd5": event.data.value.sourceName, // source name
            "cd12": event.data.value.productName, // product name
            "cd13": "External link", // link type
            "cd14": event.data.value.link, // link url
            "cd15": event.data.value.name // link name
          }
        })
        window.open(event.data.value.link);
      } else if (event.data.message == "sendImageData" && event.data.value.name != undefined) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "webSrdClicks",
            "eventLabel": event.data.value.docName,
            "cd1": role,
            "cd2": "webSrdClicks",
            "cd3": event.data.value.docName, // doc name
            "cd5": event.data.value.sourceName, // source name
            "cd12": event.data.value.productName, // product name
            "cd13": "Image", // link type
            "cd14": event.data.value.link, // link url
            "cd15": event.data.value.name // link name
          }
        })
      } else if (event.data.message == "sendCitationsData" && event.data.value.name != undefined) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventAction": "webSrdClicks",
            "eventLabel": event.data.value.docName,
            "cd1": role,
            "cd2": "webSrdClicks",
            "cd3": event.data.value.docName, // doc name
            "cd5": event.data.value.sourceName, // source name
            "cd12": event.data.value.productName, // product name
            "cd13": "Citations hover", // link type
            "cd14": "", // link url
            "cd15": event.data.value.name // link name
          }
        })
      }
    }
    window.addEventListener("message", scrollToTop)

    return () => {
      window.removeEventListener("message", scrollToTop);
      clearTimeout(timer)
    }
  }, [])

  // useEffect(() => {
  //   if (id != "" || id != undefined) {
  //     window.scrollTo(0, 0)
  //   }

  // }, [id])

  const downloadPdf = () => {
    fetch(`${linkToPdfDownload}`).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = window.document.createElement("a");
        alink.href = fileURL;
        const name = `${linkToPdfDownload}`.split("/").pop()
        alink.download = `${name}`;
        alink.click();
      })
    })
  }

  const onSourceClick = () => {
    const filteredMember = manufacturerDetails.find(detail => detail.companyName.toLowerCase().includes(sourceName.toLowerCase()))
    if (filteredMember) {
      window.open(filteredMember.medicalInformationLink)
    }
  }

  return (
    <div >
      <styled.ProductHeader ref={headerRef}>
        <styled.ProductTitle>{title.length > (window.innerWidth > 375 ? 45 : 35) ?
          <div>
            {title.substring(0, (window.innerWidth > 375 ? 45 : 35))}<styled.Tooltip>...<styled.TooltipText>{title}</styled.TooltipText></styled.Tooltip>
          </div> : title}
        </styled.ProductTitle>
        <styled.SourceContainer>
          <styled.LabelWrapper >
            <styled.Label>{source}</styled.Label>
            <styled.Label>{product}</styled.Label>
          </styled.LabelWrapper>
          <styled.ValueWrapper >
            <styled.SourceValue onClick={onSourceClick}>{sourceName}</styled.SourceValue>
            <styled.FormValue>{products.length > 17 ?
              <div>{products.substring(0, 17)}<styled.Tooltip>...<styled.ProductTooltipText>{products}</styled.ProductTooltipText></styled.Tooltip></div>
              : products}</styled.FormValue>
          </styled.ValueWrapper>
        </styled.SourceContainer>
      </styled.ProductHeader>
      <BreadCrumbs
        drugName={title}
        resources={resources}
        downloadPIDocument={downloadPdf}
        showDownload={true}
        showSearchDetails={props.SearchFunctionality}
      />
      <styled.ProductContent>
        <iframe src={urlForSrdIFrame}
          width="100%" height="700" className="iframe" style={{border: "none"}}>
        </iframe>
      </styled.ProductContent>

    </div>
  )
}

export default SrdPage;