import { setSearchData } from "../../components/auto-suggest-search/actions";

export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
export const GET_SUCCESS_SEARCH_RESULT = "GET_SUCCESS_SEARCH_RESULT";
export const GET_FAIL_SEARCH_RESULT = "GET_FAIL_SEARCH_RESULT";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const GET_PI_DETAILS = "GET_PI_DETAILS";
export const GET_PI_DETAILS_SUCCESS = "GET_PI_DETAILS_SUCCESS";
export const GET_PI_DETAILS_FAILURE = "GET_PI_DETAILS_FAILURE";
export const EMPTY_PI_DETAILS = "EMPTY_PI_DETAILS";
export const SET_FIRST_SEARCH = "SET_FIRST_SEARCH";
export const SET_EXPERT = "SET_EXPERT";
export const GET_MANUFACTURER_DETAILS = "GET_MANUFACTURER_DETAILS";
export const GET_SUCCESS_MANUFACTURER_DETAILS = "GET_SUCCESS_MANUFACTURER_DETAILS";
export const GET_FAIL_MANUFACTURER_DETAILS = "GET_FAIL_MANUFACTURER_DETAILS";
export const GET_SOURCE_NAME = "GET_SOURCE_NAME";
export const GET_SOURCE_NAME_SUCCESS = "GET_SOURCE_NAME_SUCCESS";
export const GET_SOURCE_NAME_FAIL = "GET_SOURCE_NAME_FAIL";
export const SET_DUMMY_SEARCH_RESULT = "SET_DUMMY_SEARCH_RESULT";
export const REMOVE_DUMMY_SEARCH_RESULT = "REMOVE_DUMMY_SEARCH_RESULT";
export const GET_SUMMARY = "GET_SUMMARY";
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAIL = "GET_SUMMARY_FAIL";
export const UNSET_EXPERT = "UNSET_EXPERT";
export const GET_DATA_USING_CODENAME = "GET_DATA_USING_CODENAME";
export const GET_DATA_USING_CODENAME_SUCCESS = "GET_DATA_USING_CODENAME_SUCCESS";
export const GET_DATA_USING_CODENAME_FAIL = "GET_DATA_USING_CODENAME";
export const ERROR_OCCUERD = "ERROR_OCCURED";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUMMARY = "CLEAR_SUMMARY";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const CLEAR_SEARCH_QUERY = "CLEAR_SEARCH_QUERY";

export function getSearchResult({ userInput, termInput, from, degree, description, sessionId, aId, fromOprx, fromAds, fromHcpPopup }) {
  return {
    type: GET_SEARCH_RESULT,
    payload: {
      "userInput": userInput,
      "termInput":termInput,
      "from": from,
      "degree": degree,
      "description": description,
      "sessionId": sessionId,
      aId,
      fromOprx,
      fromAds,
      fromHcpPopup
    }
  }
}

export function getPIDetails(productId, degree, description, sessionId, version) {
  return {
    type: GET_PI_DETAILS,
    payload: {
      productId,
      degree,
      description,
      sessionId,
      version
    }
  }
}

export function emptyPiDetails() {
  return {
    type: EMPTY_PI_DETAILS
  }
}

export function setSearchResult() {
  return {
    type: SET_SEARCH_RESULT
  }
}

export function getManufacturerDetails(productName) {
  return {
    type: GET_MANUFACTURER_DETAILS,
    payload: {
      productName
    }
  }
}

export function setFirstSearch() {
  return {
    type: SET_FIRST_SEARCH
  }
}

export function setExpert() {
  return {
    type: SET_EXPERT
  }
}

export function unSetExpert() {
  return {
    type: UNSET_EXPERT
  }
}

// export function getSourceName( companyDetail ) {
//   return {
//     type: GET_SOURCE_NAME,
//     payload: {
//       companyDetail
//     }
//   }
// }

export function setSearchQuery(value) {
  return {
    type: SET_SEARCH_QUERY,
    payload: {
      value
    }
  }
}

export function setDummySearchResult(value) {
  return {
    type: SET_DUMMY_SEARCH_RESULT,
    payload: {
      value
    }
  }
}

export function clearDummySearchResult() {
  return {
    type: REMOVE_DUMMY_SEARCH_RESULT
  }
}

export function getSummary(summaryUrl) {
  return {
    type: GET_SUMMARY,
    payload:{
      summaryUrl
    }
  }
}

export function getSectionNameUsingDataCode() {
  return {
    type: GET_DATA_USING_CODENAME
  }
}

export function errorOccured() {
  return {
    type: ERROR_OCCUERD
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  }
}

export function clearSummary() {
  return {
    type: CLEAR_SUMMARY
  }
}

export function clearSearchQuery() {
  return {
    type: CLEAR_SEARCH_QUERY
  }
}
export { setSearchData };
