export const GET_AUTO_SUGGEST = "GET_AUTO_SUGGEST";
export const GET_SUCCESS_AUTO_SUGGEST = "GET_SUCCESS_AUTO_SUGGEST";
export const GET_FAIL_AUTO_SUGGEST = "GET_FAIL_AUTO_SUGGEST";
export const UPDATE_SEARCH_DATA = "UPDATE_SEARCH_DATA";
export const SET_AUTO_SUGGEST = "SET_AUTO_SUGGEST";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";

export function getAutoSuggest({ userInput }) {
  return {
    type: GET_AUTO_SUGGEST,
    payload: {
      "userInput": userInput
    }
  }
}

export function updateSearchData(userInput) {
  return {
    type: UPDATE_SEARCH_DATA,
    payload: {
      userInput
    }
  }
}

export function setAutoSuggest() {
  return {
    type: SET_AUTO_SUGGEST
  }
}

export function setSearchData() {
  return {
    type: SET_SEARCH_DATA
  }
}
