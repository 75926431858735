import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_LATEST_NEWS, GET_SUCCESS_LATEST_NEWS, GET_FAIL_LATEST_NEWS, GET_PARTNERSHIP_DATA, GET_PARTNERSHIP_DATA_FAIL, GET_PARTNERSHIP_DATA_SUCCESS,
  NEWS_LETTER_SUBSCRIPTION, NEWS_LETTER_SUCCESS_SUBSCRIPTION, NEWS_LETTER_FAIL_SUBSCRIPTION, getPartnershipData
} from "./actions";

import { getLatestNews, newsLetterSubscription, getpartnershipData } from "./api";

export function* getLatestNewsDataWorker({ payload }) {
  try {
    const data = yield call(getLatestNews, payload);

    yield put({
      type: GET_SUCCESS_LATEST_NEWS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: GET_FAIL_LATEST_NEWS });
  }
}

export function* newsLetterSubscriptionDataWorker({ payload }) {
  try {
    const data = yield call(newsLetterSubscription, payload);
    yield put({
      type: NEWS_LETTER_SUCCESS_SUBSCRIPTION,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: NEWS_LETTER_FAIL_SUBSCRIPTION });
  }

}

export function* getLatestNewsDataWatcher() {
  yield takeLatest(GET_LATEST_NEWS, getLatestNewsDataWorker);
  yield takeLatest(NEWS_LETTER_SUBSCRIPTION, newsLetterSubscriptionDataWorker);
  yield takeLatest(GET_PARTNERSHIP_DATA, getPartnershipDataWorker);
}

export function* getPartnershipDataWorker() {
  try {
    const data = yield call(getpartnershipData, GET_PARTNERSHIP_DATA);
    yield put({
      type: GET_PARTNERSHIP_DATA_SUCCESS,
      payload: {
        result: data
      }
    })
  }
  catch (e) {
    yield put({ type: GET_PARTNERSHIP_DATA_FAIL })
  }
}

export default {
  getLatestNewsDataWatcher
};
