import React, { useState, useEffect } from "react";
import _size from "lodash/size";
import { withRouter } from "react-router-dom";
import * as styled from "./styles";
import { endpoint } from "../../config/app.config";
import resources from "../../config/content/AdditionalResource";

class MemberImageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLeftArrow: false,
      showRightArrow: true,
      isMobileDevice: false
    }
  }

  componentDidMount() {
    if (window.innerWidth < 695) {
      this.setState({ isMobileDevice: true })
    }
    window.addEventListener("resize", this.detectDisplay);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectDisplay);
  }

  detectDisplay = () => {
    if (window.innerWidth < 695) {
      this.setState({ isMobileDevice: true })
    }
    else {
      this.setState({ isMobileDevice: false });
    }
  }

  redirectToManufacturer = (manufacturerId, manufacturerName, history, companyURLTitle) => {
    history.push({
      pathname: `/members/${companyURLTitle}`,
      state: {
        _id: manufacturerId,
        _from: "members"
      }
    })
  }

  manageArrows = (ref) => {
    const SCROLL_NAVIGATE_VALUE = window.innerWidth >= 695 ? 290 : 160;
    if (ref.scrollLeft >= 0 && ref.scrollLeft < 50) {
      this.setState({
        showLeftArrow: false,
        showRightArrow: true
      })
    }
    else if (ref.scrollLeft >= SCROLL_NAVIGATE_VALUE && (ref.scrollLeft + ref.offsetWidth) < (ref.scrollWidth - 30)) {
      this.setState({
        showLeftArrow: true,
        showRightArrow: true
      })
    }
    else if ((ref.scrollLeft + ref.offsetWidth) > (ref.scrollWidth - 30)) {
      this.setState({
        showLeftArrow: true,
        showRightArrow: false
      })
    }
  }

  leftArrowClick = () => {
    const SCROLL_NAVIGATE_VALUE = window.innerWidth >= 695 ? 290 : 160;
    this.memberref.scrollLeft -= SCROLL_NAVIGATE_VALUE;
    setTimeout(() => {
      this.manageArrows(this.memberref)
    }, 400
    )
  }

  rightArrowClick = () => {
    const SCROLL_NAVIGATE_VALUE = window.innerWidth >= 695 ? 290 : 160;
    this.memberref.scrollLeft += SCROLL_NAVIGATE_VALUE;
    setTimeout(() => {
      this.manageArrows(this.memberref)
    }, 400)
  }

  displayManufactureLogo = (data) => {
    const { history, alt_txt_placeholder } = this.props;
    const { isMobileDevice } = this.state;
    if (!isMobileDevice) {
      return (
        <styled.ManufacturersGrid ref={(elm) => { this.memberref = elm }} className="logo-list">
          {data.map((datum, index) => (
            <styled.ManufacturersGridItem key={index} onClick={() => { this.redirectToManufacturer(datum._id, datum.companyName, history, datum.urlTitle) }} >
              <img src={`${endpoint}/api/assets/images?id=${datum.companyLogo}`} alt={`${alt_txt_placeholder}${datum.companyName}`} />
            </styled.ManufacturersGridItem>
          ))}
        </styled.ManufacturersGrid>
      )
    }
    else {
      const rowArray = [];
      const columnArray = [];
      for (let index = 0; index < _size(data); index = index + 3) {
        const content = [];
        for (let subindex = index; subindex < index + 3; subindex++) {
          if (subindex < _size(data)) {
            content.push(
              <styled.Column key={subindex} onClick={() => { this.redirectToManufacturer(data[subindex]._id, data[subindex].companyName, history, data[subindex].urlTitle) }}>
                <img src={`${endpoint}/api/assets/images?id=${data[subindex].companyLogo}`} alt={`${alt_txt_placeholder}${data[subindex].companyName}`}></img></styled.Column>
            );
          }
        }
        columnArray.push(content);
      }
      columnArray.map((element, index) => {
        rowArray.push(
          <styled.Row key={index}>{element}</styled.Row>
        )
      })
      return (
        <styled.MobileLogGrid ref={(elm) => { this.memberref = elm }}>
          {rowArray}
        </styled.MobileLogGrid>
      )
    }
  }

  render() {
    const { data, heading, history } = this.props;
    const { filterImages: { left_arrow_active, left_arrow_Inactive, right_arrow_active, right_arrow_Inactive } } = resources;
    const { showLeftArrow, showRightArrow } = this.state;
    return (
      _size(data) > 0 ?
        <styled.ManufacturersSection>
          <styled.ManufacturersHeading>
            {heading}
          </styled.ManufacturersHeading>
          <styled.ManufacturersContainer>
            <styled.Arrow>
              {
                showLeftArrow ?
                  <img src={left_arrow_active} onClick={this.leftArrowClick} />
                  :
                  <img src={left_arrow_Inactive} />
              }
            </styled.Arrow>
            {this.displayManufactureLogo(data)}
            <styled.Arrow>
              {
                showRightArrow ?
                  <img src={right_arrow_active} onClick={this.rightArrowClick} />
                  :
                  <img src={right_arrow_Inactive} />
              }
            </styled.Arrow>
          </styled.ManufacturersContainer>
        </styled.ManufacturersSection>
        : null
    );
  }
}

export default withRouter(MemberImageList);
